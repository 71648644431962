import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

class InterfaceBee2Link extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      nbSitesActifs: 0,
      nbSitesTotal: 0,
      error: '',
    }
  }

  componentWillMount() {
    this.fetchDatas()
  }

  fetchDatas() {
    fetch(localStorage.backoffice_api + '/api/interfaces/bee2Link/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then(async (response) => {
      if (!response.ok) {
        var message = (await response.json()).message;
        if(!message) message = response.statusText;
        throw Error(message);
      }
      return response.json();
    })
    .then((json) => {
      var nbSitesActifs = 0;
      for(var s in json.sites){
        if(json.sites[s].Actif)
          ++nbSitesActifs;
      }
      this.setState({
        nbSitesActifs: nbSitesActifs,
        nbSitesTotal: json.sites.length,
        loaded: true,
      });
    })
    .catch((error) => {
      this.setState({
        loaded: true,
        error: error.message
      })
      console.log(error)
    });
  }

  render() {
    if (!this.state.loaded) {
      return (<div className="col-sm-3">
        <div className="card bg-light mb-3">
          <div className="card-header">Bee2Link</div>
          <div className="card-body">
            <h4 className="card-title">Chargement...</h4>
            <p className="card-text text-center">
              <i className='fa fa-spinner fa-spin fa-fw'></i>
            </p>
          </div>
        </div>
      </div>)
    }

    if (this.state.loaded && this.state.nbSitesActifs > 0) {
      return (<div className="col-sm-3">
        <div className={"card mb-3 bg-success text-white"}>
          <div className="card-header">Bee2Link</div>
          <div className="card-body">
            <h4 className="card-title">{this.state.nbSitesActifs}/{this.state.nbSitesTotal} sites</h4>
            <p className="card-text">
              <Link to='interfaces/bee2Link' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
            </p>
          </div>
        </div>
      </div>)
    }

    if (this.state.loaded && this.state.error) {
      return (<div className="col-sm-3">
        <div className={"card mb-3 bg-secondary text-white"}>
          <div className="card-header">Bee2Link</div>
          <div className="card-body">
            <h4 className="card-title">Erreur</h4>
            <p className="card-text">
              {this.state.error}
            </p>
          </div>
        </div>
      </div>)
    }

    return (<div className="col-sm-3">
      <div className="card text-white bg-secondary mb-3">
        <div className="card-header">Bee2Link</div>
        <div className="card-body">
          <h4 className="card-title">Désactivé</h4>
          <Link to='interfaces/bee2Link' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
        </div>
      </div>
    </div>)
  }
}

export default withRouter(InterfaceBee2Link);
