import React, { Component } from 'react';
import Utils from '../../../Utils';
var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
        sites : [],
        sitesPVO : [],
        updated : false,
        alert : 'none',
        errorMessage: '',
    }
  }

  componentWillMount() {
    this.fetchSite();
    this.fetchSitePVO();
  }

  fetchSitePVO(){
    fetch(localStorage.backoffice_api + '/api/interfaces/planetVO2/sitesPVO', {
      method : 'GET',
      headers : {
        authorization : localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var bdgp = Utils.fromJWT('BDGp').toLowerCase().replace("bdgp", "").replace("v4", "");
      var isProd = lodash.startsWith(Utils.fromJWT('serverName').toLowerCase(), "prod-crm");
      var sites = lodash.sortBy(json.sitesPVO, (site) => {
        var pvoGpIdent = site.id.toLowerCase().split("_")[0];
        if(!isProd){
          //console.log(pvoGpIdent)
          if(lodash.includes(pvoGpIdent, "demopadi"))
            return " "+pvoGpIdent+" "+site.companyName+" "+site.label;
        }

        if(lodash.includes(pvoGpIdent, bdgp))
          return (" "+pvoGpIdent+" "+site.companyName.trim()+" "+site.label).toLowerCase();
        else 
          return (pvoGpIdent+" "+site.companyName.trim()+" "+site.label).toLowerCase();
      });

      this.setState({
        sitesPVO : sites
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSite(){
    fetch(localStorage.backoffice_api + '/api/interfaces/planetVO2/sites', {
      method : 'GET',
      headers : {
        authorization : localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        sites : lodash.sortBy(json.sites, (site) => {
          return site.Libelle_Plaque + ' ' + site.Descr
        })
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  submitConfig(event){
    event.preventDefault();
    window.scroll(0, 0);

    fetch(localStorage.backoffice_api + '/api/interfaces/planetVO2/sites', {
      method : 'PUT',
      headers : {
        authorization : localStorage.JWT,
        "Content-Type" : "application/json"
      },
      body:JSON.stringify({
          sites : this.state.sites
      })
    })
    .then(async (response) => {
      if (!response.ok) {
        throw Error(await response.text());
      }
      return response.json();
    })
    .then((json)=>{
        this.setState({
          alert : 'success'
        })
        setTimeout(
          function() {
            this.setState({
              alert : 'none'
            })
          }
          .bind(this),5000
        );
      }
    )
    .catch((error) => {
      error = JSON.parse(error.message)
      this.setState({
        alert: 'error',
        errorMessage: error.message
      })
      console.log(error)
    })
    .finally(() => {
      this.fetchSite()
    });
  }

  onChangeSitePVO2(e){
    let id = e.target.id
    let value = e.target.value
    let sites = this.state.sites
    var i = lodash.findIndex(sites, function (site) {return site.IDSite == id})
    if(value != 0){
      sites[i].update = true
      sites[i].IDSitePVO2 = value
    }else{
      sites[i].update = true
      sites[i].IDSitePVO2 = 0
      sites[i].ActifPVO2 = 0
    }
  
    this.setState({
        updated : true,
        sites : sites
    })
  }

  onClickActif(IDSite, e){
    let actif = e.target.checked
    let sites = this.state.sites
    let i = lodash.findIndex(sites, function (site) {return site.IDSite == IDSite})
    sites[i].update = true
    sites[i].ActifPVO2 = actif
  
    this.setState({
        updated : true,
        sites : sites
    })
  }

  render() {
    if(this.state.sites.length == 0){
      return <div className='container-fluid text-center'><i className="fa fa-refresh fa-spin fa-2x"></i></div>
    }

    return (
      <div className='container-fluid mb-4'>
        {this.state.alert == 'success' ? 
          <div className="alert alert-success" role="alert">Les modifications ont été prises en comptes.</div> : null}
        {this.state.alert == 'error' ?
          <div className="alert alert-danger" role="alert">Une erreur est survenue lors de la modification{this.state.errorMessage ? " : "+this.state.errorMessage : ""}</div> : null}
        <form onSubmit={(e) => this.submitConfig(e)}>

        <div className="row">
          <div className="col mt-1 mb-2">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th>Société</th>
                  <th>Site</th>
                  <th style={{width:'400px'}}>Site PVO2</th>
                  <th className="text-center">Actif</th>
                </tr>
              </thead>
              <tbody>
                {this.state.sites.map((site,index)=>{
                  return (
                    <tr key={index}>
                      <td>{site.Libelle_Plaque}</td>
                      <td>{site.Descr}</td>
                      <td>
                        <select className="form-control form-control-sm" style={{width:'400px'}} id={site.IDSite} value={site.IDSitePVO2 ? site.IDSitePVO2 : 0} onChange={this.onChangeSitePVO2.bind(this)}>
                          <option value={0}>-</option>
                          {this.state.sitesPVO.map((pvo,index)=>{
                            return <option key={index} value={pvo.id}>{pvo.id} - {pvo.companyName.toUpperCase()} "{pvo.label}"</option>
                          })}
                        </select>
                      </td>
                      <td className="text-center">
                        <input type="checkbox" checked={site.ActifPVO2 ? site.ActifPVO2 : false} onChange={this.onClickActif.bind(this, site.IDSite)} disabled={!site.IDSitePVO2} style={{verticalAlign:'middle'}}/>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
          </div>

          {this.state.updated ?
            <div className="row mb-2" style={{ marginTop: '15px' }}>
              <div className="col text-center">
                <button type="submit" className="btn btn-primary">
                  {this.state.saving ?
                    <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                  : null}
                  Enregistrer
                </button>
              </div>
            </div>
          : null}
        </form>
      </div>);
  }
}

export default Form;
