import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      config: null,
      updatedConfig: false,
      fetchInProgressConfig: false,
    }
  }

  componentWillMount() {
    this.fetchConfig()
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/smartDataForLead/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        config: json
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeConfig(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var config = this.state.config

    if (type === 'checkbox') {
      config[name] = event.target.checked
    }
    else {
      config[name] = value
    }

    this.setState({
      updatedConfig: true,
      config: config,
    })
  }

  handleSubmitConfig(event) {
    event.preventDefault();

    this.setState({
      fetchInProgressConfig: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/smartDataForLead/config', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.config)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgressConfig: false,
        })
        throw Error(response);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        config: null,
        updatedConfig: false,
        fetchInProgressConfig: false,
      }, () => {
        this.fetchConfig();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.config)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>SmartDataForLead</h2>

            <br />
            <form onSubmit={this.handleSubmitConfig.bind(this)}>
                <div className="row mb-3">
                  <div className="col">
                    <div className="card">
                      <div className="card-body p-2 pl-4 pt-3">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="GRP_SmartDataForLead" checked={this.state.config.GRP_SmartDataForLead} onChange={this.onChangeConfig.bind(this)} />
                            &nbsp; Activer l'interface SmartDataForLead
                            <small className="form-text text-muted">Si coché : activation de l'utilisation de l'api SmartDataForLead<br/>
                            Cela nécessite que les identifiants aient été renseignées</small>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.config.GRP_SmartDataForLead ?
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label htmlFor="GRP_SmartDataForLead_Login" className="col-sm-3 col-form-label">Login</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="GRP_SmartDataForLead_Login" name="GRP_SmartDataForLead_Login"  value={this.state.config.GRP_SmartDataForLead_Login}  onChange={this.onChangeConfig.bind(this)}/>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group row">
                      <label htmlFor="GRP_SmartDataForLead_Password" className="col-sm-3 col-form-label">Password</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="GRP_SmartDataForLead_Password" name="GRP_SmartDataForLead_Password"  value={this.state.config.GRP_SmartDataForLead_Password}  onChange={this.onChangeConfig.bind(this)}/>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group row">
                      <label htmlFor="GRP_SmartDataForLead_Password" className="col-sm-3 col-form-label">Délai</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="GRP_SmartDataForLead_Password" name="GRP_SmartDataForLead_Delai"  value={this.state.config.GRP_SmartDataForLead_Delai}  onChange={this.onChangeConfig.bind(this)}/>
                        <small className="form-text text-muted">Délai en nombre de jours entre chaque récupération via SmartDataForLead</small>
                      </div>
                    </div>
                  </div>
                </div>
                : null}
                {this.state.updatedConfig ?
                  <div className="row mb-2" style={{ marginTop: '15px' }}>
                    <div className="col text-center">
                      <button disabled={this.state.fetchInProgressConfig} type="submit" className="btn btn-primary">
                        {this.state.fetchInProgressConfig ?
                          <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                        : null}
                        Enregistrer
                      </button>
                    </div>
                  </div>
                : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);