export const receptionsLeads = [
  {
      "code": "CDV",
      "libelle": "Chefs des ventes"
  },
  {
      "code": "VEND",
      "libelle": "Vendeurs"
  },
  {
      "code": "BDC",
      "libelle": "BDC"
  }
]