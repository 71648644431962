import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'

var lodash = require('lodash')

class Kpi extends Component {

  constructor(props) {
    super(props);

    this.state = {
      affaires: [],
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
      fetchInProgress: true,
    }
  }

  componentWillMount() {
    this.fetchLeads()
  }

  fetchLeads() {
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/nissanSiebel/kpi?start_date='+this.state.start_date.format('YYYY-MM-DD HH:mm:ss')+'+&end_date='+this.state.end_date.format('YYYY-MM-DD HH:mm:ss'), {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var affaires = lodash.orderBy(json, (j) => {
        return j.DateDebut;
      }, 'desc')

      this.setState({
        affaires: affaires,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  displayStatutAffaire(affaire) {
    var statusAffaire = affaire.IDAffaireStatut;

    if (statusAffaire == 1) {
      return 'A valider';
    }
    else if (statusAffaire == 2) {
      return 'Annulée';
    }
    else if (statusAffaire == 3) {
      return 'En cours';
    }
    else if (statusAffaire == 7) {
      return 'Fusionnée';
    }
    else if (statusAffaire == 5) {
      return 'Gagnée';
    }
    else if (statusAffaire == 4) {
      var reason = '';
      if (affaire.AffaireRaisonStop_Libelle) {
        reason += ' ('+affaire.AffaireRaisonStop_Libelle+')';
      }
      return 'Perdue' + reason;
    }
    else if (statusAffaire == 6) {
      return 'Reportée';
    }

    return statusAffaire;
  }

  displayOffres(offres) {

    if (!offres.length) {
      return null;
    }

    return (<ul>
      {offres.map((offre) => {
        return <li key={offre.IDOffres}>{offre.IDOffres} - {offre.OFS_Descr}</li>;
      })}
    </ul>)
  }

  getFirstKpi(affaire) {

    let length = affaire.kpi.length ? affaire.kpi.length : 1;

    return (<tr key={affaire.CodeAffaireSource+'-0'}>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.CodeAffaireSource}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{moment(affaire.DateDebut).format('DD/MM/YYYY hh:mm')}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.DateOuvertureLead ? moment(affaire.DateOuvertureLead).format('DD/MM/YYYY hh:mm') : null}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.Name} {affaire.Firstname}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{this.displayStatutAffaire(affaire)}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{this.displayOffres(affaire.offres)}</td>
        {affaire.kpi[0] ? <td>{affaire.kpi[0].SalesCycle}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{moment(affaire.kpi[0].TDUpdateTimestamp).format('DD/MM/YYYY hh:mm')}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{affaire.kpi[0].DealerLeadReturnedDate ? moment(affaire.kpi[0].DealerLeadReturnedDate).format('DD/MM/YYYY') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{affaire.kpi[0].ConfirmedTestDriveDate ? moment(affaire.kpi[0].ConfirmedTestDriveDate).format('DD/MM/YYYY') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{affaire.kpi[0].ClosedDate ? moment(affaire.kpi[0].ClosedDate).format('DD/MM/YYYY') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{affaire.kpi[0].Comments}</td> : <td></td>}
      </tr>);
  }

  getKpi(affaire, k) {
    return (<tr key={k.CodeAffaireSource+'-'+k.IDNissanLeadSiebelOut}>
        <td>{k.SalesCycle}</td>
        <td>{moment(affaire.kpi[0].TDUpdateTimestamp).format('DD/MM/YYYY hh:mm')}</td>
        <td>{affaire.kpi[0].DealerLeadReturnedDate ? moment(affaire.kpi[0].DealerLeadReturnedDate).format('DD/MM/YYYY') : null}</td>
        <td>{affaire.kpi[0].ConfirmedTestDriveDate ? moment(affaire.kpi[0].ConfirmedTestDriveDate).format('DD/MM/YYYY') : null}</td>
        <td>{affaire.kpi[0].ClosedDate ? moment(affaire.kpi[0].ClosedDate).format('DD/MM/YYYY') : null}</td>
        <td>{k.Comments}</td>
      </tr>);
  }

  addMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).add(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).add(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  subMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).subtract(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).subtract(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  render() {


    if (this.state.fetchInProgress) {
      return null;
    }


    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>Nissan - Siebel</h2>

            <br />

            <h3>Leads</h3>

            <div className='row' style={{ marginBottom: '15px', }}>
              <div className="col-md-4 text-right">
                  <a onClick={this.subMonth.bind(this)}>
                    <i className="fa fa-chevron-left"></i>
                  </a>
              </div>
              <div className="col-md-4 text-center">
                Du <b>{this.state.start_date.format('DD/MM/YYYY')}</b> au <b>{this.state.end_date.format('DD/MM/YYYY')}</b>
              </div>
              <div className="col-md-4 text-left">
                <a onClick={this.addMonth.bind(this)}>
                  <i className="fa fa-chevron-right"></i>
                </a>
              </div>
            </div>

            <table className="table table-bordered table-sm" style={{'font-size': '11px'}}>
              <thead>
                <tr>
                  <th>Code affaire source</th>
                  <th>Date début affaire</th>
                  <th>Date ouverture affaire</th>
                  <th>Prospect</th>
                  <th>Status affaire</th>
                  <th>Offres</th>
                  <th>SalesCycle</th>
                  <th>TDUpdateTimestamp</th>
                  <th>DealerLeadReturnedDate</th>
                  <th>ConfirmedTestDriveDate</th>
                  <th>ClosedDate</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {this.state.affaires.map((affaire) => {

                  var content = []
                  content.push(this.getFirstKpi(affaire));

                  if (affaire.kpi.length > 1) {
                    affaire.kpi.map((k, i) => {
                      if (i == 0) {
                        return
                      }

                      content.push(this.getKpi(affaire, k));
                    })
                  }

                  return content;

                })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Kpi);
