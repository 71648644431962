import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profils: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchProfils();
  }

  fetchProfils() {
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var profils = lodash.sortBy(json, (profil) => {
        return profil.DESCR;
      })

      this.setState({
        profils: profils,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (

      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Profils eSeller</h2>

            <br />

            <div>
              <Link to='/profils/eSellerTeam/create' className='btn btn-light'>
                <i className="fa fa-clone"></i> Dupliquer un profil
              </Link>

              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par nom' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {this.state.profils.map((profil) => {
                if (this.state.filterName) {
                  if (profil.DESCR.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                    return false;
                  }
                }

                return (<tr key={profil.DRPROFILE_ID}>
                  <td>{profil.DRPROFILE_ID}</td>
                  <td>{profil.DESCR}</td>
                  <td className="text-right">
                    <Link to={'/profils/eSellerTeam/'+profil.DRPROFILE_ID} className='btn btn-primary btn-sm'>
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
