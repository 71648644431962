import React, { Component } from 'react';

var lodash = require('lodash')

class ModalResponsables extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchingUtilisateurs: false,
            saving: false,

            utilisateurs: [],
            IDsPeopleResponsables: [],
            IDPeopleSelected: '',
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.plaque && (!prevProps.plaque || prevProps.plaque.IDPlaque != this.props.plaque.IDPlaque))
        {
            this.setState({
                IDsPeopleResponsables: this.props.plaque.EstimationsRepriseResp.map(resp => {return resp.IDPeople})
            })
            this.fetchUtilisateurs();
        }
    }

    fetchUtilisateurs() {
        this.setState({
            utilisateurs: [],
            fetchingUtilisateurs: true
        })
        fetch(localStorage.backoffice_api + '/api/societes/'+this.props.plaque.IDPlaque+'/utilisateurs', {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((json) => {
            this.setState({
                utilisateurs: json,
                fetchingUtilisateurs: false
            });
        })
        .catch((error) => {
            console.log(error)
        });
    }

    addResponsable(){
        if(!this.state.IDPeopleSelected)
            return;

        var IDsPeopleResponsables = this.state.IDsPeopleResponsables;
        IDsPeopleResponsables.push(parseInt(this.state.IDPeopleSelected));

        this.setState({
            IDsPeopleResponsables: IDsPeopleResponsables
        })
    }

    removeResponsable(IDPeople){
        var IDsPeopleResponsables = this.state.IDsPeopleResponsables;
        IDsPeopleResponsables = lodash.without(IDsPeopleResponsables, IDPeople);

        this.setState({
            IDsPeopleResponsables: IDsPeopleResponsables
        })
    }

    submitReponsables(){
        this.setState({
            saving: true
        })
        fetch(localStorage.backoffice_api + '/api/estimationsReprise/'+this.props.plaque.IDPlaque+'/responsables', {
            method: 'POST',
            headers: {
                authorization: localStorage.JWT,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                IDsPeopleResponsables: this.state.IDsPeopleResponsables
            })
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            if(this.props.onSaved)
                this.props.onSaved();

            this.setState({
                saving: false
            })
            this.hide();
        })
        .catch((error) => {
            console.log(error)
        });
    }

    changeValue(name, value){
        this.setState({
            [name]:value
        });
    }

    show() {
        window.$("#modalResponsables").modal("show");
    }

    hide() {
        window.$("#modalResponsables").modal("hide");
    }

    render() {

        if(!this.props.plaque)
            return null;

        const responsables = lodash.filter(this.state.utilisateurs, (utilisateur) => {
            return this.state.IDsPeopleResponsables.indexOf(utilisateur.IDPeople) !== -1
        })

        return (
        <div className="modal" role="dialog" id="modalResponsables">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Responsables estimations de reprise {this.props.plaque.Libelle}</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    {this.state.fetchingUtilisateurs ? 
                        <div className="modal-body pb-5 text-center">
                            <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                        </div>
                        :
                        <div className="modal-body pb-1">
                            <div className="form-group row">
                                <div className="col-8">
                                    <select className="form-control" name="IDPeopleSelected" value={this.state.IDPeopleSelected} onChange={(e) => this.changeValue(e.target.name, e.target.value)}>
                                        <option value=''>Ajouter un responsable</option>
                                        {this.state.utilisateurs.map((utilisateur, i) => {
                                            if(this.state.IDsPeopleResponsables.indexOf(utilisateur.IDPeople) === -1)
                                                return <option key={i} value={utilisateur.IDPeople}>{utilisateur.Name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <button className='btn btn-light' onClick={this.addResponsable.bind(this)}>
                                        <i className='fa fa-plus'></i> Ajouter
                                    </button>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col">
                                    <table className="table table-striped table-sm table-hover">
                                        <thead>
                                            <tr>
                                                <th>Responsables</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {responsables.map((resp, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{resp.Name}</td>
                                                    <td className="text-right">
                                                        <button className='btn btn-light btn-sm' onClick={this.removeResponsable.bind(this, resp.IDPeople)}>
                                                            <i className='fa fa-minus'></i> Retirer
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {//Les utilisateurs qui seraient reponsables mais ne seraient feraient plus partie des utilisateurs de la plaque (ou seraient désactivés)
                                        this.props.plaque.EstimationsRepriseResp.map((resp, i) => {
                                            if(this.state.IDsPeopleResponsables.indexOf(resp.IDPeople) !== -1 && !lodash.find(this.state.utilisateurs, {'IDPeople': resp.IDPeople}))
                                            {
                                                return (
                                                    <tr key={i}>
                                                        <td>{resp.Name}</td>
                                                        <td className="text-right">
                                                            <button className='btn btn-light btn-sm' onClick={this.removeResponsable.bind(this, resp.IDPeople, true)}>
                                                                <i className='fa fa-minus'></i> Retirer
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }   
                    
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit'  data-dismiss="modal" id='submitEmail' onClick={() => this.submitReponsables()}>
                                {this.state.saving && <i className="fa fa-spin fa-spinner mr-1"/>}
                                Valider
                            </button>&nbsp;
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalResponsables;

