import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      isEdit: false,
      fetchUpdateInProgress: false,
      filterUtilisateursName: '',
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs()
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/interfaces/renault/csb/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      this.setState({
        utilisateurs: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteConfUser(IDPeople) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer la configuration de cet utilisateur ?')) {
      return;
    }

    fetch(localStorage.backoffice_api + '/api/interfaces/renault/CSB/utilisateurs/'+IDPeople, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateurs: null,
      }, () => {
        this.fetchUtilisateurs();
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeUser(IDPeople, event) {

    var utilisateurs = this.state.utilisateurs;

    var indexUtilisateur = lodash.findIndex(utilisateurs, (u) => {
      return u.IDPeople == IDPeople;
    })

    utilisateurs[indexUtilisateur][event.target.name] = event.target.value;
    utilisateurs[indexUtilisateur].isEdit = true;

    this.setState({
      utilisateurs: utilisateurs,
      isEdit: true,
    })
  }

  onSubmit(event) {

    event.preventDefault();

    fetch(localStorage.backoffice_api + '/api/interfaces/renault/CSB/utilisateurs', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.utilisateurs)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateurs: null,
      }, () => {
        this.fetchUtilisateurs();
      })

    })
    .catch((error) => {
      console.log(error)
    });

  }

  render() {

    if (!this.state.utilisateurs)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <div className='clearfix'>
              <h2 className='pull-left'>Renault - Interface CSB (Configurateur neuf)</h2>
            </div>

            <div>

              <div>
                <div className='float-right'>
                  <div className="form-inline float-left"  style={{ marginTop: '2px', marginRight: '4px' }}>
                    <div className="input-group" style={{ width: '220px' }}>
                      <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                      <input type='search' className='form-control form-control-sm float-right' value={this.state.filterUtilisateursName} onChange={(event) => this.setState({filterUtilisateursName: event.target.value})} placeholder={`Filtre par nom d'utilisateur`} />
                    </div>
                  </div>
                </div>

                <div className='clearfix'></div>
              </div>

              <br />

              {this.state.isEdit ?
                <div className='text-center'>
                    <button disabled={this.state.fetchUpdateInProgress} type="submit" className="btn btn-primary" onClick={this.onSubmit.bind(this)}>
                      {this.state.fetchUpdateInProgress ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                      : null}
                      Enregistrer les modifications
                    </button>
                  <br /><br />
                </div>
              : null}

              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>CSB Username</th>
                    <th>CSB Password</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.utilisateurs.map((utilisateur) => {

                    if (this.state.filterUtilisateursName) {
                      if (utilisateur.Name.toLowerCase().search(this.state.filterUtilisateursName.toLowerCase()) === -1) {
                        return false;
                      }
                    }
                    
                    return (<tr key={utilisateur.IDPeople}>
                      <td>{utilisateur.IDPeople}</td>
                      <td>{utilisateur.Name}</td>
                      <td>
                        <input name='username' className='form-control form-control-sm' value={utilisateur.username} onChange={this.onChangeUser.bind(this, utilisateur.IDPeople)} />
                      </td>
                      <td>
                        <input name='password' className='form-control form-control-sm' value={utilisateur.password} onChange={this.onChangeUser.bind(this, utilisateur.IDPeople)} />
                      </td>
                      <td>
                        {utilisateur.username || utilisateur.password ?
                          <button type="button" className='btn btn-danger btn-sm' data-toggle="tooltip" title="Supprimer la configuration de l'utilisateur" onClick={this.deleteConfUser.bind(this, utilisateur.IDPeople)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        : null}
                      </td>
                    </tr>)
                })}
                </tbody>
              </table>

              {this.state.isEdit ?
                <div className='text-center'>
                    <br />
                    <button disabled={this.state.fetchUpdateInProgress} type="submit" className="btn btn-primary" onClick={this.onSubmit.bind(this)}>
                      {this.state.fetchUpdateInProgress ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                      : null}
                      Enregistrer les modifications
                    </button>
                </div>
              : null}

            </div>

          </div>
        </div>
      </div>);
  }
}

export default withRouter(Index);
