import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import 'moment/locale/fr';
import moment from 'moment'
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      passerelles: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchPasserelles();
  }

  fetchPasserelles() {
    fetch(localStorage.backoffice_api + '/api/passerelles', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var passerelles = lodash.sortBy(json, (passerelle) => {
        return passerelle.Libelle;
      })

      this.setState({
        passerelles: passerelles,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (

      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Passerelles</h2>

            <br />

            <div>
              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par libellé' />
                  </div>
                </div>
              </div>

              <div className='clearfix' />
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Libellé</th>
                  <th>Commentaire</th>
                  <th>Date référence</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {this.state.passerelles.map((passerelle) => {
                if (this.state.filterName) {
                  if (passerelle.Libelle.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                    return false;
                  }
                }

                return (<tr key={passerelle.IDPasserelleDMS} className={(passerelle.DateHRefProchainTransfert ? '' : 'table-warning') + (moment(passerelle.DateHRefProchainTransfert) < moment().subtract(1, 'days') ? 'table-danger' : '')}>
                  <td>{passerelle.IDPasserelleDMS}</td>
                  <td>{passerelle.Libelle}</td>
                  <td>{passerelle.Commentaire}</td>
                  <td>{passerelle.DateHRefProchainTransfert ? moment(passerelle.DateHRefProchainTransfert).utc(0).format('L LT') : null}</td>
                  <td>
                    {!Utils.isAccesClient() ? 
                    <Link to={'/passerelles/'+passerelle.IDPasserelleDMS} className='btn btn-primary btn-sm'>
                      <i className="fa fa-pencil"></i>
                    </Link>
                    :null}
                    &nbsp;
                    {!Utils.isAccesClient() ? 
                    <Link to={'/passerelles/'+passerelle.IDPasserelleDMS+'/columns'} className='btn btn-primary btn-sm'>
                      <i className="fa fa-align-justify" aria-hidden="true"></i>
                    </Link>
                    :null}
                    &nbsp;
                    <Link to={'/passerelles/'+passerelle.IDPasserelleDMS+'/history'} className='btn btn-primary btn-sm'>
                      <i className="fa fa-history"></i>
                    </Link>
                  </td>
                </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
