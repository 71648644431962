import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statutJuridique: {
        "Descr": "",
        "Actif": true
      },
      updated: false,
      fetching: false,
      submitting: false,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchStatutJuridique(this.props.match.params.id)
    }
  }

  async fetchStatutJuridique(IDStatutJuridique) {
    try {
      this.setState({
        fetching: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/statutsJuridiques/' + IDStatutJuridique, {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })
      const statutJuridique = await response.json()

      this.setState({
        statutJuridique: statutJuridique,
      })
    }
    catch(error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetching: false
      })
    }
  }

  onChangeValue(event) {
    var statutJuridique = this.state.statutJuridique;

    if (event.currentTarget.type === 'checkbox') {
			statutJuridique[event.currentTarget.name] = event.currentTarget.checked
		}
		else {
			statutJuridique[event.currentTarget.name] = event.currentTarget.value
		}

    this.setState({
      updated: true,
      statutJuridique: statutJuridique
    })
  }

  canSubmit(){
    return (this.state.updated && !this.state.fetching && this.state.statutJuridique.Descr.trim() != '');
  }

  onSubmit(event) {
    event.preventDefault()

    if (!this.props.match.params.id) {
      this.createStatutJuridique();
    }
    else {
      this.updateStatutJuridique();
    }
  }

  async createStatutJuridique() {
    try {
      this.setState({
        submitting: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/statutsJuridiques', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.statutJuridique)
      })

      if(!response.ok) {
        throw Error(await response.json());
      }

      this.props.history.push('/statutsJuridiques')
    }
    catch(error) {
      console.log(error)
      alert(error)
      this.setState({
        submitting: false
      })
    }
  }

  async updateStatutJuridique() {
    try {
      this.setState({
        submitting: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/statutsJuridiques/'+this.state.statutJuridique.IDStatutJuridique, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.statutJuridique)
      })

      if(!response.ok) {
        throw Error(await response.json());
      }

      this.props.history.push('/statutsJuridiques')
    }
    catch(error) {
      console.log(error)
      alert(error)
      this.setState({
        submitting: false
      })
    }
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2 className="mb-4">Statuts juridiques</h2>

            {this.state.fetching ? 
              <div className="d-flex flex-row align-items-center justify-content-center">
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              </div>
            :
              <form onSubmit={(e) => this.onSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                      <div className="card-header">
                        {`${this.props.match.params.id ? "Modifier" : "Ajouter"} un type de société`}
                      </div>
                      <div className="card-body pb-3">
                        <div className="form-group row">
                          <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libellé</label>
                          <div className="col-sm-6">
                            <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.statutJuridique.Descr} onChange={(e) => this.onChangeValue(e)} maxLength="200" required='required' />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label htmlFor="Actif" className="col-sm-3 col-form-label">Actif</label>
                          <div className="col-sm-6 mt-1">
                            <input className="form-check-input" type="checkbox" name="Actif" checked={this.state.statutJuridique.Actif} onChange={(e) => this.onChangeValue(e)} />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col text-center">
                            <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                              {this.state.submitting ?
                                <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                              : null}
                              Valider
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            } 
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
