export const workflowsBDC = [
  {
    "code": "BdcToChefsVentes",
    "libelle": "Chefs des ventes",
  },
  {
    "code": "BdcToVendeurs",
    "libelle": "Vendeurs",
  },
  {
    "code": "BdcToChefsVentesVendeurs",
    "libelle": "Chefs des ventes et Vendeurs",
  },
  {
    "code": "BdcToPotCommunVendeurs",
    "libelle": "Pot commun vendeurs",
  },
  {
    "code": "BdcToChefsVentesVendeursPotCommunVendeurs",
    "libelle": "Chefs des ventes, Vendeurs et Pot commun vendeurs"
  }
]