import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import './Login.css';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchInProgress: false,
      login: '',
      password: '',
    }
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {

    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })
    
    var url = window.location.hostname;
    //console.log(url);

    fetch(process.env.REACT_APP_BACKOFFICE_API + '/api/authenticate', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify({login: this.state.login, url:url, password: this.state.password})
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      localStorage.JWT = 'JWT ' + json.token
      localStorage.backoffice_api = process.env.REACT_APP_BACKOFFICE_API
      var subdomain = url.split(".")[0];
      if(subdomain === "backoffice" || subdomain === "localhost" || subdomain === "dev")
        this.props.history.push('/DatabaseSelect')
      window.location.reload();
    })
    .catch((error) => {
      this.setState({
        fetchInProgress: false,
      })
      console.log(error)
    });
  }

  render() {

    return (
      <div style={{ backgroundColor: '#EEE', height: '100%' }}>
        <section className="login fdb-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-7 col-xl-5 text-center">
                <div className="fdb-box fdb-touch">
                  <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="row">
                      <div className="col">
                        <img src='./logo.png' alt='logo' />
                        <h1>Backoffice</h1>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <input type="text" className="form-control" placeholder="Nom d'utilisateur" name='login' onChange={this.onChange.bind(this)} />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <input type="password" className="form-control mb-1" placeholder="Mot de passe" name='password' onChange={this.onChange.bind(this)}  />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <button className="btn" type="submit" disabled={this.state.fetchInProgress}>
                        {this.state.fetchInProgress ?
                          <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                        : null}
                          Valider
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Login);
