import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../Utils';
import CustomerAccessMenu from './CustomerAccessMenu'
import AdminMenu from './AdminMenu'

class Header extends Component {

  disconnect() {
    delete localStorage.JWT;
    delete localStorage.bdgp_selected;
    delete localStorage.activites;
    window.location.href = '/';
    return false;
  }

  render() {
    const MenuComponent = Utils.isAccesClient() ? CustomerAccessMenu : AdminMenu;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark navbar-fixed-top" style={{ backgroundColor: '#9A0059', marginBottom: '30px' }}>
        <Link className="navbar-brand" to="/dashboard">eSeller Backoffice</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <MenuComponent onClickDisconnect={this.disconnect.bind(this)}/>
        </div>
      </nav>
    );
  }
}

export default Header;
