var jwt = require("jsonwebtoken");

export default class Utils {
  static isAccesClient(){
    var subdomain = window.location.hostname.toLowerCase().split(".");
    return subdomain[1] === "backoffice" && subdomain[0] !== "dev";
  }

  static fromJWT(prop) {
    var data = jwt.decode(localStorage.JWT.split(' ')[1]);
    return data[prop];
  }

  static isActivite(IDActivite) {
    return localStorage.activites && localStorage.activites.indexOf(IDActivite) !== -1;
  }

  static isNissan(){
    var data = jwt.decode(localStorage.JWT.split(' ')[1]);
    if(data && data["BDGp"] && data['BDGp'].includes("BDGpNi")){
      return true
    }
    return false
    
  }
}