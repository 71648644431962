import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Select from 'react-select';
import Utils from '../../Utils';
import FormDemande from '../FormDemande/Form';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societe: null,
      activites: [],
      marques: [],
      marquesComp: [],
      selectedMarques: '',
      updated: false,
      fetchInProgress: false,
      fetchSociete: false,
      redirect:false
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchSociete()
      this.fetchMarquesComp()
    }
    else {
      this.setState({societe: {
        IDActivite: 1,
        Libelle: '',
        EMailsContacts: '',
        DureeValid: 5,
        ImprPortableComOffre: true,
        NbJrsRelanceLiv: null,
        Affiche_TTC: true,
        LibRelanceLiv: '',
        InterfaceCorporateSales: false,
        JoursRapportAuto: '2,3,4,5,6,7',
        CreationOffreSansEtape: false,
      }}, () => {
        this.fetchMarques()
      })
    }

    this.fetchActivites()

    this.redirectList = this.redirectList.bind(this)
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchSociete) {
      this.fetchSociete()
      this.fetchMarquesComp()
    }
  }

  componentDidMount(){
    window.$('body').on('click', 'a.variable', function() {
      window.$('#TexteEmailOffre').val(window.$('#TexteEmailOffre').val() + ' ' + window.$(this).text());
      return false;
    });

    if(!this.props.match.params.id)
      this.generateTooltips();
  }

  fetchSociete() {

    this.setState({
      fetchSociete: true,
    })

    fetch(localStorage.backoffice_api + '/api/societes/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        societe: json,
      }, () => {
        this.fetchMarques();
        this.generateTooltips();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchMarquesComp() {
    fetch(localStorage.backoffice_api + '/api/societes/'+this.props.match.params.id + '/marques', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var marques = json.map((marque) => {
        return marque.Make
      }).join(',');

      this.setState({
        marquesComp: json,
        selectedMarques: marques,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchMarques() {
    if (!this.state.societe && this.state.societe.IDActivite) {
      return;
    }

    fetch(localStorage.backoffice_api + '/api/marques?IDActivite='+this.state.societe.IDActivite, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        marques: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchActivites() {
    fetch(localStorage.backoffice_api + '/api/activites/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        activites: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var societe = this.state.societe

    if (type === 'checkbox') {
      societe[name] = event.target.checked
    }
    else {
      societe[name] = value
    }

    this.setState({
      updated: true,
      societe: societe,
      marques: (name === 'IDActivite') ? null : this.state.marques,
    }, () => {
      if (name === 'IDActivite')
        this.fetchMarques()
    })
  }

  onChangeMarques(values) {
    var marques = values.map((value) => {
      return value.value
    }).join(',');

    this.setState({
      selectedMarques: marques,
      updated: true,
    })
  }

  handleSubmit(event) {
    console.log(this.state.redirect)
    event.preventDefault()
    
    this.setState({
      fetchInProgress: true,
    })
    
    if (!this.props.match.params.id) {
      this.createSociete();
    }
    else {
      this.editSociete();
    }
  }

  redirectList(choix){
    this.setState({
      redirect:choix
    })
  }

  createSociete() {
    fetch(localStorage.backoffice_api + '/api/societes/', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.societe)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var IDPlaque = json.IDPlaque
      fetch(localStorage.backoffice_api + '/api/societes/'+ IDPlaque + '/marques', {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.selectedMarques.split(','))
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            fetchInProgress: false,
          })
          throw Error(response);
        }

        this.setState({
          societe: null,
          updated: false,
          fetchInProgress: false,
        }, () => {
          if(this.state.redirect == true){
            this.props.history.push('/societes/')
          }else{
            this.props.history.push('/societes/' + IDPlaque)
          }
          
        })
      })
      .catch((error) => {
        console.log(error)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editSociete() {
    fetch(localStorage.backoffice_api + '/api/societes/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.societe)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      fetch(localStorage.backoffice_api + '/api/societes/'+this.props.match.params.id + '/marques', {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.selectedMarques.split(','))
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            fetchInProgress: false,
          })
          throw Error(response);
        }

        this.setState({
          updated: false,
          societe: null,
          fetchInProgress: false,
        }, () => {
          this.fetchSociete()
          this.fetchMarquesComp()
          if(this.state.redirect == true){
            this.props.history.push('/societes/')
          }
        })
      })
      .catch((error) => {
        console.log(error)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateTooltips() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  buttonSave(){
    return (
    <div className ="row justify-content-center">
                  {this.state.updated ?
                    <div className="row" style={{ marginTop: '15px', marginBottom:'15px', marginLeft:"10px" }}>
                      <div className="col text-center">
                        <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" onClick={this.redirectList.bind(this,false)}>
                          {this.state.fetchInProgress ?
                            <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                          : null}
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  : null}

                  {this.state.updated ?
                    <div className="row" style={{ marginTop: '15px', marginBottom:'15px', marginLeft:"10px" }}>
                      <div className="col text-center">
                        <button disabled={this.state.fetchInProgress} className="btn btn-primary" onClick={this.redirectList.bind(this,true)}>
                          {this.state.fetchInProgress ?
                            <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                          : null}
                          Enregistrer et revenir a la liste
                        </button>
                      </div>
                    </div>
                  : null}
              </div>)
  }

  render() {

    if (!this.state.societe) {
      return null;
    }

    if(Utils.isAccesClient()){
      if (!this.props.match.params.id) {
        return this.renderFormDemande();
      }
      else {
        return this.renderFormDemandeModif();
      }
    }

    return (
      <div className='container-fluid' id="formSociete">

          <h2>Sociétés</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libellé</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.societe.Libelle} onChange={this.onChange.bind(this)} maxLength="60" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDActivite" className="col-sm-3 col-form-label">Activité</label>
                  <div className="col-sm-6">
                    <select value={this.state.societe.IDActivite ? this.state.societe.IDActivite : ''} onChange={this.onChange.bind(this)} id='IDActivite' name="IDActivite" className="form-control form-control-sm" required='required'>
                      {this.state.activites.map((activite, i) => {
                        return <option key={i} value={activite.IDActivite}>{activite.Descr}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMailsContacts" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EMailsContacts" name="EMailsContacts" value={this.state.societe.EMailsContacts} onChange={this.onChange.bind(this)} maxLength="1000" />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMailsContacts" className="col-sm-3 col-form-label">Marques</label>
                  <div className="col-sm-6">
                  <Select multi={true} name="marques" value={this.state.selectedMarques} options={this.state.marques} searchable={true} placeholder='Marques...' onChange={this.onChangeMarques.bind(this)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDMiniGpOffre" className="col-sm-3 col-form-label">IDMiniGpOffre</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="IDMiniGpOffre" name="IDMiniGpOffre" value={this.state.societe.IDMiniGpOffre ? this.state.societe.IDMiniGpOffre : ''} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDMiniGpStock" className="col-sm-3 col-form-label">IDMiniGpStock</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="IDMiniGpStock" name="IDMiniGpStock" value={this.state.societe.IDMiniGpStock} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDMiniGpRapport" className="col-sm-3 col-form-label">IDMiniGpRapport</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="IDMiniGpRapport" name="IDMiniGpRapport" value={this.state.societe.IDMiniGpRapport ? this.state.societe.IDMiniGpRapport : ''} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="TitreRepriseOffre" className="col-sm-3 col-form-label">TitreRepriseOffre</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="TitreRepriseOffre" name="TitreRepriseOffre" value={this.state.societe.TitreRepriseOffre} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="DureeValid" className="col-sm-3 col-form-label">Durée de validité d'une offre en jour</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="DureeValid" name="DureeValid" value={this.state.societe.DureeValid} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="ImprPortableComOffre" checked={this.state.societe.ImprPortableComOffre} onChange={this.onChange.bind(this)} />
                        &nbsp; ImprPortableComOffre
                        <small className="form-text text-muted">Impression du numéro de portable du commercial sur l'offre</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="NbJrsRelanceLiv" className="col-sm-3 col-form-label">NbJrsRelanceLiv</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="NbJrsRelanceLiv" name="NbJrsRelanceLiv" value={this.state.societe.NbJrsRelanceLiv ? this.state.societe.NbJrsRelanceLiv : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours de relance après livraison dans le cas d'une seule relance sinon voir NbJrsRelanceLivMulti. Rapport créé automatiquement lors de la facture. Si Null pas de rapport créé</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="LibRelanceLiv" className="col-sm-3 col-form-label">LibRelanceLiv</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="LibRelanceLiv" name="LibRelanceLiv" value={this.state.societe.LibRelanceLiv ? this.state.societe.LibRelanceLiv : ''} onChange={this.onChange.bind(this)} maxLength='30' />
                    <small className="form-text text-muted">Libellé Relance Livraison (Action à faire) du rapport créé automatiquement lors de la facture</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="NbJrsRelanceLivMulti" className="col-sm-3 col-form-label">NbJrsRelanceLivMulti</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="NbJrsRelanceLivMulti" name="NbJrsRelanceLivMulti" value={this.state.societe.NbJrsRelanceLivMulti ? this.state.societe.NbJrsRelanceLivMulti : ''} onChange={this.onChange.bind(this)} maxLength='500' />
                    <small className="form-text text-muted">Nombre de jours de relances et libellés multiples après livraison sous la forme : LibelleTypeDeContact1-NbJoursRelance1-LibelleActionAFaire1;LibelleTypeDeContact2-NbJoursRelance2-LibelleActionAFaire2;... (ex : Facture-30-Relance Satisfaction;Facture-335-Relance Anniversaire 1 an;Facture-670-Relance Anniversaire 2 ans;)</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="AlerteNbJoursEnStock" className="col-sm-3 col-form-label">AlerteNbJoursEnStock</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="AlerteNbJoursEnStock" name="AlerteNbJoursEnStock" value={this.state.societe.AlerteNbJoursEnStock ? this.state.societe.AlerteNbJoursEnStock : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours en stock d'un véhicule à partir duquel un indicateur visuel s'affiche</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="NbJrsRelanceAvLiv" className="col-sm-3 col-form-label">NbJrsRelanceAvLiv</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="NbJrsRelanceAvLiv" name="NbJrsRelanceAvLiv" value={this.state.societe.NbJrsRelanceAvLiv ? this.state.societe.NbJrsRelanceAvLiv : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours de relance avant la livraison prévue du rapport créé automatiquement lors de la commande validée. Si Null pas de rapport créé</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="AlerteNbJoursEnStock" className="col-sm-3 col-form-label">AlerteNbJoursEnStock</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="AlerteNbJoursEnStock" name="AlerteNbJoursEnStock" value={this.state.societe.AlerteNbJoursEnStock ? this.state.societe.AlerteNbJoursEnStock : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours en stock d'un véhicule à partir duquel un indicateur visuel s'affiche</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="LibRelanceAvLiv" className="col-sm-3 col-form-label">LibRelanceAvLiv</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="LibRelanceAvLiv" name="LibRelanceAvLiv" value={this.state.societe.LibRelanceAvLiv ? this.state.societe.LibRelanceAvLiv : ''} onChange={this.onChange.bind(this)} maxLength='30' />
                    <small className="form-text text-muted">Libellé de relance avant livraison (Action à faire) du rapport créé automatiquement lors de la commande validée</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EmailEnvoiAutoBonDeCde" className="col-sm-3 col-form-label">EmailEnvoiAutoBonDeCde</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EmailEnvoiAutoBonDeCde" name="EmailEnvoiAutoBonDeCde" value={this.state.societe.EmailEnvoiAutoBonDeCde ? this.state.societe.EmailEnvoiAutoBonDeCde : ''} onChange={this.onChange.bind(this)} maxLength='500' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="UrlCotation" className="col-sm-3 col-form-label">UrlCotation</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="UrlCotation" name="UrlCotation" value={this.state.societe.UrlCotation ? this.state.societe.UrlCotation : ''} onChange={this.onChange.bind(this)} maxLength='255' />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="InterfaceCorporateSales" checked={this.state.societe.InterfaceCorporateSales} onChange={this.onChange.bind(this)} />
                        &nbsp; InterfaceCorporateSales
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="Affiche_TTC" checked={this.state.societe.Affiche_TTC} onChange={this.onChange.bind(this)} />
                        &nbsp; Affiche_TTC
                        <small className="form-text text-muted">Affiche les prix en TTC par défaut lors de la création d'une offre</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EmailAdministratifWelcomePro" className="col-sm-3 col-form-label">EmailAdministratifWelcomePro</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EmailAdministratifWelcomePro" name="EmailAdministratifWelcomePro" value={this.state.societe.EmailAdministratifWelcomePro ? this.state.societe.EmailAdministratifWelcomePro : ''} onChange={this.onChange.bind(this)} maxLength='255' />
                    <small className="form-text text-muted">Adresse e-mail pour recevoir les mails administratifs des infomédiaires comme Le Bon Coin, La Centrale,... quand on est abonné à Welcome-pro</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="NomFicBonDeCdeVO" className="col-sm-3 col-form-label">NomFicBonDeCdeVO</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="NomFicBonDeCdeVO" name="NomFicBonDeCdeVO" value={this.state.societe.NomFicBonDeCdeVO ? this.state.societe.NomFicBonDeCdeVO : ''} onChange={this.onChange.bind(this)} maxLength='60' />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="JoursRapportAuto" className="col-sm-3 col-form-label">JoursRapportAuto</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="JoursRapportAuto" name="JoursRapportAuto" value={this.state.societe.JoursRapportAuto ? this.state.societe.JoursRapportAuto : ''} onChange={this.onChange.bind(this)} maxLength='13' required='required' />
                    <small className="form-text text-muted">Jours autorisés où on peut générer un rapport automatique sous la forme "Jour1,Jour2,..."  (ex : 2,3,4,5,6,7)<br />1:Dimanche, 2:Lundi, 3:Mardi, 4:Mercredi, 5:Jeudi, 6:Vendredi, 7:Samedi</small>
                  </div>
                </div>
              </div>


              <div className="col">
                <div className="form-check">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" name="CreationOffreSansEtape" checked={this.state.societe.CreationOffreSansEtape} onChange={this.onChange.bind(this)} />
                    &nbsp; Creation offre sans étape
                  </label>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col">
                <div className="form-group row" id="formGroupTexteEmailOffre">
                  <label htmlFor="TexteEmailOffre" className="col-sm-3 col-form-label" >
                    TexteEmailOffre
                  </label>
                  <div className="col-sm-6 d-flex flex-direction-column">
                    <textarea rows="6" className="form-control form-control-sm" id="TexteEmailOffre" name="TexteEmailOffre" onChange={this.onChange.bind(this)} maxLength='8000' value={this.state.societe.TexteEmailOffre ? this.state.societe.TexteEmailOffre : ''}/>
                    <i id='helpTexteEmailOffre' className="fa fa-question-circle text-primary ml-2" data-container="#formSociete" data-toggle="tooltip" data-trigger="click" data-placement="top" data-html="true" title="
                        <b>Variables utilisables :</b><i className='fa fa-times float-right text-light' onclick={$('#helpTexteEmailOffre').click();}/><br/>
                        <a href='#' className='variable'>@CIVILITE_CLIENT</a><br/>
                        <a href='#' className='variable'>@NOM_CLIENT</a><br/>
                        <a href='#' className='variable'>@PRENOM_CLIENT</a><br/>
                        <a href='#' className='variable'>@NOM_COMMERCIAL</a><br/>
                        <a href='#' className='variable'>@PRENOM_COMMERCIAL</a><br/>
                        <a href='#' className='variable'>@NOM_SITE</a><br/>
                        <a href='#' className='variable'>@ADRESSE_SITE</a><br/>
                        <a href='#' className='variable'>@TEL_COMMERCIAL</a><br/>
                        <a href='#' className='variable'>@FAX_SITE</a><br/>
                        <a href='#' className='variable'>@PORTABLE_COMMERCIAL</a><br/>
                        <a href='#' className='variable'>@TEL_SITE</a><br/>
                        <a href='#' className='variable'>@EMAIL</a><br/>
                        <a href='#' className='variable'>@LOGO</a><br/>
                        <a href='#' className='variable'>@INFO_OFFRE2</a> : '...la proposition commerciale vu ensemble concernant votre intérêt pour le...'<br/>
                        <a href='#' className='variable'>@INFO_OFFRE</a> : '...notre meilleure proposition sur le...'
                      ">
                    </i>
                  </div>
                </div>
              </div>


              <div className="col">
                
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="RelanceApresClotureLead" checked={this.state.societe.RelanceApresClotureLead} onChange={this.onChange.bind(this)} />
                        &nbsp; Relance après clôture d'un lead
                        <small className="form-text text-muted">Proposer la création d'une relance après clôture d'un lead</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                {this.state.societe.RelanceApresClotureLead ?
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="RelanceApresClotureLeadObligatoire" checked={this.state.societe.RelanceApresClotureLeadObligatoire} onChange={this.onChange.bind(this)} />
                          &nbsp; Obligation de créer une relance après clôture du lead
                          <small className="form-text text-muted">Utilisé seulement si "Relance après clôture d'un lead" est coché</small>
                        </label>
                      </div>
                    </div>
                  </div>
                : null}
              </div>
            </div>

              {this.buttonSave()}
            
        </form>
      </div>
    );
  }

  renderAccesClient(){
    return (
      <div className='container-fluid'>

          <h2>Sociétés</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libellé</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.societe.Libelle} onChange={this.onChange.bind(this)} maxLength="60" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMailsContacts" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EMailsContacts" name="EMailsContacts" value={this.state.societe.EMailsContacts} onChange={this.onChange.bind(this)} maxLength="1000" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="DureeValid" className="col-sm-3 col-form-label">Durée de validité d'une offre en jour</label>
                  <div className="col-sm-6">
                    <input type="number" className="form-control form-control-sm" id="DureeValid" name="DureeValid" value={this.state.societe.DureeValid} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                
                </div>
              </div>
            </div>

            {this.buttonSave()}
            
        </form>
      </div>
    );
  }

  renderFormDemande(){
    return (
      <div className='container-fluid'>

          <h2>Sociétés</h2>
          <br />

          <FormDemande titre="Demande d'ajout d'une société" sujet="Création d'une société" type="ajout_societe"/>
      </div>
    );
  }

  renderFormDemandeModif(){
    return (
      <div className='container-fluid'>

          <h2>Sociétés</h2>
          <br />

          <FormDemande titre={"Demande de modification de la société "+this.state.societe.Libelle} sujet={"Modification d'une société ("+this.state.societe.Libelle+")"} type="modif_societe" societe={this.state.societe.Libelle}/>
      </div>
    );
  }
}

export default Form;
