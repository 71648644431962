import React, { Component } from 'react'

const lodash = require('lodash')

class PlafondsRemise extends Component {

    //Profils concernés avec leurs IDs (IDDRProfileEsellerLead)
    profils = {
        "VEND": 2,
        "CDV": 3
    }

    constructor(props) {
        super(props);

        this.state = {
            plafondsRemise: [],
            updatedPlafondsRemiseIds: [],
            societes: [],
            updated: false,
            gpParams: null,
            gpParamsUpdated: false
        }
    }

    componentDidMount() {
        this.fetchGpParams()
        this.fetchPlafondsRemise();
        this.fetchSocietes();
    }

    async fetchPlafondsRemise() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/plafondsRemise', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let plafondsRemise = await response.json();

            this.setState({
                plafondsRemise: plafondsRemise
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    async fetchSocietes() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/societes', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let societes = await response.json();
            societes = lodash.sortBy(societes, (societe) => {
                return societe.Libelle
            })

            this.setState({
                societes: societes
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    async fetchGpParams() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/gpParametres', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            this.setState({
                gpParams: await response.json()
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    onChange(IDSociete, IDProfil, e) {
        const pattern = /^((\d+(\.\d?)?|\.\d+)%?)?$/;
        if (!pattern.test(e.target.value)) {
            return;
        }

        let value = e.target.value
        if (Number(value) > 100) {
            value = 100
        }

        let plafondsRemise = this.state.plafondsRemise
        let updatedPlafondsRemiseIds = this.state.updatedPlafondsRemiseIds
        let idx = plafondsRemise.findIndex(p => p.IDPlaque == IDSociete && p.IDDRProfileEsellerLead == IDProfil)

        if (idx !== -1) {
            plafondsRemise[idx].Plafond = value
            if (!updatedPlafondsRemiseIds.includes(plafondsRemise[idx].IDPlafondRemise)) {
                updatedPlafondsRemiseIds.push(plafondsRemise[idx].IDPlafondRemise)
            }
        }
        else {
            plafondsRemise.push({
                IDPlaque: IDSociete,
                IDDRProfileEsellerLead: IDProfil,
                Plafond: value
            })
        }

        this.setState({
            plafondsRemise: plafondsRemise,
            updated: true,
            updatedPlafondsRemiseIds: updatedPlafondsRemiseIds
        })
    }

    onChangeActivationPlafondsRemise(e) {
        let gpParams = this.state.gpParams
        gpParams.GRP_PlafondsRemise = e.currentTarget.checked
        this.setState({
            gpParams: gpParams,
            gpParamsUpdated: true,
            updated: true
        })
    }

    async onSubmit() {
        this.setState({
            submitting: true
        })

        try {
            if(this.state.gpParamsUpdated) {
                await this.saveGpParams()
            }
            await this.savePlafondsRemise()

            this.setState({
                updated: false
            })
        }
        catch (error) {
            alert("Erreur lors de l'enregistrement")
            console.log(error)
        }
        finally {
            this.setState({
                submitting: false
            })
        }
    }

    async savePlafondsRemise() {
        const updatedPlafondsRemise = lodash.filter(this.state.plafondsRemise, p => !p.IDPlafondRemise || this.state.updatedPlafondsRemiseIds.includes(p.IDPlafondRemise))

        const response = await fetch(localStorage.backoffice_api + '/api/plafondsRemise', {
            method: 'PATCH',
            headers: {
                authorization: localStorage.JWT,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "plafondsRemise": updatedPlafondsRemise
            })
        })

        if (!response.ok) {
            throw Error(response.statusText);
        }

        this.setState({
            updatedPlafondsRemiseIds: []
        })
    }

    async saveGpParams() {
        await fetch(localStorage.backoffice_api + '/api/GpParametres', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(this.state.gpParams)
        })
    }

    render() {
        const plafondsRemiseParSociete = lodash.groupBy(this.state.plafondsRemise, p => p.IDPlaque)
        const plafondsRemiseGroupe = lodash.keyBy(this.state.plafondsRemise.filter(p => p.IDPlaque == null), "IDDRProfileEsellerLead")

        return (
            <div className='container-fluid mb-2'>
                <div className='row'>
                    <div className='col'>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h2>Plafonds de remise</h2>
                            <div>
                                {this.state.updated ?
                                    <div className="d-flex flex-row">
                                        <div className="alert alert-warning m-0 p-1 mr-1">
                                            <i className="fa fa-warning mr-1"></i>
                                            Modifications non sauvegardées
                                        </div>
                                        <button type="submit" className="btn btn-primary m-0 p-1" onClick={this.onSubmit.bind(this)}>
                                            {this.state.submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                            Valider
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div className="d-inline-flex flex-column w-auto">
                            <div className="card mb-3" style={{width: "fit-content"}}>
                                <div className="card-body p-3">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" checked={this.state.gpParams?.GRP_PlafondsRemise === true} onChange={this.onChangeActivationPlafondsRemise.bind(this)} />
                                            Activer les plafonds de remise
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {this.state.gpParams?.GRP_PlafondsRemise &&
                                <table className="table table-striped w-auto">
                                    <tbody>
                                        <tr>
                                            <th scope="col">
                                                Groupe<br />
                                                <small className="text-muted">Si aucun plafond n'est défini, alors le montant des remises est libre</small>
                                            </th>
                                            <th scope="col" className="align-bottom">Vendeurs</th>
                                            <th scope="col" className="align-bottom">Chefs de ventes</th>
                                            <th scope="col" className="d-none"></th>
                                        </tr>
                                        <tr>
                                            <td>Plafond de remise global au groupe pour les sociétés non configurées</td>
                                            {["VEND", "CDV"].map(profilKey => {
                                                const profilId = this.profils[profilKey]
                                                return (
                                                    <td key={profilKey}>
                                                        <input type="text" onChange={this.onChange.bind(this, null, profilId)} value={plafondsRemiseGroupe[profilId]?.Plafond ?? ""} style={{ width: '5em' }} /> %
                                                    </td>
                                                )
                                            })}
                                            <td className="border-0 bg-transparent">
                                                {plafondsRemiseGroupe[this.profils["VEND"]]?.Plafond > plafondsRemiseGroupe[this.profils["CDV"]]?.Plafond ?
                                                    <div>
                                                        <i className="fa fa-exclamation-triangle text-warning mr-2"/>
                                                        Le plafond de remise Vendeurs est supérieur au plafond de remise Chefs des ventes
                                                    </div>
                                                :null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">
                                                Sociétés <br />
                                                <small className="text-muted">Si aucun plafond n'est défini, alors le plafond du groupe s'applique</small>
                                            </th>
                                            <th scope="col" className="align-bottom">Vendeurs</th>
                                            <th scope="col" className="align-bottom">Chefs de ventes</th>
                                        </tr>
                                        {this.state.societes.map((societe) => {
                                            const plafondsParProfil = lodash.keyBy(plafondsRemiseParSociete[societe.IDPlaque], "IDDRProfileEsellerLead")
                                            const atLeastOneplafondIsDefined = !!plafondsRemiseParSociete[societe.IDPlaque]?.length
                                            return (
                                                <tr key={societe.IDPlaque}>
                                                    <td>{societe.Libelle}</td>
                                                    {["VEND", "CDV"].map(profilKey => {
                                                        const profilId = this.profils[profilKey]
                                                        return (
                                                            <td key={profilKey}>
                                                                <input type="text" onChange={this.onChange.bind(this, societe.IDPlaque, profilId)} value={plafondsParProfil[profilId]?.Plafond ?? ""} style={{ width: '5em' }} /> %
                                                            </td>
                                                        )
                                                    })}
                                                    <td className="border-0 bg-white">
                                                        {atLeastOneplafondIsDefined && Number((plafondsParProfil[this.profils["VEND"]] ?? plafondsRemiseGroupe[this.profils["VEND"]])?.Plafond) > Number((plafondsParProfil[this.profils["CDV"]] ?? plafondsRemiseGroupe[this.profils["CDV"]])?.Plafond) ?
                                                            <div>
                                                                <i className="fa fa-exclamation-triangle text-warning mr-2"/>
                                                                Le plafond de remise Vendeurs est supérieur au plafond de remise Chefs des ventes
                                                            </div>
                                                        :null}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>

                        <div className="row mt-4">
                            <div className="col text-center">
                                <button disabled={!this.state.updated} type="submit" className="btn btn-primary" onClick={this.onSubmit.bind(this)}>
                                    {this.state.submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default PlafondsRemise