import { Link } from "react-router-dom"
import React from "react"

function Index() {

    return <>
        <div className={"container-fluid mb-2"}>
            <div className={"row"}>
                <div className={"col"}>
                    <h2 className={"mb-4 d-flex justify-content-between"}>
                        <div>
                            Wally
                        </div>
                        <div style={{ fontSize: "0.6em" }} className={"text-secondary"}>
                            Actif de 7h à 20h du Lundi au samedi
                        </div>
                    </h2>

                    <div className="card my-4">
                        <div className="card-header" style={{ backgroundColor: "#ebedeb" }}>
                            Customer first
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to="/interfaces/wally/customer_first/comptes" className="list-group-item list-group-item-action">Compte</Link>
                            <Link to="/interfaces/wally/customer_first/point-de-vente" className="list-group-item list-group-item-action">Point de vente</Link>
                            <Link to="/interfaces/wally/customer_first/origines" className="list-group-item list-group-item-action">Origine</Link>
                            <Link to="/interfaces/wally/customer_first/logs" className="list-group-item list-group-item-action">Historique des intégrations</Link>
                        </ul>
                    </div>

                    <div className="card my-4">
                        <div className="card-header" style={{ backgroundColor: "#ebedeb" }}>
                            H-Way
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to="/interfaces/wally/hway/logs" className="list-group-item list-group-item-action">Historique des intégrations</Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Index