import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import moment from 'moment'
import { GrilleMalusMasse } from './Models/GrilleMalusMasse'
import { GrilleMalusMasseDTO } from "./Models/GrilleMalusMasseDTO"
import { GrilleMalusMasseValueDTO } from "./Models/GrilleMalusMasseValueDTO"
import lodash from "lodash"

export default function FormGrilleMalusEcologique() {

    const backofficeApiClient = new BackOfficeAPIClient()

    const [ fetching, setFetching ] = useState<boolean>(true)
    const [ submitting, setSubmitting ] = useState<boolean>(false)
    const [ updated, setUpdated ] = useState<boolean>(false)
    const [ error, setError ] = useState<string>()
    const [ message, setMessage ] = useState<string>()
    const [ formDto, setFormDto ] = useState<GrilleMalusMasseDTO>(new GrilleMalusMasseDTO())

    const { IDGrille } = useParams<{ IDGrille?: string }>()
    const history = useHistory()

    useEffect(() => {
        if (IDGrille) {
            fetchGrid()
        } else {
            setFetching(false)
        }
    }, [])

    const onChange = (changes: Partial<GrilleMalusMasseDTO>) => {
        setFormDto(prev => {
            return {
                ...prev,
                ...changes,
            }
        })
        setUpdated(true)
    }

    const onChangeLine = (key: string, changes: Partial<GrilleMalusMasseValueDTO>) => {
        setFormDto(prev => {
            const updatedValues = prev.Values.map(value =>
                value.Key === key
                    ? { ...value, ...changes }
                    : value,
            )

            return {
                ...prev,
                Values: updatedValues,
            }
        })

        setUpdated(true)
    }

    const fetchGrid = async () => {
        setFetching(true)

        try {
            const grid = await backofficeApiClient.get<GrilleMalusMasse>(`/malusMasse/${IDGrille}`)

            grid.Values.map((v) => {
                v.Key = lodash.uniqueId()
            })

            setFormDto(prev => {
                return {
                    ...prev,
                    IDGrilleMalusMasse: grid.IDGrilleMalusMasse,
                    Libelle: grid.Libelle,
                    DateDebut: moment(grid.DateDebut).format("YYYY-MM-DD"),
                    DateFin: moment(grid.DateFin).format("YYYY-MM-DD"),
                    Values: grid.Values,
                }
            })
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setFetching(false)
        }
    }

    const createGrid = async () => {
        setSubmitting(true)

        try {
            const result = await backofficeApiClient.post<{ IDGrilleMalusMasse: number }>("/malusMasse", formDto)
            history.push(`/malusMasse/${result.IDGrilleMalusMasse}`)
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setSubmitting(false)
        }
    }

    const updateGrid = async () => {
        setSubmitting(true)

        try {
            await backofficeApiClient.put(`/malusMasse/${formDto.IDGrilleMalusMasse}`, formDto)
            setSubmitting(false)
            setMessage("Modifications enregistées")
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setSubmitting(false)
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()

        setError("")
        setMessage("")
        if (formDto.IDGrilleMalusMasse) {
            updateGrid()
        } else {
            createGrid()
        }
    }

    const addLine = () => {
        const lastLine = formDto.Values[formDto.Values.length - 1]

        setFormDto(prev => {
            return {
                ...prev,
                Values: [ ...prev.Values, new GrilleMalusMasseValueDTO(lastLine.MasseMax! + 1) ],
            }
        })
    }

    const removeLastItem = () => {
        setFormDto(prev => {return { ...prev, Values: prev.Values.slice(0, -1) }})
    }

    return (
        <div className="container-fluid mb-2">
            <h2>Grille malus sur la masse {formDto.Libelle ? `: ${formDto.Libelle}` : ""}</h2>
            <br/>

            {error &&
                <div className="alert alert-danger">{error}</div>
            }
            {message &&
                <div className="alert alert-info">{message}</div>
            }

            {fetching ?
                <div className="d-flex flex-col align-items-center justify-content-center">
                    <i className="fa fa-spinner fa-spin fa-fw fa-2x"></i>
                </div>
                :
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="DateDebut" className="col-sm-1 col-form-label">Date début</label>
                                <div className="col-sm-2">
                                    <input type="date" onChange={(e) => onChange({ DateDebut: e.currentTarget.value })} value={formDto.DateDebut ?? ""} className="form-control form-control-sm"
                                           required id="dateDebut" name="dateDebut"/>
                                </div>
                                <label htmlFor="DateFin" className="col-sm-1 col-form-label">Date fin</label>
                                <div className="col-sm-2">
                                    <input type="date" onChange={(e) => onChange({ DateFin: e.currentTarget.value })} min={formDto.DateDebut} value={formDto.DateFin ?? ""}
                                           className="form-control form-control-sm" id="dateFin" name="dateFin"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="Libellé" className="col-sm-1 col-form-label">Libellé</label>
                                <div className="col-sm-3">
                                    <input type="text" onChange={(e) => onChange({ Libelle: e.currentTarget.value })} className="form-control form-control-sm" id="libelle" name="libelle"
                                           value={formDto.Libelle} maxLength={60} required/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-info">La valeur de la MasseMax de la dernière ligne ne doit pas être remplie pour pouvoir enregistrer.</div>
                    <table className="table table-striped table-hover table-sm">
                        <thead>
                        <tr>
                            <th>Masse min (kg)</th>
                            <th>Masse max (kg)</th>
                            <th>Montant taxe (€)</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {formDto.Values.map((v, idx) => {

                            const isMasseMaxDisabled = idx !== formDto.Values.length - 1
                            const canBeDeleted = idx === formDto.Values.length - 1 && idx !== 0

                            return (
                                <tr key={v.Key}>
                                    <td>{v.MasseMin ?? ""} </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control form-control-sm col-sm-3"
                                            value={v.MasseMax ?? ""}
                                            required={idx !== formDto.Values.length - 1}
                                            disabled={isMasseMaxDisabled}
                                            min={idx >= 1 ? v.MasseMin : undefined}
                                            onChange={(e) => {
                                                const value = e.currentTarget.value === "" ? undefined : Number(e.currentTarget.value)
                                                onChangeLine(v.Key, {
                                                    MasseMax: value,
                                                })
                                            }}/>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control form-control-sm col-sm-3"
                                            value={v.MontantTaxe ?? ""}
                                            required
                                            onChange={(e) =>
                                                onChangeLine(v.Key, {
                                                    MontantTaxe: Number(e.currentTarget.value),
                                                })
                                            }/>
                                    </td>
                                    <td>
                                        {canBeDeleted &&
                                            <div onClick={removeLastItem} className="btn btn-danger btn-sm text-white">
                                                <i className="fa fa-trash"></i>
                                            </div>
                                        }
                                    </td>
                                </tr>)
                        })}
                        </tbody>
                    </table>
                    <div className="row" style={{ marginTop: '15px' }}>
                        <div className="col text-left">
                            <button disabled={!formDto.Values[formDto.Values.length - 1].MasseMax} onClick={addLine} type={"button"} className="btn btn-light">
                                <i className="fa fa-plus"></i> Ajouter une ligne
                            </button>
                        </div>
                    </div>

                    {updated ?
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col text-center">
                                <button disabled={submitting || !!formDto.Values[formDto.Values.length - 1].MasseMax} type="submit" className="btn btn-primary">
                                    {submitting ?
                                        <span><i className="fa fa-spinner fa-spin fa-fw"></i>&nbsp;</span>
                                        : null}
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                        : null}
                </form>
            }
        </div>
    )

}