import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

var lodash = require('lodash')

class NissanRechercheCodeSite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codeSite: '',
      codeVendeur: '',
      vecturyID: '',
      results: [],
      fetchInProgress: false,
      searchMode: '',
      servers:[],
    }
  }

  componentDidMount() {
    this.fetchServers();
  }

  search() {
      fetch(localStorage.backoffice_api + '/api/nissan/' + (this.state.searchMode === 'site' ?  'searchCodeSite' : 'searchVendeur'), {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
          codeSite: this.state.codeSite,
          codeVendeur: this.state.codeVendeur,
          vecturyID: this.state.vecturyID
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var results = lodash.sortBy(json, ['serveurGp', 'BDGp', 'CodeSite', 'CodeVendeur'])
      this.setState({
        results: results,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchServers() {
    fetch(process.env.REACT_APP_BACKOFFICE_API + '/api/servers', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      
      this.setState({
        servers: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeValue(event) {
    this.setState({
      [event.target.name]: event.target.value,
      results: [],
    }, () => {
      this.setState({
        searchMode: this.searchMode(),
      })
    });
  }

  handleSubmit(event) {

    event.preventDefault()

    this.setState({
        fetchInProgress: true,
    })

    this.search();
  }

  canSubmit() {
    return (this.state.codeSite && this.state.codeSite.length) || (this.state.codeVendeur && this.state.codeVendeur.length) || (this.state.vecturyID && this.state.vecturyID.length)
  }

  searchMode(){
    if(this.state.codeSite && this.state.codeSite.length)
      return 'site';
    else if((this.state.codeVendeur && this.state.codeVendeur.length) || (this.state.vecturyID && this.state.vecturyID.length))
      return 'vendeur';
  }

  selectClient(idx) {
    var result = this.state.results[idx];

    var server = lodash.find(this.state.servers, (server) => {
      return server.name.toLowerCase() === result.ServeurGp.toLowerCase();
    });

    if(server){
      fetch(localStorage.backoffice_api + '/api/adminSelectDatabase', {
        method: 'POST',
        headers: {
          server: server.ip,
          bdgp: result.BDGp,
          authorization: localStorage.JWT,
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        localStorage.JWT = 'JWT ' + json.token;
        localStorage.bdgp_selected = 1;
        this.props.history.push('/dashboard')
      })
      .catch((error) => {
        console.log(error)
      });
    }
    else {
      alert("Adresse IP du serveur "+result.ServeurGp+" non trouvée.");
    }
    return false;
  }

  render() {

    return (
      <div className='container' style={{ marginTop: '50px' }}>
        <div className='row justify-content-center'>
          <div className='col-md-12 text-center'>
            <h3 style={{ marginBottom: '20px' }}>Nissan - Recherche Code Site / Vendeur</h3>

            <form className="form-inline" onSubmit={this.handleSubmit.bind(this)}>

              <div className="form-group" style={{ width: '100%', }}>
                <div className="col-sm-4" style={{ marginTop: '15px' }}>
                    Code site
                    <br/>
                    <input readOnly={this.state.codeVendeur != '' || this.state.vecturyID != ''} type='text' className='form-control' value={this.state.codeSite} name="codeSite" onChange={this.onChangeValue.bind(this)} placeholder='030102'/>
                </div>
                <div className="col-sm-4" style={{ marginTop: '15px' }}>
                    Code vendeur
                    <br/>
                    <input readOnly={this.state.codeSite != '' || this.state.vecturyID != ''}  type='text' className='form-control' value={this.state.codeVendeur} name="codeVendeur" onChange={this.onChangeValue.bind(this)} placeholder='FRD01234'/>
                </div>
                <div className="col-sm-4" style={{ marginTop: '15px' }}>
                    Vectury ID
                    <br/>
                    <input readOnly={this.state.codeVendeur != '' || this.state.codeSite != ''}  type='text' className='form-control' value={this.state.vecturyID} name="vecturyID" onChange={this.onChangeValue.bind(this)} placeholder='d123456'/>
                </div>
              </div>

              {this.canSubmit() ?
                <div className='text-center' style={{ width: '100%', marginTop:'30px' }}>
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" style={{ marginTop: '15px', width: '50%' }}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Valider
                  </button>
                </div>
              : null}

            </form>

          </div>

            {this.state.results.length ?
                <div className='col-lg-12 col-md-12 text-center'>
                    <br />
                    <br />
                    {this.state.searchMode === 'site' ?
                      <table className='table table-striped table-hover table-sm'>
                          <thead>
                              <tr>
                                  <th>ServeurGp</th>
                                  <th>BDGp</th>
                                  <th>Code site</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.results.map((result, index) => {
                                  return <tr key={index}>
                                      <td>{result.ServeurGp}</td>
                                      <td>{result.BDGp}</td>
                                      <td>{result.Login.replace('NI_52', '')}</td>
                                      <td className="text-right">
                                          <a href='#' className='btn btn-primary btn-sm' onClick={() => this.selectClient(index)}>
                                             Choisir ce client
                                          </a>
                                      </td>
                                  </tr>
                              })}
                              
                          </tbody>
                      </table>
                    :this.state.searchMode === 'vendeur' ?
                      <table className='table table-striped table-hover table-sm'>
                          <thead>
                              <tr>
                                  <th>ServeurGp</th>
                                  <th>BDGp</th>
                                  <th>Code vendeur</th>
                                  <th>Vectury ID</th>
                                  <th>Nom</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              {this.state.results.map((result, index) => {
                                  return <tr key={index}>
                                      <td>{result.ServeurGp}</td>
                                      <td>{result.BDGp}</td>
                                      <td>{result.CodeVendeur}</td>
                                      <td>{result.VecturyID}</td>
                                      <td>{result.Nom} {result.Prenom}</td>
                                      <td className="text-right">
                                          <a href='#' className='btn btn-primary btn-sm' onClick={() => this.selectClient(index)}>
                                             Choisir ce client
                                          </a>
                                      </td>
                                  </tr>
                              })}
                              
                          </tbody>
                      </table>
                    :null}
                </div>
            : null}
        </div>
      </div>
    );
  }
}

export default withRouter(NissanRechercheCodeSite);
