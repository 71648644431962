import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bdc: [],
      allBdc: [],
      filter: '',
    }
  }

  componentWillMount() {
    this.fetchBdc();
  }

  fetchBdc() {
    fetch(localStorage.backoffice_api + '/api/BonDeCommande', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        bdc: json,
        allBdc: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  filter(event){
    var filter = event.target.value;
    var bdc = lodash.filter(this.state.allBdc, (d) => {
      return d.Descr.toLowerCase().search(filter.toLowerCase()) !== -1;
    });
    this.setState({
      bdc: bdc,
      filter: filter
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Bons de commande (MarqueNeufOcc)</h2>

            <br/>

            <div>
              <Link to='/BonDeCommande/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filter} onChange={(e) => this.filter(e)} placeholder='Filtre par description' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Activité</th>
                  <th>Description</th>
                  <th>Neuf</th>
                  <th>Fichier BDC</th>
                  <th>Fichier CGV</th>
                  <th>Fichier Autre</th>
                  <th>Image logo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.bdc.map((ligne) => {
                  return (<tr key={ligne.IDMarqueNeufOcc}>
                    <td>{ligne.IDMarqueNeufOcc}</td>
                    <td>{ligne.Activite_Descr}</td>
                    <td>{ligne.Descr}</td>
                    <td>{ligne.Neuf ? 'Oui' : 'Non'}</td>
                    <td>{ligne.NomFicBonDeCommande}</td>
                    <td>{ligne.NomFicConditionsGenVente}</td>
                    <td>{ligne.NomFicAutreDoc}</td>
                    <td>{ligne.NomImageLogo}</td>
                    <td className="text-right">
                      <Link to={'/BonDeCommande/'+ligne.IDMarqueNeufOcc} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>
            <br/>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
