import React, { Component } from 'react';
import { TypeClient } from '../../enums/TypeClient';

const lodash = require('lodash')

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            champs: [],
            configs: [],
            fetching: false,
            submitting: false,
            message:""
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    async fetchData() {
        this.setState({
            fetching: true
        })

        await this.fetchChampsFicheClient()
        await this.fetchChampsFicheClientConfigs()

        this.setState({
            fetching: false
        })
    }

    async fetchChampsFicheClient() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/champsFicheClient', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            var champs = (await response.json())
            champs = lodash.sortBy(champs, (champ) => {
                return champ.Description.toLowerCase();
            })

            this.setState({
                champs: champs,
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    async fetchChampsFicheClientConfigs() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/champsFicheClient/configurations', {
                method: 'GET',

                headers: {
                    authorization: localStorage.JWT,
                }
            })
            var configs = (await response.json())
            this.setState({
                configs: configs
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    isChecked(IDChampsFicheClient) {
        return lodash.filter(this.state.configs, (champ) => {
            return champ.IDChampsFicheClient === IDChampsFicheClient;
        }).length ?? true
    }

    async addChamps(IDChampsFicheClient) {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/champsFicheClient', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.JWT,
                },
                body: JSON.stringify({
                    "IDChampsFicheClient": IDChampsFicheClient
                })
            })
            var res = (await response.json())

            if (res) {
                this.setState({
                    configs: [...this.state.configs, { IDChampsFicheClient: IDChampsFicheClient }]
                })
            }
        } catch (error) {
            this.setState({
                message: "Erreur lors de l'ajout du champ"
            })
            setTimeout(
                function () {
                    this.setState({
                        message: ""
                    })
                }.bind(this), 3000
            );
            console.log(error)
        }
    }

    async deleteChamps(IDChampsFicheClient) {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/champsFicheClient', {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.JWT,
                },
                body: JSON.stringify({
                    "IDChampsFicheClient": IDChampsFicheClient
                })
            })
            var res = (await response.json())

            if (res) {
                this.setState({
                    configs: this.state.configs.filter((champ) => champ.IDChampsFicheClient !== IDChampsFicheClient)
                })
            }
        }
        catch (error) {
            this.setState({
                message: "Erreur lors de la suppression du champ"
            })
            setTimeout(
                function () {
                    this.setState({
                        message: ""
                    })
                }.bind(this), 3000
            );
            console.log(error)
        }
    }

    onChangeChecked(e, IDChampsFicheClient) {
        if (e.currentTarget.checked) {
            this.addChamps(IDChampsFicheClient)
        } else {
            this.deleteChamps(IDChampsFicheClient)
        }
    }

    render() {
        console.log(this.state.configs)
        return (
            <div className='container-fluid mb-2'>
                <div className="row">
                    <div className="col">

                        <h2 className="mb-4">Champs fiche client</h2>
                        {this.state.message !== "" ?
                            <div className="alert alert-danger" role="alert">{this.state.message}</div>
                            : null}

                        {this.state.fetching ?
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <i className='fa fa-spinner fa-spin fa-fw'></i>
                            </div>
                            :
                            <div className='row mx-4 '>
                                <div className="col-6 text-center border-right">
                                    <h3 className='mb-4'>Particulier</h3>
                                    <table className="table table-striped table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>Afficher</th>
                                                <th>Description</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.champs.filter((c) => c.IDTFicheCli == TypeClient.Particulier).map((champ) => {
                                                return (
                                                    <tr >
                                                        <td>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="GRP_EstEnGestionTTC"
                                                                checked={this.isChecked(champ.IDChampsFicheClient)}
                                                                onChange={(e) => this.onChangeChecked(e, champ.IDChampsFicheClient)}
                                                            />
                                                        </td>
                                                        <td>{champ.Description}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6 text-center">
                                    <h3 className='mb-4' >Professionnel</h3>
                                    <table className="table table-striped table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th>Afficher</th>
                                                <th>Description</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.champs.filter((c) => c.IDTFicheCli == TypeClient.Societe).map((champ) => {
                                                return (
                                                    <tr >
                                                        <td>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="GRP_EstEnGestionTTC"
                                                                checked={this.isChecked(champ.IDChampsFicheClient)}
                                                                onChange={(e) => this.onChangeChecked(e, champ.IDChampsFicheClient)}
                                                            />
                                                        </td>
                                                        <td>{champ.Description}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
