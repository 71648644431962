import React, { Component } from 'react';
import Select from 'react-select'

const lodash = require('lodash')

class SitesConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sites: [],
            fetching: false,
            templatesImpressionOffreOptList: [],
            templatesImpressionBdcOptList: [],
        }
    }

    async componentDidMount() {
        this.fetchSites()

        //On retire des listes les templates configurés sur le groupe
        const IDsTemplateImpressionOffreGroupe = this.props.configs.filter(c => c.Categorie == "offre" && c.IDSite === null).map(c => c.IDTemplateImpression)
        const IDsTemplateImpressionBdcGroupe = this.props.configs.filter(c => c.Categorie == "bdc" && c.IDSite === null).map(c => c.IDTemplateImpression)
        this.setState({
            templatesImpressionOffreOptList: this.props.templatesImpressionOffreOptList.filter(c => !IDsTemplateImpressionOffreGroupe.includes(c.value)),
            templatesImpressionBdcOptList: this.props.templatesImpressionBdcOptList.filter(c => !IDsTemplateImpressionBdcGroupe.includes(c.value)),
        }) 
    }

    async fetchSites() {
        try {
            this.setState({
                fetching: true
            })
            const response = await fetch(localStorage.backoffice_api + '/api/sites', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })
            var sites = (await response.json())
            sites = lodash.filter(sites, site => site.Est_eSellerLeadActif && !site.EstFictif);
            lodash.each(sites, (site) => {
                if (site.Plaque_Libelle != site.Libelle) {
                    site.Libelle = site.Plaque_Libelle + " - " + site.Libelle;
                }
            })
            sites = lodash.sortBy(sites, (site) => {
                return site.Libelle;
            })
            this.setState({
                sites: sites,
            })
        }
        catch (error) {
            console.log(error)
        }
        finally {
            this.setState({
                fetching: false
            })
        }
    }

    getSelectedTemplatesOffre(IDSite) {
        return this.props.configs.filter(c => c.Categorie == "offre" && c.IDSite == IDSite).map(c => c.IDTemplateImpression)
    }

    getSelectedTemplatesBdc(IDSite) {
        return this.props.configs.filter(c => c.Categorie == "bdc" && c.IDSite == IDSite).map(c => c.IDTemplateImpression)
    }

    getTemplateOffreDescription(IDTemplateImpression) {
        return <span style={{backgroundColor: "#DEF"}} className="border border-primary p-1 rounded mx-1">{this.props.templatesImpressionOffreOptList.find(t => t.value == IDTemplateImpression)?.label}</span>
    }

    getTemplateBdcDescription(IDTemplateImpression) {
        return <span style={{backgroundColor: "#DEF"}} className="border border-primary p-1 rounded mx-1">{this.props.templatesImpressionBdcOptList.find(t => t.value == IDTemplateImpression)?.label}</span>
    }

    render() {
        return (
            <div>
                <div className="alert alert-primary d-flex flex-column">
                    <div className="mb-1">
                        <i className='fa fa-info-circle mr-2'/>
                        Configuration par site : les templates disponibles pour chaque site en plus des templates du groupe.<br/>
                    </div>
                    <b>Templates du groupe : </b>
                    <div className="my-1">Offre : {this.props.configs.filter(c => c.Categorie == "offre" && c.IDSite === null).map(c => this.getTemplateOffreDescription(c.IDTemplateImpression))}</div>
                    <div className="my-1">Bdc : {this.props.configs.filter(c => c.Categorie == "bdc" && c.IDSite === null).map(c => this.getTemplateBdcDescription(c.IDTemplateImpression))}</div>
                </div>

                {this.state.fetching ?
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <i className='fa fa-spinner fa-spin fa-fw'></i>
                    </div>
                    :
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Site</th>
                                <th scope="col" style={{ width: '30%' }}>Templates Offre</th>
                                <th scope="col" style={{ width: '30%' }}>Templates Bon de commande</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sites.map((site) => {
                                return (
                                    <tr key={site.IDSite}>
                                        <td>{site.IDSite}</td>
                                        <td>{site.Libelle}</td>
                                        <td>
                                            <Select multi
                                                onChange={this.props.onChange.bind(this, site.IDSite, "offre")}
                                                value={this.getSelectedTemplatesOffre(site.IDSite)}
                                                options={this.state.templatesImpressionOffreOptList}
                                                searchable={true}
                                                placeholder='...'
                                            />
                                        </td>
                                        <td>
                                            <Select multi
                                                onChange={this.props.onChange.bind(this, site.IDSite, "bdc")}
                                                value={this.getSelectedTemplatesBdc(site.IDSite)}
                                                options={this.state.templatesImpressionBdcOptList}
                                                searchable={true}
                                                placeholder='...'

                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}

export default SitesConfig;
