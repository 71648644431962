import React, { Component } from 'react';

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
        origineContact: null,
        updated: false,
        fetchOrigineContact: false,
        saving: false,
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchOrigineContact()
    }
    else {
      this.setState({origineContact: {
        "Descr": '',
      }})
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchOrigineContact) {
      this.fetchOrigineContact()
    }
  }

  onChange(event) {
    var name = event.target.name
    var value = event.target.value

    var origineContact = this.state.origineContact

    origineContact[name] = value

    this.setState({
      updated: true,
      origineContact: origineContact,
    })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({
      saving: true,
    })

    if (!this.props.match.params.id) {
      this.createOrigineContact();
    }
    else {
      this.editOrigineContact();
    }
  }

  fetchOrigineContact() {
    this.setState({
      fetchOrigineContact: true,
    })

    fetch(localStorage.backoffice_api + '/api/originesContact/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        origineContact: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editOrigineContact() {
    fetch(localStorage.backoffice_api + '/api/originesContact/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.origineContact)
    })
    .then((response) => {

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        origineContact: null,
        saving: false,
      }, () => {
        this.fetchOrigineContact()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  createOrigineContact() {
    fetch(localStorage.backoffice_api + '/api/originesContact', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.origineContact)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.props.history.push('/originesContact')
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.origineContact) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Origines contact</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.origineContact.Descr} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
                  </div>
                </div>
              </div>
            </div>

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.saving} type="submit" className="btn btn-primary">
                    {this.state.saving ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
