import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

	constructor(props) {
		super(props);

		this.state = {
			values: [],
		}
	}

	componentWillMount() {
		this.fetchValues();
	}

	fetchValues() {
		fetch(localStorage.backoffice_api + '/api/table/CategorieClient', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				var values = lodash.sortBy(json, (value) => {
					return value.Libelle;
				})

				this.setState({
					values: values,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	render() {

		return (
			<div className='container-fluid'>
				<div className="row">
					<div className="col">
						<h2>Catégories client</h2>
						<br />
						<div>
							<Link to='/table/CategorieClient/create' className='btn btn-light'>
								<i className="fa fa-plus"></i> Ajouter
							</Link>
						</div>
						<br />
						<table className="table table-striped table-hover table-sm">
							<thead>
								<tr>
									<th>#</th>
									<th>Type fiche client</th>
									<th>Libellé</th>
									<th>Est actif</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.values.map((r) => {
									return (<tr key={r.IDCatClient}>
										<td>{r.IDCatClient}</td>
										<td>{r.TFicheCli}</td>
										<td>{r.Descr}</td>
										<td >
											{r.EstActif && <i className="fa fa-check"></i>}
										</td>
										<td>
											<Link to={'/table/CategorieClient/' + r.IDCatClient} className='btn btn-primary btn-sm'>
												<i className="fa fa-pencil"></i>
											</Link>
										</td>
									</tr>)
								})}
							</tbody>
						</table>

					</div>
				</div>
			</div>
		);
	}
}

export default List;
