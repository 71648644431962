import React, { Component } from 'react';

import Select from 'react-select';

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      relance: null,
      societes: [],
      sites: [],
      evenements: [],
      updated: false,
      fetchInProgress: false,
      regle: '',
      dispo: '',
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchRelance()
    }
    else {
      this.setState({relance: {
        'Libelle': '',
        'NbJours': '',
        'IDRelanceAutoEvenement': '',
        'Sites': [],
        'Plaques': []
      }})
    }

    this.fetchSocietes()
    this.fetchSites()
    this.fetchEvenements()
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchRelance) {
      this.fetchRelance()
    }
  }

  fetchRelance() {

    this.setState({
      fetchRelance: true,
    })

    fetch(localStorage.backoffice_api + '/api/relances/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var regle = (parseInt(json.NbJours, 10) < 0) ? 'Avant' : 'Après';

      var hasSocietesChecked = json.Plaques && json.Plaques.length;
      var hasSitesChecked = json.Sites && json.Sites.length;

      var dispo = '';
      if (json.Groupe) {
        dispo = 'Groupe';
      }
      else if (hasSocietesChecked) {
        dispo = 'Société';
      }
      else if (hasSitesChecked) {
        dispo = 'Site';
      }

      this.setState({
        relance: json,
        regle: regle,
        dispo: dispo
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        societes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        sites: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchEvenements() {
    fetch(localStorage.backoffice_api + '/api/relances/evenements/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        evenements: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var relance = this.state.relance

    if (type === 'checkbox') {
      relance[name] = event.target.checked
    }
    else {
      relance[name] = value
    }

    this.setState({
      updated: true,
      relance: relance,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createRelance();
    }
    else {
      this.editRelance();
    }
  }

  createRelance() {
    fetch(localStorage.backoffice_api + '/api/relances/', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.relance)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      this.setState({
        relance: null,
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/relances/' + json.IDRelanceAuto)
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  editRelance() {
    fetch(localStorage.backoffice_api + '/api/relances/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.relance)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        relance: null,
        fetchInProgress: false,
      }, () => {
        this.fetchRelance()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeDispo(event) {
    var value = event.target.value;
    var relance = this.state.relance

    if (value === 'Groupe') {
      relance['Groupe'] = 1;
      relance.Plaques = [];
      relance.Sites = [];

      this.setState({
        updated: true,
        relance: relance,
        dispo: value,
      })
    }
    else if (value === 'Société') {
      relance['Groupe'] = 0;
      relance.Sites = [];

      this.setState({
        updated: true,
        relance: relance,
        dispo: value,
      })
    }
    else if (value === 'Site') {
      relance['Groupe'] = 0;
      relance.Plaques = [];

      this.setState({
        updated: true,
        relance: relance,
        dispo: value,
      })
    }
  }

  checkAllSite(event) {
    var relance = this.state.relance;
    relance.Sites = this.state.sites;
    this.setState({
      relance: relance,
      updated: true,
    })
  }

  uncheckAllSite(event) {
    var relance = this.state.relance;
    relance.Sites = []
    this.setState({
      relance: relance,
      updated: true,
    })
  }

  onChangeSite(IDSite, event) {

    if (event.target.checked) { // add
      var relance = this.state.relance;
      if (!relance.Sites) {
        relance.Sites = [];
      }
      relance.Sites.push({IDSite: IDSite});
      this.setState({
        relance: relance,
        updated: true,
      })
    }
    else { // remove
      var relance = this.state.relance;
      relance.Sites = lodash.filter(relance.Sites, (s) => {
        return parseInt(s.IDSite, 10) !== parseInt(IDSite, 10);
      })
      this.setState({
        relance: relance,
        updated: true,
      })
    }
  }

  isCheckedSite(IDSite, event) {

    if (!this.state.relance.Sites || !this.state.relance.Sites.length)
      return false;

    var exist = lodash.findIndex(this.state.relance.Sites, (s) => {
      return parseInt(s.IDSite, 10) === parseInt(IDSite, 10);
    })

    if (exist === -1)
      return false;

    return true;
  }

  onChangePlaque(IDPlaque, event) {

    if (event.target.checked) { // add
      var relance = this.state.relance;
      if (!relance.Plaques) {
        relance.Plaques = [];
      }
      relance.Plaques.push({IDPlaque: IDPlaque});
      this.setState({
        relance: relance,
        updated: true,
      })
    }
    else { // remove
      var relance = this.state.relance;
      relance.Plaques = lodash.filter(relance.Plaques, (s) => {
        return parseInt(s.IDPlaque, 10) !== parseInt(IDPlaque, 10);
      })
      this.setState({
        relance: relance,
        updated: true,
      })
    }
  }

  checkAllPlaque(event) {
    var relance = this.state.relance;
    relance.Plaques = this.state.societes;
    this.setState({
      relance: relance,
      updated: true,
    })
  }

  uncheckAllPlaque(event) {
    var relance = this.state.relance;
    relance.Plaques = []
    this.setState({
      relance: relance,
      updated: true,
    })
  }

  isCheckedPlaque(IDPlaque, event) {

    if (!this.state.relance.Plaques || !this.state.relance.Plaques.length)
      return false;

    var exist = lodash.findIndex(this.state.relance.Plaques, (s) => {
      return parseInt(s.IDPlaque, 10) === parseInt(IDPlaque, 10);
    })

    if (exist === -1)
      return false;

    return true;
  }

  render() {

    if (!this.state.relance) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Relances</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libellé</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.relance.Libelle} onChange={this.onChange.bind(this)} maxLength="30" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="NbJours" className="col-sm-3 col-form-label">Nombre de jours</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="NbJours" name="NbJours" value={this.state.relance.NbJours ? this.state.relance.NbJours : ''} onChange={this.onChange.bind(this)} />
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="regle" className="col-sm-3 col-form-label">Règle</label>
                  <div className="col-sm-6">
                    <select value={this.state.regle} onChange={(e) => this.setState({regle: e.target.value})} id='regle' name="regle" className="form-control form-control-sm" required='required'>
                      <option value=''></option>
                      <option value='Avant'>Avant</option>
                      <option value='Après'>Après</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDRelanceAutoEvenement" className="col-sm-3 col-form-label">Événement</label>
                  <div className="col-sm-6">
                    <select value={this.state.relance.IDRelanceAutoEvenement ? this.state.relance.IDRelanceAutoEvenement : ''} onChange={this.onChange.bind(this)} id='IDRelanceAutoEvenement' name="IDRelanceAutoEvenement" className="form-control form-control-sm" required='required'>
                      <option value=''></option>
                      {this.state.evenements.map((evenement, i) => {
                        return <option key={evenement.IDRelanceAutoEvenement} value={evenement.IDRelanceAutoEvenement}>{evenement.Libelle}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="dispo" className="col-sm-3 col-form-label">Disponibilité</label>
                  <div className="col-sm-6">
                    <select value={this.state.dispo} onChange={this.onChangeDispo.bind(this)} id='dispo' name="dispo" className="form-control form-control-sm" required='required'>
                      <option value=''></option>
                      <option value='Groupe'>Groupe</option>
                      <option value='Société'>Société</option>
                      <option value='Site'>Site</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col"></div>
            </div>

            {this.state.dispo === 'Société' ?
              <div className="row">
                <div className="col">
                  <h3>Sociétés</h3>

                  <br />

                  <div className='float-right'>
                    <a className='btn btn-link' onClick={this.checkAllPlaque.bind(this)} tabIndex="1">Tout cocher</a> / <a className='btn btn-link' onClick={this.uncheckAllPlaque.bind(this)} tabIndex="2">Tout décocher</a>
                  </div>

                  <table className="table table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.societes.map((societe) => {
                        return (<tr key={societe.IDPlaque}>
                          <td>{societe.IDPlaque}</td>
                          <td>{societe.Libelle}</td>
                          <td>
                            <input type="checkbox" checked={this.isCheckedPlaque(societe.IDPlaque)} onChange={this.onChangePlaque.bind(this, societe.IDPlaque)} />
                          </td>
                        </tr>)
                    })}
                    </tbody>
                  </table>

                </div>
              </div>
            : null}

            {this.state.dispo === 'Site' ?
              <div className="row">
                <div className="col">
                  <h3>Sites</h3>

                  <br />

                  <div className='float-right'>
                    <a className='btn btn-link' onClick={this.checkAllSite.bind(this)} tabIndex="1">Tout cocher</a> / <a className='btn btn-link' onClick={this.uncheckAllSite.bind(this)} tabIndex="2">Tout décocher</a>
                  </div>

                  <table className="table table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Société</th>
                        <th>Nom</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sites.map((site) => {
                        if (this.state.filterName) {
                          if (site.Descr.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                            return false;
                          }
                        }

                        return (<tr key={site.IDSite}>
                          <td>{site.IDSite}</td>
                          <td>{site.Plaque_Libelle}</td>
                          <td>{site.Descr}</td>
                          <td>
                            <input type="checkbox" checked={this.isCheckedSite(site.IDSite)} onChange={this.onChangeSite.bind(this, site.IDSite)} />
                          </td>
                        </tr>)
                    })}
                    </tbody>
                  </table>

                </div>
              </div>
            : null}

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
