import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      lignes: [],
    }
  }

  componentWillMount() {
    this.fetchLignes();
  }

  fetchLignes() {
    fetch(localStorage.backoffice_api + '/api/wsimport', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        lignes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>WSImport (Importation des leads)</h2>

            <br />

            <Link to='/wsimport/create' className='btn btn-light'>
              <i className="fa fa-plus"></i> Ajouter
            </Link>

            <br /><br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>Login</th>
                  <th>Mot de passe</th>
                  <th>Société</th>
                  <th>Site</th>
                </tr>
              </thead>
              <tbody>
                {this.state.lignes.map((ligne, i) => {
                  return (<tr key={i}>
                    <td>{ligne.Login}</td>
                    <td>{ligne.Password}</td>
                    <td>{ligne.Societe ? ligne.Societe.Libelle : ''} ({ligne.IDPlaque})</td>
                    <td>{ligne.Site ? ligne.Site.Descr : ''} {ligne.IDSite ? "("+ligne.IDSite+")" : ''}</td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
