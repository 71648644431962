import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Form from './Form';
import Logs from './Logs';

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
        ongletActif: 'Configuration'
    }
  }

  setOngletActif(onglet){
    this.setState({
      ongletActif: onglet
    })
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>Interface PVO2</h2>

            <br />

            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item">
                <a className="nav-link active" id="configuration-tab" data-toggle="tab" href="#configuration" role="tab" aria-controls="configuration" aria-selected="true" onClick={() => this.setOngletActif('Configuration')}>Configuration</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="logs-tab" data-toggle="tab" href="#logs" role="tab" aria-controls="logs" aria-selected="true" onClick={() => this.setOngletActif('Logs')}>Logs</a>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
              <div className="tab-pane fade show active" id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                <Form />
              </div>
              <div className="tab-pane fade" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                {/* Pour éviter de charger les logs alors qu'on veut juste voir la config */
                  this.state.ongletActif === "Logs" ?
                    <Logs />
                  :
                  null
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
