import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      offresReglements: [],
    }
  }


  componentWillMount() {
    this.fetchOffreReglement();
  }

  fetchOffreReglement() {
    fetch(localStorage.backoffice_api + '/api/offrereglement', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        offresReglements: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Offre Règlement</h2>
            <br />
            <div>
              <Link to='/offrereglement/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>ID offre règlement</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {this.state.offresReglements.map((offre) => {

                  return (<tr key={offre.IDOffreReglement}>
                    <td>{offre.IDOffreReglement}</td>
                    <td>{offre.Descr}</td>
                    <td className='text-right'>
                      <Link to={'/offrereglement/'+offre.IDOffreReglement} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
