import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fonction: null,
      updated: false,
      fetchInProgress: false,
      fetchFonction: false,
    }
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      this.fetchFonction()
    }
    else {
      this.setState({fonction: {
        "Descr": '',
      }})
    }
  }

  fetchFonction() {
    this.setState({
      fetchFonction: true,
    })
    
    fetch(localStorage.backoffice_api + '/api/fonctions/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        fonction: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeDescr(event) {
    var fonction = this.state.fonction;
    if(fonction === null)fonction = {};
    fonction.Descr = event.target.value;

    this.setState({
      updated: true,
      fonction: fonction
    })
  }

  canSubmit(){
    return (this.state.updated && !this.state.fetchInProgress && this.state.fonction.Descr != '');
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createFonction();
    }
    else {
      this.updateFonction();
    }
  }

  createFonction() {
    fetch(localStorage.backoffice_api + '/api/fonctions', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.fonction)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/fonctions')
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  updateFonction() {
    fetch(localStorage.backoffice_api + '/api/fonctions/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.fonction)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/fonctions')
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.fonction) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Fonctions</h2>

          <form onSubmit={(e) => this.onSubmit(e)}>

            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card">
                  <div className="card-header">
                    {this.props.match.params.id ? "Modifier une fonction" : "Ajouter une fonction"}
                  </div>
                  <div className="card-body pb-3">
                    <div className="form-group row">
                      <label htmlFor="Nom" className="col-sm-3 col-form-label">Nom</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="Nom" name="Nom" value={this.state.fonction.Descr} onChange={(e) => this.onChangeDescr(e)} maxLength="60" required='required' />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col text-center">
                        <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                          {this.state.fetchInProgress ?
                            <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                          : null}
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
        </form>
      </div>
    );
  }
}

export default Form;
