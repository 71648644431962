import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statutsJuridiques: [],
      fetching: false,
      showOnlyActive: false,
    }
  }

  componentDidMount() {
    this.fetchStatutsJuridiques();
  }

  async fetchStatutsJuridiques() {
    try {
      this.setState({
        fetching: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/statutsJuridiques', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })
      var statutsJuridiques = (await response.json())
      statutsJuridiques = lodash.sortBy(statutsJuridiques, 'Descr')

      this.setState({
        statutsJuridiques: statutsJuridiques,
      }, this.filter)
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetching: false
      })
    }
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2 className="mb-4">Statuts juridiques</h2>

            <div className="d-flex flex-row align-items-center justify-content-between mb-3">
              <Link to='/statutsJuridiques/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>
            </div>

            {this.state.fetching ?
              <div className="d-flex flex-row align-items-center justify-content-center">
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              </div>
              :
              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Libellé</th>
                    <th>
                      Actif
                      <input type="checkbox" className="ml-2" checked={this.state.showOnlyActive} onChange={(e) => this.setState({showOnlyActive: e.currentTarget.checked})}/>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.statutsJuridiques.filter(t => t.Actif || !this.state.showOnlyActive).map((t) => {
                    return (<tr key={t.IDStatutJuridique}>
                      <td>{t.IDStatutJuridique}</td>
                      <td>{t.Descr}</td>
                      <td>{t.Actif && <i className="fa fa-check"></i>}</td>
                      <td className="text-right">
                        <Link to={'/statutsJuridiques/' + t.IDStatutJuridique} className='btn btn-primary btn-sm'>
                          <i className="fa fa-pencil"></i>
                        </Link>
                      </td>
                    </tr>);
                  })}
                </tbody>
              </table>
            }

          </div>
        </div>
      </div>
    );
  }
}

export default List;
