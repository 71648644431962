import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class InterfaceSmartDataForLead extends Component {

  constructor(props) {
    super(props);

    this.state = {
      datas: null,
    }
  }

  componentWillMount() {
    this.fetchInterfaceSmartDataForLead();
  }

  fetchInterfaceSmartDataForLead() {
    fetch(localStorage.backoffice_api + '/api/interfaces/smartDataForLead/dashboard', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
            datas: json,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {

    if (!this.state.datas) {
      return (<div className="col-sm-3">
        <div className="card bg-light mb-3">
          <div className="card-header">SmartDataForLead</div>
          <div className="card-body">
            <h4 className="card-title">Chargement...</h4>
            <p className="card-text text-center">
              <i className='fa fa-spinner fa-spin fa-fw'></i>
            </p>
          </div>
        </div>
      </div>)
    }
    var classes, title, text;
    if (this.state.datas && this.state.datas.Actif){
      if(this.state.datas.Password != "" && this.state.datas.Login != ""){
        classes = "bg-success text-white";
        title = "Configuré";
        text = " ";
      }
      else if(this.state.datas.Password == "" || this.state.datas.Login == ""  ){
        classes = "bg-warning text-black";
        title = "Non configuré";
        text = "identifiants non renseigné";
      }
    }else{
        classes = "text-white bg-secondary";
        title = "Désactivé";
        text = ""
    }
    
    return (
    <div className="col-sm-3">
      <div className={"card mb-3 "+classes}>
        <div className="card-header">SmartDataForLead</div>
        <div className="card-body">
          <h5 className="card-title" style={{lineHeight:'1.8rem'}}>{title}</h5>
          <p className="card-text">
            <Link to='interfaces/smartDataForLead' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
          </p>
        </div>
      </div>
    </div>);
  }
}

export default InterfaceSmartDataForLead;
