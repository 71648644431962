import React, { Component } from 'react';

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchingCoefficients: false,
      coefficients: [],
      saving: false,
      saved: false,
    }
  }

  componentDidMount() {
    this.fetchCoefficients();
  }

  fetchCoefficients() {
    this.setState({
      fetchingCoefficients: true,
    })

    fetch(localStorage.backoffice_api + '/api/objectifsVendeur/coefficients', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        coefficients: json,
        fetchingCoefficients: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeCoefficient(e) {
    var coefficients = this.state.coefficients;
    var value = e.target.value.replace(',', '.');
    if(isNaN(value))
      return;

    coefficients[e.target.name] = value;
    this.setState({
      coefficients: coefficients,
      saved: false
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      saving: true,
      saved: false
    })

    fetch(localStorage.backoffice_api + '/api/objectifsVendeur/coefficients', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify({
        coefficients: this.state.coefficients,
      })
    })
    .then((response) => {

        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });

          this.setState({
            saving: false,
          })

          throw Error(response);
        }

        this.setState({
          saving: false,
          saved: true
        })
    })
    .catch((error) => {
        console.log(error)
    });
  }

  render() {

    if (this.state.fetchingCoefficients) {
      return (
        <div className='container-fluid'>
          <h2>Objectifs vendeurs : Coefficents</h2>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Objectifs vendeurs : Coefficents</h2>

            {this.state.saved ? 
              <div className="alert alert-success">
                Coefficients enregistrés
              </div>
            :null}

            <form className="form-inline" onSubmit={this.handleSubmit.bind(this)}>

              <div className="row mt-3">
                <div className="col w-auto">
                  <div className="d-flex flex-row align-items-end" style={{"columnGap": 15}}>
                  {lodash.map(this.state.coefficients, (coeff, i) => {
                      return (
                        <div className="d-flex flex-column" key={i}>
                          <div>
                            <b>{i}</b>
                          </div>
                          <div>
                            <input type="text" pattern="[0-9]+(\.[0-9])?" name={i} value={coeff || ''} className="form-control" style={{width: '10em'}} onChange={this.onChangeCoefficient.bind(this)}/>
                          </div>
                        </div>
                      )
                    })
                  }
                    <button type="submit" className="btn btn-primary inline-block">
                      {this.state.saving ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                      : null}
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Form;
