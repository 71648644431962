import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statutsVehicules: [],
      allStatutsVehicules: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchStatutsVehicules();
  }

  fetchStatutsVehicules() {
    fetch(localStorage.backoffice_api + '/api/statutsVehicules', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var statutsVehicules = lodash.groupBy(json, 'IDMatStatus');

      this.setState({
        statutsVehicules: statutsVehicules,
        allStatutsVehicules: statutsVehicules,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  filter(event){
    var statutsVehicules = this.state.allStatutsVehicules;
    var filterName = event.target.value;
    statutsVehicules = lodash.filter(statutsVehicules, (s) => {
      return s[0].Descr.toLowerCase().search(filterName.toLowerCase()) !== -1
    });
    this.setState({
      statutsVehicules: statutsVehicules,
      filterName: filterName
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Statuts véhicules</h2>

            <div>
              <div className='float-right mb-1'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(e) => this.filter(e)} placeholder='Filtre par statut' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Groupements de statuts</th>
                </tr>
              </thead>
              <tbody>
              {
                lodash.map(this.state.statutsVehicules, (s) => {
                  return (<tr key={"statuts"+s[0].IDMatStatus}>
                    <td>{s[0].IDMatStatus} - {s[0].Descr}</td>
                    <td>
                      {lodash.map(s, (gpStatus) => {
                        if(gpStatus.IDGpStatus)
                          return <div key={gpStatus.IDMatStatus+'-'+gpStatus.IDGpStatus}>{gpStatus.IDGpStatus} - {gpStatus.GpStatusDescr}</div>
                        else 
                          return <div key={gpStatus.IDMatStatus}></div>
                      })}
                    </td>
                  </tr>);
                })
              }
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
