import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cartes: [],
      allCartes: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchCartes();
  }

  fetchCartes() {
    fetch(localStorage.backoffice_api + '/api/cartesDeFidelite', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var cartes = lodash.sortBy(json, (c) => {
        return c.Descr;
      })

      this.setState({
        cartes: cartes,
        allCartes: cartes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  filter(event){
    var cartes = this.state.allCartes;
    var filterName = event.target.value;
    cartes = cartes.filter((c) => {
      return c.Descr.toLowerCase().search(filterName.toLowerCase()) !== -1
    });
    this.setState({
      cartes: cartes,
      filterName: filterName
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Cartes de fidélité</h2>

            <br />

            <div>
              <Link to='/cartesDeFidelite/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>
              &nbsp;
              {Utils.fromJWT('BDGp') === "BDGpGroupeSipaV4" ?
                <Link to='/cartesDeFidelite/importClients' className='btn btn-light'>
                  <i className="fa fa-upload"></i> Importer fidélité clients
                </Link>
              :null}

              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(e) => this.filter(e)} placeholder='Filtre par nom' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.cartes.map((c) => {
                  return (<tr key={"fonction"+c.IDFidelite}>
                    <td>{c.IDFidelite}</td>
                    <td>{c.Descr}</td>
                    <td className="text-right">
                      <Link to={'/cartesDeFidelite/'+c.IDFidelite} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>);
                })
              }
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
