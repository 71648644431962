import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      civilites: [],
    }
  }


  componentWillMount() {
    this.fetchCivilites();
  }

  fetchCivilites() {
    fetch(localStorage.backoffice_api + '/api/civilites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        civilites: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Civilités</h2>
            <br />
            <div>
              <Link to='/civilites/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>ID Civilités</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {this.state.civilites.map((civilite) => {
                return (<tr key={civilite.IDCivilite}>
                <td>{civilite.IDCivilite}</td>
                <td>{civilite.Descr}</td>
                <td className='text-right'>
                    <Link to={'/civilites/'+civilite.IDCivilite} className='btn btn-primary btn-sm'>
                    <i className="fa fa-pencil"></i>
                    </Link>
                </td>
                </tr>)
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
