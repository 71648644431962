import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import 'moment/locale/fr';
import moment from 'moment'
require('moment-precise-range-plugin')

class List extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      passerelle: null,
      history: [],
    }
  }

  componentWillMount() {
    this.fetchPasserelle();
    this.fetchPasserelleHistory();
  }

  fetchPasserelle() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        passerelle: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchPasserelleHistory() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id+'/history', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        history: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.passerelle) {
      return null;
    }

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Passerelles</h2>

            <br />

            <div className="row">
              <div className='col'>
                <b>IDPasserelleDMS</b> : {this.state.passerelle.IDPasserelleDMS}
              </div>
              <div className='col'>
                <b>Libelle</b> : {this.state.passerelle.Libelle}
              </div>
              <div className='col'>
                <b>DateHRefProchainTransfert</b> : {this.state.passerelle.DateHRefProchainTransfert ? moment(this.state.passerelle.DateHRefProchainTransfert).utc(0).format('L LT') : null}
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>Date Appel WS</th>
                  <th>Date Fin WS</th>
                  <th>Durée</th>
                  <th>Date référence</th>
                  <th>Nb de Véhicules</th>
                  <th>Nb Clients</th>
                  <th>Nb Erreurs</th>
                </tr>
              </thead>
              <tbody>
              {this.state.history.map((h) => {
                return (<tr key={h.IDPasserelleDMSHisto} className={h.NbErreurs ? 'table-danger' : ''}>
                  <td>{moment(h.DateHAppelWS).utc(0).format('L LT')}</td>
                  <td>{moment(h.DateHFinWS).utc(0).format('L LT')}</td>
                  <td>{moment.preciseDiff(h.DateHAppelWS, h.DateHFinWS)}</td>
                  <td>{moment(h.DateHRef).utc(0).format('L LT')}</td>
                  <td>{h.NbMat}</td>
                  <td>{h.NbClients}</td>
                  <td>
                    {h.NbErreurs ?
                      <Link to={'/passerelles/'+this.state.passerelle.IDPasserelleDMS+'/history/'+h.IDPasserelleDMSHisto+'/errors'}>
                        <b>{h.NbErreurs}</b>
                      </Link>
                    : h.NbErreurs}
                  </td>
                  <td>{h.DateHRefProchainTransfert ? moment(h.DateHRefProchainTransfert).utc(0).format('L LT') : null}</td>
                </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
