import React, { Component } from 'react';
var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      saving: false,
      loadingSitesFromBee2Link: false,

      sites: [],
      sitesBee2Link: [],
      sitesBee2LinkResult: [],
    }
  }

  componentWillMount() {
    this.fetchConfig();
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/bee2Link/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        loaded: true,
        sites: json.sites,
      }, () => 
          {
            for(var i in this.state.sites)
            {
              if(this.state.sites[i].ClientIDBee2Link)
                this.fetchSitesBee2Link(this.state.sites[i].ClientIDBee2Link);
            }
          });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  loadSitesBee2Link(ClientIDBee2Link){
    if(ClientIDBee2Link === null || ClientIDBee2Link === "")
      return;
    else if(!this.state.sitesBee2LinkResult[ClientIDBee2Link])
      this.fetchSitesBee2Link(ClientIDBee2Link);
  }

  fetchSitesBee2Link(ClientIDBee2Link){
    var sitesBee2LinkResult = this.state.sitesBee2LinkResult;
    sitesBee2LinkResult[ClientIDBee2Link] = 'loading';
    this.setState({
      sitesBee2LinkResult:sitesBee2LinkResult,
      loadingSitesFromBee2Link: true
    });
    fetch(localStorage.backoffice_api + '/api/interfaces/bee2Link/getSitesBee2link?IdClient='+ClientIDBee2Link, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sitesBee2Link = this.state.sitesBee2Link;
      sitesBee2Link[ClientIDBee2Link] = json.sites;
      sitesBee2LinkResult[ClientIDBee2Link] = 'ok';
      this.setState({
        sitesBee2Link: sitesBee2Link,
        sitesBee2LinkResult: sitesBee2LinkResult,
        loadingSitesFromBee2Link: false
      });
    })
    .catch((error) => {
      var sitesBee2Link = this.state.sitesBee2Link;
      sitesBee2Link[ClientIDBee2Link] = [];
      sitesBee2LinkResult[ClientIDBee2Link] = 'ko';
      this.setState({
        sitesBee2Link: sitesBee2Link,
        sitesBee2LinkResult: sitesBee2LinkResult,
        loadingSitesFromBee2Link: false
      });
      //console.log(error)
    });
  }

  submitConfig(event) {
    event.preventDefault();

    for(var i in this.state.sites) {
      var ClientIDBee2Link = this.state.sites[i].ClientIDBee2Link;
      if(this.state.sitesBee2LinkResult[ClientIDBee2Link] && this.state.sitesBee2LinkResult[ClientIDBee2Link] == 'ko')
      {
        alert("Au moins un ClientID Bee2Link n'est pas reconnu par Bee2Link");
        return false; 
      }
    }

    this.setState({
      saving: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/bee2Link/config', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify({
        IdClient: this.state.IdClient,
        sites: this.state.sites,
      })
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        saving: false,
        sites:[]
      }, () => this.fetchConfig());
    })
    .catch((error) => {
      console.log(error)
    });
  }

  setSiteActif(IDSite, checked){
    var sites = this.state.sites;
    var idx = lodash.findIndex(sites, {IDSite:IDSite});
    if(!sites[idx].IDSiteBee2Link){
      alert("La correspondance avec Bee2Link doit être effectuée pour pouvoir activer le site");
      return false;
    }

    sites[idx].Actif = checked;
    sites[idx].updated = true;

    this.setState({
      sites: sites,
      updated: true,
    });
  }

  setSiteEstPasserelle(IDSite, checked){
    var sites = this.state.sites;
    var idx = lodash.findIndex(sites, {IDSite:IDSite});

    sites[idx].EstPasserelle = checked;
    sites[idx].updated = true;

    this.setState({
      sites: sites,
      updated: true,
    });
  }

  setClientIDBee2Link(IDSite, ClientIDBee2Link) {
    var sites = this.state.sites;
    var idx = lodash.findIndex(sites, {IDSite:IDSite});
    sites[idx].ClientIDBee2Link = ClientIDBee2Link;
    sites[idx].IDSiteBee2Link = 0;
    sites[idx].updated = true;
    if(ClientIDBee2Link === "")sites[idx].Actif = false;
    else if(sites[idx].Actif === null)sites[idx].Actif = false;

    this.setState({
      sites: sites,
      updated: true,
    });
  }

  setSiteBee2Link(IDSite, IDSiteBee2Link) {
    IDSiteBee2Link = parseInt(IDSiteBee2Link);
    var sites = this.state.sites;
    var idx = lodash.findIndex(sites, {IDSite:IDSite});
    sites[idx].IDSiteBee2Link = IDSiteBee2Link;
    sites[idx].updated = true;
    if(IDSiteBee2Link === 0)sites[idx].Actif = false;
    else if(sites[idx].Actif === null)sites[idx].Actif = false;

    this.setState({
      sites: sites,
      updated: true,
    });
  }

  render() {

    if (!this.state.loaded) {
      return null;
    }

    return (
      <div className='container-fluid mb-4'>

          <h2>Interface Bee2Link</h2>

          <form onSubmit={(e) => this.submitConfig(e)}>

          <div className="row">
            <div className="col mt-4 mb-2">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>Société</th>
                        <th>Site</th>
                        <th>ClientID Bee2Link</th>
                        <th>Site Bee2Link</th>
                        <th className="text-center">Est Passerelle</th>
                        <th className="text-center">Actif</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.sites.map((site) => {
                      return (
                        <tr key={site.IDSite}>
                          <td>{site.Libelle_Societe}</td>
                          <td>{site.Descr}</td>
                          <td className="d-flex align-items-center">
                            <input className="form-control w-75 d-inline-block" type="text" placeholder="" value={site.ClientIDBee2Link ? site.ClientIDBee2Link : ''}
                              onChange={(e) => this.setClientIDBee2Link(site.IDSite, e.target.value)}
                              onBlur={() => this.loadSitesBee2Link(site.ClientIDBee2Link)}/>
                              {
                                this.state.sitesBee2LinkResult[site.ClientIDBee2Link] ? 
                                  this.state.sitesBee2LinkResult[site.ClientIDBee2Link] === 'loading' ?
                                    <i className="fa fa-refresh fa-spin" style={{fontSize:'1.5em'}}></i>
                                  :this.state.sitesBee2LinkResult[site.ClientIDBee2Link] === 'ok' ?
                                    <i className="fa fa-check-circle text-success" style={{fontSize:'1.5em'}}></i>
                                  :this.state.sitesBee2LinkResult[site.ClientIDBee2Link] === 'ko' ?
                                    <i className="fa fa-times text-danger" style={{fontSize:'1.5em'}}></i>
                                  :null
                                :null
                              }
                          </td>
                          <td style={{width:'1px'}}>
                            <select className="form-control" onChange={(e) => this.setSiteBee2Link(site.IDSite, e.target.value)} value={site.IDSiteBee2Link ? site.IDSiteBee2Link : ''} style={{width:'400px'}}>
                              <option value={0}></option>
                              {this.state.sitesBee2Link[site.ClientIDBee2Link] ? 
                                this.state.sitesBee2Link[site.ClientIDBee2Link].map((sb2l) => {
                                  return <option key={"sb2l"+sb2l.id} value={sb2l.id}>{sb2l.id} - {sb2l.libelle}</option>
                                })
                                :null
                              }
                            </select>
                          </td>
                          <td className="text-center"><input className="mt-1" type="checkbox" checked={site.EstPasserelle === true} onChange={(e) => this.setSiteEstPasserelle(site.IDSite, e.target.checked)}/></td>
                          <td className="text-center"><input className="mt-1" type="checkbox" checked={site.Actif === true} onChange={(e) => this.setSiteActif(site.IDSite, e.target.checked)}/></td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
            </div>
          </div>

          {this.state.updated ?
              <div className="row mb-2" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.saving || this.state.loadingSitesFromBee2Link} type="submit" className="btn btn-primary">
                    {this.state.saving ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>);

  }
}

export default Form;
