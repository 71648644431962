import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

class InterfaceBaseVN extends Component {

  constructor(props) {
    super(props);

    this.state = {
      datas: null,
    }
  }

  componentWillMount() {
    this.fetchDatas()
  }

  fetchDatas() {
    fetch(localStorage.backoffice_api + '/api/interfaces/basevn/dashboard', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        datas: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.datas) {
      return (<div className="col-sm-3">
        <div className="card bg-light mb-3">
          <div className="card-header">BaseVN</div>
          <div className="card-body">
            <h4 className="card-title">Chargement...</h4>
            <p className="card-text text-center">
              <i className='fa fa-spinner fa-spin fa-fw'></i>
            </p>
          </div>
        </div>
      </div>)
    }

    if (this.state.datas && this.state.datas.Actif) {
      return (<div className="col-sm-3">
        <div className={"card mb-3" + (this.state.datas.Warning ? ' bg-warning text-black' : ' bg-success text-white')}>
          <div className="card-header">BaseVN</div>
          <div className="card-body">
            <h4 className="card-title">{this.state.datas.CountSitesActif}/{this.state.datas.CountSites} sites</h4>
            <p className="card-text">
              <Link to='interfaces/basevn' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
            </p>
          </div>
        </div>
      </div>)
    }

    return (<div className="col-sm-3">
      <div className="card text-white bg-secondary mb-3">
        <div className="card-header">BaseVN</div>
        <div className="card-body">
          <h4 className="card-title">Désactivé</h4>
          <Link to='interfaces/basevn' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
        </div>
      </div>
    </div>)
  }
}

export default withRouter(InterfaceBaseVN);
