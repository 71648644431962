import React, { Component } from 'react';

class Form extends Component {

	constructor(props) {
		super(props);

		this.state = {
			raisonCloture: null,
			updated: false,
			fetchInProgress: false,
			fetchValue: false,
			typesCloture: []
		}
	}

	componentWillMount() {

		this.fetchTypesCloture()

		if (this.props.match.params.id) {
			this.fetchValue()
		}
		else {
			this.setState({
				raisonCloture: {
					"Libelle": '',
					"AvecRelance": false,
					"IDAffaireTypeStop": 1,
					"Actif": true
				}
			})
		}
	}

	componentDidUpdate() {
		if (this.props.match.params.id && !this.state.fetchValue) {
			this.fetchValue()
		}
	}

	fetchTypesCloture() {
		fetch(localStorage.backoffice_api + '/api/typesCloture', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				console.log(json)
				this.setState({
					typesCloture: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchValue() {

		this.setState({
			fetchValue: true,
		})

		fetch(localStorage.backoffice_api + '/api/table/RaisonClotureLead/' + this.props.match.params.id, {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					raisonCloture: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	onChange(event) {
		var type = event.target.type
		var name = event.target.name
		var value = event.target.value

		var object = this.state.raisonCloture

		if (type === 'checkbox') {
			object[name] = event.target.checked
		}
		else {
			object[name] = value
		}

		this.setState({
			updated: true,
			raisonCloture: object,
		})
	}

	handleSubmit(event) {
		event.preventDefault()

		this.setState({
			fetchInProgress: true,
		})

		if (!this.props.match.params.id) {
			this.createValue();
		}
		else {
			this.editValue();
		}
	}

	createValue() {
		fetch(localStorage.backoffice_api + '/api/table/RaisonClotureLead', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.raisonCloture)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					updated: false,
					fetchInProgress: false,
				}, () => {
					this.props.history.push('/table/RaisonClotureLead?success=created')
				})

			})
			.catch((error) => {
				console.log(error)
			});
	}

	editValue() {

		fetch(localStorage.backoffice_api + '/api/table/RaisonClotureLead/' + this.props.match.params.id, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.raisonCloture)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response);
				}

				this.setState({
					updated: false,
					raisonCloture: null,
					fetchInProgress: false,
				}, () => {
					this.props.history.push('/table/RaisonClotureLead?success=updated')
				})
			})
			.catch((error) => {
				console.log(error)
			});
	}

	canSubmit(){
		return (this.state.updated && !this.state.fetchInProgress && this.state.raisonCloture.Libelle);
	}

	render() {

		if (!this.state.raisonCloture) {
			return null;
		}

		return (
			<div className='container-fluid'>

				<h2>Raisons de clôture</h2>

				<form onSubmit={this.handleSubmit.bind(this)}>

				<div className="row">
              		<div className="col-lg-6 offset-lg-3">
                		<div className="card">
							<div className="card-header">
								{this.props.match.params.id ? "Modifier une raison de clôture" : "Ajouter une raison de clôture"}
							</div>
                  			<div className="card-body pb-3">
								<div className="form-group row">
									<label htmlFor="IDAffaireTypeStop" className="col-sm-3 col-form-label">Type clôture</label>
									<div className="col-sm-6">
										<select required='required' value={this.state.raisonCloture.IDAffaireTypeStop ? this.state.raisonCloture.IDAffaireTypeStop : ''} onChange={this.onChange.bind(this)} id='IDAffaireTypeStop' name="IDAffaireTypeStop" className="form-control form-control-sm">
											{this.state.typesCloture.map((typeCloture, i) => {
												return <option key={i} value={typeCloture.IDAffaireTypeStop}>{typeCloture.Libelle}</option>
											})}
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="Descr" className="col-sm-3 col-form-label">Libelle</label>
									<div className="col-sm-6">
										<input type="text" className="form-control form-control-sm" id="Descr" name="Libelle" value={this.state.raisonCloture.Libelle} onChange={this.onChange.bind(this)} required='required' />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="AvecRelance" className="col-sm-3 col-form-label">Avec relance</label>
									<div className="col-sm-6 mt-1">
										<input className="form-check-input" type="checkbox" name="AvecRelance" checked={this.state.raisonCloture.AvecRelance} onChange={this.onChange.bind(this)} />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="Actif" className="col-sm-3 col-form-label">Actif</label>
									<div className="col-sm-6 mt-1">
										<input className="form-check-input" type="checkbox" name="Actif" checked={this.state.raisonCloture.Actif} onChange={this.onChange.bind(this)} />
									</div>
								</div>
								<div className="row" style={{ marginTop: '15px' }}>
									<div className="col text-center">
										<button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
											{this.state.fetchInProgress ?
												<span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
												: null}
											Enregistrer
										</button>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default Form;
