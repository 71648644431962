import React, { Component } from 'react';

import 'moment/locale/fr';
import moment from 'moment'
require('moment-precise-range-plugin')

class PasserelleHistoryErrors extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      passerelle: null,
      history: null,
    }
  }

  componentWillMount() {
    this.fetchPasserelle();
    this.fetchPasserelleHistoryErrors();
  }

  fetchPasserelle() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        passerelle: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchPasserelleHistoryErrors() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id+'/history/'+this.props.match.params.IDPasserelleDMSHisto+'/errors', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        history: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.passerelle || !this.state.history) {
      return null;
    }

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Passerelles</h2>

            <br />

            <div className="row">
              <div className='col'>
                <b>IDPasserelleDMS</b> : {this.state.passerelle.IDPasserelleDMS}
              </div>
              <div className='col'>
                <b>Libelle</b> : {this.state.passerelle.Libelle}
              </div>
              <div className='col'>
                <b>DateHRefProchainTransfert</b> : {this.state.passerelle.DateHRefProchainTransfert ? moment(this.state.passerelle.DateHRefProchainTransfert).utc(0).format('L LT') : null}
              </div>
            </div>

            <br />

            <div className="row">
              <div className='col'>
                <b>Date Appel WS</b> : {moment(this.state.history.DateHAppelWS).utc(0).format('L LT')}
              </div>
              <div className='col'>
                <b>Date Fin WS</b> : {moment(this.state.history.DateHFinWS).utc(0).format('L LT')}
              </div>
              <div className='col'>
                <b>Date référence</b> : {moment(this.state.history.DateHFinWS).utc(0).format('L LT')}
              </div>
            </div>

            <br />

            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.history.DetailErreurs}
            </pre>

          </div>
        </div>
      </div>
    );
  }
}

export default PasserelleHistoryErrors;
