import React, { Component } from 'react'
import Select from 'react-select'

const lodash = require('lodash')

class WLTP extends Component {

  constructor(props) {
    super(props);

    this.state = {
      update: false,
      sites: [],
      marques: [],
      filtre: ["FORD","OPEL","AUDI"],
      message: ""
    }

  }

  componentWillMount() {
    this.fetchSites();
    this.fetchMarques();
  }

  //récupérer toutes les marques
  fetchMarques(){
    fetch(localStorage.backoffice_api + '/api/getMarques', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var marques = []
      var marquesSort = lodash.sortBy(json, (marque) => {
        return marque.Descr
      })

      marquesSort.map( (row)=>marques.push( {value: row.IDMarque.toString() , label: row.Descr} ))

      marques = this.filtrerMarques(marques)
  
      this.setState({
        marques: marques
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  //récupérer tout les sites
  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sitesWLTP', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Plaque_Libelle
      })

      this.setState({
        sites: sites
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


  //ajoute une marque pour un site
  onChangeMarque(idSite,values) {
    var marques = values.map( (value) => {
      return value.value
    }).join(',');
    let sites = this.state.sites

    sites.map( (site)=>{
        if(site.IDSite == idSite){
            site.selectedMarques = marques
        }
    })

    this.setState({
      update: true,
    })
  }

  //sauvegarder les modifications
  save(){
    fetch(localStorage.backoffice_api + '/api/wltp/save', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'sites': this.state.sites
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      if(json.update == true){
        this.setState({
          message: json.message,
          update: false,
        })
        setTimeout(
          function() {
            this.setState({
              message: ""
            })
          }
          .bind(this),3000
        );
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  //filtrer les marques à afficher
  filtrerMarques(sites){
    let filterArray = []
    sites.map((site)=>{
      if( this.state.filtre.includes(site.label) ){
        filterArray.push(site)
      }
    })
    return filterArray
  }


  //rendu
  render() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>WLTP</h2>
            <div className="float-right mb-3 mr-5">
              {this.state.update == true ? 
                <button onClick={this.save.bind(this)} type="button" className="btn btn-primary">Enregistrer</button>
              : null}
            </div>
            {this.state.message != "" ?
              <div className="alert alert-success" role="alert">{this.state.message}</div> 
            : null}
            <table className="table table-striped">
              <thead>
                  <tr>
                  <th scope="col">Société</th>
                  <th scope="col">Site</th>
                  <th scope="col" className="text-center">Marques</th>
                  </tr>
              </thead>
              <tbody>
                {this.state.sites.map((site) => {
                  return (<tr key={site.IDSite}>
                    <td>{site.Plaque_Libelle}</td>
                    <td>{site.Descr}</td>
                    <td style={{width:'600px'}}>
                      <Select multi={true} onChange={this.onChangeMarque.bind(this,site.IDSite)} name="marques" value={site.selectedMarques} options={this.state.marques} searchable={true} placeholder='Marques...' />
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default WLTP;
