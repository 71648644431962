import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      updateInProgress: [],
    }
  }

  componentWillMount() {
    this.fetchSites()
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Descr;
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  hasProblemSite(site) {
    if (!site.Libelle) {
      return true;
    }

    if (!site.EstFictif && !site.EstVO && !site.EstVN) {
      return true;
    }

    if (site.EstVN && !site.Marques.length) {
      return true;
    }

    return false;
  }

  onChange(site, event) {

    var updateInProgress = this.state.updateInProgress;
    this.state.updateInProgress.push(site.IDSite);

    this.setState({
      updateInProgress: updateInProgress,
    })

    if (event.target.name === 'EstVO') {
      site.EstVO = event.target.checked;
    }
    else if (event.target.name === 'EstVN') {
      site.EstVN = event.target.checked;
    }
    else if (event.target.name === 'EstFictif') {
      site.EstFictif = event.target.checked;
    }

    fetch(localStorage.backoffice_api + '/api/sites/'+site.IDSite, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(site)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === site.IDSite;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexSite = lodash.findIndex(this.state.sites, (u) => {
        return u.IDSite === site.IDSite;
      })

      var sites = this.state.sites;
      sites[indexSite] = site;

      this.setState({
        sites: sites,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div>
      
        <Link to='/sites' className='btn btn-light' style={{ marginTop: '5px', marginBottom: '15px', }}>
          <i className="fa fa-list"></i> Gérer tous les sites
        </Link>

        <div className="alert alert-primary" role="alert">
          Sont affichés seulement les sites actifs pour eSeller Lead
        </div>

        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Société</th>
              <th>Site</th>
              <th>Libellé (eSeller Lead)</th>
              <th>Marque(s)</th>
              <th className='text-center'>Est fictif</th>
              <th className='text-center'>Est VN</th>
              <th className='text-center'>Est VO</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.sites.map((site) => {
              if (!site.Est_eSellerLeadActif) {
                return null;
              }

              return (<tr key={site.IDSite} className={this.hasProblemSite(site) ? 'table-warning' : ''}>
                <td>{site.IDSite}</td>
                <td>{site.Plaque_Libelle}</td>
                <td>{site.Descr}</td>
                <td>{site.Libelle}</td>
                <td>
                  {site.Marques.map((m) => {return m.Make}).join(', ')}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(site.IDSite) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, site)} checked={site.EstFictif} name='EstFictif' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(site.IDSite) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, site)} checked={site.EstVN} name='EstVN' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(site.IDSite) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, site)} checked={site.EstVO} name='EstVO' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td>
                  <Link to={'/sites/'+site.IDSite} className='btn btn-primary btn-sm'>
                    <i className="fa fa-pencil"></i>
                  </Link>
                </td>
              </tr>)
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(Index);
