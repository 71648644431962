import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

var lodash = require('lodash')

class Utilisateur extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updated: false,
      fetchInProgress: false,
      config: null,
      utilisateur: null,
      societes: [],
    }
  }

  componentWillMount() {
    this.fetchConfig()
    this.fetchSocietes()
    this.fetchUtilisateur()
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/utilisateurs/'+this.props.match.params.id+'/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      if (!json.ApiKey) {
        json.ApiKey= "4a0e09f26dcd1eab";
      }
      
      this.setState({
        config: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        societes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchUtilisateur() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateur: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleSubmitConfig(event) {
    event.preventDefault();

    this.setState({
      fetchInProgressConfig: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/utilisateurs/'+this.props.match.params.id+'/societes', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.config)
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        config: null,
        updated: false,
        fetchInProgressConfig: false,
      }, () => {
        this.fetchConfig();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeConfig(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var config = this.state.config

    if (type === 'checkbox') {
      config[name] = event.target.checked
    }
    else {
      config[name] = value
    }

    this.setState({
      updated: true,
      config: config,
    })
  }

  onChangeSociete(IDPlaque, event) {
    var config = this.state.config

    var index = lodash.findIndex(config.Plaques, (Plaque) => {
      return parseInt(IDPlaque, 10) === parseInt(Plaque.IDPlaque, 10);
    });

    config.Plaques[index].Actif = event.target.checked;

    this.setState({
      updated: true,
      config: config,
    })
  }

  render() {

    if (!this.state.config || !this.state.utilisateur || !this.state.societes.length)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>PlanetVO</h2>

            <br />

            <h3>Configuration</h3>

            <br />

            <form onSubmit={this.handleSubmitConfig.bind(this)}>

              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="BasePVO" className="col-sm-3 col-form-label">BasePVO</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="BasePVO" name="BasePVO" value={this.state.config.BasePVO} onChange={this.onChangeConfig.bind(this)} maxLength="50" required='required' />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="ApiKey" className="col-sm-3 col-form-label">ApiKey</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="ApiKey" name="ApiKey" value={this.state.config.ApiKey || '4a0e09f26dcd1eab'} onChange={this.onChangeConfig.bind(this)} maxLength="50" required='required' />
                    </div>
                  </div>
                </div>

                <div className="col"></div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="LoginInterface" className="col-sm-3 col-form-label">Login PVO</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="LoginInterface" name="LoginInterface" value={this.state.config.LoginInterface ? this.state.config.LoginInterface : ''} onChange={this.onChangeConfig.bind(this)} maxLength="50" required='required' />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="PasswordInterface" className="col-sm-3 col-form-label">Password PVO</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="PasswordInterface" name="PasswordInterface" value={this.state.config.PasswordInterface ? this.state.config.PasswordInterface : ''} onChange={this.onChangeConfig.bind(this)} maxLength="50" required='required' />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="NickName" className="col-sm-3 col-form-label">NickName PVO</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="NickName" name="NickName" value={this.state.config.NickName ? this.state.config.NickName : ''} onChange={this.onChangeConfig.bind(this)} maxLength="50" required='required' />
                      <small className="form-text text-muted">Attention mettre le nom du vendeur comme dans PVO !</small>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div>
                <h3>Sociétés</h3>

                <table className="table table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Libellé</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.config.Plaques.map((societe) => {
                      return (<tr key={societe.IDPlaque}>
                        <td>{societe.IDPlaque}</td>
                        <td>{societe.Libelle}</td>
                        <td>
                          <input type="checkbox" checked={societe.Actif} onChange={this.onChangeSociete.bind(this, societe.IDPlaque)} />
                        </td>
                      </tr>)
                  })}
                  </tbody>
                </table>

              </div>

              {this.state.updated ?
                <div className="row" style={{ marginTop: '15px' }}>
                  <div className="col text-center">
                    <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                      {this.state.fetchInProgress ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                      : null}
                      Enregistrer
                    </button>
                  </div>
                </div>
              : null}

            </form>
          </div>
        </div>
      </div>);
  }
}

export default withRouter(Utilisateur);
