import React, { useEffect, useState } from "react"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import { useParams } from "react-router-dom"
import { titleTypes } from "../Types/TitleType"
import { OrigineMarqueWally } from "../Types/Class/OrigineMarqueWally"
import Select from 'react-select'
import { Origine } from "../../../../types/Origine"
import { MarqueWally } from "../Types/Class/MarqueWally"
import { OrigineWally } from "../Types/Class/OrigineWally"
import List from "./List"
import { CompteWally } from "../Types/Class/CompteWally"
import { CrmParam } from "../Types/CrmParam"
import { SiteWally } from "../Types/Class/SiteWally"


interface FormAddOrigine
{
    idMarqueExternal?: number | null,
    idOrigineExternal?: number | null,
    idOrigineSeller?: number | null,
}

interface selectValue
{
    label: string,
    value: number,
}

export default function Index() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [ fetchProgress, setFetchProgress ] = useState<boolean>(false)
    const [ accounts, setAccounts ] = useState<CompteWally[]>([])
    const [ originesMarquesExternal, setOrigineMarqueExternal ] = useState<OrigineMarqueWally[]>([])
    const [ originesESBO, setOriginesESBO ] = useState<Origine[]>([])
    const [ marquesExternal, setMarquesExternal ] = useState<MarqueWally[]>([])
    const [ originesExternal, setOriginesExternal ] = useState<OrigineWally[]>([])
    const [ isSetting, setIsSetting ] = useState<boolean>(false)
    const [ isOnSaving, setIsOnSaving ] = useState<boolean>(false)
    const [ isCurrentSetting, setIsCurrentSetting ] = useState<boolean>(false)
    const [ accountSelected, setAccountSelect ] = useState<CompteWally | null>(null)
    const [ formAddOrigine, setFormAddOrigine ] = useState<FormAddOrigine>({
        idMarqueExternal: null,
        idOrigineExternal: null,
        idOrigineSeller: null,
    })

    const params: CrmParam = useParams<CrmParam>()
    useEffect(() => {
        fetchAccount()
        fetchOrigineEsbo()
    }, [])
    const fetchAccount = () => {
        setFetchProgress(true)
        backofficeApiClient
            .get<CompteWally[]>('/interfaces/wally/accounts')
            .then((accountData: CompteWally[]) => {
                if (accountData.length === 0) return
                const transformedAccounts = accountData.map(account => new CompteWally(account))
                setAccounts(transformedAccounts)
            }).catch((err) => {
            console.error('Erreur lors de la récupération des comptes:', err)
        }).finally(() => setFetchProgress(false))
    }

    const fetchOrigineEsbo = () => {
        setFetchProgress(true)
        backofficeApiClient
            .get<Origine[]>('/interfaces/wally/origines')
            .then((r: Origine[]) => {
                if (r.length === 0) {
                    setOriginesESBO([])
                    return
                }
                const transformedAccounts = r.map(ori => new Origine(ori))
                                             .sort(sortByOrigineESBO)
                setOriginesESBO(transformedAccounts)
            }).catch((err) => {
            console.error('Erreur lors de la récupération les origines eSeller:', err)
        }).finally(() => setFetchProgress(false))
    }

    const fetchReloadDataAccount = async (acc_id: string) => {
        setFetchProgress(true)

        try {
            /// ORIGINE
            const origineData = await backofficeApiClient
                .get<OrigineWally[]>('/interfaces/wally/accounts/' + acc_id + '/origines')
            const transformedDataOrigine = origineData.map(ori => new OrigineWally(ori))
            setOriginesExternal(transformedDataOrigine)

            /// Marque
            const marqueData = await backofficeApiClient
                .get<OrigineWally[]>('/interfaces/wally/accounts/' + acc_id + '/marques')
            const transformedDataMarques = marqueData.map(mq => new MarqueWally(mq))
            setMarquesExternal(transformedDataMarques)

            /// Origine / Marque
            const origineMarqueData = await backofficeApiClient
                .get<OrigineMarqueWally[]>('/interfaces/wally/accounts/' + acc_id + '/marques-origines')
            const transformedData = origineMarqueData
                .map(orimq => new OrigineMarqueWally(orimq, transformedDataMarques, transformedDataOrigine, originesESBO))
                .sort(sortByOriginesMarques)
            setOrigineMarqueExternal(transformedData)

        } catch (err) {
            console.error(err)
        } finally {
            setFetchProgress(false)
        }

    }

    const fetchOrigineMarques = async (acc_id: string) => {
        /// Origine / Marque
        const origineMarqueData = await backofficeApiClient
            .get<OrigineMarqueWally[]>('/interfaces/wally/accounts/' + acc_id + '/marques-origines')
        console.log("origine")
        const transformedData = origineMarqueData
            .map(orimq => new OrigineMarqueWally(orimq, marquesExternal, originesExternal, originesESBO))
            .sort(sortByOriginesMarques)
        setOrigineMarqueExternal(transformedData)

    }

    const handleSelectAccount = (e: any) => {
        const accSel = accounts.find(acc => acc.IdWallyCompte == e.target.value)
        if (!accSel) {
            setAccountSelect(null)
            setOriginesExternal([])
            setMarquesExternal([])
            return
        }
        if (isCurrentSetting) { // Si en cours de modif alors on met une alert
            if (!window.confirm("Vous avez des modifications en cours. Annuler ? ")) return
        }
        setAccountSelect(accSel)
        fetchReloadDataAccount(accSel.IdWallyCompte)
        setIsCurrentSetting(false)
    }

    // FORM ADD Origin
    const handleOnChangeMarqueExternal = (marqueExt: selectValue) => {
        setFormAddOrigine((prevFormAddOrigine) => ({ ...prevFormAddOrigine, idMarqueExternal: marqueExt ? marqueExt.value : null }))
    }
    const handleOnChangeOrigineExternal = (origineExt: selectValue) => {
        setFormAddOrigine((prevFormAddOrigine) => ({ ...prevFormAddOrigine, idOrigineExternal: origineExt ? origineExt.value : null }))
    }
    const handleOnChangeOrigineESBO = (origineESBO: selectValue) => {
        setFormAddOrigine((prevFormAddOrigine) => ({
            ...prevFormAddOrigine,
            idOrigineSeller: origineESBO ? origineESBO.value : null,
        }))
    }

    const handleFetchAddOrigineMarque = () => {
        if (!accountSelected) return
        setIsOnSaving(true)
        backofficeApiClient
            .post<any>('/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte + "/marques-origines", formAddOrigine)
            .then((r: any) => {
                fetchOrigineMarques(accountSelected.IdWallyCompte)
                setFormAddOrigine({ idMarqueExternal: null, idOrigineExternal: null, idOrigineSeller: null })
            })
            .catch((err) => {
                const err_resp = JSON.parse(err.message)
                if (err_resp.error_code === 409) alert(err_resp.message)
                console.error('Erreur lors de l\'enregistrement de l\'origine:', err)
            }).finally(() => setIsOnSaving(false))
    }

    // SETTING Origin

    const handleChangeOrigin = (origineMarqueToUpdate: OrigineMarqueWally) => {
        return () => {
            const updatedOrigineMarque: OrigineMarqueWally = {
                ...origineMarqueToUpdate,
                isCurrentChange: true,
            }
            setOrigineMarqueExternal((prevOme: OrigineMarqueWally[]) =>
                prevOme.map(ome =>
                    ome.getPrimaryKey() === origineMarqueToUpdate.getPrimaryKey() ? updatedOrigineMarque : ome,
                ),
            )
        }
    }

    const handleFetchSaveChangeOrigin = (origin: OrigineMarqueWally) => {
        return () => {
            if (!accountSelected) return
            backofficeApiClient
                .put<any>('/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte + "/marques-origines/" + origin.IdWallyOrigine + "/" + origin.IdWallyMarque,
                    {idOrigineAction: origin.IDOrigineActionModifying})
                .then((r: any) => {
                    fetchOrigineMarques(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    const err_resp = JSON.parse(err.message)
                    if (err_resp.error_code === 409) alert(err_resp.message)
                    console.error('Erreur lors de la modification de l\'origine eSeller:', err)
                })
        }
    }

    // DELETING Origin
    const handleDeletingOrigin = (origin: OrigineMarqueWally) => {
        return () => {
            if (!accountSelected) return

            backofficeApiClient
                .delete<any>('/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte + "/marques-origines/" + origin.IdWallyOrigine + "/" + origin.IdWallyMarque, null)
                .then((r: any) => {
                    fetchOrigineMarques(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    console.error('Erreur lors de la suppression de l\'origine:', err)
                })
        }
    }


    function sortByOriginesMarques(a:OrigineMarqueWally, b:OrigineMarqueWally) {
        if( !a.origineESBO) return 0;
        if( !b.origineESBO) return 0;

        const nameA = a.origineESBO.LibelleFamille // ignore upper and lowercase
        const nameB = b.origineESBO.LibelleFamille  // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        // names must be equal
        return 0
    }

    function sortByOrigineESBO(a:Origine, b:Origine) {
        const nameA = a.LibelleFamille // ignore upper and lowercase
        const nameB = b.LibelleFamille // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        // names must be equal
        return 0
    }

    return <>
        <div className={"container-fluid mb-5 pr-5 pl-5"}>
            <div className={"row"}>
                <div>
                    <ButtonAction href={"/interfaces/wally/"} className={"btn btn-secondary"}>
                        <i className={"fa-solid fa-chevron-left fa-xs"}></i>
                    </ButtonAction>
                </div>
                <div className={"col"}>
                    <h2 className={"mb-4 d-flex justify-content-between"}>
                        <div>
                            Origine {titleTypes[params.crm_name]}
                        </div>
                        <div style={{ fontSize: "0.6em" }} className={"text-secondary"}>
                            Actif de 7h à 20h du Lundi au samedi
                        </div>
                    </h2>
                </div>
            </div>

            <div className={"row mt-3"}>
                <div className={"col"}>
                    <div className={"text-center"}>
                        {fetchProgress && <span><i className="fa fa-spinner fa-spin fa-fw fa-xl"></i>&nbsp;</span>}
                    </div>

                    <div className={"col bg-secondary p-1"}>
                        <select value={accountSelected?.IdWallyCompte} onChange={handleSelectAccount} name="IDDRProfileEsellerLead"
                                className="form-control form-control-sm col-3">
                            <option value="0">Sélectionner un compte</option>
                            {accounts.map((account) => {
                                return <option key={account.IdWallyCompte} value={account.IdWallyCompte}>{account.Identifiant + " " + account.EmailNotification}</option>
                            })}
                        </select>
                    </div>
                    <table className={"table table-bordered table-hover"}>
                        <thead>
                        <tr>
                            <th className={"w-25"}>Marque{titleTypes[params.crm_name]}</th>
                            <th className={"w-auto"}>Origine {titleTypes[params.crm_name]}</th>
                            <th className={"w-50"}>Origine eSeller</th>
                            <th className={"w-auto"}>Action</th>
                        </tr>

                        <tr className={"thead"} key={"new"}>
                            <td>
                                <Select name="idMarqueExternal"
                                        value={formAddOrigine.idMarqueExternal}
                                        options={marquesExternal.map((m) => {
                                            return { value: m.IdWallyMarque, label: m.Nom }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder="Choisissez une marque du CRM"
                                        onChange={handleOnChangeMarqueExternal}
                                        isMulti={false} isClearable={true}
                                />

                            </td>
                            <td>
                                <Select name="idOrigineExternal"
                                        value={formAddOrigine.idOrigineExternal}
                                        options={originesExternal.map((o) => {
                                            return { value: o.IdWallyOrigine, label: o.Nom }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder="Choisissez origine du CRM"
                                        onChange={handleOnChangeOrigineExternal}
                                        isMulti={false} isClearable={true}
                                />
                            </td>
                            <td>
                                <Select name="idOrigineSeller"
                                        value={formAddOrigine.idOrigineSeller}
                                        options={originesESBO.map((o) => {
                                            return { value: o.IDOrigineAction, label: o.LibelleFamille + " - " + o.LibelleSousFamille + " - " + o.LibelleOrigine }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder="Choisissez une origine"
                                        onChange={handleOnChangeOrigineESBO}
                                        isMulti={false} isClearable={true}
                                />
                            </td>
                            <td className={"d-inline-flex justify-content-center col"}>
                                <ButtonAction2 onClick={handleFetchAddOrigineMarque} type="button"
                                               disabled={(
                                                   formAddOrigine.idOrigineSeller === null ||
                                                   formAddOrigine.idOrigineExternal === null ||
                                                   formAddOrigine.idMarqueExternal === null)}
                                               className="btn btn-secondary bg-success ">
                                    <i className={"fa-solid fa-plus fa-xs"}></i>
                                </ButtonAction2>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {originesMarquesExternal.map((ome: OrigineMarqueWally) =>
                            <List key={ome.getPrimaryKey()}
                                  originExt={ome}
                                  originesESBO={originesESBO}
                                  setOrigineMarqueExternal={setOrigineMarqueExternal}
                                  handleChangeOrigin={handleChangeOrigin}
                                  handleFetchSaveChangeOrigin={handleFetchSaveChangeOrigin}
                                  handleDeletingOrigin={handleDeletingOrigin}
                            />,
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </>
}
