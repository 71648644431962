
import React, { Component } from 'react';
import moment from 'moment'

class ModalDetailsLog extends Component {

  render () {

    return (
    <div className="modal" role="dialog" id="modalDetailsLog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content border-0">
                <div className="modal-header pt-1 bg-light">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body p-0 pb-1">
                  {
                    this.props.log ? 
                      <table className="table table-sm table-striped">
                        <tbody>
                          <tr>
                            <th>Date</th>
                            <td>{moment(this.props.log.Datetime.replace("Z", "")).format("DD-MM-YYYY hh:mm:ss")}</td>
                          </tr>
                          <tr>
                            <th>Utilisateur</th>
                            <td>{this.props.log.PeopleName}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{this.props.log.Email}</td>
                          </tr>
                          <tr>
                            <th>Action</th>
                            <td>{this.props.log.Action}</td>
                          </tr>
                          <tr>
                            <th>Requête</th>
                            <td style={{wordBreak: 'break-all'}}>
                              {this.props.log.Request}
                            </td>
                          </tr>
                          <tr>
                            <th>Réponse</th>
                            <td style={{wordBreak: 'break-all'}}>
                              {this.props.log.Response}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    :
                    null
                  }
                </div>
                
            </div>
        </div>
    </div>
    )
  }
}

export default ModalDetailsLog;