import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'

var lodash = require('lodash')

class Kpi extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr')

    this.state = {
      affaires: [],
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
      fetchInProgress: true,
      execute: null,
      sites: null,
      updateInProgress: false,
      config: null,
    }
  }

  componentWillMount() {
    this.fetchLeads()
    this.fetchLastExecute()
    this.fetchSites()
    this.fetchConfig()
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Descr;
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/hondaKpi/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        config: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchLeads() {
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/hondaKpi/kpi?start_date='+this.state.start_date.format('YYYY-MM-DD HH:mm:ss')+'+&end_date='+this.state.end_date.format('YYYY-MM-DD HH:mm:ss'), {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var affaires = lodash.orderBy(json, (j) => {
        return j.DateDebut;
      }, 'desc')

      this.setState({
        affaires: affaires,
        fetchInProgress: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchLastExecute() {
    fetch(localStorage.backoffice_api + '/api/interfaces/hondaKpi/execute', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        execute: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  displayStatutAffaire(affaire) {
    var statusAffaire = affaire.IDAffaireStatut;

    if (statusAffaire == 1) {
      return 'A valider';
    }
    else if (statusAffaire == 2) {
      return 'Annulée';
    }
    else if (statusAffaire == 3) {
      return 'En cours';
    }
    else if (statusAffaire == 7) {
      return 'Fusionnée';
    }
    else if (statusAffaire == 5) {
      return 'Gagnée';
    }
    else if (statusAffaire == 4) {
      var reason = '';
      if (affaire.AffaireRaisonStop_Libelle) {
        reason += ' ('+affaire.AffaireRaisonStop_Libelle+')';
      }
      return 'Perdue' + reason;
    }
    else if (statusAffaire == 6) {
      return 'Reportée';
    }

    return statusAffaire;
  }

  displayOffres(offres) {

    if (!offres.length) {
      return null;
    }

    return (<ul>
      {offres.map((offre) => {
        return <li key={offre.IDOffres}>{offre.IDOffres} - {offre.OFS_Descr}</li>;
      })}
    </ul>)
  }

  displayErrors(errors) {

    if (!errors) {
      return null;
    }

    return <ul>
      {errors.map((e, i) => {
        return <li key={i}>{e.code} - {e.message}</li>
      })}
    </ul>
  }

  getFirstKpi(affaire) {

    let length = affaire.kpi.length ? affaire.kpi.length : 1;

    var datas = affaire.kpi[0] && affaire.kpi[0].datas ? JSON.parse(affaire.kpi[0].datas) : {};
    var response = affaire.kpi[0] && affaire.kpi[0].response ? JSON.parse(affaire.kpi[0].response) : {};

    return (<tr key={affaire.CodeAffaireSource+'-0'}>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.CodeAffaireSource}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{moment(affaire.DateDebut).utc().format('DD/MM/YYYY HH:mm')}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.DateOuvertureLead ? moment(affaire.DateOuvertureLead).utc().format('DD/MM/YYYY HH:mm') : null}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{affaire.IDClient} - {affaire.Name} {affaire.Firstname}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{this.displayStatutAffaire(affaire)}</td>
        <td style={{ verticalAlign: 'middle', }} rowSpan={length}>{this.displayOffres(affaire.offres)}</td>
        {affaire.kpi[0] ? <td>{affaire.kpi[0].action}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{moment(affaire.kpi[0].date).format('DD/MM/YYYY HH:mm')}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{datas['date-rappel'] ? moment(datas['date-rappel']).format('DD/MM/YYYY HH:mm') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{datas['date-essai'] ? moment(datas['date-essai']).format('DD/MM/YYYY') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{datas['date-achat'] ? moment(datas['date-achat']).format('DD/MM/YYYY') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{datas['dernier-contact'] ? moment(datas['dernier-contact']).format('DD/MM/YYYY HH:mm') : null}</td> : <td></td>}
        {affaire.kpi[0] ? <td>{response['message']} {this.displayErrors(response.errors)}</td> : <td></td>}
      </tr>);
  }

  getKpi(affaire, k) {

    var datas = k.datas ? JSON.parse(k.datas) : {};
    var response = k.response ? JSON.parse(k.response) : {};

    return (<tr key={k.CodeAffaireSource+'-'+k.IDHondaKPILogs}>
        <td>{k.action}</td>
        <td>{moment(k.date).format('DD/MM/YYYY HH:mm')}</td>
        <td>{datas['date-rappel'] ? moment(datas['date-rappel']).format('DD/MM/YYYY HH:mm') : null}</td>
        <td>{datas['date-essai'] ? moment(datas['date-essai']).format('DD/MM/YYYY') : null}</td>
        <td>{datas['date-achat'] ? moment(datas['date-achat']).format('DD/MM/YYYY') : null}</td>
        <td>{datas['dernier-contact'] ? moment(datas['dernier-contact']).format('DD/MM/YYYY HH:mm') : null}</td>
        <td>{response['message']} {this.displayErrors(response.errors)}</td>
      </tr>);
  }

  addMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).add(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).add(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  subMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).subtract(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).subtract(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  onChangeApiKey(CodeConstructeur, event) {
    console.log(event.target.value)
    var config = this.state.config;

    var index = lodash.findIndex(config, (c) => {
      return c.CodeConstructeur == CodeConstructeur;
    })

    if (index < 0) {
      config.push({
        CodeConstructeur: CodeConstructeur,
        ApiKey: event.target.value,
      })
    }
    else {
      config[index].ApiKey = event.target.value;
    }

    this.setState({
      config: config,
    })
  }

  saveConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/hondaKpi/config', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.config)
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      window.location.reload();
      return;
    })
    .catch((error) => {
      console.log(error)
    });
  }

  getValue(CodeConstructeur) {
    var index = lodash.findIndex(this.state.config, (c) => {
      return c.CodeConstructeur == CodeConstructeur;
    })

    if (index < 0) {
      return '';
    }

    return this.state.config[index].ApiKey;
  }

  render() {


    if (this.state.fetchInProgress) {
      return null;
    }


    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>Honda - KPI</h2>

            <br />

            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item">
                <a className="nav-link active" id="logs-tab" data-toggle="tab" href="#logs" role="tab" aria-controls="logs" aria-selected="true">Logs</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="configuration-tab" data-toggle="tab" href="#configuration" role="tab" aria-controls="configuration" aria-selected="true">Paramétrage</a>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
              <div className="tab-pane fade show active" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                <div>
                  <br />

                  {this.state.execute && this.state.execute.date_last_execute ?
                    <span style={{ fontSize: '12px', fontWeight: 'normal', }}>Dernier envoi des KPI : {moment(this.state.execute.date_last_execute).utc().format('DD/MM/YYYY HH:mm:ss')}</span>
                  : null}

                  <div className='row' style={{ marginBottom: '15px', }}>
                    <div className="col-md-4 text-right">
                        <a onClick={this.subMonth.bind(this)}>
                          <i className="fa fa-chevron-left"></i>
                        </a>
                    </div>
                    <div className="col-md-4 text-center">
                      Du <b>{this.state.start_date.format('DD/MM/YYYY')}</b> au <b>{this.state.end_date.format('DD/MM/YYYY')}</b>
                    </div>
                    <div className="col-md-4 text-left">
                      <a onClick={this.addMonth.bind(this)}>
                        <i className="fa fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>

                  <table className="table table-bordered table-sm" style={{'fontSize': '11px'}}>
                    <thead>
                      <tr>
                        <th>Code affaire source</th>
                        <th>Date début affaire</th>
                        <th>Date ouverture affaire</th>
                        <th>Prospect</th>
                        <th>Status affaire</th>
                        <th>Offres</th>
                        <th>Type KPI</th>
                        <th>Date KPI</th>
                        <th>Date rappel</th>
                        <th>Date Essai</th>
                        <th>Date Achat</th>
                        <th>Dernier Contact</th>
                        <th>Réponse</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.affaires.map((affaire) => {

                        var content = []
                        content.push(this.getFirstKpi(affaire));

                        if (affaire.kpi.length > 1) {
                          affaire.kpi.map((k, i) => {
                            if (i == 0) {
                              return
                            }

                            content.push(this.getKpi(affaire, k));
                          })
                        }

                        return content;

                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="tab-pane fade" id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                <div>
                  <br />

                  {this.state.sites && this.state.config ?
                    <div>
                      <table className="table table-striped table-hover table-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Société</th>
                            <th>Site</th>
                            <th>Code constructeur</th>
                            <th>x-api-dealer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.sites.map((site) => {
                            if (!site.CodeConstructeur) {
                              return null;
                            }

                            return (<tr key={site.IDSite}>
                              <td>{site.IDSite}</td>
                              <td>{site.Plaque_Libelle}</td>
                              <td>{site.Descr}</td>
                              <td>{site.CodeConstructeur}</td>
                              <td>
                                <textarea className='form-control' value={this.getValue(site.CodeConstructeur)} onChange={this.onChangeApiKey.bind(this, site.CodeConstructeur)} />
                              </td>
                            </tr>)
                        })}
                        </tbody>
                      </table>

                      <button className='btn btn-primary btn-sm text-white' onClick={this.saveConfig.bind(this)}>
                        {this.state.updateInProgress ? <span><i className='fa fa-spinner fa-spin fa-fw'></i></span> : ''} Enregistrer
                      </button>
                    </div>
                  : null}

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Kpi);
