import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import moment from 'moment'

import lodash from "lodash";
import { GrilleMalusEcologique } from './Models/GrilleMalusEcologique';

interface GrilleMalusEcologiqueValueDTO {
    EmissionCO2: number
    MontantTaxe: number | null
}

class GrilleMalusEcologiqueDTO {
    IDGrilleMalusEcologique?: number
    Libelle: string = ""
    DateDebut?: string
    DateFin?: string
    CO2Min?: number
    CO2Max?: number

    Values: GrilleMalusEcologiqueValueDTO[] = []
}

export default function FormGrilleMalusEcologique() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [fetching, setFetching] = useState<boolean>(true)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [updated, setUpdated] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [message, setMessage] = useState<string>()
    const [formDto, setFormDto] = useState<GrilleMalusEcologiqueDTO>(new GrilleMalusEcologiqueDTO())

    const { IDGrille } = useParams<{ IDGrille?: string }>();
    const history = useHistory();

    useEffect(() => {
        if (IDGrille) {
            fetchGrid()
        }
        else {
            setFetching(false)
        }
    }, [])

    useEffect(() => {
        if (formDto.CO2Min && formDto.CO2Max) {
            generateValues()
        }
    }, [formDto.CO2Min, formDto.CO2Max])

    const generateValues = () => {
        let values: GrilleMalusEcologiqueValueDTO[] = formDto.Values
        if (formDto.CO2Min && formDto.CO2Max && formDto.CO2Max >= formDto.CO2Min) {
            for (let emissionCO2 = formDto.CO2Min; emissionCO2 <= formDto.CO2Max; ++emissionCO2) {
                if (!values.find(v => v.EmissionCO2 == emissionCO2)) {
                    values.push({
                        EmissionCO2: emissionCO2,
                        MontantTaxe: null
                    })
                }
            }

            values = values.filter(v => v.EmissionCO2 >= formDto.CO2Min! && v.EmissionCO2 <= formDto.CO2Max!)
            values = lodash.sortBy(values, "EmissionCO2")
        }

        onChange({ Values: values })
    }

    const onChange = (changes: Partial<GrilleMalusEcologiqueDTO>) => {
        setFormDto(prev => {
            return {
                ...prev,
                ...changes
            }
        })
        setUpdated(true)
    }

    const onChangeCO2Min = (value: number) => {
        if (value < 0) {
            value = 0
        }
        else if (value > 1000) {
            value = 1000
        }

        let co2Max = formDto.CO2Max
        if (!co2Max || value >= co2Max) {
            co2Max = value
        }

        onChange({
            CO2Min: value,
            CO2Max: co2Max
        })
    }

    const onChangeCO2Max = (value: number) => {
        if (value < 0) {
            value = 0
        }
        else if (value > 1000) {
            value = 1000
        }

        onChange({ CO2Max: value })
    }

    const onChangeTaxe = (emissionCO2: number, montantTaxe: number) => {
        setFormDto(prev => {
            const updatedValues = prev.Values.map(value =>
                value.EmissionCO2 === emissionCO2
                    ? { ...value, MontantTaxe: montantTaxe }
                    : value
            );

            return {
                ...prev,
                Values: updatedValues
            };
        });

        setUpdated(true);
    }

    const fetchGrid = async () => {
        setFetching(true)

        try {
            const grid = await backofficeApiClient.get<GrilleMalusEcologique>(`/malusEcologique/${IDGrille}`)

            setFormDto(prev => {
                return {
                    ...prev,
                    IDGrilleMalusEcologique: grid.IDGrilleMalusEcologique,
                    Libelle: grid.Libelle,
                    DateDebut: moment(grid.DateDebut).format("YYYY-MM-DD"),
                    DateFin: moment(grid.DateFin).format("YYYY-MM-DD"),
                    CO2Min: grid.CO2Min,
                    CO2Max: grid.CO2Max,
                    Values: grid.Values
                }
            })
        }
        catch (error: any) {
            console.log(error)
            setError(error.message)
        }
        finally {
            setFetching(false)
        }
    }

    const createGrid = async () => {
        setSubmitting(true)

        try {
            const result = await backofficeApiClient.post<{ IDGrilleMalusEcologique: number }>("/malusEcologique", formDto)
            history.push(`/malusEcologique/${result.IDGrilleMalusEcologique}`);
        }
        catch (error: any) {
            console.log(error)
            setError(error.message)
        }
        finally {
            setSubmitting(false)
        }
    }

    const updateGrid = async () => {
        setSubmitting(true)

        try {
            await backofficeApiClient.put(`/malusEcologique/${formDto.IDGrilleMalusEcologique}`, formDto)
            setSubmitting(false)
            setMessage("Modifications enregistées")
        }
        catch (error: any) {
            console.log(error)
            setError(error.message)
        }
        finally {
            setSubmitting(false)
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()

        setError("")
        setMessage("")
        if (formDto.IDGrilleMalusEcologique) {
            updateGrid()
        }
        else {
            createGrid()
        }
    }

    return (
        <div className='container-fluid mb-2'>
            <h2>Grille malus écologique {formDto.Libelle ? `: ${formDto.Libelle}` : ""}</h2>
            <br />

            {error &&
                <div className='alert alert-danger'>{error}</div>
            }
            {message &&
                <div className='alert alert-info'>{message}</div>
            }

            {fetching ?
                <div className="d-flex flex-col align-items-center justify-content-center">
                    <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                </div>
                :
                <form onSubmit={(e) => handleSubmit(e)}>

                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="DateDebut" className="col-sm-1 col-form-label">Date début</label>
                                <div className="col-sm-2">
                                    <input type="date" onChange={(e) => onChange({ DateDebut: e.currentTarget.value })} value={formDto.DateDebut ?? ""} className="form-control form-control-sm" required id="dateDebut" name="dateDebut" />
                                </div>
                                <label htmlFor="DateFin" className="col-sm-1 col-form-label">Date fin</label>
                                <div className="col-sm-2">
                                    <input type="date" onChange={(e) => onChange({ DateFin: e.currentTarget.value })} min={formDto.DateDebut} value={formDto.DateFin ?? ""} className="form-control form-control-sm" id="dateFin" name="dateFin" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="Libellé" className="col-sm-1 col-form-label">Libellé</label>
                                <div className="col-sm-3">
                                    <input type="text" onChange={(e) => onChange({ Libelle: e.currentTarget.value })} className="form-control form-control-sm" id="libelle" name="libelle" value={formDto.Libelle} maxLength={60} required />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="CO2Min" className="col-sm-2 col-form-label">Valeur CO2 première ligne</label>
                                <div className="col-sm-1">
                                    <input type="number" onChange={(e) => onChangeCO2Min(Number(e.currentTarget.value))} className="form-control form-control-sm" id="CO2Min" name="CO2Min" value={formDto.CO2Min ?? ""} required />
                                </div>
                                <label htmlFor="CO2Max" className="col-sm-2 col-form-label">Valeur CO2 dernière ligne</label>
                                <div className="col-sm-1">
                                    <input type="number" onChange={(e) => onChangeCO2Max(Number(e.currentTarget.value))} className="form-control form-control-sm" id="CO2Max" name="CO2Max" value={formDto.CO2Max ?? ""} required />
                                </div>
                            </div>
                        </div>
                    </div>

                    <table className="table table-striped table-hover table-sm">
                        <thead>
                            <tr>
                                <th>Taux emission CO2</th>
                                <th>Montant taxe CO2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formDto.Values.map((v, idx) => {
                                return (<tr key={v.EmissionCO2}>
                                    <td>{v.EmissionCO2} {idx == formDto.Values.length - 1 ? "(et supérieur)" : ""}</td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm col-sm-3" value={v.MontantTaxe ?? ""} required onChange={(e) => onChangeTaxe(v.EmissionCO2, Number(e.currentTarget.value))} />
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>

                    {updated ?
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col text-center">
                                <button disabled={submitting} type="submit" className="btn btn-primary">
                                    {submitting ?
                                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                                        : null}
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                        : null}
                </form>
            }
        </div>
    );
}