import { ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import React, { MouseEventHandler } from "react"
import Select from "react-select"
import { Site } from "../../../../types/Site"
import { SiteWally } from "../Types/Class/SiteWally"

interface Props
{
    sites: Site[],
    ws: SiteWally,
    setWallySites: (e: any) => void,
    handleChangePdv: (siteToUpdate: SiteWally) => MouseEventHandler
    handleDeletingSite: (pdv: SiteWally) => MouseEventHandler
    handleFetchSaveChangePdv: (pdv: SiteWally) => MouseEventHandler
}

interface selectValue
{
    label: string,
    value: number,
}

export default function List(props: Props) {

    const handleOnChangeSetWsAction = (originSelected: selectValue) => {
        if (!originSelected) {
            alert("Veuillez sélectionner une origine ou annulez la modification")
            return
        }
        const updatedSite: SiteWally = {
            ...props.ws,
            isCurrentChange: true,
            IDSiteModifying: originSelected.value,
        }
        props.setWallySites((prevPdv: SiteWally[]) =>
            prevPdv.map(pdv =>
                pdv.getPrimaryKey() === props.ws.getPrimaryKey() ? updatedSite : pdv,
            ),
        )
    }

    const handleCancelModification = () => {

        const updatedWallySite: SiteWally = {
            ...props.ws,
            isCurrentChange: false,
            IDSiteModifying: props.ws.IDSite,
        }
        props.setWallySites((prevWallySite: SiteWally[]) =>
            prevWallySite.map(pws =>
                pws.getPrimaryKey() === props.ws.getPrimaryKey() ? updatedWallySite : pws,
            ),
        )
    }

    return <tr key={props.ws.getPrimaryKey()}>
        <td>
            {props.ws.NomWallySite}
        </td>
        <td>
            {props.ws.NomWallyMarque}
        </td>
        <td>
            <>
                {props.ws.isCurrentChange ?
                    <Select name="idSiteSeller"
                            value={props.ws.IDSiteModifying}
                            options={props.sites.map((site) => {
                                return { value: site.IDSite, label: site.Descr }
                            })}
                            isSearchable={true} required={false}
                            placeholder="Changez le site Eseller"
                            onChange={handleOnChangeSetWsAction}
                            isMulti={false} isClearable={false}
                    />
                    :
                    <>  {(props.ws.IDSite !== null) ? props.ws.SiteDescr : "-"}   </>
                }
            </>
        </td>
        <td>
            <div className={"d-inline-flex"}>
                {(props.ws.IDSite !== null) ?
                    <>
                        {props.ws.isCurrentChange ?
                            <>
                                <ButtonAction2 onClick={props.handleFetchSaveChangePdv(props.ws)}
                                               className="btn btn-success btn-sm mr-2">
                                    <i className={"fa-solid fa-check fa-xs"}></i>
                                </ButtonAction2>
                                <ButtonAction2 onClick={handleCancelModification}
                                               className="btn btn-secondary btn-sm mr-2">
                                    <i className={"fa-solid fa-ban fa-xs"}></i>
                                </ButtonAction2>
                            </>
                            :
                            <>
                                <ButtonAction2 onClick={props.handleChangePdv(props.ws)} className="btn btn-primary btn-sm mr-2">
                                    <i className={"fa-solid fa-pen fa-xs"}></i>
                                </ButtonAction2>
                                <ButtonAction2 onClick={props.handleDeletingSite(props.ws)}
                                               className="btn btn-secondary btn-danger btn-sm">
                                    <i className={"fa-solid fa-trash fa-xs"}></i>
                                </ButtonAction2>
                            </>
                        }
                    </>
                    :
                    <ButtonAction2 onClick={props.handleFetchSaveChangePdv(props.ws)}
                                   className="btn btn-success btn-sm mr-2">
                        <i className={"fa-solid fa-check fa-xs"}></i>
                    </ButtonAction2>

                }


            </div>
        </td>
    </tr>
}