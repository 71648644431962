//Very simple BackOffice API Client
export default class BackOfficeAPIClient {

    async request(endpoint: string, method: string, data?: any): Promise<any> {
        const url = `${localStorage.backoffice_api}/api${endpoint}`;
        const options: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.JWT,
            },
            body: data ? JSON.stringify(data) : undefined
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                console.error(`HTTP error! Status: ${response.status}`)
                throw new Error(await response.text());
            }
            return await response.json();
        } catch (error: any) {
            this.handleError(error);
            throw error;
        }
    }

    async get<T>(endpoint: string): Promise<T> {
        return await this.request(endpoint, 'GET');
    }

    async post<T>(endpoint: string, data: any): Promise<T> {
        return await this.request(endpoint, 'POST', data);
    }

    async put<T>(endpoint: string, data: any): Promise<T> {
        return await this.request(endpoint, 'PUT', data);
    }

    async patch<T>(endpoint: string, data: any): Promise<T> {
        return await this.request(endpoint, 'PATCH', data);
    }

    async delete<T>(endpoint: string, data: any): Promise<T> {
        return await this.request(endpoint, 'DELETE', data);
    }

    private handleError(error: any) {
        console.error('API request error:', error);
    }
}