import lodash from "lodash"

export class GrilleMalusMasseValueDTO
{
    Key!: string
    MasseMin!: number
    MasseMax!: number | null
    MontantTaxe!: number

    constructor(masseMin = 0) {
        this.Key = lodash.uniqueId()
        this.MasseMin = masseMin
    }
}