import React, { Component } from 'react';
var lodash = require('lodash')

class ModalCreateChamp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            descr: "",
            typeMaterielAgriIDs: [],

            saving:false,
        }
    }

    submitChamp(){
        if(this.state.saving)return false;

        this.setState({
            saving:true
        });
        fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/champs', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                descr: this.state.descr,
                typeMaterielAgriIDs: this.state.typeMaterielAgriIDs
            })
        })
        .then((response) => {

            if (!response.ok) {
              response.json().then((value) => {
                alert("Erreur : "+value.message)
              });
      
              this.setState({
                saving: false,
              })
      
              throw Error(response);
            }

            this.setState({
                saving: false,
              })
            this.props.onSubmit();
        })
        .catch((error) => {
            console.log(error)
        });
    }

    canSubmit(){
        return this.state.descr.trim() != "" && !this.state.saving;
    }

    changeValue(name, value){
        this.setState({
            [name]:value
        });
    }

    selectTypeMateriel(event){
        var value = parseInt(event.target.value);
        var typeMaterielAgriIDs = this.state.typeMaterielAgriIDs;
        if(event.target.checked)
            typeMaterielAgriIDs.push(value);
        else {
            typeMaterielAgriIDs = lodash.filter(typeMaterielAgriIDs, (id) => {
                return id !== value
            })
        }

        this.setState({
            typeMaterielAgriIDs: typeMaterielAgriIDs
        })
    }

    reset(){
        this.setState({
            descr:'',
            typeMaterielAgriIDs: [],
        });
    }

    render() {

        return (
            <div className="modal" role="dialog" id="modalCreateChamp">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Créer un champ</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Description
                            </div>
                            <div className="col-8">
                                <input type="text" name="descr" maxLength="50" className="form-control" value={this.state.descr} onChange={(e) => this.changeValue(e.target.name, e.target.value)}/>
                            </div>
                        </div>
                        {this.props.typesMaterielAgri && this.props.typesMaterielAgri.length ? 
                            <div className="border mt-3">
                                <span className="p-2"><b>Associer ce champ aux types de matériels suivants</b></span>
                                <div style={{maxHeight:'500px',overflowY:'auto'}}>
                                    <table className="table table-striped table-sm table-hover mb-0">
                                        <tbody>
                                        {this.props.typesMaterielAgri.map((typeMat) => {
                                        return <tr key={typeMat.IDTypeMaterialAgri}>
                                            <td>
                                                <label className="label-row">
                                                    <input type="checkbox" value={typeMat.IDTypeMaterialAgri} checked={this.state.typeMaterielAgriIDs.indexOf(typeMat.IDTypeMaterialAgri) !== -1} onClick={this.selectTypeMateriel.bind(this)}/> {typeMat.Descr}
                                                </label>
                                            </td>
                                        </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        : null}
                    </div>
                    
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit'  data-dismiss="modal" disabled={!this.canSubmit()} onClick={() => this.submitChamp()}>Valider</button>&nbsp;
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalCreateChamp;

