import React, { Component } from 'react';
import Utils from '../../Utils';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      demande: {
        name: Utils.fromJWT('name'),
        sujet: '',
        commentaire: '',
      },
      success: null,
      sending: false,
    }
  }

  componentDidMount(){
    var demande = this.state.demande;
    demande.sujet = this.props.sujet;
    this.setState({
      demande: demande
    })
  }

  onChangeValue(e){
    var demande = this.state.demande;
    demande[e.target.name] = e.target.value;

    this.setState({
      demande: demande
    })
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      sending: true
    })

    var demande = Object.assign({}, this.state.demande);
    if(this.props.type === "modif_societe"){
      demande.commentaire = "Société : "+this.props.societe +"\n"+demande.commentaire;
    }

    fetch(localStorage.backoffice_api + '/api/demandes/'+this.props.type, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(demande)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert("Une erreur s'est produite : "+value.message)
          this.setState({
            sending: false,
          })
        });
      }
      else {
        this.setState({
          sending: false,
          success: true
        })
      }
    })
    .catch((error) => {
      console.log(error)
      this.setState({
        sending: false,
      })
    });
  }

  render() {

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="card">
              <div className="card-header">
                {this.props.titre}
              </div>
              <div className="card-body pb-3">
                {this.state.success ? 
                  <div className="alert alert-success">
                    Votre demande a été envoyée. Vous serez recontacté prochainement.
                  </div>
                :null}
                <div className="form-group row">
                  <label htmlFor="Nom" className="col-sm-3 col-form-label">Nom</label>
                  <div className="col-sm-9">
                    <input type="text" readOnly={true} className="form-control form-control-sm" value={this.state.demande.name} />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="Nom" className="col-sm-3 col-form-label">Sujet</label>
                  <div className="col-sm-9">
                    <input type="text" readOnly={true} className="form-control form-control-sm" value={this.props.sujet} />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="Nom" className="col-sm-3 col-form-label">Commentaire</label>
                  <div className="col-sm-9">
                    <textarea onChange={this.onChangeValue.bind(this)} className="form-control form-control-sm" name="commentaire" value={this.state.demande.commentaire}></textarea>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col text-center mt-2">
                    <button type='submit' name='valider' className='btn btn-primary' disabled={this.state.sending || this.state.success}>
                      {this.state.sending ? <i className="fa fa-spinner fa-spin fa-fw"/> : null}
                      Envoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;
