import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      offreReglement: null,
      updated: false,
      fetchIDOffre: false,
      saving: false,
      create:false,
      update:false
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchOffreReglement()
    }
    else {
      this.setState({offreReglement: {
        "IDOffreReglement": '',
        "Descr": '',
      }})
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchIDOffre) {
      this.setState({
        updated:false,
        saving:false,
      })
      this.fetchOffreReglement()
    }
  }

  fetchOffreReglement() {
    this.setState({
      fetchIDOffre: true,
    })

    fetch(localStorage.backoffice_api + '/api/offrereglement/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        offreReglement: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var offreReglement = this.state.offreReglement

    offreReglement[name] = value

    this.setState({
      updated: true,
      offreReglement: offreReglement,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      saving: true,
    })

    if (!this.props.match.params.id) {
      this.createOffreReglement();
    }
    else {
      this.editOffreReglement();
    }
  }

  createOffreReglement() {
    fetch(localStorage.backoffice_api + '/api/offrereglement', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.offreReglement)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        create:true,
        update:false
      })
      this.props.history.push('/offrereglement/'+json.IDOffre)
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editOffreReglement() {
    fetch(localStorage.backoffice_api + '/api/offrereglement/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.offreReglement)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        offreReglement: null,
        saving: false,
        update:true,
        create:false
      }, () => {
        this.fetchOffreReglement()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.offreReglement) {
      return null;
    }
    
    return (
      <div className='container-fluid'>
        <h2>Offre règlement</h2>
        <br />

        {this.state.create ? 
        <div className="alert alert-success" role="alert">
          Offre règlement ajoutée avec succès.
        </div> 
        : null}

        {this.state.update ? 
        <div className="alert alert-success" role="alert">
         Offre règlement modifiée avec succès.
        </div> 
        : null}

        <Link to='/offrereglement' className='btn btn-light'>
          <i className="fa fa-arrow-left"></i> Retour
        </Link>
        
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="row">
            <div className="col">
              <div className="form-group row">
                <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.offreReglement.Descr} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
                </div>
              </div>
            </div>
          </div>
          {this.state.updated ?
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col text-center">
                <button disabled={this.state.saving} type="submit" className="btn btn-primary">
                  {this.state.saving ?
                    <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                  : null}
                  Enregistrer
                </button>
              </div>
            </div>
          : null}
        </form>
      </div>
    );
  }
}

export default Form;
