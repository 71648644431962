import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './LogSuppression.css';
import { JSON_SCHEMA } from 'js-yaml';

var moment = require('moment');

class LogSuppression extends Component {

  constructor(props) {
    super(props);

    this.state = {
      elementPerPage:20,
      minRow:0,
      pageActuelle:1,
      pageMax:0,
      pagination:true,
      types:'',
      logs:null,
      totalLogs:'',
      filters:{
        IDLigneSupprimee:'',
        NomTable:'',
        NumeroSerieMat:'',
        RefDMS:''
      }
    }
  }

  componentWillMount(){
    this.fetchLogs('begin',this.state.filters,this.state.elementPerPage,1)
  }

  fetchLogs(choix,filters,elementPerPage,pageActuelle,e) {
    var min;

    switch (choix) {

      case 'select':
        min = 0
        if (e.target.value == 0) {
          filters.NomTable = ''
        } else {
          filters.NomTable = e.target.value
        }
      break;

      case 'begin':
        min = 0
      break;

      case 'back':
        min = this.state.minRow - this.state.elementPerPage
      break;

      case 'next':
        min = this.state.minRow + this.state.elementPerPage
      break;

      case 'end':
        min = this.state.totalLogs - this.state.elementPerPage
        if (min < 20) {
          min = 20
        }
      break;
    }

    fetch(localStorage.backoffice_api + '/api/logSuppression', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'filters':filters,
        'min':min,
        'elementPerPage':elementPerPage
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
          logs:json.logs,
          totalLogs:json.max,
          types:json.types,
          minRow:min,
          pageActuelle:pageActuelle,
          pageMax:Math.round(json.max / this.state.elementPerPage),
          filters:{
            NomTable:filters.NomTable,
            IDLigneSupprimee:filters.IDLigneSupprimee,
            NumeroSerieMat:filters.NumeroSerieMat,
            RefDMS:filters.RefDMS
          }
          
      })

      if (json.max > 20 && Math.round(json.max / this.state.elementPerPage) == 1) {
        this.setState({
          pageMax:2
        })
      }

      if (json.max <= 20) {
        this.setState({
          pagination:false
        })
      } else {
        this.setState({
          pagination:true
        })
      }

    })
    .catch((error) => {
      console.log(error)
    });
  }

  filter(filtre,e){
    var filters = this.state.filters

    Object.keys(filters).forEach(index => {
      if(index == filtre){
        filters[index] = e.target.value
      }
    })

    this.fetchLogs('begin',filters,this.state.elementPerPage,1)
  }



  pagination(choix){

    switch (choix) {

      case 'begin':
        this.fetchLogs('begin',this.state.filters,this.state.elementPerPage,1)
      break;

      case 'back':
        var page = this.state.pageActuelle - 1
        this.fetchLogs('back',this.state.filters,this.state.elementPerPage,page)
      break;

      case 'next':
        var page = this.state.pageActuelle + 1
        this.fetchLogs('next',this.state.filters,this.state.elementPerPage,page)
      break;

      case 'end':
        var page = this.state.pageMax
        this.fetchLogs('end',this.state.filters,this.state.elementPerPage,page)
      break;
    }
  }

  render() {

    if ( !this.state.logs ) {
        return null
    }

    return (
      <div className='container-fluid'>
        <h2> LogSuppression </h2>

        <div className="float-left">
          <strong> Nombre de résultat : </strong> {this.state.totalLogs}
        </div>

        <div className='float-right'>
          <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
            <div className="input-group margin-right">
              <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
              <input type='search' className='form-control form-control-sm float-right' value={this.state.filters.IDLigneSupprimee} onChange={this.filter.bind(this,'IDLigneSupprimee')} placeholder='Filtre par IDLigne' />
            </div>
            <div className="input-group margin-right">
              <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
              <input type='search' className='form-control form-control-sm float-right' value={this.state.filters.RefDMS} onChange={this.filter.bind(this,'RefDMS')} placeholder='Filtre par RefDMS' />
            </div>
            <div className="input-group margin-right">
              <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
              <input type='search' className='form-control form-control-sm float-right' value={this.state.filters.filterNumeroSerie} onChange={this.filter.bind(this,'NumeroSerieMat')} placeholder='Filtre par NumeroSerie' />
            </div>
          </div>
          <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
            <div className="input-group">
              <select onChange={this.fetchLogs.bind(this,'select',this.state.filters,this.state.elementPerPage,1)} className="form-control form-control-sm" placeholder='Filtre par types'>
                <option key='0' value='0'>Filtre par types</option>
                  {this.state.types.map((type) => {
                    return <option key={type.NomTable} value={type.NomTable}>{type.NomTable}</option>
                  })}
              </select>
            </div>
          </div>
        </div>

        <br/><br/>

        {this.state.totalLogs == 0 ? <div class="alert alert-dark" role="alert"> Il n'y a aucun résultat pour cette recherche. </div> : null}

        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>IDLigneSupprimée</th>
              <th>IDPeople</th>
              <th>DateHSuppression</th>
              <th>NomTable</th>
              <th>RefDMS</th>
              <th>RemplacerParIDClient</th>
              <th>NumeroSerieMat</th>
              <th>IDClientMat</th>
            </tr>
          </thead>

          <tbody> 
            {this.state.logs.map((log) => {
              return (<tr key={log.IDLogSuppression}>
                <td>{log.IDLogSuppression}</td>
                <td>{log.IDLigneSupprimee}</td>
                <td>{log.IDPeople}</td>
                <td>{moment(log.DateHSuppression).format("L")}&nbsp; - &nbsp;{moment(log.DateHSuppression).format("LTS")}</td>
                <td>{log.NomTable}</td>
                <td>{log.RefDMS}</td>
                <td>{log.RemplacerParIDClient}</td>
                <td>{log.NumeroSerieMat}</td>
                <td>{log.IDClientMat}</td>
              </tr>)
            })}
          </tbody>
        </table>
            
        <nav className="d-flex justify-content-center" aria-label="Pagination">
          {this.state.pagination && this.state.pageActuelle == 1 && this.state.pageActuelle != this.state.pageMax ?
            <ul className="pagination">
              <li  value={this.state.pageActuelle} className="page-item "><a className="page-link activePage">{this.state.pageActuelle}</a></li>
              <li onClick={this.pagination.bind(this,'next')} value={this.state.pageActuelle + 1} className="page-item"><a className="page-link">{this.state.pageActuelle + 1}</a></li>
              <li onClick={this.pagination.bind(this,'next')} value={this.state.pageActuelle + 1} className="page-item"><a className="page-link">&raquo;</a></li>
              <li onClick={this.pagination.bind(this,'end')} className="page-item"><a className="page-link">Fin</a></li>
            </ul>
          : null }
        {this.state.pagination && this.state.pageActuelle > 1 && this.state.pageActuelle < this.state.pageMax ?
          <ul className="pagination">
            <li onClick={this.pagination.bind(this,'begin')} className="page-item"><a className="page-link">Début</a></li>
            <li onClick={this.pagination.bind(this,'back')} value={this.state.pageActuelle - 1} className="page-item"><a className="page-link">&laquo;</a></li>
            <li onClick={this.pagination.bind(this,'back')} value={this.state.pageActuelle - 1} className="page-item"><a className="page-link">{this.state.pageActuelle - 1}</a></li>
            <li  value={this.state.pageActuelle} className="page-item "><a className="page-link activePage">{this.state.pageActuelle}</a></li>
            <li onClick={this.pagination.bind(this,'next')} value={this.state.pageActuelle + 1} className="page-item"><a className="page-link">{this.state.pageActuelle + 1}</a></li>
            <li onClick={this.pagination.bind(this,'next')} value={this.state.pageActuelle + 1} className="page-item"><a className="page-link">&raquo;</a></li>
            <li onClick={this.pagination.bind(this,'end')} className="page-item"><a className="page-link">Fin</a></li>
          </ul>
        : null }   
        {this.state.pagination && this.state.pageActuelle == this.state.pageMax ?
          <ul className="pagination">
            <li onClick={this.pagination.bind(this,'begin')} className="page-item"><a className="page-link">Début</a></li>
            <li onClick={this.pagination.bind(this,'back')} value={this.state.pageActuelle - 1} className="page-item"><a className="page-link">&laquo;</a></li>
            <li onClick={this.pagination.bind(this,'back')} value={this.state.pageActuelle - 1} className="page-item"><a className="page-link">{this.state.pageActuelle - 1}</a></li>
            <li  value={this.state.pageActuelle} className="page-item "><a className="page-link activePage">Fin</a></li>
          </ul>
        : null }  
        </nav>
      </div>
    );
  }
}

export default LogSuppression;

