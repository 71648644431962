import React, { Component } from 'react'

class DPOContact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      groupeStatus: null,
    }
  }

  componentWillMount() {
    this.fetchDPOGroupeStatus();
  }

  fetchDPOGroupeStatus() {
    fetch(localStorage.backoffice_api + '/api/GpParametres/DPOContact', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        //console.log(json);
        this.setState({
          groupeStatus: json,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {

    if (!this.state.groupeStatus) {
      return (<div className="col-sm-3">
        <div className="card bg-light mb-3">
          <div className="card-header">DPO Contact</div>
          <div className="card-body">
            <h4 className="card-title">Chargement...</h4>
            <p className="card-text text-center">
              <i className='fa fa-spinner fa-spin fa-fw'></i>
            </p>
          </div>
        </div>
      </div>)
    }

    if (this.state.groupeStatus){
      var classes, title, text;
      if(this.state.groupeStatus.config === true){
        classes = "bg-success text-white";
        title = "Groupe configuré";
        text = " ";
      }
      else if(this.state.groupeStatus.mailDPOSent === true){
        classes = "bg-warning text-black";
        title = "Groupe existant, non configuré";
        text = "Mail envoyé au DPO";
      }
      else if(this.state.groupeStatus.exist === true){
        classes = "bg-warning text-black";
        title = "Groupe existant, non configuré";
        text = "Mail non-envoyé au DPO";
      }
      else {
        classes = "bg-danger text-black";
        title = "Groupe non créé";
        text = this.groupeStatus.message ? this.groupeStatus.message : '';
      }
    } 
    
    return (
    <div className="col-sm-3">
      <div className={"card mb-3 "+classes}>
        <div className="card-header">DPO Contact</div>
        <div className="card-body">
          <h5 className="card-title" style={{lineHeight:'1.8rem'}}>{title}</h5>
          <p className="card-text">
            <a className="btn btn-sm btn-link" disabled>{text}</a><br/>
          </p>
        </div>
      </div>
    </div>);
  }
}

export default DPOContact;
