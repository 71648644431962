import React, { useEffect, useState } from "react"
import BackOfficeAPIClient from "../../../clients/BackofficeAPIClient"
import { ConfigDBS } from "./DBSSiteUtils"
import Auth from "./Auth"
import ListDBS from "./ListDBS"

export default function Index() {

    const backofficeApiClient = new BackOfficeAPIClient()

    const [config, setConfig] = useState<ConfigDBS>(new ConfigDBS())
    const [updatedConfig, setUpdatedConfig] = useState<boolean>(false)
    const [fetchInProgressConfig, setFetchInProgressConfig] = useState<boolean>(false)
    const [selectedNav, setSelectedNav] = useState<string>("auth")

    useEffect(() => {
        fetchConfigDBS(true)
    }, [])

    // Récupération de la configuraiton DBS
    const fetchConfigDBS = async (withLoading: boolean) => {
        if (withLoading) {
            setFetchInProgressConfig(true);
        }
        try {
            const resultConfig = await backofficeApiClient.get<ConfigDBS>("/interfaces/dbs/config");
            const sanitizedConfig = sanitizeNullValues(resultConfig);
            setConfig(sanitizedConfig);
            setUpdatedConfig(false);
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration")
        }finally {
            if (withLoading) {
                setFetchInProgressConfig(false);
            }
        }
    }


    // Fonction utilitaire pour remplacer les nulls par des chaînes vides
    const sanitizeNullValues = (config: ConfigDBS): ConfigDBS => {
        const sanitizedConfig = { ...config };
        Object.keys(sanitizedConfig).forEach((key) => {
            const prop = key as keyof ConfigDBS;
            if (sanitizedConfig[prop] === null) {
                sanitizedConfig[prop] = "";
            }
        });
        return sanitizedConfig;
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col'>
                    <h2 className={"mb-4"}>API DBS</h2>

                    <div className="d-flex flex-row justify-content-between mb-4">
                        <ul className="nav nav-tabs d-flex flex-grow-1" id="myTab">
                            <li className="nav-item">
                                <a className={`nav-link  ${selectedNav=== 'auth' ? "active" : ""}`} href="#" id="groupe-tab" role="tab"
                                   onClick={() => setSelectedNav("auth")}>Auth API DBS</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link  ${selectedNav=== 'list' ? "active" : ""}`} href="#" id="groupe-tab" role="tab"
                                   onClick={() => setSelectedNav("list")}>Liste des sites</a>
                            </li>
                        </ul>
                    </div>
                    <div className={selectedNav === "auth" ? "d-block" : "d-none"}>
                        <Auth key={"auth"}
                              config={config}
                              setConfig={setConfig}
                              backofficeApiClient={backofficeApiClient}
                              fetchInProgressConfig={fetchInProgressConfig}
                              setFetchInProgressConfig={setFetchInProgressConfig}
                              updatedConfig={updatedConfig}
                              setUpdatedConfig={setUpdatedConfig}
                        />
                    </div>
                    <div className={selectedNav === "list" ? "d-block" : "d-none"}>
                        <ListDBS key={"list"} backofficeApiClient={backofficeApiClient} fetchInProgressConfig={fetchInProgressConfig}  />
                    </div>
                </div>
            </div>
        </div>
    );

}
