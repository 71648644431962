export class Origine
{
    IDOrigineAction!: number
    LibelleOrigine!: string
    LibelleSousFamille!: string
    LibelleFamille!: string

    constructor(data?: Partial<Origine>) {
        Object.assign(this, data)
    }
}