import React, { Component } from 'react';

//var lodash = require('lodash')

class ModalTransferOrigine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            IDOrigineFrom:0,
            IDOrigineTo:0,
        }
    }

    submitTransfer(){
        fetch(localStorage.backoffice_api + '/api/origines/'+this.state.IDOrigineFrom+'/transfer', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body:JSON.stringify({
                IDOrigineActionTo:this.state.IDOrigineTo
            })
        })
        .then((response) => {

            if (response.ok) {
                alert("Transfert effectué");
                window.location.reload();
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    changeValue(name, value){
        this.setState({
            [name]:value
        });
    }

    canSubmit(){
        return this.state.IDOrigineFrom !== 0 && this.state.IDOrigineTo !== 0 && this.state.IDOrigineFrom !== this.state.IDOrigineTo;
    }

    render() {

        return (
            <div className="modal" role="dialog" id="modalTransferOrigine">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Transférer une origine vers une autre</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {false ?
                        <div className="modal-body pb-1">
                            <div className="row">
                                <div className="col text-center m-5">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw fa-2x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col-12">
                                Transférer depuis <select className="form-control w-auto" value={this.state.IDOrigineFrom} onChange={(e) => this.changeValue("IDOrigineFrom", e.target.value)}>
                                <option value={0}>Origine source</option>
                                {this.props.origines.map((origine) => {
                                    return (<option key={"originefrom"+origine.IDOrigineAction} value={origine.IDOrigineAction}>{origine.Famille_Libelle} / {origine.SousFamille_Libelle} / {origine.Libelle} / {origine.Type_Description}</option>)
                                })}
                                </select> 
                            </div>
                            <div className="col-12">
                                Vers <select className="form-control w-auto" value={this.state.IDOrigineTo} onChange={(e) => this.changeValue("IDOrigineTo", e.target.value)}>
                                <option value={0}>Origine cible</option>
                                {this.props.origines.map((origine) => {
                                    return (<option key={"origineto"+origine.IDOrigineAction} value={origine.IDOrigineAction}>{origine.Famille_Libelle} / {origine.SousFamille_Libelle} / {origine.Libelle} / {origine.Type_Description}</option>)
                                })}
                                </select>
                            </div>
                        </div>
                        <div className="alert alert-danger">Tous les rapports, offres, affaires ... seront transférés vers l'origine cible.</div>
                    </div>
                    }
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit' id='submitEmail' disabled={!this.canSubmit()} onClick={() => this.submitTransfer()}>Valider</button>&nbsp;
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalTransferOrigine;

