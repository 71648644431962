import React, { Component } from 'react';

//var lodash = require('lodash')

class ModalDeleteOrigine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            origine: false,
            utilisations: null
        }
    }

    componentDidUpdate(){
        if(this.props.origine && this.props.origine.IDOrigineAction !== this.state.origine.IDOrigineAction)
        {
            this.setState({
                origine: this.props.origine,
                utilisations: null
            }, 
                () => this.fetchOrigineUse()
            );
        }
    }

    fetchOrigineUse(){
        this.setState({
            fetching:true
        });
        fetch(localStorage.backoffice_api + '/api/origines/'+this.props.origine.IDOrigineAction+'/use', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
        })
        .then((response) => {

            if (response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetching: false,
                        utilisations: value
                    });
                });
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    deleteOrigine() {
        fetch(localStorage.backoffice_api + '/api/origines/'+this.props.origine.IDOrigineAction, {
            method: 'DELETE',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
        })
        .then((response) => {

            if (response.ok) {
                window.location.reload();
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    render() {

        return (
        <div className="modal" role="dialog" id="modalDeleteOrigine">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Supprimer une origine</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.fetching ?
                        <div className="modal-body pb-1">
                            <div className="row">
                                <div className="col text-center m-5">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw fa-2x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col">
                                <h4 className="mt-2 mb-4">{this.state.origine.Famille_Libelle} / {this.state.origine.SousFamille_Libelle} / {this.state.origine.Libelle} / {this.state.origine.Type_Description}</h4>
                                {this.state.utilisations && this.state.utilisations.length > 0 ?
                                    <div>
                                        Cette origine ne peut pas être supprimée car elle est utilisée dans {this.state.utilisations.length > 1 ? "les tables suivantes" : "la table suivante"} : 
                                        <ul>
                                        {this.state.utilisations.map((a, i) => {
                                            return (<li key={"table"+a.table}>{a.table} : {a.nb} occurence{a.nb > 0 ? 's' : ''}</li>)
                                        })}
                                        </ul>
                                    </div>
                                :
                                    <div>
                                        Cette origine sera supprimée définitivement
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    }
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                        {this.state.utilisations && this.state.utilisations.length > 0 ?
                            <button className="btn btn-secondary" type='submit' data-dismiss="modal">Fermer</button>
                            :
                            <div>
                                <button className="btn btn-primary" type='submit' onClick={() => this.deleteOrigine()}>Supprimer</button>&nbsp;
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalDeleteOrigine;

