import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {CSVLink} from 'react-csv';
import moment from 'moment'
import Utils from '../../../Utils';

var lodash = require('lodash')

class Synthese extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      sites: [],
      utilisateurs: [],
      datas: [],
      finishGeneratedDatas: false,

      datasUtilisateurs: [],
      finishGeneratedDatasUtilisateurs: false,
    }
  }

  componentWillMount() {
    this.fetchSites();

  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Descr;
      })

      this.setState({
        sites: sites,
      }, () => {
        this.fetchUtilisateurs();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var utilisateurs = lodash.sortBy(json, (utilisateur) => {
        return utilisateur.Nom;
      })

      this.setState({
        utilisateurs: utilisateurs,
      }, () => {
        this.generateDatas();
        this.generateDatasUtilisateurs();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  getUtilisateursFilterBySite(utilisateurs, IDSite, EstVN, EstVO) {
    return lodash.filter(utilisateurs, (utilisateur) => {

      if (!utilisateur.Sites)
        return false;

      if (!((EstVN && utilisateur.TypeVN) || (EstVO && utilisateur.TypeVO)))
        return null;

      var indexSite = lodash.findIndex(utilisateur.Sites, (site) => {
        return site.IDSite === IDSite;
      });

      return indexSite !== -1;
    });
  }

  getUtilisateurs(IDSite, EstSiteVN, EstSiteVO, UtilisateurTypeProfil, UtilisateurTypeVN, UtilisateurTypeVO) {

    var _utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {

      if (!utilisateur.Actif) {
        return null;
      }
      if (UtilisateurTypeVN && !utilisateur.TypeVN) {
        return null;
      }
      if (UtilisateurTypeVO && !utilisateur.TypeVO) {
        return null;
      }

      return utilisateur.DRProfileEsellerLead_Libelle === UtilisateurTypeProfil;
    });

    _utilisateurs = this.getUtilisateursFilterBySite(_utilisateurs, IDSite, EstSiteVN, EstSiteVO);

    return _utilisateurs;
  }

  generateDatas() {
    // https://github.com/abdennour/react-csv

    var datas = [];
    datas.push(['Société', 'Site', 'Nom du site eSeller Lead', 'Chef des ventes VN', 'Chef des ventes VO', 'Vendeurs VN', 'Vendeurs VO'])

    this.state.sites.map((site) => {
      if (!site.Est_eSellerLeadActif)
        return null

      datas.push([
        site.Plaque_Libelle,
        site.Descr,
        site.Libelle,
        lodash.join(this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Chef des ventes", true, false).map((utilisateur) => { return utilisateur.Firstname +' '+ utilisateur.Lastname; }), ', '),
        lodash.join(this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Chef des ventes", false, true).map((utilisateur) => { return utilisateur.Firstname +' '+ utilisateur.Lastname; }), ', '),
        lodash.join(this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Vendeur", true, false).map((utilisateur) => { return utilisateur.Firstname +' '+ utilisateur.Lastname; }), ', '),
        lodash.join(this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Vendeur", false, true).map((utilisateur) => { return utilisateur.Firstname +' '+ utilisateur.Lastname; }), ', '),
      ]);
    })

    this.setState({
      datas: datas,
      finishGeneratedDatas: true,
    });
  }

  generateDatasUtilisateurs() {
    // https://github.com/abdennour/react-csv

    var datas = [];
    datas.push(['Nom', 'Prénom', 'Téléphone portable', 'Profil eSeller Team', 'Profil eSeller Lead', 'Login', 'Mot de passe'])

    this.state.utilisateurs.map((u) => {
      if(!u.Actif)
        return null;

      datas.push([
        u.Firstname,
        u.Lastname,
        u.MobilePhone,
        u.DRPROFILE_DESCR,
        u.DRProfileEsellerLead_Libelle,
        u.LoginWEB,
        u.Password,
      ]);
    })

    this.setState({
      datasUtilisateurs: datas,
      finishGeneratedDatasUtilisateurs: true,
    });
  }

  getFileName() {
    var name = 'Synthèse_eSeller_Lead_Attributions_Sites_';
    name += Utils.fromJWT('serverName') +'-'+ Utils.fromJWT('BDGp') +"_";
    name += moment().format('DD-MM-YYYY-hh-mm-ss');
    return name;
  }

  getFileNameUtilisateurs() {
    var name = 'Synthèse_eSeller_Lead_Utilisateurs_';
    name += Utils.fromJWT('serverName') +'-'+ Utils.fromJWT('BDGp') +"_";
    name += moment().format('DD-MM-YYYY-hh-mm-ss');
    return name;
  }

  render() {

    if (!this.state.finishGeneratedDatas || !this.state.finishGeneratedDatasUtilisateurs)
      return null;

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <div>
              <CSVLink data={this.state.datas} filename={this.getFileName()} className="float-right btn btn-light" target="_self" separator={";"}>
                  <i className="fa fa-download" aria-hidden="true"></i> Télécharger attributions sites et utilisateurs
              </CSVLink>

              <CSVLink data={this.state.datasUtilisateurs} filename={this.getFileNameUtilisateurs()} className="float-right btn btn-light" target="_self" separator={";"}>
                  <i className="fa fa-download" aria-hidden="true"></i> Télécharger utilisateurs
              </CSVLink>

              <div className='clearfix'></div>
            </div>

            <br />

            <div className="alert alert-primary" role="alert">
              Cette synthèse est automatiquement générée à partir de la configuration des sites et utilisateurs.
            </div>

            <br />

            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th></th>
                  <th>Chefs des ventes VN</th>
                  <th>Chefs des ventes VO</th>
                  <th>Vendeurs VN</th>
                  <th>Vendeurs VO</th>
                </tr>
              </thead>
              <tbody>
                {this.state.sites.map((site) => {
                  if (!site.Est_eSellerLeadActif)
                    return null;

                  return (<tr key={site.IDSite}>
                    <td>
                      <b>{site.Plaque_Libelle} - {site.Libelle} ({site.Descr})</b>
                      <br/>
                      {!site.Libelle ?
                        <em style={{color: 'red', fontWeight: 'bold'}}>Le site ne dispose pas de libellé propre à eSeller Lead, <Link to={'/sites/'+site.IDSite}>éditer le site</Link><br/></em>
                      : null}
                      <em>IDSite : {site.IDSite} <br/> EstVN : {site.EstVN ? 'Oui' : 'Non'} <br/> EstVO : {site.EstVO ? 'Oui' : 'Non'}</em>
                    </td>
                    <td>
                      <ul>
                        {this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Chef des ventes", true, false).map((utilisateur) => {
                          return <li key={utilisateur.IDPeople}>{utilisateur.Firstname} {utilisateur.Lastname}</li>
                        })}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Chef des ventes", false, true).map((utilisateur) => {
                          return <li key={utilisateur.IDPeople}>{utilisateur.Firstname} {utilisateur.Lastname}</li>
                        })}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Vendeur", true, false).map((utilisateur) => {
                          return <li key={utilisateur.IDPeople}>{utilisateur.Firstname} {utilisateur.Lastname}</li>
                        })}
                      </ul>
                    </td>
                    <td>
                      <ul>
                      {this.getUtilisateurs(site.IDSite, site.EstVN, site.EstVO, "Vendeur", false, true).map((utilisateur) => {
                        return <li key={utilisateur.IDPeople}>{utilisateur.Firstname} {utilisateur.Lastname}</li>
                      })}
                      </ul>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default Synthese;
