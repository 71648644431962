import React, { Component } from 'react'

const lodash = require('lodash')


class OffreConstructeur extends  Component{
    constructor(props){
        super(props);

        this.state={
            sites:[],
            message:""
        }
    }

    componentWillMount() {
        this.fetchSites();

      }

    //récupérer tout les sites
    fetchSites() {
        fetch(localStorage.backoffice_api + '/api/offreConstructeur', {
        method: 'GET',
        headers: {
            authorization: localStorage.JWT,
        }
        })
        .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
        })
        .then((json) => {
        var sites = lodash.sortBy(json, (site) => {
            return site.Plaque_Libelle

        })

        this.setState({
            sites: sites
        });
        })
        .catch((error) => {
        console.log(error)
        });
    }

    onChange(IDSite){
        let sites = this.state.sites

        let site = lodash.find(sites,function(site){return site.IDSite == IDSite})

        if(site.OffreCodeConstructeurActif == true){
            site.OffreCodeConstructeurActif=false
        }
        else{
            site.OffreCodeConstructeurActif=true
        }

        this.update(site);

        
        this.setState({
            sites:sites
        })
    }

    update(site){
        fetch(localStorage.backoffice_api + '/api/offreConstructeur', {
            method: 'POST',
            headers: {
                authorization: localStorage.JWT,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'site': site
              })
            })
            .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
            })
            .then((json) => {
                console.log(json)
            })
            .catch((error) => {
                console.log(error)
                let err = lodash.find(this.state.sites,function(s){return s.IDSite == site.IDSite})

                if(err.OffreCodeConstructeurActif == true){
                    err.OffreCodeConstructeurActif=false
                }
                else{
                    err.OffreCodeConstructeurActif=true
                }

                this.setState({
                    message:"Erreur lors de la modification"
                })

                setTimeout(
                    function() {
                        this.setState({
                            message:""
                        })
                    }
                    .bind(this),3000
                  );
            });

    }

    render(){
        console.log(this.state.sites)
        return(

            <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <h2>Offre via code constructeur</h2>


                        {this.state.message != "" ?
                          <div className="alert alert-danger" role="alert">{this.state.message}</div> 
                        : null}
                        <table className="table table-striped">
                          <thead>
                              <tr>
                              <th scope="col">Société</th>
                              <th scope="col">Site</th>
                              <th scope="col" className="text-center">Actif</th>
                              </tr>
                          </thead>
                          <tbody>
                            {this.state.sites.map((site) => {
                              return (<tr key={site.IDSite}>
                                <td>{site.Plaque_Libelle}</td>
                                <td>{site.Descr}</td>
                                <td className="text-center"> 
                                    <input onChange={this.onChange.bind(this,site.IDSite)} type="checkbox" checked={site.OffreCodeConstructeurActif == 1 ? true: false}/>
                                </td>
                              </tr>)
                            })}
                          </tbody>
                       </table>
                      </div>
                    </div>
                  </div>
        )
    }
}


export default OffreConstructeur