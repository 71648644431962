import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

class InterfacePlanetVO2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
      fetching: true
    }
  }

  componentDidMount() {
    this.fetchDatas()
  }

  async fetchDatas() {
    this.setState({
      fetching: true
    })
    try {
      let response = await fetch(localStorage.backoffice_api + '/api/interfaces/planetvo2/dashboard', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        }
      })
      if (!response.ok) {
        throw Error(response.statusText);
      }
      this.setState({
        data: await response.json()
      });
    }
    catch(error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetching: false
      })
    }
  }

  getTitle() {
    if(this.state.fetching) {
      return "Chargement..."
    }

    if(!this.state.data.countActiveSites) {
      return "Désactivé"
    }

    return `${this.state.data.countActiveSites}/${this.state.data.countSites} site${this.state.data.countSites > 1 ? "s": ""}`
  }

  getClassNames() {
    if(this.state.fetching) {
      return "bg-light"
    }

    if(!this.state.data.countActiveSites) {
      return "text-white bg-secondary "
    }

    if(this.state.data.countActiveSites < this.state.data.countSites) {
      return "bg-warning text-black"
    }

    return "bg-success text-white"
  }

  render() {
    return (
      <div className="col-sm-3">
        <div className={`card mb-3 ${this.getClassNames()}`}>
          <div className="card-header">PlanetVO 2</div>
          <div className="card-body">
            <h4 className="card-title">{this.getTitle()}</h4>
            <p className={`card-text ${this.state.fetching ? "text-center" : ""}`}>
              {this.state.fetching ?
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              :
                <Link to='interfaces/planetVO2' className='btn btn-sm btn-outline-dark'>Paramétrage de l'interface</Link>
              }
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(InterfacePlanetVO2);
