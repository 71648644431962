import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societes: [],
      sites: [],
      updated: false,
      updateInProgress: [],
    }
  }

  componentWillMount() {
    this.fetchSocietes()
    this.fetchSites()
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var societes = lodash.sortBy(json, (societe) => {
        return societe.Libelle;
      })

      this.setState({
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/interfaces/corporateSales/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Plaque_Libelle + ' ' + site.Descr;
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateWS(IDSite) {

    var updateInProgress = this.state.updateInProgress;
    updateInProgress.push(IDSite);
    this.setState({
      updateInProgress: updateInProgress,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/corporateSales/sites/'+IDSite+'/generateWS', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        sites: [],
        updateInProgress: [],
      }, () => {
        this.fetchSites()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.societes)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>Nissan - Corporate Sales</h2>

            <br />

            <h3>Sociétés</h3>

            <Link to='/societes' className='btn btn-light' style={{ marginTop: '5px', marginBottom: '15px', }}>
              <i className="fa fa-list"></i> Gérer tous les sociétés
            </Link>

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Libellé</th>
                  <th className='text-center'>Interface activé ?</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.societes.map((societe) => {
                  return (<tr key={societe.IDPlaque}>
                    <td>{societe.IDPlaque}</td>
                    <td>{societe.Libelle}</td>
                    <td className='text-center'>{societe.InterfaceCorporateSales ? 'Oui' : 'Non'}</td>
                    <td>
                      <Link to={'/societes/'+societe.IDPlaque} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

            <br />

            {this.state.sites.length ?
              <div>
                <h3>Configuration des identifiants WSImport</h3>
                <br />
                <table className="table table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Société</th>
                      <th>Site</th>
                      <th>Code constructeur</th>
                      <th>Login WS</th>
                      <th>Password WS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sites.map((site) => {
                      return (<tr key={site.IDSite} className={!site.Config ? 'table-warning' : ''}>
                        <td>{site.IDSite}</td>
                        <td>{site.Plaque_Libelle}</td>
                        <td>{site.Descr}</td>
                        <td>{site.CodeConstructeur}</td>
                        <td>
                          {site.Config ? site.Config.Login : ''}
                        </td>
                        <td>
                          {site.Config ? site.Config.Password : ''}
                        </td>
                        <th>
                          {site.Config ?
                            <button className='btn btn-primary btn-sm text-white' onClick={this.generateWS.bind(this, site.IDSite)} disabled={this.state.updateInProgress.indexOf(site.IDSite) >= 0}>
                              {this.state.updateInProgress.indexOf(site.IDSite) >= 0 ? <span><i className='fa fa-spinner fa-spin fa-fw'></i></span> : ''} Re-générer
                            </button>
                          :
                            <a className='btn btn-primary btn-sm text-white' onClick={this.generateWS.bind(this, site.IDSite)}>
                              Générer
                            </a>
                          }
                        </th>
                      </tr>)
                  })}
                  </tbody>
                </table>

              </div>
            : null}

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
