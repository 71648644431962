import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      allDocuments: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchDocuments();
  }

  fetchDocuments() {
    fetch(localStorage.backoffice_api + '/api/docsAdmin', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var documents = lodash.sortBy(json, (f) => {
        return f.Descr;
      })

      this.setState({
        documents: documents,
        allDocuments: documents,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  filter(event){
    var filterName = event.target.value;
    var documents = lodash.filter(this.state.allDocuments, (d) => {
      return d.Descr.toLowerCase().search(filterName.toLowerCase()) !== -1 || d.Url.toLowerCase().search(filterName.toLowerCase()) !== -1;
    });
    this.setState({
      documents: documents,
      filterName: filterName
    });
  }

  deleteDocAdmin(IDDocumentAdmin){
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement ce document administratif ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/docsAdmin/'+IDDocumentAdmin, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      this.fetchDocuments();
    })
    .catch((error) => {
      console.log(error)
    });

    return false;
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Documents administratifs</h2>

            <br />

            <div>
              <Link to='/docsAdmin/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(e) => this.filter(e)} placeholder='Filtre par description ou url' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Module</th>
                  <th>Description</th>
                  <th>Url</th>
                  <th>Catalogue</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.documents.map((d) => {
                  return (<tr key={"document"+d.IDDocumentAdmin}>
                    <td>{d.IDDocumentAdmin}</td>
                    <td>{d.IDDocumentAdminModule} - {d.Module}</td>
                    <td>{d.Descr}</td>
                    <td>{d.Url}</td>
                    <td>{d.Catalogue ? "Oui" : "Non"}</td>
                    <td className="text-right">
                      <Link to={'/docsAdmin/'+d.IDDocumentAdmin} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      &nbsp;
                      <a href="#" onClick={this.deleteDocAdmin.bind(this, d.IDDocumentAdmin)} className='btn btn-danger btn-sm text-white'>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>);
                })
              }
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
