import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class ImportClients extends Component {

  constructor(props) {
    super(props);

    this.state = {
      importInProgress: false,
      importCompleted: false,
      importResult: null,
      file: null,
    }
  }
  
  canSubmit(){
    return !this.state.importInProgress && this.state.file;
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({
      importInProgress: true,
    })

    const formData = new FormData()
    formData.append("file", this.state.file)
    
    fetch(localStorage.backoffice_api + '/api/cartesDeFidelite/importFideliteClients', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT
      },
      body: formData
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          importInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        importInProgress: false,
        importCompleted: true,
        importResult: json
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  selectFile(event){
    this.setState({
      file: event.target.files[0]
    })
  }

  reset(){
    this.setState({
      file:null,
      importInProgress: false,
      importCompleted: false,
      importResult: null
    })
  }

  
  render() {

    return (
      <div className='container-fluid'>

          <h2>Cartes de fidélité</h2>

          <form onSubmit={(e) => this.onSubmit(e)}>

            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card">
                  <div className="card-header">
                    Importer fichier des nouvelles cartes de fidélité clients
                  </div>
                  <div className="card-body pb-3">
                    <div className="form-group row">
                      <div className="col">
                        {this.state.importInProgress ? 
                          <div>
                            <i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;Importation en cours
                          </div>
                        :this.state.importCompleted ? 
                          <div>
                            {this.state.importResult.nbRows > 0 && !this.state.importResult.message ?
                              <div className="alert alert-success">
                                {this.state.importResult.nbRows} clients mis à jour
                              </div>
                            :this.state.importResult.nbRows > 0 && this.state.importResult.message ?
                              <div className="alert alert-warning">
                                {this.state.importResult.nbRows} clients mis à jour. {this.state.importResult.message}
                              </div>
                            :
                              <div className="alert alert-danger">
                                {this.state.importResult.message}
                              </div>
                            }
                          </div>
                        :
                          <div>
                            <label htmlFor="Nom" className="col-sm-3 col-form-label">Fichier (format .xlsx)</label>
                            <input type='file' name='file' accept='.xlsx' required onChange={(e) => {this.selectFile(e)}}/>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="alert alert-info">
                          La colonne A doit contenir un VIN et la colonne G la date de début du contrat.<br/>
                          La première ligne doit être une ligne d'entête (elle sera ignorée).
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col text-center">
                        {this.state.importCompleted ?
                          <div>
                            <Link to='/cartesDeFidelite' className='btn btn-primary'>
                              Retour
                            </Link>
                            &nbsp;
                            <button className='btn btn-primary' onClick={() => this.reset()}>
                              Importer un nouveau fichier
                            </button>
                          </div>
                        :
                          <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                            {this.state.importInProgress ?
                              <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                            : null}
                            Importer
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
        </form>
      </div>
    );
  }
}

export default ImportClients;
