import React, { Component } from 'react';

var lodash = require('lodash')

class ModalEditWorkflow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            receptionLeads: "",
            workflowBDC: "",
            saving: false,
            workflow: null,
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.workflow && (!prevProps.workflow || prevProps.workflow.IDWorkflowLead != this.props.workflow.IDWorkflowLead)) {
            this.setState({
                workflow: this.props.workflow
            })
        }
    }

    onSelectReceptionLeads(event) {
        var workflow = this.state.workflow;
        workflow.ReceptionLeads = event.target.value;

        this.setState({
            workflow: workflow
        });
    }

    onSelectWorkflowBDC(event) {
        var workflow = this.state.workflow;
        workflow.WorkflowBDC = event.target.value;

        this.setState({
            workflow: workflow
        });
    }

    save() {
        this.setState({
            saving: true
        })
        fetch(localStorage.backoffice_api + '/api/workflowsLeads/'+this.props.workflow.IDWorkflowLead, {
            method: 'PUT',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                "workflow": this.state.workflow
            })
        })
        .then((response) => {
            if (!response.ok) {
              response.json().then((value) => {
                alert(value.message)
              });
              this.setState({
                saving: false,
              })
              throw Error(response);
            }

            if(this.props.onWorkflowUpdated) {
                this.props.onWorkflowUpdated(this.state.workflow);
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }

    render() {

        return (
        <div className="modal" role="dialog" id="modalEditWorkflow" data-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Modifier un workflow</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    {this.state.workflow ? 
                        <div className="modal-body pb-1">
                            <div className="form-group row">
                                <div className="col-2 m-auto">
                                    Origine
                                </div>
                                <div className="col-10">
                                    {this.state.workflow.OrigineLibelle}
                                </div>
                            </div>

                            <div className="form-group row border-top pt-3">
                                <div className="col-5 m-auto d-flex flex-column">
                                    <div>Site</div>
                                    <div>{this.state.workflow.SiteLibelle}</div>
                                </div>

                                <div className="col-2 pl-0">
                                    <div className="d-flex flex-column">
                                        <div>Leads réceptionnés par</div>
                                        <select className="form-control form-control-sm" value={this.state.workflow.ReceptionLeads} onChange={this.onSelectReceptionLeads.bind(this)}>
                                            {this.props.receptionsLeads.map((att) => {
                                                return (<option key={att.code} value={att.code}>{att.libelle}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-5 pl-0">
                                    {this.state.workflow.ReceptionLeads == "BDC" ? 
                                        <div className="d-flex flex-column">
                                            <div>Transfert vers</div>
                                            <select className="form-control form-control-sm" value={this.state.workflow.WorkflowBDC} onChange={this.onSelectWorkflowBDC.bind(this)}>
                                                <option value="">Utiliser la configuration par défaut du groupe</option>
                                                {this.props.workflowsBDC.map((t, i) => {
                                                    return <option value={t.code} key={i}>{t.libelle}</option>
                                                })}
                                            </select>
                                        </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    :null}
                    
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit' onClick={this.save.bind(this)}>Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalEditWorkflow;

