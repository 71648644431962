import React, { Component } from 'react';

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codeOP: null,
      updated: false,
      fetchCodeOP: false,
      saving: false,
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchCodeOP()
    }
    else {
      this.setState({codeOP: {
        "Code": '',
        "Descr": '',
      }})
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchCodeOP) {
      this.fetchCodeOP()
    }
  }

  fetchCodeOP() {

    this.setState({
      fetchCodeOP: true,
    })

    fetch(localStorage.backoffice_api + '/api/codesOP/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        codeOP: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var codeOP = this.state.codeOP

    codeOP[name] = value

    this.setState({
      updated: true,
      codeOP: codeOP,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      saving: true,
    })

    if (!this.props.match.params.id) {
      this.createCodeOP();
    }
    else {
      this.editCodeOP();
    }
  }

  createCodeOP() {
    fetch(localStorage.backoffice_api + '/api/codesOP', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.codeOP)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.props.history.push('/codesOP')
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editCodeOP() {
    fetch(localStorage.backoffice_api + '/api/codesOP/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.codeOP)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        codeOP: null,
        saving: false,
      }, () => {
        this.fetchCodeOP()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.codeOP) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Codes OP</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Code" className="col-sm-3 col-form-label">Code</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Code" name="COP_Code" value={this.state.codeOP.COP_Code} onChange={this.onChange.bind(this)} maxLength="5" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Descr" name="COP_Description" value={this.state.codeOP.COP_Description} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
                  </div>
                </div>
              </div>
            </div>

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.saving} type="submit" className="btn btn-primary">
                    {this.state.saving ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
