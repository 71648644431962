import React, { Component } from 'react';
import { workflowsBDC } from '../../enums/WorkflowsBDC';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      params: null,
      updated: false,
      fetchInProgress: false,
    }
  }

  componentWillMount() {
    this.fetchParams()
  }

  fetchParams() {
    fetch(localStorage.backoffice_api + '/api/gpParametres', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        params: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var params = this.state.params

    if (type === 'checkbox') {
      params[name] = event.target.checked
    }
    else {
      params[name] = value
    }

    this.setState({
      updated: true,
      params: params,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/gpParametres', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.params)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        params: null,
        fetchInProgress: false,
      }, () => {
        this.fetchParams()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.params) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>GpParametre</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_EstEnGestionTTC" checked={this.state.params.GRP_EstEnGestionTTC} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_EstEnGestionTTC
                        <small className="form-text text-muted">Est en gestion TTC pour les articles</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_TsfCreeClientSiInexistant" checked={this.state.params.GRP_TsfCreeClientSiInexistant} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_TsfCreeClientSiInexistant
                        <small className="form-text text-muted">Transfert DMS-CRM : Si coché on crée le client s'il n'est pas trouvé pour l'affecter au véhicule</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_ListeChampsCreationProspect" className="col-sm-3 col-form-label">GRP_ListeChampsCreationProspect</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_ListeChampsCreationProspect" name="GRP_ListeChampsCreationProspect" value={this.state.params.GRP_ListeChampsCreationProspect} onChange={this.onChange.bind(this)} maxLength='1000' />
                    <small className="form-text text-muted">Liste sous la forme NomChamp1-"Saisie obligatoire : 0 ou 1"-"Masque de saisie : Maj (Majuscule) ou Min (Minuscule) ou PremLettreMaj (1ère lettre en majuscule)"Saisie obligatoire sans "NC": 0 ou 1";NomChamp2...</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_AutoriseSaisieManuVehOffre" checked={this.state.params.GRP_AutoriseSaisieManuVehOffre} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_AutoriseSaisieManuVehOffre
                        <small className="form-text text-muted">Si coché, on autorise la saisie manuelle d'un véhicule lors de la création d'une offre</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_TauxTVA" className="col-sm-3 col-form-label">GRP_TauxTVA</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_TauxTVA" name="GRP_TauxTVA" value={this.state.params.GRP_TauxTVA} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Taux de TVA en vigueur</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_CodePays" className="col-sm-3 col-form-label">GRP_CodePays</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_CodePays" name="GRP_CodePays" value={this.state.params.GRP_CodePays} onChange={this.onChange.bind(this)} maxLength='2' />
                    <small className="form-text text-muted">Code pays ISO (FR : France, CH : Suisse,...)</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_NbRelanceMaxParJour" className="col-sm-3 col-form-label">GRP_NbRelanceMaxParJour</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_NbRelanceMaxParJour" name="GRP_NbRelanceMaxParJour" value={this.state.params.GRP_NbRelanceMaxParJour} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de relances maxi par jour (Plan de charge)</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_GereMalusVDCommeVN" checked={this.state.params.GRP_GereMalusVDCommeVN} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_GereMalusVDCommeVN
                        <small className="form-text text-muted">Gestion du malus pour les VD comme pour les VN</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_ForceEnvoiFM" checked={this.state.params.GRP_ForceEnvoiFM} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_ForceEnvoiFM
                        <small className="form-text text-muted">FLAG pour forcer ou non envoi au FM avant edition BDC</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_ListeChampsBDC" className="col-sm-3 col-form-label">GRP_ListeChampsBDC</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_ListeChampsBDC" name="GRP_ListeChampsBDC" value={this.state.params.GRP_ListeChampsBDC} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Liste des champs pour valider un bon de commande</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_DureeValiditePassword" className="col-sm-3 col-form-label">GRP_DureeValiditePassword</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_DureeValiditePassword" name="GRP_DureeValiditePassword" value={this.state.params.GRP_DureeValiditePassword} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Délai de validité du mot de passe en nombre de jours (0 si durée n'expire jamais)</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_RechercheVN" className="col-sm-3 col-form-label">GRP_RechercheVN</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_RechercheVN" name="GRP_RechercheVN" value={this.state.params.GRP_RechercheVN ? this.state.params.GRP_RechercheVN : ''} onChange={this.onChange.bind(this)} maxLength='5' />
                    <small className="form-text text-muted">Paramétrage recherche VN (Neuf Direction Location)</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_RechercheVO" className="col-sm-3 col-form-label">GRP_RechercheVO</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_RechercheVO" name="GRP_RechercheVO" value={this.state.params.GRP_RechercheVO} onChange={this.onChange.bind(this)} maxLength='5' />
                    <small className="form-text text-muted">Paramétrage recherche VO (Occasion Direction Location)</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_GereBonusVDCommeVN" checked={this.state.params.GRP_GereBonusVDCommeVN} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_GereBonusVDCommeVN
                        <small className="form-text text-muted">Gestion du bonus pour les VD comme pour les VN</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_EssaiObligatoire" className="col-sm-3 col-form-label">GRP_EssaiObligatoire</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_EssaiObligatoire" name="GRP_EssaiObligatoire" value={this.state.params.GRP_EssaiObligatoire} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Saisie des essais 0=Non obligatoire 1=Offre et BDC 2=Seulement BDC</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_NbJrsRelanceAvantFinGarantie" className="col-sm-3 col-form-label">GRP_NbJrsRelanceAvantFinGarantie</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_NbJrsRelanceAvantFinGarantie" name="GRP_NbJrsRelanceAvantFinGarantie" value={this.state.params.GRP_NbJrsRelanceAvantFinGarantie ? this.state.params.GRP_NbJrsRelanceAvantFinGarantie : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours avant la fin de garantie pour les relances automatiques</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="GRP_NbJrsRelanceAvantFinFinancement" className="col-sm-3 col-form-label">GRP_NbJrsRelanceAvantFinFinancement</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="GRP_NbJrsRelanceAvantFinFinancement" name="GRP_NbJrsRelanceAvantFinFinancement" value={this.state.params.GRP_NbJrsRelanceAvantFinFinancement ? this.state.params.GRP_NbJrsRelanceAvantFinFinancement : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Nombre de jours avant la fin de financement pour les relances automatiques</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_AfficheNumSerieBdC"
                               checked={this.state.params.GRP_AfficheNumSerieBdC} onChange={this.onChange.bind(this)}/>
                        &nbsp; GRP_AfficheNumSerieBdC
                        <small className="form-text text-muted">Si coché, on affiche le numéro de série du véhicule à
                          l'édition des offres et bons de commande</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_ClientRenault"
                               checked={this.state.params.GRP_ClientRenault} onChange={this.onChange.bind(this)}/>
                        &nbsp; GRP_ClientRenault
                        <small className="form-text text-muted">Si coché, on active les paramètres spécifiques Renault
                          pour un client.</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadGereLeadVO"
                               checked={this.state.params.GRP_eSellerLeadGereLeadVO}
                               onChange={this.onChange.bind(this)}/>
                        &nbsp; GRP_eSellerLeadGereLeadVO
                        <small className="form-text text-muted">Si coché : eSeller Lead gère les leads à redistribuer de
                          type VO</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadGereLeadVN" checked={this.state.params.GRP_eSellerLeadGereLeadVN} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_eSellerLeadGereLeadVN
                        <small className="form-text text-muted">Si coché : eSeller Lead gère les leads à redistribuer de type VN</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerTeamGereLeadVO" checked={this.state.params.GRP_eSellerTeamGereLeadVO} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_eSellerTeamGereLeadVO
                        <small className="form-text text-muted">Si coché : eSeller Team gère les leads à redistribuer de type VO</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerTeamGereLeadVN" checked={this.state.params.GRP_eSellerTeamGereLeadVN} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_eSellerTeamGereLeadVN
                        <small className="form-text text-muted">Si coché : eSeller Team gère les leads à redistribuer de type VN</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadAtelierMailRapport" checked={this.state.params.GRP_eSellerLeadAtelierMailRapport} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_eSellerLeadAtelierMailRapport
                        <small className="form-text text-muted">Si coché : eSeller Lead Atelier : géstion simplifié avec email et rapport</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="drprofile_id" className="col-sm-3 col-form-label">GRP_eSellerLeadProcessBDC</label>
                  <div className="col-sm-6">
                    <select value={this.state.params.GRP_eSellerLeadProcessBDC ? this.state.params.GRP_eSellerLeadProcessBDC : ''} onChange={this.onChange.bind(this)} id='GRP_eSellerLeadProcessBDC' name="GRP_eSellerLeadProcessBDC" className="form-control form-control-sm">
                      {!this.state.params.GRP_eSellerLeadProcessBDC ?
                        <option value='0'></option>
                      :null}
                      {workflowsBDC.map((workflow, i) => {
                        return <option key={i} value={workflow.code}>BDC -&gt; {workflow.libelle}</option>
                      })}
                    </select>
                    <small className="form-text text-muted ml-3">Correspond au workflow par défaut. Cependant, des workflows peuvent être définis par origine dans "Configuration =&gt; Origines", bouton "Workflows"</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_esellerLeadEditRapportCloture" checked={this.state.params.GRP_esellerLeadEditRapportCloture} onChange={this.onChange.bind(this)} />
                        &nbsp; BDC peut traiter rapport clôt
                        <small className="form-text text-muted">Si coché : eSeller Lead : les personnes du BDC peuvent éditer les rapports clôturés</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_BDCTraitementLeadAmontChefVente" checked={this.state.params.GRP_BDCTraitementLeadAmontChefVente} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_BDCTraitementLeadAmontChefVente
                        <small className="form-text text-muted">Si coché : eSeller Lead : Traitement des leads par le BDC avant de transférer aux chefs des ventes</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_reportingTransformationCyclevente" checked={this.state.params.GRP_reportingTransformationCyclevente} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_reportingTransformationCyclevente
                        <small className="form-text text-muted">Si coché : Le reporting taux de transofrmation se base sur le cycle de vente, sinon sur les offres</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_BDCTraitementLeadsAPV" checked={this.state.params.GRP_BDCTraitementLeadsAPV} onChange={this.onChange.bind(this)} />
                        &nbsp; APV : Traitement leads business par BDC
                        <small className="form-text text-muted">Si coché : eSeller Lead APV : Les leads business (Prise de RDV/Demande de devis) doivent préalablement être traités par un BDC avant d'être transférés aux conseillers service</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="TypeBDC" className="col-sm-3 col-form-label">Type BDC</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="TypeBDC" name="TypeBDC" value={this.state.params.TypeBDC ? this.state.params.TypeBDC : ''} onChange={this.onChange.bind(this)} />
                    <small className="form-text text-muted">Indique le nom du fichier à choisir pour les impressions</small>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadFormatTelFR" checked={this.state.params.GRP_eSellerLeadFormatTelFR} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_eSellerLeadFormatTelFR
                        <small className="form-text text-muted">Si coché les téléphones sont affichés et validés au format français dans eSeller Lead</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_AnalyseMarge" checked={this.state.params.GRP_AnalyseMarge} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_AnalyseMarge
                        <small className="form-text text-muted">Si coché : On peut ajouter un fichier d'analyse de marge sur une offre</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_OffreValiditeFinDeMois" checked={this.state.params.GRP_OffreValiditeFinDeMois} onChange={this.onChange.bind(this)} />
                        &nbsp; GRP_OffreValiditeFinDeMois
                        <small className="form-text text-muted">Si coché : par défaut les offres sont valides jusqu'à la fin du mois (PRC)</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.updated ?
              <div className="row mb-2" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
