import { StateEnumLabel } from "../Enum/StateEnumLabel"
import { FlagEnum } from "../Enum/FlagEnum"

export class CompteWally
{
    IdWallyCompte!: string
    Identifiant!: string
    MotDePasse!: string
    EtatDuCompte!: keyof typeof StateEnumLabel
    EmailNotification!: string
    Flag?: keyof typeof FlagEnum
    settingPassword ?: boolean
    newPassword ?: string
    isPasswordHidden ?: boolean
    savingPassword ?: boolean

    constructor(data?: Partial<CompteWally>) {
        Object.assign(this, data)
        this.settingPassword = false
        this.newPassword = this.MotDePasse
        this.isPasswordHidden = true
        this.savingPassword = false
    }
}