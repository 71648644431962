import React, { Component } from 'react';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categoriesProduction : [],
      volumesProduction: [],

      typesProduction:[],

      IDCategorieProduction: 0,
      IDSousCategorieProduction: 0,
      IDVolumeProduction: 0,
    }
  }

  componentDidMount() {
    this.fetchTypesProduction();
    this.fetchCategoriesProduction();
    this.fetchVolumesProduction();
    this.fetchCategoriesProduction();
  }

  fetchCategoriesProduction(){
    fetch(localStorage.backoffice_api + '/api/categoriesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var categoriesProduction = json; //lodash.orderBy(json, 'Libelle');

      this.setState({
        categoriesProduction: categoriesProduction,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchVolumesProduction(){
    fetch(localStorage.backoffice_api + '/api/volumesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var volumesProduction = json; //lodash.orderBy(json, 'Libelle');

      this.setState({
        volumesProduction: volumesProduction,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchVolumesProduction(){
    fetch(localStorage.backoffice_api + '/api/volumesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var volumesProduction = json; //lodash.orderBy(json, 'Libelle');

      this.setState({
        volumesProduction: volumesProduction,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchTypesProduction(){
    fetch(localStorage.backoffice_api + '/api/typesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        typesProduction: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeValue(name, value){
    if(name === "IDCategorieProduction") {
      this.setState({
        [name]:value,
        IDSousCategorieProduction:0
      });
    }
    else {
      this.setState({
          [name]:value
      });
    }
  }

  canSubmit(){
    return this.state.IDCategorieProduction != 0;
  }

  addTypeProduction(){
    var body = {
      IDCategorieProduction: this.state.IDCategorieProduction !== 0 ? this.state.IDCategorieProduction : null,
      IDSousCategorieProduction: this.state.IDSousCategorieProduction !== 0 ? this.state.IDSousCategorieProduction : null,
      IDVolumeProduction: this.state.IDVolumeProduction !== 0 ? this.state.IDVolumeProduction : null
    }

    fetch(localStorage.backoffice_api + '/api/typesProduction', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        IDCategorieProduction:0,
        IDSousCategorieProduction:0,
        IDVolumeProduction:0
      })
      this.fetchTypesProduction();
    })
    .catch((error) => {
      error.json().then( errorMessage => {
        if(errorMessage.message.includes("Violation of UNIQUE KEY"))
          alert("Erreur : cette association de catégories existe déjà");
        else 
          alert("Erreur : "+errorMessage.message);
      })
    });
  }

  getLibelleCategorieProduction(IDCategorieProduction){
    var categ = this.state.categoriesProduction.find((categ) => {
      return categ.IDCategorieProduction === IDCategorieProduction
    });
    if(categ)
      return categ.Libelle;
    else 
      return '-';
  }

  getLibelleSousCategorieProduction(IDCategorieProduction, IDSousCategorieProduction){
    var categorieProduction = this.state.categoriesProduction.find((categ) => {
      return categ.IDCategorieProduction === IDCategorieProduction
    });

    if(!categorieProduction)
      return '-';

    var categ = categorieProduction.sousCategoriesProduction.find((categ) => {
      return categ.IDSousCategorieProduction === IDSousCategorieProduction
    });

    if(categ)
      return categ.Libelle;
    else 
      return '-';
  }

  getLibelleVolumeProduction(IDVolumeProduction){
    var volume = this.state.volumesProduction.find((volume) => {
      return volume.IDVolumeProduction === IDVolumeProduction
    });
    if(volume)
      return volume.Libelle;
    else 
      return '-';
  }

  deleteLine(IDTypeProduction){
    if(!window.confirm('Supprimer définitivement cette ligne ?'))
      return false;

    fetch(localStorage.backoffice_api + '/api/typesProduction/'+IDTypeProduction, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.fetchTypesProduction();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    var correpondancesLibelles = lodash.map(this.state.typesProduction, (c) => {
      return {
        categorieProduction: this.getLibelleCategorieProduction(c.IDCategorieProduction),
        sousCategorieProduction: this.getLibelleSousCategorieProduction(c.IDCategorieProduction, c.IDSousCategorieProduction),
        volumeProduction: this.getLibelleVolumeProduction(c.IDVolumeProduction),
        IDTypeProduction: c.IDTypeProduction
      }
    });
    correpondancesLibelles = lodash.sortBy(correpondancesLibelles, ['categorieProduction', 'sousCategorieProduction', 'volumeProduction']);

    var categorieProduction = lodash.find(this.state.categoriesProduction, (categ) => {
      return categ.IDCategorieProduction === parseInt(this.state.IDCategorieProduction)
    })

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Types de production Agri</h2>

            <br/>

            <div className="row">
              <div className="col-12 bg-light p-4">
                <div className="row">
                  <div className="col-2">
                    <select name="IDCategorieProduction" className="custom-select w-100" value={this.state.IDCategorieProduction} onChange={(e) => this.changeValue(e.target.name, e.target.value)}>
                      <option value={0}>Catégorie production</option>
                      {this.state.categoriesProduction.map((categ) => {
                        return <option key={categ.IDCategorieProduction} value={categ.IDCategorieProduction}>{categ.Libelle}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-2">
                    <select name="IDSousCategorieProduction" className="custom-select w-100" value={this.state.IDSousCategorieProduction} onChange={(e) => this.changeValue(e.target.name, e.target.value)}>
                      <option value={0}>Sous-catégorie</option>
                      {categorieProduction ?
                        categorieProduction.sousCategoriesProduction.map((categ) => {
                          return <option key={categ.IDSousCategorieProduction} value={categ.IDSousCategorieProduction}>{categ.Libelle}</option>
                        })
                        :
                        null
                      }
                    </select>
                  </div>
                  <div className="col-2">
                    <select name="IDVolumeProduction" className="custom-select w-100" value={this.state.IDVolumeProduction} onChange={(e) => this.changeValue(e.target.name, e.target.value)}>
                      <option value={0}>Volume</option>
                      {this.state.volumesProduction.map((volume) => {
                        return <option key={volume.IDVolumeProduction} value={volume.IDVolumeProduction}>{volume.Libelle}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-2">
                    <button type='button' className='btn btn-secondary' onClick={() => this.addTypeProduction()} disabled={!this.canSubmit()}>
                      <i className='fa fa-plus'></i> Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <table className="table table-striped table-sm table-hover">
                  <thead>
                    <tr>
                      <th>Catégorie de production</th>
                      <th>Sous catégorie</th>
                      <th>Volume de production</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? 
                      <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                    :this.state.typesProduction.length === 0 ?
                      <tr><td colSpan="5">Aucune correspondance n'est configurée</td></tr>
                    :
                      correpondancesLibelles.map((corresp, i) => {
                        return (
                          <tr key={i}>
                            <td>{corresp.categorieProduction}</td>
                            <td>{corresp.sousCategorieProduction}</td>
                            <td>{corresp.volumeProduction}</td>
                            <td className="text-right">
                              <button className='btn btn-light btn-sm' onClick={() => this.deleteLine(corresp.IDTypeProduction)}>
                                <i className='fa fa-trash'></i> Supprimer
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Index;
