import React, { Component } from 'react';
var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      document: null,
      updated: false,
      fetchInProgress: false,
      fetchDocument: false,
      activites:[],
    }
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      this.fetchDocument()
    }
    else {
      this.setState({document: {
        IDActivite: 0,
        IDFamille: 0,
        Descr: "",
        Neuf: false,
        NomImageLogo: "",
        NomFicConditionsGenVente: "",
        TexteReprise: "",
        AfficheOptionVersionConstr: false,
        NomFicBonDeCommande: "",
        NomFicAutreDoc: "",
        TexteCO2: "",
      }})
    }

    this.fetchActivites();
  }

  fetchDocument() {
    this.setState({
      fetchDocument: true,
    })
    
    fetch(localStorage.backoffice_api + '/api/BonDeCommande/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        document: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchActivites() {
    fetch(localStorage.backoffice_api + '/api/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var activites_ids = lodash.uniq(lodash.map(json, 'IDActivite'));

      fetch(localStorage.backoffice_api + '/api/activites/', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        var document = this.state.document;
        document.IDActivite = activites_ids[0];
        this.setState({
          activites: lodash.filter(json, (a) => {
            return activites_ids.indexOf(a.IDActivite) !== -1;
          }),
        });
      })
      .catch((error) => {
        console.log(error)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeValue(event) {
    var document = this.state.document;
    if(document === null)document = {};

    if(event.target.type === 'checkbox')
      document[event.target.name] = event.target.checked;
    else   
      document[event.target.name] = event.target.value;

    this.setState({
      updated: true,
      document: document
    })
  }

  canSubmit(){
    return (this.state.updated && !this.state.fetchInProgress && this.state.document.Descr != '');
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createDocument();
    }
    else {
      this.updateDocument();
    }
  }

  createDocument() {
    fetch(localStorage.backoffice_api + '/api/BonDeCommande', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.document)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/BonDeCommande')
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  updateDocument() {
    fetch(localStorage.backoffice_api + '/api/BonDeCommande/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.document)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/BonDeCommande')
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.document) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Bon de commande</h2>

          <form onSubmit={(e) => this.onSubmit(e)}>

            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card">
                  <div className="card-header">
                    {this.props.match.params.id ? "Modifier un bon de commande" : "Ajouter un bon de commande"}
                  </div>
                  <div className="card-body pb-3">
                    <div className="form-group row">
                      <label htmlFor="IDActivite" className="col-sm-3 col-form-label">Activité</label>
                      <div className="col-sm-6">
                        <select className="form-control form-control-sm" id="IDActivite" name="IDActivite" value={this.state.document.IDActivite} onChange={(e) => this.onChangeValue(e)} required='required'>
                          {
                            this.state.activites.map((a) => {
                              return <option key={a.IDActivite} value={a.IDActivite}>{a.Descr}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.document.Descr} onChange={(e) => this.onChangeValue(e)} maxLength="60" required='required' />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="Neuf" className="col-sm-3 col-form-label">Neuf</label>
                      <div className="col-sm-6">
                        <input type='checkbox' name="Neuf" id="Neuf"  style={{verticalAlign: '-40%'}} onChange={(e) => this.onChangeValue(e)} checked={this.state.document.Neuf} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="NomFicBonDeCommande" className="col-sm-3 col-form-label">Fichier BDC</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="NomFicBonDeCommande" name="NomFicBonDeCommande" value={this.state.document.NomFicBonDeCommande} onChange={(e) => this.onChangeValue(e)} maxLength="60" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="NomFicConditionsGenVente" className="col-sm-3 col-form-label">Fichier CGV</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="NomFicConditionsGenVente" name="NomFicConditionsGenVente" value={this.state.document.NomFicConditionsGenVente} onChange={(e) => this.onChangeValue(e)} maxLength="60" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="NomFicAutreDoc" className="col-sm-3 col-form-label">Fichier autre</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="NomFicAutreDoc" name="NomFicAutreDoc" value={this.state.document.NomFicAutreDoc} onChange={(e) => this.onChangeValue(e)} maxLength="60" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="NomImageLogo" className="col-sm-3 col-form-label">Image Logo</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="NomImageLogo" name="NomImageLogo" value={this.state.document.NomImageLogo} onChange={(e) => this.onChangeValue(e)} maxLength="60" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="TexteReprise" className="col-sm-3 col-form-label">Texte reprise</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="TexteReprise" name="TexteReprise" value={this.state.document.TexteReprise} onChange={(e) => this.onChangeValue(e)} maxLength="255" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="AfficheOptionVersionConstr" className="col-sm-3 col-form-label text-nowrap">AfficheOptionVersionConstr</label>
                      <div className="col-sm-6">
                        <input type='checkbox' name="AfficheOptionVersionConstr" id="AfficheOptionVersionConstr"  style={{verticalAlign: '-40%'}} onChange={(e) => this.onChangeValue(e)} checked={this.state.document.AfficheOptionVersionConstr} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="TexteCO2" className="col-sm-3 col-form-label">Texte CO2</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="TexteCO2" name="TexteCO2" value={this.state.document.TexteCO2} onChange={(e) => this.onChangeValue(e)} maxLength="1000" />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col text-center">
                        <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                          {this.state.fetchInProgress ?
                            <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                          : null}
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
        </form>
      </div>
    );
  }
}

export default Form;
