import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      originesContact: [],
      filterDescription:''
    }
  }

  componentWillMount() {
    this.fetchOriginesContact();
  }

  fetchOriginesContact() {
    fetch(localStorage.backoffice_api + '/api/originesContact', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        originesContact: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteOrigineContact(IDOrigineCont) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cette origine ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/originesContact/'+IDOrigineCont, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      
      this.fetchOriginesContact();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  afficheLienDelete(originesContact){
    if(originesContact.nbCli == 0){
      return(
        <a onClick={this.deleteOrigineContact.bind(this, originesContact.IDOrigineCont)} className='btn btn-danger btn-sm text-white'>
          <i className="fa fa-trash"></i>
        </a>
      ) 
    }
  }


  render() {

    return (
        <div className='container-fluid'>
          <div className="row">
            <div className="col">
  
              <h2>Origines contact</h2>
  
              <br />
  
              <div>
              <div className="btn-group" role="group" aria-label="Basic example">
                <Link to='/originesContact/create' className='btn btn-light'>
                  <i className="fa fa-plus"></i> Ajouter
                </Link>
                <Link to='/originesContact/transfert' className='btn btn-warning'>
                  <i className="fa fa-arrow-right"></i> Transfert
                </Link>
              </div>
                <div className='float-right'>
                  <div className="form-inline float-left" style={{ marginTop: '2px' }}>
                    <div className="input-group">
                      <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                      <input type='search' className='form-control form-control-sm float-right' value={this.state.filterDescription} onChange={(event) => this.setState({filterDescription: event.target.value})} placeholder='Filtre par description' />
                    </div>
                  </div>
                </div>
              </div>
  
              <br />
  
              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.originesContact.map((originesContact) => {
                    if (this.state.filterDescription) {
                      if (originesContact.Descr.toLowerCase().search(this.state.filterDescription.toLowerCase()) === -1) {
                        return false;
                      }
                    }
  
                    return (<tr key={originesContact.IDOrigineCont}>
                      <td>{originesContact.IDOrigineCont}</td>
                      <td>{originesContact.Descr}</td>
                      <td className='text-right pr-5'>
                        {this.afficheLienDelete(originesContact)}
                        &nbsp;
                        <Link to={'/originesContact/'+originesContact.IDOrigineCont} className='btn btn-primary btn-sm'>
                          <i className="fa fa-pencil"></i>  
                        </Link>
                      </td>
                    </tr>)
                })}
                </tbody>
              </table>
  
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default List;
  