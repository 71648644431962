import React, { Component } from 'react';
import Select from 'react-select'

class GroupeConfig extends Component {

    constructor(props) {
        super(props);
    }

    getSelectedTemplatesOffre() {
        return this.props.configs.filter(c => c.Categorie == "offre" && c.IDSite === null).map(c => c.IDTemplateImpression)
    }

    getSelectedTemplatesBdc() {
        return this.props.configs.filter(c => c.Categorie == "bdc" && c.IDSite === null).map(c => c.IDTemplateImpression)
    }

    getSelectedTemplatesBonEssai() {
        return this.props.configs.filter(c => c.Categorie == "bonEssai" && c.IDSite === null).map(c => c.IDTemplateImpression)
    }

    render() {
        return (
            <div>
                <div className="alert alert-primary">
                    <i className='fa fa-info-circle mr-2' />
                    Configuration globale du groupe. Les templates sélectionnés ici sont disponibles pour tous les sites.
                </div>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Offre</th>
                            <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Bon de commande</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Select multi
                                    onChange={this.props.onChange.bind(this, null, "offre")}
                                    value={this.getSelectedTemplatesOffre()}
                                    options={this.props.templatesImpressionOffreOptList}
                                    searchable={true}
                                    placeholder='...'
                                />
                            </td>
                            <td>
                                <Select multi
                                    onChange={this.props.onChange.bind(this, null, "bdc")}
                                    value={this.getSelectedTemplatesBdc()}
                                    options={this.props.templatesImpressionBdcOptList}
                                    searchable={true}
                                    placeholder='...'
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Bon d'Essai</th>
                            <th scope="col" className="border-0" style={{ width: '50%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Select multi
                                    onChange={this.props.onChange.bind(this, null, "bonEssai")}
                                    value={this.getSelectedTemplatesBonEssai()}
                                    options={this.props.templatesImpressionBonEssaiOptList}
                                    searchable={true}
                                    placeholder='...'
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default GroupeConfig;
