import React, { Component } from 'react';
import ModalCreateChamp from './ModalCreateChamp';

var lodash = require('lodash')

class Champs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      typeMaterielAgri : null,

      champs: null,
      fetchingChamps: false,

      updated: false,
      saving: false,
    }
  }

  componentDidMount() {
    this.fetchTypeMaterielAgri();
    this.fetchChamps();
  }

  fetchTypeMaterielAgri() {
    fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/'+this.props.match.params.IDTypeMaterialAgri, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        typeMaterielAgri: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  } 

  fetchChamps() {
    this.setState({
      fetchingChamps: true
    })
    fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/champs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        champs: lodash.groupBy(json, (c) => {
          if(c.Categorie === null)return '';
          return c.Categorie;
        }),
        fetchingChamps: false
      }, () => {console.log(this.state.champs)});
    })
    .catch((error) => {
      console.log(error)
    });
  }

  saveChamps() {
    this.setState({
      saving: true
    })
    var champsIDs = lodash.map(this.state.typeMaterielAgri.Champs, (c) => {
      return c.IDFieldMat;
    });
    fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/'+this.state.typeMaterielAgri.IDTypeMaterialAgri+'/champs', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({Champs: champsIDs})
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        saving: false,
        updated: false
      }, () => {
        //this.props.history.push('/typesMaterielAgri')
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({
        saving: false
      });
    });
  }

  addChamp(champ) {
    var typeMaterielAgri = this.state.typeMaterielAgri;
    typeMaterielAgri.Champs.push(champ);
    this.setState({
      typeMaterielAgri: typeMaterielAgri,
      updated: true,
    })
  }

  removeChamp(champ) {
    var typeMaterielAgri = this.state.typeMaterielAgri;
    typeMaterielAgri.Champs = lodash.filter(typeMaterielAgri.Champs, (c) => {
      return c.IDFieldMat !== champ.IDFieldMat
    })
    this.setState({
      typeMaterielAgri: typeMaterielAgri,
      updated: true,
    })
  }

  canSubmit() {
    return this.state.updated && !this.state.saving
  }

  getNameCategorieChamps(categ){
    if(categ === 'gestion')
      return 'Gestion'
    else if(categ === 'tech')
      return 'Caractéristiques techniques'
    else if(categ === '')
      return 'Champs personnalisés'
  }

  render() {
    if(!this.state.typeMaterielAgri || !this.state.champs)
      return null;

    var IDsChampsUsed = lodash.map(this.state.typeMaterielAgri.Champs, (champ) => {
      return champ.IDFieldMat;
    });

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col mb-4">

            <h2>Types Materiels Agri : {this.state.typeMaterielAgri.Descr}</h2>

            <br/>
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col d-flex align-items-center font-weight-bold pl-4">
                        Champs existants
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-2">
                      {lodash.map(this.state.champs, (champs, categorie) => {
                        return (
                              <table className="table table-striped table-sm table-hover mb-4 border-0" key={categorie}>
                                <thead>
                                  <tr>
                                    <th className="border-top-0">
                                      {this.getNameCategorieChamps(categorie)}
                                    </th>
                                    <th className="border-top-0 text-right">
                                      {categorie === '' ?
                                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalCreateChamp" onClick={() => this.refs.modalCreateChamp.reset()}>
                                          <i className='fa fa-plus'></i> Créer un champ
                                        </button>
                                      :null}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {champs.map((champ) => {
                                  if(IDsChampsUsed.indexOf(champ.IDFieldMat) === -1) {
                                    return (<tr key={champ.IDFieldMat}>
                                        <td colSpan={2} className="p-0 pl-1">
                                          <div className="champ-materiel d-flex justify-content-between align-items-center p-1">
                                            {champ.Descr} {!champ.MaterialTableCol ? "*" : ""}
                                            <button type='button' className='btn btn-primary btn-sm' onClick={() => {this.addChamp(champ)}}>Ajouter</button>
                                          </div>
                                        </td>
                                      </tr>)
                                  }
                                })}
                                </tbody>
                              </table>
                        )
                      })}
                  </div>
                </div>
              </div>


              <div className="col-lg-3 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col d-flex align-items-center font-weight-bold pl-4">
                        Champs associés au type "{this.state.typeMaterielAgri.Descr}"
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-2">
                    {this.state.typeMaterielAgri.Champs.length  ? 
                        lodash.map(lodash.groupBy(this.state.typeMaterielAgri.Champs, (c) => {
                           if(c.Categorie === null)return '';
                           return c.Categorie;
                         }), (champs, categorie) => {
                          return (
                            <table className="table table-striped table-sm table-hover mb-4 border-0" key={categorie}>
                              <thead>
                                <tr>
                                  <th className="border-top-0">
                                    {this.getNameCategorieChamps(categorie)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                              {champs.map((champ) => {
                                  return (<tr key={champ.IDFieldMat}>
                                      <td className="p-0 pl-1">
                                        <div className="champ-materiel d-flex justify-content-between align-items-center p-1">
                                          {champ.Descr} {!champ.MaterialTableCol ? "*" : ""}
                                          <button type='button' className='btn btn-danger btn-sm' onClick={() => {this.removeChamp(champ)}}>Retirer</button>
                                        </div>
                                      </td>
                                    </tr>)
                              })}
                              </tbody>
                            </table>
                          )
                        })
                      :
                      <div className="p-1">
                        Aucun champ n'est associé à ce type de matériel
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12">
                  <button type='button' className='btn btn-primary' disabled={!this.canSubmit()} onClick={this.saveChamps.bind(this)}>
                    {this.state.saving ? 
                      <i className="fa fa-spinner fa-spin fa-fw"/> 
                    :null}
                     Sauvegarder
                  </button>
              </div>
            
            </div>
          </div>
        </div>
        <ModalCreateChamp ref="modalCreateChamp" onSubmit={this.fetchChamps.bind(this)}/>
      </div>
    )
  }
}

export default Champs;
