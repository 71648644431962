import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Index extends Component {

    render() {

        return (
            <div className='container-fluid mb-2'>
                <div className="row">
                    <div className="col">
                        <h2 className="mb-4">Listes</h2>
                        <div className="card my-4">
                            <div className="card-header" style={{backgroundColor:"#ebedeb"}}>
                                Client
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to='/table/categorieClient' className="list-group-item list-group-item-action">Catégories client</Link>
                                <Link to='/fonctions' className="list-group-item list-group-item-action">Fonctions (interlocuteur)</Link>
                                <Link to='/originesContact' className="list-group-item list-group-item-action">Origines contact</Link>
                                <Link to='/table/SITFAM' className="list-group-item list-group-item-action">Situations familiale</Link>
                                <Link to='/statutsJuridiques' className="list-group-item list-group-item-action">Statuts juridiques</Link>
                            </ul>
                        </div>
                        <div className="card my-4">
                            <div className="card-header" style={{backgroundColor:"#ebedeb"}}>
                                Planning
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to='/holidayTypes' className="list-group-item list-group-item-action">Types d'absence</Link>
                            </ul>
                        </div>
                        <div className="card my-4">
                            <div className="card-header" style={{backgroundColor:"#ebedeb"}}>
                                Offre / BDC
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to='/libellesRemise' className="list-group-item list-group-item-action">Libellés remises (PRC)</Link>
                                <Link to='/table/MoyenPaiement' className="list-group-item list-group-item-action">Moyens de paiement</Link>
                                <Link to='/OffreReglement' className="list-group-item list-group-item-action">Offres règlement</Link>
                            </ul>
                        </div>
                        <div className="card my-4">
                            <div className="card-header" style={{backgroundColor:"#ebedeb"}}>
                                Suivi lead
                            </div>
                            <ul className="list-group list-group-flush">
                                <Link to='/table/RaisonClotureLead' className="list-group-item list-group-item-action">Raisons clôture de lead</Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
