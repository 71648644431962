import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      updateInProgress: [],
      editSites: [],
    }
  }

  componentWillMount() {
    this.fetchSites()
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Descr;
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(site, event) {
    if (event.target.name === 'CodeConstructeur') {
      site.CodeConstructeur = event.target.value;
    }
    else if (event.target.name === 'OpelVNToolbox') {
      site.OpelVNToolbox = event.target.checked;
    }

    var editSites = this.state.editSites;
    editSites.push(site.IDSite);

    this.setState({
      editSites: editSites,
    })
  }

  saveSite(site) {
    console.log(site)

    var updateInProgress = this.state.updateInProgress;
    this.state.updateInProgress.push(site.IDSite);

    this.setState({
      updateInProgress: updateInProgress,
    })

    fetch(localStorage.backoffice_api + '/api/sites/'+site.IDSite, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(site)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === site.IDSite;
      });

      var editSites = this.state.editSites;
      lodash.remove(this.state.editSites, (id) => {
        return id === site.IDSite;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexSite = lodash.findIndex(this.state.sites, (u) => {
        return u.IDSite === site.IDSite;
      })

      var sites = this.state.sites;
      sites[indexSite] = site;

      this.setState({
        sites: sites,
        editSites: editSites,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>

      <div className='row'>
        <div className='col'>
          <h2>Opel VN Toolbox</h2>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Société</th>
                  <th>Site</th>
                  <th style={{ width: '200px' }}>Code constructeur</th>
                  <th className='text-center'>Activation</th>
                  <th style={{ width: '200px' }}></th>
                </tr>
              </thead>
              <tbody>
                {this.state.sites.map((site) => {
                  return (<tr key={site.IDSite}>
                    <td>{site.IDSite}</td>
                    <td>{site.Plaque_Libelle}</td>
                    <td>{site.Descr}</td>
                    <td className='text-left'>
                      {this.state.updateInProgress.indexOf(site.IDSite) === -1 ?
                        <input type='text' name='CodeConstructeur' className='form-control' value={site.CodeConstructeur ? site.CodeConstructeur : ''} onChange={this.onChange.bind(this, site)} style={{ width: '100px', display: 'inline-block', marginRight: '5px'}} />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(site.IDSite) === -1 ?
                        <input type='checkbox' onChange={this.onChange.bind(this, site)} checked={site.OpelVNToolbox} name='OpelVNToolbox' disabled={!site.CodeConstructeur || site.CodeConstructeur === ''} />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td>
                      {this.state.updateInProgress.indexOf(site.IDSite) === -1 && this.state.editSites.indexOf(site.IDSite) !== -1 ?
                        <button className='btn btn-primary btn-sm' onClick={this.saveSite.bind(this, site)}>Enregistrer</button>
                      : null}
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
