import React, { Component } from 'react';

class ModalAddCategorieProduction extends Component {

    constructor(props) {
        super(props);

        this.state = {
            libelle: "",

            saving:false,
        }
    }

    submitCategorieProduction(){
        if(this.state.saving)return false;

        this.setState({
            saving:true
        });
        fetch(localStorage.backoffice_api + '/api/categoriesProduction', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                libelle: this.state.libelle,
            })
        })
        .then((response) => {

            if (!response.ok) {
              response.json().then((value) => {
                alert(value.message)
              });
      
              this.setState({
                saving: false,
              })
      
              throw Error(response);
            }

            this.setState({
                saving: false,
              })
            this.props.onSubmit();
        })
        .catch((error) => {
            console.log(error)
        });
    }

    canSubmit(){
        return this.state.libelle.trim() != "" && !this.state.saving;
    }

    changeValue(name, value){
        this.setState({
            [name]:value
        });
    }

    reset(){
        this.setState({
            libelle:'',
        });
    }

    render() {

        return (
            <div className="modal" role="dialog" id="modalAddCategorieProduction">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Ajouter une Catégorie de production</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Libellé
                            </div>
                            <div className="col-8">
                                <input type="text" maxLength="50" name="libelle" className="form-control" value={this.state.libelle} onChange={(e) => this.changeValue(e.target.name, e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit'  data-dismiss="modal" id='submitEmail' disabled={!this.canSubmit()} onClick={() => this.submitCategorieProduction()}>Valider</button>&nbsp;
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalAddCategorieProduction;

