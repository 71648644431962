import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {
  successMessages = {
    "created": "Raison de clôture ajoutée",
    "updated": "Raison de clôture modifiée"
  }

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      successMessage: null,
      showOnlyActive: false,
    }
  }

  componentDidMount() {
    this.fetchValues();
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    if(params.get("success") && this.successMessages[params.get("success")]) {
      this.setState({
        successMessage: this.successMessages[params.get("success")]
      })
    }
  }

  fetchValues() {
    fetch(localStorage.backoffice_api + '/api/table/RaisonClotureLead', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var values = lodash.sortBy(json, (value) => {
        return value.Libelle;
      })

      this.setState({
        values: values,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            {this.state.successMessage &&
              <div className="alert alert-success" role="alert">
                {this.state.successMessage}
              </div> 
            }

            <h2>Raisons de clôture</h2>

            <div className="mt-3 mb-2">
              <Link to='/table/RaisonClotureLead/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

            </div>

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type clôture</th>
                  <th>Libelle</th>
                  <th className="text-center">Avec relance</th>
                  <th className="text-center">
                    Actif
                    <input type="checkbox" className="ml-2" checked={this.state.showOnlyActive} onClick={(e) => this.setState({showOnlyActive: e.currentTarget.checked})}/>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.values.filter(v => v.Actif || !this.state.showOnlyActive).map((r) => {
                  return (<tr key={r.IDAffaireRaisonStop}>
                    <td>{r.IDAffaireRaisonStop}</td>
                    <td>{r.TypeCloture}</td>
                    <td>{r.Libelle}</td>
                    <td className="text-center">
											{r.AvecRelance && <i className="fa fa-check"></i>}
										</td>
                    <td className="text-center">
											{r.Actif && <i className="fa fa-check"></i>}
										</td>
                    <td className="text-right">
                      <Link to={'/table/RaisonClotureLead/'+r.IDAffaireRaisonStop} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
