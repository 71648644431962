import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import { TemplateBDC } from "./Models/TemplateBDC"

declare const window: any
export default function ListTemplateBDC() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [ fetching, setFetching ] = useState<boolean>(true)
    const [ error, setError ] = useState<string>()
    const [ templates, setTemplates ] = useState<TemplateBDC[]>([])

    useEffect(
        () => {
            fetchTemplates()
        },
        [],
    )

    useEffect(() => {
        window.$('[data-toggle="tooltip"]').tooltip()
    })

    const fetchTemplates = async () => {
        setFetching(true)
        try {
            setTemplates(await backofficeApiClient.get<any[]>("/templatesBDC"))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setFetching(false)
        }
    }

    const deleteTemplate = async (IDTemplateBDC: number) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement ce bon de commande ?'))
            return

        backofficeApiClient.delete(`/templatesBDC/${IDTemplateBDC}`, null).then(() => {
            fetchTemplates()
        })
    }

    return (
        <div className="container-fluid mb-2">
            <div className="row">
                <div className="col">
                    {fetching ?
                        <div className="d-flex flex-col align-items-center justify-content-center">
                            <i className="fa fa-spinner fa-spin fa-fw fa-2x"></i>
                        </div>
                        : error ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className="fa fa-warning"></i>
                                {error}
                            </div>
                            :
                            <div>
                                <h5>Configuration des BDC par marque</h5>

                                <Link to="/templatesBDC/create" className="btn btn-light mb-2">
                                    <i className="fa fa-plus"></i> Ajouter
                                </Link>

                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Activité</th>
                                        <th>Nom</th>
                                        <th>Marques</th>
                                        <th>Neuf/Occasion</th>
                                        <th>BDC</th>
                                        <th>CGV</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {templates.map(t => {
                                        return (
                                            <tr key={t.IDTemplateBDC}>
                                                <td>{t.IDTemplateBDC}</td>
                                                <td>{t.Activite_Descr}</td>
                                                <td>{t.Nom}</td>
                                                <td>
                                                            <span className="" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
                                                                  title={`${t.Marques.length > 2 ? t.Marques.map((m: any) => m.Marque).join(", ") : ""}`}>
                                                                {t.Marques.length === 0 ? "Toutes" : t.Marques.length
                                                                <= 2 ? t.Marques.map((m: any) => m.Marque).join(", ") : `${t.Marques.length} ${t.Marques.length === 1 ? "marque" : "marques"}`}
                                                            </span>
                                                </td>
                                                <td>{t.MaterialType ?? "Neuf & Occasion"}</td>
                                                <td>{t.DocumentBDC}</td>
                                                <td>{t.DocumentCGV}</td>

                                                <td className="text-right">
                                                    <Link to={'/templatesBDC/' + t.IDTemplateBDC} className="btn btn-primary btn-sm">
                                                        <i className="fa fa-pencil"></i>
                                                    </Link>
                                                    &nbsp;
                                                    <a href="#" onClick={() => deleteTemplate(t.IDTemplateBDC!)} className="btn btn-danger btn-sm text-white">
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}