import React, { Component } from 'react';

var lodash = require('lodash')

class ModalAddWorkflow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            IDFamilleOrigineContact: 0,
            IDSousFamilleOrigineAction: 0,
            IDOrigineAction: 0,

            IDSite: 0,
            receptionLeads: "",
            workflowBDC: "",

            workflows: [],

            saving: false,
        }
    }

    onSelectFamille(event) {
        this.setState({
            IDFamilleOrigineContact: parseInt(event.target.value),
            IDSousFamilleOrigineAction: 0,
            IDOrigineAction: 0,
        });
    }

    onSelectSousFamille(event) {
        this.setState({
            IDSousFamilleOrigineAction: parseInt(event.target.value),
            IDOrigineAction: 0,
        });
    }

    onSelectOrigine(event) {
        this.setState({
            IDOrigineAction: parseInt(event.target.value)
        });
    }

    onSelectSite(event) {
        this.setState({
            IDSite: parseInt(event.target.value)
        });
    }

    onSelectReceptionLeads(event) {
        this.setState({
            receptionLeads: event.target.value
        });
    }

    onSelectWorkflowBDC(event) {
        this.setState({
            workflowBDC: event.target.value
        });
    }

    onChangeReceptionLeadsForSite(IDSite, event) {
        var workflows = this.state.workflows;
        var idx = lodash.findIndex(workflows, d => d.IDSite == IDSite);
        workflows[idx].ReceptionLeads = event.target.value;

        this.setState({
            workflows: workflows
        })
    }

    onChangeWorkflowBDCForSite(IDSite, event) {
        var workflows = this.state.workflows;
        var idx = lodash.findIndex(workflows, d => d.IDSite == IDSite);
        workflows[idx].WorkflowBDC = event.target.value;

        this.setState({
            workflows: workflows
        })
    }

    getSousFamilles() {
        if(!this.state.IDFamilleOrigineContact){
            return [];
        }

        return lodash.filter(this.props.sousFamilles, sf => {
            return sf.IDFamilleOrigineContact == this.state.IDFamilleOrigineContact
        })
    }

    getOrigines() {
        if(!this.state.IDSousFamilleOrigineAction) {
            return [];
        }

        return lodash.filter(this.props.origines, o => {
            return o.IDSousFamilleOrigineAction == this.state.IDSousFamilleOrigineAction
        })
    }

    getSitesWithoutWorkflowForCurrentOrigine() {
        var sitesWithWorkflow = lodash.map(this.state.workflows, (d) => d.IDSite);

        var workflowsForOrigine = lodash.filter(this.props.alreadyExistingWorkflows, (d) => d.IDOrigineAction == this.state.IDOrigineAction);
        sitesWithWorkflow = sitesWithWorkflow.concat(lodash.map(workflowsForOrigine, (d) => d.IDSite));

        var sites = lodash.filter(this.props.sites, (site) => sitesWithWorkflow.indexOf(site.IDSite) === -1);

        return sites;
    }

    currentOrigineHasDefaultWorkflow() {
        return (lodash.find(this.props.alreadyExistingWorkflows, (w) => w.IDOrigineAction == this.state.IDOrigineAction && w.IDSite === null) != null);
    }

    getSelectedOrigineLibelle() {
        if(this.state.IDSousFamilleOrigineAction == -1) {
            return "Toutes les origines de la famille "+this.getSelectedFamilleLibelle();
        }
        if(this.state.IDOrigineAction == -1) {
            return "Toutes les origines "+this.getSelectedFamilleLibelle()+" / "+this.getSelectedSousFamilleLibelle();
        }

        var origine = lodash.find(this.props.origines, (o) => o.IDOrigineAction == this.state.IDOrigineAction);

        return origine.Famille_Libelle+" / "+origine.SousFamille_Libelle+" / "+origine.Libelle+" ("+origine.Type_Description+")";
    }

    getSelectedSousFamilleLibelle() {
        var sousFamille = lodash.find(this.props.sousFamilles, (sf) => sf.IDSousFamilleOrigineAction == this.state.IDSousFamilleOrigineAction);

        return sousFamille.Libelle;
    }

    getSelectedFamilleLibelle() {
        var famille = lodash.find(this.props.familles, (f) => f.IDFamilleOrigineContact == this.state.IDFamilleOrigineContact);

        return famille.Libelle;
    }

    getSiteLibelle(IDSite) {
        if(IDSite == -1) {
            return "Sites sans configuration spécifique"
        }

        var site = lodash.find(this.props.sites, (s) => s.IDSite == IDSite);

        return site.Libelle || site.Descr;
    }

    getNbWorkflowsEcrases() {
        var nb = 0;
        for(var w in this.state.workflows) {
            if(this.state.IDSousFamilleOrigineAction == -1) {
                var origines = lodash.filter(this.props.origines, (o) => o.IDFamilleOrigineContact == this.state.IDFamilleOrigineContact);
            }
            else if(this.state.IDOrigineAction == -1) {
                var origines = lodash.filter(this.props.origines, (o) => o.IDSousFamilleOrigineAction == this.state.IDSousFamilleOrigineAction);
            }

            for(var o in origines) {
                if(lodash.find(this.props.alreadyExistingWorkflows, aew => aew.IDOrigineAction == origines[o].IDOrigineAction && aew.IDSite == this.state.workflows[w].IDSite)){
                    ++nb;
                }
            }
        }
        return nb;
    }

    addWorkflow() {
        var workflows = this.state.workflows;

        workflows.push({
            "IDOrigineAction": this.state.IDOrigineAction,
            "IDSite": this.state.IDSite,
            "ReceptionLeads": this.state.receptionLeads,
            "WorkflowBDC": this.state.workflowBDC,
            "SiteLibelle": this.getSiteLibelle(this.state.IDSite),
        });

        workflows = lodash.sortBy(workflows, "SiteLibelle");

        this.setState({
            workflows: workflows,
            IDSite: 0,
        })
    }

    removeWorkflow(IDSite) {
        var workflows = lodash.filter(this.state.workflows, (d) => d.IDSite != IDSite)

        this.setState({
            workflows: workflows
        })
    }

    confirmSave() {
        var nbWorkflows = this.state.workflows.length;
        var texte;
        if(this.state.IDSousFamilleOrigineAction == -1) {
            texte = nbWorkflows + " workflow"+ (nbWorkflows > 1 ? "s seront créés" : " sera créé")+" pour chaque origine de la famille "+this.getSelectedFamilleLibelle()+". "+(lodash.filter(this.props.origines, (o) => o.IDFamilleOrigineContact == this.state.IDFamilleOrigineContact).length * this.state.workflows.length)+" workflows seront créés au total. Continuer ?"
        }
        else if(this.state.IDOrigineAction == -1) {
            texte = nbWorkflows + " workflow"+ (nbWorkflows > 1 ? "s seront créés" : " sera créé")+" pour chaque origine de la sous-famille "+this.getSelectedFamilleLibelle()+" / "+this.getSelectedOrigineLibelle()+". "+(lodash.filter(this.props.origines, (o) => o.IDSousFamilleOrigineAction == this.state.IDSousFamilleOrigineAction).length * this.state.workflows.length)+" workflows seront créés au total. Continuer ?"
        }
        else {
            texte = nbWorkflows + " workflow"+ (nbWorkflows > 1 ? "s seront créés" : " sera créé")+" pour l'origine "+this.getSelectedOrigineLibelle()+". Continuer ?"
        }

        return window.confirm(texte);
    }

    /**
     * Lorsque qu'on n'a pas sélectionné une origine précise ("Toutes les origines"), 
     * alors il faut boucler sur toute les origines concernées pour crééer les workflows, qui sont individuels à une origine
     */
    formatWorkflowsForSave() {
        if(this.state.IDSousFamilleOrigineAction == -1) {
            var origines = lodash.filter(this.props.origines, (o) => o.IDFamilleOrigineContact == this.state.IDFamilleOrigineContact);
        }
        else if(this.state.IDOrigineAction == -1) {
            var origines = lodash.filter(this.props.origines, (o) => o.IDSousFamilleOrigineAction == this.state.IDSousFamilleOrigineAction);
        }
        else {
            return this.state.workflows;
        }

        var workflows = [];
        for(var w in this.state.workflows) {
            for(var o in origines) {
                workflows.push({
                    "IDOrigineAction": origines[o].IDOrigineAction,
                    "IDSite": this.state.workflows[w].IDSite,
                    "ReceptionLeads": this.state.workflows[w].ReceptionLeads,
                    "WorkflowBDC": this.state.workflows[w].WorkflowBDC,
                });
            }
        }

        return workflows;
    }

    save() {
        if(!this.confirmSave()) {
            return;
        }

        this.setState({
            saving: true
        })

        var workflows = this.formatWorkflowsForSave();
        
        fetch(localStorage.backoffice_api + '/api/workflowsLeads', {
            method: 'PATCH',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                "workflows": workflows
            })
        })
        .then((response) => {
            if (!response.ok) {
              response.json().then((value) => {
                alert(value.message)
              });
              this.setState({
                saving: false,
              })
              throw Error(response);
            }

            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
    }

    render() {
        const nbWorkflowsEcrases = this.getNbWorkflowsEcrases();

        return (
        <div className="modal" role="dialog" id="modalAddWorkflow" data-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Configurer un workflow</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    
                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col-2 m-auto">
                                Origine
                            </div>
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-4">
                                        <select className="form-control" style={{backgroundColor:"#fff"}} value={this.state.IDFamilleOrigineContact} disabled={this.state.workflows.length} onChange={this.onSelectFamille.bind(this)}>
                                            <option value='0'>Famille</option>
                                            {this.props.familles.map((famille) => {
                                                return (<option key={"famille"+famille.IDFamilleOrigineContact} value={famille.IDFamilleOrigineContact}>{famille.Libelle}</option>);
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <select className="form-control" style={{backgroundColor:"#fff"}}  value={this.state.IDSousFamilleOrigineAction} disabled={this.state.workflows.length} onChange={this.onSelectSousFamille.bind(this)}>
                                            <option value='0'>Sous-famille</option>
                                            {this.state.IDFamilleOrigineContact ? 
                                                <option value='-1'>Toutes les sous-familles</option>
                                            :null}
                                            {this.getSousFamilles().map(sousFamille => {
                                                return (<option key={"sousFamille"+sousFamille.IDSousFamilleOrigineAction} value={sousFamille.IDSousFamilleOrigineAction}>{sousFamille.Libelle}</option>);
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <select className="form-control" style={{backgroundColor:"#fff"}}  value={this.state.IDOrigineAction} disabled={this.state.workflows.length} onChange={this.onSelectOrigine.bind(this)}>
                                            <option value='0'>Origine</option>
                                            {this.state.IDSousFamilleOrigineAction ? 
                                                <option value='-1'>Toutes les origines</option>
                                            :null}
                                            {this.getOrigines().map(origine => {
                                                return (<option key={"origine"+origine.IDOrigineAction} value={origine.IDOrigineAction}>{origine.Libelle} ({origine.Type_Description})</option>);
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.IDOrigineAction ? 
                            <div className="form-group row border-top pt-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-5 d-flex align-items-end pr-0">
                                            <div className="d-flex flex-column">
                                                <div>Site</div>
                                                <select className="form-control form-control-sm" value={this.state.IDSite} onChange={this.onSelectSite.bind(this)}>
                                                    <option value='0'>Sélectionner un site</option>
                                                    {!this.currentOrigineHasDefaultWorkflow() ?
                                                        <option value='-1'>Sites sans configuration spécifique</option>
                                                    :null}
                                                    {this.getSitesWithoutWorkflowForCurrentOrigine().map((site) => {
                                                        return (<option key={site.IDSite} value={site.IDSite}>{site.Libelle || site.Descr}</option>);
                                                    })}
                                                </select>
                                            </div>
                                            <span className="mx-3">
                                                &#x27A4;
                                            </span>
                                        </div>

                                        <div className="col-2 pl-0">
                                            <div className="d-flex flex-column">
                                                <div>Leads réceptionnés par</div>
                                                <select className="form-control form-control-sm" value={this.state.receptionLeads} onChange={this.onSelectReceptionLeads.bind(this)}>
                                                    <option value="">Sélectionner un profil</option>
                                                    {this.props.receptionsLeads.map((att) => {
                                                        return (<option key={att.code} value={att.code}>{att.libelle}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.receptionLeads == "BDC" ? 
                                            <div className="col-4 pl-0">
                                                <div className="d-flex flex-column">
                                                    <div>Transfert vers</div>
                                                    <select className="form-control form-control-sm" value={this.state.workflowBDC} onChange={this.onSelectWorkflowBDC.bind(this)}>
                                                        <option value="">Utiliser la configuration par défaut du groupe</option>
                                                        {this.props.workflowsBDC.map((t, i) => {
                                                            return <option value={t.code} key={i}>{t.libelle}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        :null}

                                        <div className="col-1 d-flex align-items-end">
                                            <button className="btn btn-primary btn-sm" type='button' disabled={!this.state.IDOrigineAction || !this.state.IDSite || !this.state.receptionLeads} onClick={this.addWorkflow.bind(this)}>Ajouter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :null}

                        {this.state.workflows.length ? 
                            <div className="form-group row border-top pt-3">
                                <div className="col-12 m-auto pb-2">
                                    {nbWorkflowsEcrases > 0 ? 
                                        <div className="alert alert-danger">
                                            Il existe déjà des workflows pour certains couples Origine/Site ({nbWorkflowsEcrases}). Ceux-ci seront écrasés.
                                        </div>
                                    :null}
                                    {this.state.IDOrigineAction == -1 ? 
                                        <b>Les workflows suivants seront créées pour {this.getSelectedOrigineLibelle()}</b> 
                                    :
                                        <b>Les workflows suivants seront créées concernant l'origine {this.getSelectedOrigineLibelle()} :</b> 
                                    }
                                </div>
                                <div className="col-12">
                                    {this.state.workflows.map((workflow, i) => {
                                        return (
                                            <div key={i} className="row">
                                                <div className="col-5 text-sm d-flex justify-content-between">
                                                    <div>
                                                        {workflow.SiteLibelle}
                                                    </div>
                                                    <span className="mx-3">
                                                        &#x27A4;
                                                    </span>
                                                </div>
                                                <div className="col-2">
                                                    <select className="form-control form-control-sm" value={workflow.ReceptionLeads} onChange={this.onChangeReceptionLeadsForSite.bind(this, workflow.IDSite)}>
                                                        {this.props.receptionsLeads.map((att) => {
                                                            return (<option key={att.code} value={att.code}>{att.libelle}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                                
                                                <div className="col-4 pl-0">
                                                    {workflow.ReceptionLeads == "BDC" ? 
                                                        <select className="form-control form-control-sm" value={workflow.WorkflowBDC} onChange={this.onChangeWorkflowBDCForSite.bind(this, workflow.IDSite)}>
                                                            <option value="">Utiliser la configuration par défaut du groupe</option>
                                                            {this.props.workflowsBDC.map((t, i) => {
                                                                return <option value={t.code} key={i}>{t.libelle}</option>
                                                            })}
                                                        </select>
                                                    :
                                                        <hr/>
                                                    }
                                                </div>

                                                <div className="col-1 m-auto pl-0">
                                                    <button type="button" className='btn btn-link text-danger shadow-none' onClick={this.removeWorkflow.bind(this, workflow.IDSite)}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        :null}
                        
                    </div>
                    
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit' disabled={!this.state.workflows.length || this.state.saving} onClick={this.save.bind(this)}>Valider</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalAddWorkflow;

