import React from "react"
import { ConfigDBS } from "./DBSSiteUtils"
import BackOfficeAPIClient from "../../../clients/BackofficeAPIClient"

interface Props {
    config: ConfigDBS,
    setConfig: (config:ConfigDBS) => void,
    setFetchInProgressConfig: (boolean:boolean) => void
    fetchInProgressConfig : boolean
    backofficeApiClient : BackOfficeAPIClient
    setUpdatedConfig: (boolean:boolean) => void
    updatedConfig : boolean
}

export default function Auth(props: Props) {
    const handleSubmitConfig = async (e: any) => {
        e.preventDefault();
        props.setFetchInProgressConfig(true)
        await props.backofficeApiClient
                   .put("/interfaces/dbs/config",props.config)
                   .catch(() => {
                       props.setConfig(new ConfigDBS());
                   })
                   .finally(() => {
                       props.setFetchInProgressConfig(false)
                       props.setUpdatedConfig(false);
                   })
    }

    const onChangeConfig = (e: any) =>  {
        const name = e.target.name as keyof ConfigDBS;
        const value = e.target.value
        const updatedConfig = { ...props.config, [name]: value };
        props.setConfig(updatedConfig);
        props.setUpdatedConfig(true)
    }

    return (
        <form onSubmit={(e) => handleSubmitConfig(e)}>
            <div className="row">
                <div className="col">
                    <div className="form-group row">
                        <label htmlFor="GRP_DBS_ClientId" className="col-sm-3 col-form-label">ClientId</label>
                        <div className="col-sm-6">
                            <input type="text" className="form-control form-control-sm" id="GRP_DBS_ClientId" name="GRP_DBS_ClientId" value={props.config.GRP_DBS_ClientId} onChange={(e) => onChangeConfig(e)} />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label htmlFor="GRP_DBS_ClientSecret" className="col-sm-3 col-form-label">ClientSecret</label>
                        <div className="col-sm-6">
                            <input type="text" className="form-control form-control-sm" id="GRP_DBS_ClientSecret" name="GRP_DBS_ClientSecret" value={props.config.GRP_DBS_ClientSecret} onChange={(e) => onChangeConfig(e)} />
                        </div>
                    </div>
                </div>
            </div>
            {props.updatedConfig ?
                <div className="row mb-2" style={{ marginTop: '15px' }}>
                    <div className="col text-center">
                        <button disabled={props.fetchInProgressConfig} type="submit" className="btn btn-primary">
                            {props.fetchInProgressConfig ?
                                <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                                : null}
                            Enregistrer
                        </button>
                    </div>
                </div>
                : null}
        </form>
    );
}