import { Site } from "../../../../../types/Site"

export class SiteWally
{
    NomWallySite!: string
    IdWallySite!: number
    NomWallyMarque!: string
    IdWallyMarque!: number
    IdCompte!: string
    IDSite!: number
    SiteDescr!: string
    Site?: Site

    isCurrentChange?: boolean
    IDSiteModifying?: number | null

    constructor(data?: Partial<SiteWally>, sites?: Site[]) {
        Object.assign(this, data)
        if (this.IDSite && sites) {
            this.Site = sites.find(s => s.IDSite === this.IDSite)
        }

        this.isCurrentChange = this.IDSite === null;
        this.IDSiteModifying = this.IDSite;
    }

    getPrimaryKey = () => {
        return this.IdWallyMarque + "_" + this.IdWallySite
    }

}


