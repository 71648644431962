import React, { Component } from 'react';

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profil: null,
      dashboards: [],
      rights: null,
      updated: false,
      fetchInProgress: false,
      softwares: [],
      modules: [],
      fonctions: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchProfil()
    this.fetchDashboards()
    this.fetchProfilRights()
  }

  fetchProfil() {
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        profil: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchDashboards() {
    fetch(localStorage.backoffice_api + '/api/dashboards', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        dashboards: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchProfilRights() {
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/'+this.props.match.params.id+'/rights', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var softwares = lodash.map(json, (software) => {
        return {
          SOFTWARE_ID: software.SOFTWARE_ID,
          SOFTWARE_DESCR: software.SOFTWARE_DESCR,
          SOFTWARE_CODE: software.SOFTWARE_CODE,
        }
      });
      softwares = lodash.uniqBy(softwares, (software) => {
        return software.SOFTWARE_ID;
      });

      var modules = lodash.map(json, (software) => {
        return {
          DRMODULE_CODE: software.DRMODULE_CODE,
          DRMODULE_DESCR: software.DRMODULE_DESCR,
          DRMODULE_ID: software.DRMODULE_ID,
          SOFTWARE_ID: software.SOFTWARE_ID,
        }
      })
      modules = lodash.uniqBy(modules, (module) => {
        return module.DRMODULE_ID;
      });

      var fonctions = lodash.map(json, (software) => {
        return {
          DRFUNCTION_CODE: software.DRFUNCTION_CODE,
          DRFUNCTION_COMMENT: software.DRFUNCTION_COMMENT,
          DRFUNCTION_DESCR: software.DRFUNCTION_DESCR,
          DRFUNCTION_ID: software.DRFUNCTION_ID,
          DRMODULE_ID: software.DRMODULE_ID,
          SOFTWARE_ID: software.SOFTWARE_ID,
        }
      })
      fonctions = lodash.uniqBy(fonctions, (fonction) => {
        return fonction.DRFUNCTION_ID;
      });

      this.setState({
        rights: json,
        softwares: softwares,
        modules: modules,
        fonctions: fonctions,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var profil = this.state.profil

    if (type === 'checkbox') {
      profil[name] = event.target.checked
    }
    else {
      profil[name] = value
    }

    this.setState({
      updated: true,
      profil: profil,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.profil)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/'+this.props.match.params.id+'/rights', {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.rights)
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            fetchInProgress: false,
          })
          throw Error(response);
        }
        this.setState({
          updated: false,
          profil: null,
          rights: null,
          softwares: [],
          modules: [],
          fonctions: [],
          fetchInProgress: false,
        }, () => {
          this.fetchProfil()
          this.fetchProfilRights()
        })
      })
      .catch((error) => {
        console.log(error)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  isChecked(DRFUNCTION_ID, DRTYPE_PRIVILEGE_ID) {
    var founds = lodash.filter(this.state.rights, (right) => {
      return (parseInt(right.DRFUNCTION_ID, 10) === parseInt(DRFUNCTION_ID, 10) && parseInt(right.DRTYPE_PRIVILEGE_ID, 10) === parseInt(DRTYPE_PRIVILEGE_ID, 10));
    })

    if (!founds.length) {
      return false;
    }

    return founds[0].DRPROFILE_PRIVILEGE_CHECKED;
  }

  isDisabled(DRFUNCTION_ID, DRTYPE_PRIVILEGE_ID) {

    if (this.state.fetchInProgress) {
      return true;
    }

    var founds = lodash.filter(this.state.rights, (right) => {
      return (parseInt(right.DRFUNCTION_ID, 10) === parseInt(DRFUNCTION_ID, 10) && parseInt(right.DRTYPE_PRIVILEGE_ID, 10) === parseInt(DRTYPE_PRIVILEGE_ID, 10));
    })

    if (!founds.length) {
      return true;
    }

    return !founds[0].DRPRIVILEGE_FUNCTION_ENABLED;
  }

  onChangeRight(fonction, DRTYPE_PRIVILEGE_CODE, e) {

    var rights = this.state.rights;
    var index = lodash.findIndex(rights, (f) => {
      return f.DRFUNCTION_ID === fonction.DRFUNCTION_ID && parseInt(f.DRTYPE_PRIVILEGE_CODE, 10) === parseInt(DRTYPE_PRIVILEGE_CODE);
    })

    rights[index].DRPROFILE_PRIVILEGE_CHECKED = !rights[index].DRPROFILE_PRIVILEGE_CHECKED;

    this.setState({
      rights: rights,
      updated: true,
    })

  }

  render() {

    if (!this.state.profil) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Profils eSeller</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="DESCR" className="col-sm-3 col-form-label">Nom du profil</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="DESCR" name="DESCR" value={this.state.profil.DESCR} onChange={this.onChange.bind(this)} maxLength="30" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDDashboard" className="col-sm-3 col-form-label">Tableau de bord</label>
                  <div className="col-sm-6">
                    <select value={this.state.profil.IDDashboard ? this.state.profil.IDDashboard : ''} onChange={this.onChange.bind(this)} id='IDDashboard' name="IDDashboard" className="form-control form-control-sm">
                      <option value=''></option>
                      {this.state.dashboards.map((dashboard, i) => {
                        return <option key={i} value={dashboard.IDDashboard}>{dashboard.Libelle}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">

                <div className='float-right'>
                  <div className="form-inline" style={{ marginTop: '2px' }}>
                    <div className="input-group">
                      <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                      <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par nom' />
                    </div>
                  </div>
                </div>

                {this.state.softwares.map((software, i) => {
                  return (
                    <div key={i}>
                      <h4>{software.SOFTWARE_CODE} : {software.SOFTWARE_DESCR}</h4>

                      {this.state.modules.map((module, j) => {

                        if (module.SOFTWARE_ID !== software.SOFTWARE_ID)
                          return null;

                        return (<div key={j}>
                          <h5>{module.DRMODULE_CODE} : {module.DRMODULE_DESCR}</h5>

                          <table className='table table-bordered table-striped table-hover table-sm'>
                            <thead>
                              <tr>
                                <th style={{ width: '10%' }}>Code</th>
                                <th style={{ width: '30%' }}>Description de la Fonction</th>
                                <th style={{ width: '40%' }}>Commentaire</th>
                                <th style={{ width: '5%' }} className='text-center'>U</th>
                                <th style={{ width: '5%' }} className='text-center'>C</th>
                                <th style={{ width: '5%' }} className='text-center'>M</th>
                                <th style={{ width: '5%' }} className='text-center'>S</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.fonctions.map((fonction, k) => {

                                if (module.SOFTWARE_ID !== software.SOFTWARE_ID || fonction.DRMODULE_ID !== module.DRMODULE_ID)
                                  return null;

                                return (<tr key={k} className={this.state.filterName && (fonction.DRFUNCTION_DESCR.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) ? 'hide' : ''}>
                                    <td>{fonction.DRFUNCTION_CODE}</td>
                                    <td>{fonction.DRFUNCTION_DESCR}</td>
                                    <td>{fonction.DRFUNCTION_COMMENT}</td>
                                    <td className='text-center'>
                                      <input type="checkbox" name="" checked={this.isChecked(fonction.DRFUNCTION_ID, 1)} disabled={this.isDisabled(fonction.DRFUNCTION_ID, 1)} onChange={this.onChangeRight.bind(this, fonction, 1)} />
                                    </td>
                                    <td className='text-center'>
                                      <input type="checkbox" name="" checked={this.isChecked(fonction.DRFUNCTION_ID, 2)} disabled={this.isDisabled(fonction.DRFUNCTION_ID, 2)} onChange={this.onChangeRight.bind(this, fonction, 2)} />
                                    </td>
                                    <td className='text-center'>
                                      <input type="checkbox" name="" checked={this.isChecked(fonction.DRFUNCTION_ID, 3)} disabled={this.isDisabled(fonction.DRFUNCTION_ID, 3)} onChange={this.onChangeRight.bind(this, fonction, 3)} />
                                    </td>
                                    <td className='text-center'>
                                      <input type="checkbox" name="" checked={this.isChecked(fonction.DRFUNCTION_ID, 4)} disabled={this.isDisabled(fonction.DRFUNCTION_ID, 4)} onChange={this.onChangeRight.bind(this, fonction, 4)} />
                                    </td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>

                        </div>)
                      })}
                    </div>)
                })}

              </div>
            </div>


            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
