import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      civilite: null,
      updated: false,
      fetchIDCivilite: false,
      saving: false,
      create:false,
      update:false
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchCivilite()
    }
    else {
      this.setState({civilite: {
        "IDCivilite": '',
        "Descr": '',
      }})
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchIDCivilite) {
      this.setState({
        updated:false,
        saving:false,
      })
      this.fetchCivilite()
    }
  }

  fetchCivilite() {
    this.setState({
      fetchIDCivilite: true,
    })

    fetch(localStorage.backoffice_api + '/api/civilites/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        civilite: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      saving: true,
    })

    if (!this.props.match.params.id) {
      this.createCivilite();
    }
    else {
      this.editCivilite();
    }
  }

  onChange(event) {

    var civilite = this.state.civilite

    civilite.Descr = event.target.value
    console.log(this.state.civilite)
    this.setState({
      updated: true,
       ivilite: civilite,
    })
  }

  createCivilite() {
    fetch(localStorage.backoffice_api + '/api/civilites', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.civilite)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        create:true,
        update:false
      })
      this.props.history.push('/civilites/'+json.IDCivilite)
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editCivilite() {
    fetch(localStorage.backoffice_api + '/api/civilites/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.civilite)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        civilite: null,
        saving: false,
        update:true,
        create:false
      }, () => {
        this.fetchCivilite()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.civilite) {
      return null;
    }
    
    return (
      <div className='container-fluid'>
        <h2>Civilités</h2>
        <br />

        {this.state.create ? 
        <div className="alert alert-success" role="alert">
          Civilité ajoutée avec succès.
        </div> 
        : null}

        {this.state.update ? 
        <div className="alert alert-success" role="alert">
         Civilité modifiée avec succès.
        </div> 
        : null}

        <Link to='/civilites' className='btn btn-light'>
          <i className="fa fa-arrow-left"></i> Retour
        </Link>
        
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="row">
            <div className="col">
              <div className="form-group row">
                <label htmlFor="Descr" className="col-sm-3 col-form-label">Description civilités</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.civilite.Descr} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
                </div>
              </div>
            </div>
          </div>
          {this.state.updated ?
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col text-center">
                <button disabled={this.state.saving} type="submit" className="btn btn-primary">
                  {this.state.saving ?
                    <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                  : null}
                  Enregistrer
                </button>
              </div>
            </div>
          : null}
        </form>
      </div>
    );
  }
}

export default Form;
