import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      societes: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchSocietes();
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var societes = lodash.sortBy(json, (societe) => {
        return societe.Libelle;
      })

      this.setState({
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Sociétés</h2>

            <br />

            <div>
              <Link to='/societes/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

              <div className='float-right'>
                <div className="form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par libellé' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Libellé</th>
                  <th>E-mail</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.societes.map((societe) => {
                  if (this.state.filterName) {
                    if (societe.Libelle.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                      return false;
                    }
                  }

                  return (<tr key={societe.IDPlaque}>
                    <td>{societe.IDPlaque}</td>
                    <td>{societe.Libelle}</td>
                    <td>{societe.EMailsContacts}</td>
                    <td>
                      <Link to={'/societes/'+societe.IDPlaque} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
