import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      document: null,
      updated: false,
      fetchInProgress: false,
      fetchDocument: false,
      modules:[],
    }
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      this.fetchDocument()
    }
    else {
      this.setState({document: {
        "IDDocumentAdminModule": 0,
        "Descr": '',
        "Url": '',
        "Catalogue": 0
      }})
    }

    this.fetchModules();
  }

  fetchDocument() {
    this.setState({
      fetchDocument: true,
    })
    
    fetch(localStorage.backoffice_api + '/api/docsAdmin/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        document: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchModules() {
    fetch(localStorage.backoffice_api + '/api/docsAdmin/modules', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        modules: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeValue(event) {
    var document = this.state.document;
    if(document === null)document = {};

    if(event.target.type === 'checkbox')
      document[event.target.name] = event.target.checked;
    else   
      document[event.target.name] = event.target.value;

    this.setState({
      updated: true,
      document: document
    })
  }

  canSubmit(){
    return (this.state.updated && !this.state.fetchInProgress && this.state.document.Descr != '' && this.state.document.Url != '' && this.state.document.IDDocumentAdminModule != 0);
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createDocument();
    }
    else {
      this.updateDocument();
    }
  }

  createDocument() {
    fetch(localStorage.backoffice_api + '/api/docsAdmin', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.document)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/docsAdmin')
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  updateDocument() {
    fetch(localStorage.backoffice_api + '/api/docsAdmin/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.document)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/docsAdmin')
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.document) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Documents administratifs</h2>

          <form onSubmit={(e) => this.onSubmit(e)}>

            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card">
                  <div className="card-header">
                    {this.props.match.params.id ? "Modifier un document administratif" : "Ajouter un document admnistratif"}
                  </div>
                  <div className="card-body pb-3">
                    <div className="form-group row">
                      <label htmlFor="IDDocumentAdminModule" className="col-sm-3 col-form-label">Module</label>
                      <div className="col-sm-6">
                        <select className="form-control form-control-sm" id="IDDocumentAdminModule" name="IDDocumentAdminModule" value={this.state.document.IDDocumentAdminModule} onChange={(e) => this.onChangeValue(e)} required='required'>
                          {
                            this.state.modules.map((m) => {
                              return <option key={m.IDDocumentAdminModule} value={m.IDDocumentAdminModule}>{m.Descr}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.document.Descr} onChange={(e) => this.onChangeValue(e)} maxLength="100" required='required' />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="Url" className="col-sm-3 col-form-label">Url</label>
                      <div className="col-sm-6">
                        <input type="text" className="form-control form-control-sm" id="Url" name="Url" value={this.state.document.Url} onChange={(e) => this.onChangeValue(e)} maxLength="255" required='required' />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="Catalogue" className="col-sm-3 col-form-label">Catalogue</label>
                      <div className="col-sm-6">
                        <input type='checkbox' name="Catalogue" id="Catalogue"  style={{verticalAlign: '-40%'}} onChange={(e) => this.onChangeValue(e)} checked={this.state.document.Catalogue} />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col text-center">
                        <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                          {this.state.fetchInProgress ?
                            <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                          : null}
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
        </form>
      </div>
    );
  }
}

export default Form;
