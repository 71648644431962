import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Links extends Component {

  constructor(props) {
    super(props);

    this.state = {
      links: [],
    }
  }

  componentWillMount() {
    this.fetchLinks();
  }

  getNameUrl(url) {
    if (url.toLowerCase().search('e-seller.selectup.com') !== -1) {
      return 'eSeller Team';
    }

    if (url.toLowerCase().search('smit.selectup.com') !== -1) {
      return 'SMIT / eSeller Team';
    }

    if (url.toLowerCase().search('serveurpub.selectup.com') !== -1) {
      return 'Serveur Pub';
    }

    if (url.toLowerCase().search('reporting.selectup.com') !== -1) {
      return 'Reporting eSeller Team';
    }

    if (url.toLowerCase().search('reportingv3.selectup.com') !== -1) {
      return 'Reporting eSeller Team V3';
    }

    if (url.toLowerCase().search('backoffice.selectup.com') !== -1) {
      return 'Back-Office eSeller Team';
    }

    return url;
  }

  fetchLinks() {
    fetch(localStorage.backoffice_api + '/api/bdref3/urls', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        links: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col text-center'>
          {this.state.links.map((url) => {
            return <a className='btn btn-outline-primary btn-sm' key={url.IDGroupements} href={'http://'+url.URL} target='_blank' style={{ marginRight: '10px' }}>
              <i className="fa fa-external-link" aria-hidden="true"></i> &nbsp;
              {this.getNameUrl(url.URL)}
            </a>;
          })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Links);
