import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import moment from 'moment'
import { GrilleMalusMasse } from './Models/GrilleMalusMasse'

export default function ListGrilleMalusMasse() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [ fetching, setFetching ] = useState<boolean>(true)
    const [ error, setError ] = useState<string>()
    const [ grillesMalusMasse, setGrillesMalusMasse ] = useState<GrilleMalusMasse[]>([])

    useEffect(() => {
        fetchGrillesMalusMasse()
    }, [])

    const fetchGrillesMalusMasse = async () => {
        setFetching(true)
        try {
            setGrillesMalusMasse((await backofficeApiClient.get<GrilleMalusMasse[]>("/malusMasse")))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setFetching(false)
        }
    }

    const deleteGrid = async (IDGrilleMalusMasse: number) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cette grille de malus sur la masse en ordre de marche ?'))
            return

        backofficeApiClient.delete(`/malusMasse/${IDGrilleMalusMasse}`, null).then(() => {
            fetchGrillesMalusMasse()
        })
    }

    return (
        <div className="container-fluid mb-2">
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Grilles malus sur la masse en ordre de marche (Malus Masse)</h2>
                    {fetching ?
                        <div className="d-flex flex-col align-items-center justify-content-center">
                            <i className="fa fa-spinner fa-spin fa-fw fa-2x"></i>
                        </div>
                        : error ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className="fa fa-warning"></i>
                                {error}
                            </div>
                            :
                            <div className="d-flex flex-column align-items-start" style={{ gap: "1em" }}>
                                <Link to="/malusMasse/create" className="btn btn-light">
                                    <i className="fa fa-plus"></i> Ajouter
                                </Link>
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                    <tr>
                                        <th>Libellé</th>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th className="text-center"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {grillesMalusMasse.map((grille) => {
                                        return (
                                            <tr key={grille.IDGrilleMalusMasse}>
                                                <td>{grille.Libelle}</td>
                                                <td>{moment(grille.DateDebut).format("DD/MM/YYYY")}</td>
                                                <td>{grille.DateFin ? moment(grille.DateFin).format("DD/MM/YYYY") : ""}</td>
                                                <td className="text-right">
                                                    <Link to={'/malusMasse/' + grille.IDGrilleMalusMasse} className="btn btn-primary btn-sm">
                                                        <i className="fa fa-pencil"></i>
                                                    </Link>
                                                    &nbsp;
                                                    <a onClick={() => deleteGrid(grille.IDGrilleMalusMasse)} className="btn btn-danger btn-sm text-white">
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}