import { MarqueWally } from "./MarqueWally"
import { OrigineWally } from "./OrigineWally"
import { Origine } from "../../../../../types/Origine"

export class OrigineMarqueWally
{
    IDOrigineAction!: number
    IdWallyMarque!: number
    IdWallyOrigine!: number
    marque?: MarqueWally
    origine?: OrigineWally
    origineESBO?: Origine
    isCurrentChange?: boolean
    IDOrigineActionModifying?: number | null

    constructor(data?: Partial<OrigineMarqueWally>, marques?: MarqueWally[], origines?: OrigineWally[], originesESBO?: Origine[]) {
        Object.assign(this, data)
        if (this.IdWallyMarque && marques) {
            this.marque = marques.find(m => m.IdWallyMarque === this.IdWallyMarque)
        }
        if (this.IdWallyOrigine && origines) {
            this.origine = origines.find(o => o.IdWallyOrigine === this.IdWallyOrigine)
        }
        if (this.IDOrigineAction && originesESBO) {
            this.origineESBO = originesESBO.find(oes => oes.IDOrigineAction === this.IDOrigineAction)
        }
        this.isCurrentChange = false
        this.IDOrigineActionModifying = this.IDOrigineAction
    }

    getPrimaryKey = () => {
        return this.IdWallyOrigine + "_" + this.IdWallyMarque
    }
}

