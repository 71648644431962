import React, { Component } from 'react'
import GroupeConfig from './GroupeConfig'
import SitesConfig from './SitesConfig'
import ListTemplateBDC from '../TemplatesBDC/ListTemplateBDC'

const lodash = require('lodash')

class Index extends Component
{

    constructor(props) {
        super(props)

        this.state = {
            sites: [],
            templatesImpression: [],
            templatesImpressionOffreOptList: [],
            templatesImpressionBdcOptList: [],
            templatesImpressionBonEssaiOptList: [],
            configs: [],
            fetching: false,
            submitting: false,
            activeTab: "configurationGroupe", //"configurationGroupe", "configurationSites", "configurationTemplateBDC"
            groupeUpdated: false,
            sitesUpdated: false,
        }
    }

    componentDidMount() {
        const urlSearchString = window.location.search
        const params = new URLSearchParams(urlSearchString)
        if (params && params.get("activeTab")) {
            this.setState({
                activeTab: params.get("activeTab"),
            })
        }
        this.fetchData()
    }

    async fetchData() {
        this.setState({
            fetching: true,
        })

        await this.fetchTemplates()
        await this.fetchTemplatesConfigs()

        this.setState({
            fetching: false,
        })
    }

    async fetchTemplates() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/templatesImpression', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                },
            })
            var templatesImpression = (await response.json())

            templatesImpression = lodash.sortBy(templatesImpression, (t) => {
                return t.Description
            })
            const templatesImpressionOffreOptList = templatesImpression.filter(t => t.TypeTemplateImpression_ActifOffre).map(t => {
                return { value: t.IDTemplateImpression, label: t.Description }
            })
            const templatesImpressionBdcOptList = templatesImpression.filter(t => t.TypeTemplateImpression_ActifBdc).map(t => {
                return { value: t.IDTemplateImpression, label: t.Description }
            })
            const templatesImpressionBonEssaiOptList = templatesImpression.filter(t => t.TypeTemplateImpression_ActifBonEssai).map(t => {
                return { value: t.IDTemplateImpression, label: t.Description }
            })

            this.setState({
                templatesImpression,
                templatesImpressionOffreOptList,
                templatesImpressionBdcOptList,
                templatesImpressionBonEssaiOptList,
            })
        } catch (error) {
            console.log(error)
        }
    }

    async fetchTemplatesConfigs() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/templatesImpression/configurations', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                },
            })
            var configs = (await response.json())
            this.setState({
                configs: configs,
                groupeUpdated: false,
                sitesUpdated: false,
            })
        } catch (error) {
            console.log(error)
        }
    }

    setActiveTab(tab, event) {
        event.preventDefault()

        this.setState({
            activeTab: tab,
        })
    }

    async onSubmit() {
        try {
            this.setState({
                submitting: true,
            })

            if (this.state.sitesUpdated) {
                const response = await fetch(localStorage.backoffice_api + '/api/templatesImpression/configurations/sites', {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        authorization: localStorage.JWT,
                    },
                    body: JSON.stringify(this.state.configs.filter(c => c.IDSite !== null)),
                })

                if (!response.ok) {
                    throw Error(await response.json())
                }
            }

            if (this.state.groupeUpdated) {
                const response = await fetch(localStorage.backoffice_api + '/api/templatesImpression/configurations/groupe', {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        authorization: localStorage.JWT,
                    },
                    body: JSON.stringify(this.state.configs.filter(c => c.IDSite === null)),
                })

                if (!response.ok) {
                    throw Error(await response.json())
                }
            }

            this.fetchTemplatesConfigs()
        } catch (error) {
            console.log(error)
            alert(error)
        } finally {
            this.setState({
                submitting: false,
            })
        }
    }

    onChangeSelectFor(IDSite, categorie, items) {
        var configs = this.state.configs
        configs = lodash.filter(configs, c => c.Categorie !== categorie || c.IDSite !== IDSite)

        for (var i in items) {
            configs.push({
                "IDTemplateImpression": items[i].value,
                "Categorie": categorie,
                "IDSite": IDSite,
            })
        }

        this.setState({
            configs: configs,
            groupeUpdated: IDSite === null ? true : this.state.groupeUpdated,
            sitesUpdated: IDSite !== null ? true : this.state.sitesUpdated,
        })
    }

    formComponents = {
        ["configurationGroupe"]: GroupeConfig,
        ["configurationSites"]: SitesConfig,
        ["configurationTemplateBDC"]: ListTemplateBDC,
    }

    showValidButton(){
        return this.state.activeTab !== "configurationTemplateBDC"
    }

    render() {
        const FormComponent = this.formComponents[this.state.activeTab]

        return (
            <div className="container-fluid mb-2">
                <div className="row">
                    <div className="col">

                        <h2 className="mb-4">Templates d'impression</h2>

                        {this.state.fetching ?
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <i className="fa fa-spinner fa-spin fa-fw"></i>
                            </div>
                            :
                            <>
                                <div className="d-flex flex-row justify-content-between">
                                    <ul className="nav nav-tabs d-flex flex-grow-1" id="myTab">
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.activeTab === "configurationGroupe" ? "active" : ""}`}
                                               href="#" id="groupe-tab" role="tab"
                                               onClick={this.setActiveTab.bind(this, 'configurationGroupe')}>Configuration Groupe</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.activeTab === "configurationSites" ? "active" : ""}`}
                                               href="#" id="sites-tab" role="tab"
                                               onClick={this.setActiveTab.bind(this, 'configurationSites')}>Configuration par
                                                Site</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.activeTab === "configurationTemplateBDC" ? "active" : ""}`}
                                               href="#" id="sites-tab" role="tab"
                                               onClick={this.setActiveTab.bind(this, 'configurationTemplateBDC')}>Configuration
                                                des BDC par marque</a>
                                        </li>
                                    </ul>
                                    <div style={{ borderBottom: "1px solid #dee2e6" }}>
                                        {this.state.groupeUpdated || this.state.sitesUpdated ?
                                            <div className="d-flex flex-row">
                                                <div className="alert alert-warning m-0 p-1 mr-1">
                                                    <i className="fa fa-warning mr-1"></i>
                                                    Modifications non sauvegardées
                                                </div>
                                                <button type="submit" className="btn btn-primary m-0 p-1"
                                                        onClick={this.onSubmit.bind(this)}>
                                                    {this.state.submitting &&
                                                        <i className="fa fa-spinner fa-spin fa-fw mr-1"></i>}
                                                    Valider
                                                </button>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div className={`tab-pane fade pt-4 show active`} role="tabpanel">
                                        <FormComponent
                                            templatesImpressionOffreOptList={this.state.templatesImpressionOffreOptList}
                                            templatesImpressionBdcOptList={this.state.templatesImpressionBdcOptList}
                                            templatesImpressionBonEssaiOptList={this.state.templatesImpressionBonEssaiOptList}
                                            configs={this.state.configs}
                                            onChange={this.onChangeSelectFor.bind(this)}
                                            submitting={this.state.submitting}
                                        />
                                    </div>

                                    {this.showValidButton() && <div className="row mt-4">
                                        <div className="col text-center">
                                            <button disabled={!this.state.groupeUpdated && !this.state.sitesUpdated}
                                                    type="submit" className="btn btn-primary"
                                                    onClick={this.onSubmit.bind(this)}>
                                                {this.state.submitting &&
                                                    <i className="fa fa-spinner fa-spin fa-fw mr-1"></i>}
                                                Valider
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Index
