import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class ImportUtilisateurs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      importInProgress: false,
      importCompleted: false,
      importResult: null,
      file: null,
    }

  }
  
  canSubmit(){
    return !this.state.importInProgress && this.state.file;
  }

  onSubmit(event) {
    event.preventDefault()

    this.setState({
      importInProgress: true,
    })

    const formData = new FormData()
    formData.append("file", this.state.file)
    
    fetch(localStorage.backoffice_api + '/api/utilisateurs/importUtilisateurs', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT
      },
      body: formData
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          importInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        importInProgress: false,
        importCompleted: true,
        importResult: json
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  selectFile(event){
    this.setState({
      file: event.target.files[0]
    })
  }

  reset(){
    this.setState({
      file:null,
      importInProgress: false,
      importCompleted: false,
      importResult: null
    })
  }

  downloadExampleFile(){
    window.location.href = '/files/import-utilisateurs.xlsx';
    return false;
  }

  
  render() {

    return (
      <div className='container-fluid'>

          <h2>Import Utilisateurs</h2>
          <form onSubmit={(e) => this.onSubmit(e)}>
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="card">
                  <div className="card-header">
                    Importer fichiers des nouveaux utilisateurs
                  </div>
                  <div className="card-body pb-3">
                    <div className="form-group row">
                      <div className="col">
                        {this.state.importInProgress ? 
                          <div>
                            <i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;Importation en cours
                          </div>
                        :this.state.importCompleted ? 
                          <div>
                            {this.state.importResult.nbUsersAddedOK > 0 || this.state.importResult.nbUsersUpdatedOK > 0 ?
                              <div className="alert alert-success">
                                {this.state.importResult.nbUsersAddedOK} utilisateur(s) ajouté(s)<br/>
                                {this.state.importResult.nbUsersUpdatedOK} utilisateur(s) modifié(s)
                              </div>
                            :
                            null}
                            {this.state.importResult.errors.length > 0 ?
                              <div className="alert alert-danger">
                                {this.state.importResult.errors.join("<br/>")}
                              </div>
                            :
                            null}
                            {this.state.importResult.errorsSites.length > 0 ?
                              <div className="alert alert-danger">
                                {this.state.importResult.errorsSites.join("<br/>")}
                              </div>
                            :
                            null}
                          </div>
                        :
                          <div>
                            <div className="row">
                              <div className="col">
                                <div className="alert alert-info">
                                  La première ligne doit être une ligne d'entête (elle sera ignorée).<br/>
                                  Les utilisateurs dont le login existe déjà seront modifiés, les autres seront créés. Aucun utilisateur ne sera supprimé.<br/>
                                  Dans la colonne "Sites", il est possible de renseigner plusieurs IDSite en les séparant par le caractère "|".<br/>
                                  Vous pouvez télécharger un gabarit du fichier <a href="/files/import-utilisateurs.xlsx" target="_blank">ICI</a>.
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <label htmlFor="Nom" className="col-sm-3 col-form-label">Fichier (format .xlsx)</label>
                                <input type='file' name='file' accept='.xlsx' required onChange={(e) => {this.selectFile(e)}}/>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col text-center">
                        {this.state.importCompleted ?
                          <div>
                            <Link to='/utilisateurs' className='btn btn-primary'>
                              Retour
                            </Link>
                            &nbsp;
                            <button className='btn btn-primary' onClick={() => this.reset()}>
                              Importer un nouveau fichier
                            </button>
                          </div>
                        :
                          <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                            {this.state.importInProgress ?
                              <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                            : null}
                            Importer
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </form>

      </div>
    );
  }
}

export default ImportUtilisateurs;
