import React, { Component } from 'react';

var lodash = require('lodash')

class ModalAddOrigine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            IDFamille:0,
            IDSousFamille:0,
            IDType:0,
            newFamilleLibelle:"",
            newSousFamilleLibelle:"",
            newOrigineLibelle:"",
            EstLead:0,
            EstInactif:0,
            EstAppelSortant:0,
            EstAppelEntrant:0,
            CreationLead:1,

            origineTypes:[],
            familles:[],
            allSousFamilles:[],
            sousFamilles:[],

            saving:false,
        }
    }

    componentWillMount() {
        this.fetchFamilles();
        this.fetchSousFamilles();
        this.fetchOrigineTypes();
    }

    fetchFamilles(){
        fetch(localStorage.backoffice_api + '/api/familles', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
        })
        .then((response) => {

            if (response.ok) {
                response.json().then((value) => {
                    this.setState({
                        familles: lodash.orderBy(value, 'Libelle')
                    });
                });
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchSousFamilles(){
        fetch(localStorage.backoffice_api + '/api/sousFamilles', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
        })
        .then((response) => {

            if (response.ok) {
                response.json().then((value) => {
                    this.setState({
                        allSousFamilles: lodash.orderBy(value, 'Libelle')
                    });
                });
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchOrigineTypes(){
        fetch(localStorage.backoffice_api + '/api/origines/types', {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
        })
        .then((response) => {

            if (response.ok) {
                response.json().then((value) => {
                    this.setState({
                        origineTypes: value,
                    });
                });
            }
            else 
                throw Error(response);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    submitOrigine(){
        if(this.state.saving)return false;

        this.setState({
            saving:true
        });
        fetch(localStorage.backoffice_api + '/api/origines', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                Libelle: this.state.newOrigineLibelle,
                IDFamille: this.state.IDFamille,
                IDSousFamille: this.state.IDSousFamille,
                newFamilleLibelle: this.state.newFamilleLibelle,
                newSousFamilleLibelle: this.state.newSousFamilleLibelle,
                IDType: this.state.IDType,
                EstLead: this.state.EstLead,
                EstInactif: this.state.EstInactif,
                EstAppelSortant: this.state.EstAppelSortant,
                EstAppelEntrant: this.state.EstAppelEntrant,
                CreationLead: this.state.CreationLead,
            })
        })
        .then((response) => {

            if (!response.ok) {
              response.json().then((value) => {
                alert(value.message)
              });
      
              this.setState({
                saving: false,
              })
      
              throw Error(response);
            }

            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
    }

    canSubmit(){
        return (
            (this.state.IDFamille > 0 || (this.state.IDFamille === -1 && this.state.newFamilleLibelle.trim() !== ""))
            && (this.state.IDSousFamille > 0 || (this.state.IDSousFamille === -1 && this.state.newSousFamilleLibelle.trim() !== ""))
            && this.state.newOrigineLibelle.trim() !== ""
            && this.state.IDType !== 0
            && !this.state.saving);
    }

    changeValue(name, value){
        this.setState({
            [name]:value
        });
    }

    preFill(origine){
        var sousFamilles = lodash.filter(this.state.allSousFamilles, function(sf){
            return sf.IDFamilleOrigineContact === origine.IDFamilleOrigineContact;
        });
        this.setState({
            IDFamille:origine.IDFamilleOrigineContact,
            IDSousFamille:origine.IDSousFamilleOrigineAction,
            IDType:0,
            newFamilleLibelle:"",
            newSousFamilleLibelle:"",
            newOrigineLibelle:origine.Libelle,
            EstLead:0,
            EstInactif:0,
            EstAppelSortant:0,
            EstAppelEntrant:0,
            sousFamilles:sousFamilles,
        });
    }
    
    reset(){
        this.setState({
            IDFamille:0,
            IDSousFamille:0,
            IDType:0,
            newFamilleLibelle:"",
            newSousFamilleLibelle:"",
            newOrigineLibelle:"",
            EstLead:0,
            EstInactif:0,
            EstAppelSortant:0,
            EstAppelEntrant:0,
        });
    }

    selectFamille(value){
        var sousFamilles = [];
        if(value > 0){
            sousFamilles = lodash.filter(this.state.allSousFamilles, function(sf){
                return sf.IDFamilleOrigineContact === value;
            });
        }

        this.setState({
            IDFamille: value,
            IDSousFamille: value === -1 ? -1 : 0,
            sousFamilles: sousFamilles
        }, () => {
            if(this.state.IDFamille === -1){
                this.refs.newFamille.focus();
            }
        });
    }

    selectSousFamille(value){
        this.setState({
            IDSousFamille: value,
        }, () => {
            if(this.state.IDSousFamille === -1){
                this.refs.newSousFamille.focus();
            }
        });
    }

    render() {

        return (
            <div className="modal" role="dialog" id="modalAddOrigine">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <div className="col modal-title h4 align-middle mt-1">Ajouter une origine</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.familles.length === 0 || this.state.allSousFamilles.length === 0 ?
                        <div className="modal-body pb-1">
                            <div className="row">
                                <div className="col text-center m-5">
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw fa-2x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="modal-body pb-1">
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Famille
                            </div>
                            <div className="col-8">
                            {this.state.IDFamille !== -1 ?
                                <select className="form-control" value={this.state.IDFamille} onChange={(e) => this.selectFamille(parseInt(e.target.value))}>
                                    <option value=''>Sélectionner la famille</option>
                                    {this.state.familles.map((famille) => {
                                        return (<option key={"famille"+famille.IDFamilleOrigineContact} value={famille.IDFamilleOrigineContact}>{famille.Libelle}</option>);
                                    })}
                                    <option value='-1'> + Ajouter une nouvelle famille</option>
                                </select>
                                :
                                <input type="text" name="newFamilleLibelle" className="form-control" ref="newFamille" value={this.state.newFamilleLibelle} onChange={(e) => this.changeValue(e.target.name, e.target.value)}/>
                            }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Sous-famille
                            </div>
                            <div className="col-8">
                            {this.state.IDFamille !== -1 && this.state.IDSousFamille !== -1 ?
                                <select className="form-control" value={this.state.IDSousFamille} onChange={(e) => this.selectSousFamille(parseInt(e.target.value))} disabled={this.state.IDFamille === 0}>
                                    <option value=''>Sélectionner la sous-famille</option>
                                    {this.state.sousFamilles.map((sousFamille) => {
                                        return (<option key={"sousfamille"+sousFamille.IDSousFamilleOrigineAction} value={sousFamille.IDSousFamilleOrigineAction}>{sousFamille.Libelle}</option>);
                                    })}
                                    <option value='-1'> + Ajouter une nouvelle sous-famille</option>
                                </select>
                                :
                                <input type="text" name="newSousFamilleLibelle" className="form-control" ref="newSousFamille" value={this.state.newSousFamilleLibelle} onChange={(e) => this.changeValue(e.target.name, e.target.value)}/>
                            }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Origine
                            </div>
                            <div className="col-8">
                                <input type="text" name="newOrigineLibelle" className="form-control" value={this.state.newOrigineLibelle} onChange={(e) => this.changeValue(e.target.name, e.target.value)}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 m-auto">
                                Type
                            </div>
                            <div className="col-8">
                                <select className="form-control" value={this.state.IDType} onChange={(e) => this.changeValue("IDType", parseInt(e.target.value))}>
                                    <option value='0'>Sélectionner le type</option>
                                    {this.state.origineTypes.map((t) => {
                                        return (<option key={"originetype"+t.IDOrigineActionType} value={t.IDOrigineActionType}>{t.Description}</option>);
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <table className="table text-center" style={{tableLayout: 'fixed'}}>
                                <tbody>
                                    <tr>
                                        <th>Lead</th>
                                        <th>Inactif</th>
                                        <th>Appel Sortant</th>
                                        <th>Appel Entrant</th>
                                        <th>Création Lead
                                            <i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
                                                        title="Si coché alors l'origine est disponible lors de la création d'un lead">
                                            </i>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="border-0 p-0"><input type="checkbox" checked={this.state.EstLead === 1} onChange={(e) => this.changeValue("EstLead", e.target.checked ? 1 : 0)}/></td>
                                        <td className="border-0 p-0"><input type="checkbox" checked={this.state.EstInactif === 1} onChange={(e) => this.changeValue("EstInactif", e.target.checked ? 1 : 0)}/></td>
                                        <td className="border-0 p-0"><input type="checkbox" checked={this.state.EstAppelSortant === 1} onChange={(e) => this.changeValue("EstAppelSortant", e.target.checked ? 1 : 0)}/></td>
                                        <td className="border-0 p-0"><input type="checkbox" checked={this.state.EstAppelEntrant === 1} onChange={(e) => this.changeValue("EstAppelEntrant", e.target.checked ? 1 : 0)}/></td>
                                        <td className="border-0 p-0"><input type="checkbox" checked={this.state.CreationLead === 1} onChange={(e) => this.changeValue("CreationLead", e.target.checked ? 1 : 0)}/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }
                    <div className="modal-footer p-3 bg-light rounded-bottom">
                        <div className="col m-auto text-right">
                            <button className="btn btn-primary" type='submit' disabled={!this.canSubmit()} onClick={() => this.submitOrigine()}>Valider</button>&nbsp;
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ModalAddOrigine;

