import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      lignes: [],
      filterDescription: '',

      societes: [],
      filterSociete: '',
    }
  }

  componentWillMount() {
    this.fetchLignes();
  }

  fetchLignes() {
    fetch(localStorage.backoffice_api + '/api/LignesFrequentes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var lignes = lodash.sortBy(json, (ligne) => {
        return ligne.Descr;
      })

      var societes = lodash.map(json, (ligne) => {
        return {'name': ligne.Plaque_Libelle}
      });
      societes = lodash.uniqBy(societes, (societe) => {
        return societe.name;
      });
      societes = lodash.sortBy(societes, (societe) => {
        return societe.name;
      });

      this.setState({
        lignes: lignes,
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteLigneFrequente(IDLignFreq) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cette ligne fréquente ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/LignesFrequentes/'+IDLignFreq, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      this.setState({
        lignes: [],
      }, () => {
        this.fetchLignes();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Lignes fréquentes</h2>

            <br />

            <div>
              <Link to='/lignesFrequentes/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>


              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <select value={this.state.filterSociete} onChange={(event) => this.setState({filterSociete: event.target.value})} className="form-control form-control-sm" placeholder='Filtre par société'>
                      <option key='0' value=''>Filtre par société</option>
                      {this.state.societes.map((societe) => {
                        if (!societe.name)
                          return null;
                        return <option key={societe.name} value={societe.name}>{societe.name}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterDescription} onChange={(event) => this.setState({filterDescription: event.target.value})} placeholder='Filtre par description' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Société</th>
                  <th>Type Ligne Fréquente</th>
                  <th>Prix Vente HT</th>
                  <th>Prix Vente TTC</th>
                  <th className='text-center'>Multiple CV</th>
                  <th className='text-center'>Inclus Matériel</th>
                  <th className='text-center'>Affiche Offre VN</th>
                  <th className='text-center'>Affiche Offre VO</th>
                  <th className='text-center'>Est carburant</th>
                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.lignes.map((ligne) => {
                  if (this.state.filterDescription) {
                    if (ligne.Descr.toLowerCase().search(this.state.filterDescription.toLowerCase()) === -1) {
                      return false;
                    }
                  }

                  if (this.state.filterSociete) {
                    if (this.state.filterSociete !== ligne.Plaque_Libelle) {
                      return null;
                    }
                  }

                  return (<tr key={ligne.IDLignFreq}>
                    <td>{ligne.IDLignFreq}</td>
                    <td>{ligne.Descr}</td>
                    <td>{ligne.Plaque_Libelle ? ligne.Plaque_Libelle : null}</td>
                    <td>{ligne.Type_Libelle ? ligne.Type_Libelle : null }</td>
                    <td>{ligne.PxVenteHT}</td>
                    <td>{ligne.PxVenteTTC}</td>
                    <td className='text-center'>{ligne.MultipleCV ? <i className="fa fa-check" aria-hidden="true"></i> : null}</td>
                    <td className='text-center'>{ligne.InclusMat ? <i className="fa fa-check" aria-hidden="true"></i> : null}</td>
                    <td className='text-center'>{ligne.AffOffre ? <i className="fa fa-check" aria-hidden="true"></i> : null}</td>
                    <td className='text-center'>{ligne.AffOffreVO ? <i className="fa fa-check" aria-hidden="true"></i> : null}</td>
                    <td className='text-center'>{ligne.EstCarburant ? <i className="fa fa-check" aria-hidden="true"></i> : null}</td>
                    <td>
                      <Link to={'/lignesFrequentes/'+ligne.IDLignFreq} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      &nbsp;
                      <a onClick={this.deleteLigneFrequente.bind(this, ligne.IDLignFreq)} className='btn btn-danger btn-sm text-white'>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
