import { OrigineMarqueWally } from "../Types/Class/OrigineMarqueWally"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import React, { MouseEventHandler, useRef } from "react"
import Select from "react-select"
import { Origine } from "../../../../types/Origine"

interface Props
{
    originesESBO: Origine[],
    originExt: OrigineMarqueWally,
    setOrigineMarqueExternal: (e: any) => void,
    handleChangeOrigin: (originExt: OrigineMarqueWally) => MouseEventHandler
    handleDeletingOrigin: (originExt: OrigineMarqueWally) => MouseEventHandler
    handleFetchSaveChangeOrigin: (originExt: OrigineMarqueWally) => MouseEventHandler
}
interface selectValue
{
    label: string,
    value: number,
}

export default function List(props: Props) {

    const handleOnChangeSetOrigineAction = (originSelected:selectValue) => {
        if(!originSelected){
            alert("Veuillez sélectionner une origine ou annulez la modification")
            return
        }
        const updatedOrigineMarque: OrigineMarqueWally = {
            ...props.originExt,
            IDOrigineActionModifying: originSelected.value
        }
        props.setOrigineMarqueExternal((prevOme: OrigineMarqueWally[]) =>
            prevOme.map(ome =>
                ome.getPrimaryKey() === props.originExt.getPrimaryKey() ? updatedOrigineMarque : ome,
            ),
        )
    }

    const handleCancelModification = () => {

        const updatedOrigineMarque: OrigineMarqueWally = {
            ...props.originExt,
            isCurrentChange: false,
            IDOrigineActionModifying: props.originExt.IDOrigineAction
        }
        props.setOrigineMarqueExternal((prevOme: OrigineMarqueWally[]) =>
            prevOme.map(ome =>
                ome.getPrimaryKey() === props.originExt.getPrimaryKey() ? updatedOrigineMarque : ome,
            ),
        )
    }

    return <tr>
        <td>
            {props.originExt.marque?.Nom}
        </td>
        <td>
            {props.originExt.origine?.Nom}
        </td>
        <td>
            {props.originExt.isCurrentChange ?
                <>
                    <Select name="idOrigineSeller"
                            value={props.originExt.IDOrigineActionModifying}
                            options={props.originesESBO.map((o) => {
                                return { value: o.IDOrigineAction, label: o.LibelleFamille + " - " + o.LibelleSousFamille + " - " + o.LibelleOrigine }
                            })}
                            isSearchable={true} required={false}
                            placeholder="Changez l'origine"
                            onChange={handleOnChangeSetOrigineAction}
                            isMulti={false} isClearable={false}
                    />
                </>
                :
                <>
                    {props.originExt.origineESBO?.LibelleFamille + " - " + props.originExt.origineESBO?.LibelleSousFamille + " - " + props.originExt.origineESBO?.LibelleOrigine}
                </>
            }
        </td>
        <td>
            <div className={"d-inline-flex"}>

                {props.originExt.isCurrentChange ?
                    <>
                        <ButtonAction2 onClick={props.handleFetchSaveChangeOrigin(props.originExt)}
                                       className="btn btn-success btn-sm mr-2">
                            <i className={"fa-solid fa-check fa-xs"}></i>
                        </ButtonAction2>
                        <ButtonAction2 onClick={handleCancelModification}
                                       className="btn btn-secondary btn-sm mr-2">
                            <i className={"fa-solid fa-ban fa-xs"}></i>
                        </ButtonAction2>
                    </>
                    :
                    <>
                        <ButtonAction2 onClick={props.handleChangeOrigin(props.originExt)} className="btn btn-primary btn-sm mr-2">
                            <i className={"fa-solid fa-pen fa-xs"}></i>
                        </ButtonAction2>
                        <ButtonAction2 onClick={props.handleDeletingOrigin(props.originExt)}
                                       className="btn btn-secondary btn-danger btn-sm">
                            <i className={"fa-solid fa-trash fa-xs"}></i>
                        </ButtonAction2>
                    </>
                }

            </div>
        </td>
    </tr>
}