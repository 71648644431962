import React, { Component } from 'react';
import ModalAddTypeMaterielAgri from './ModalAddTypeMaterielAgri';
import ModalCreateChamp from './ModalCreateChamp';
import { Link } from 'react-router-dom'
import Select from 'react-select';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      typesMaterielAgri : [],
      fetchingTypesMateriel: false,
      familles:[],
      fetchingFamilles: false,

      editingID: null,
      saving: false,
      savingFamilles: null,
      updated: false,
    }
  }

  componentDidMount() {
    this.fetchTypesMaterielAgri();
    this.fetchFamilles();
  }

  fetchTypesMaterielAgri() {
    fetch(localStorage.backoffice_api + '/api/typesMaterielAgri', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        typesMaterielAgri: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  } 

  fetchFamilles() {
    this.setState({
      fetchingFamilles: true
    })
    fetch(localStorage.backoffice_api + '/api/famillesMaterielAgri?with_type_materiel=1', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      lodash.each(json, f => {
        f.label = f.Descr;
        f.value = f.Descr;
      })
      this.setState({
        familles: json,
        fetchingFamilles: false
      });
    })
    .catch((error) => {
      console.log(error)
    });
  } 

  edit(ID, value){
    if(this.state.saving)return;
    this.setState({
      updated: false,
      editingID: ID
    })
  }

  onKeyDown(event){
    if (event.key === 'Enter') {
      this.saveEdit();
    }
  }

  onChangeValueEdit(newValue){
    if(this.state.saving)return;

    var typesMaterielAgri = lodash.forEach(this.state.typesMaterielAgri, (type) => {
      if(type.IDTypeMaterialAgri === this.state.editingID){
        type.Descr = newValue
        return false;
      }
    });
    this.setState({
      typesMaterielAgri:typesMaterielAgri,
      updated: true,
    })
  }

  onChangeFamille(IDTypeMaterialAgri, selected_familles){
    this.setState({
      savingFamilles: IDTypeMaterialAgri
    })

    var familles = this.state.familles;

    lodash.each(familles, f => {
      if(f.IDTypeMaterialAgri == IDTypeMaterialAgri)
        f.IDTypeMaterialAgri = null;
    })

    var FamillesDescr = [];

    lodash.each(selected_familles, f => {
      let idx = lodash.findIndex(familles, {Descr:f.Descr});
      familles[idx].IDTypeMaterialAgri = IDTypeMaterialAgri
      FamillesDescr.push(f.Descr)
    });

    this.setState({
      familles: familles
    })

    fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/'+IDTypeMaterialAgri+'/familles', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(FamillesDescr)
    })
    .then((response) => {
      this.setState({
        savingFamilles: null
      })
      if (!response.ok) {
        throw Error(response.statusText);
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  saveEdit(){
    if(this.state.updated)
    {
      this.setState({
        saving: true
      })

      var body = lodash.find(this.state.typesMaterielAgri, (type) => {
        return (type.IDTypeMaterialAgri === this.state.editingID);
      });

      fetch(localStorage.backoffice_api + '/api/typesMaterielAgri/'+this.state.editingID, {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(body)
      })
      .then((response) => {

          if (!response.ok) {
            response.json().then((value) => {
              alert(value.message)
            });

            this.setState({
              saving: false,
            })

            throw Error(response);
          }

          this.setState({
            saving: false,
            editingID: null,
          })
      })
      .catch((error) => {
          console.log(error)
      });
    }
    else {
      this.setState({
        saving: false,
        editingID: null,
      })
    }
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col mb-5">

            <h2>Types Materiels Agri</h2>

            <br/>
            
            {this.state.typesMaterielAgri.length ? 
              <span style={{fontSize: '0.9em'}} className='font-italic'>Double-clic sur un libellé pour le modifier</span>
            :null}
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col-5 d-flex align-items-center font-weight-bold pl-4">
                        Types matériels agri
                      </div>
                      <div className="col-7 text-right">
                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalAddTypeMaterielAgri" onClick={() => this.refs.modalAddTypeMaterielAgri.reset()}>
                          <i className='fa fa-plus'></i> Ajouter un type de matériel
                        </button>
                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalCreateChamp" onClick={() => this.refs.modalCreateChamp.reset()}>
                          <i className='fa fa-plus'></i> Créer un champ
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {this.state.typesMaterielAgri.length ? 
                      <table className="table table-striped table-sm table-hover mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Libellé</th>
                            <th>Familles</th>
                            <th className="text-right">Nombre de champs</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.typesMaterielAgri.map((typeMat) => {
                          return <tr key={typeMat.IDTypeMaterialAgri}><td>{typeMat.IDTypeMaterialAgri}</td><td onDoubleClick={() => this.edit(typeMat.IDTypeMaterialAgri, typeMat.Descr)}>
                            {this.state.editingID === typeMat.IDTypeMaterialAgri ? 
                              <span>
                                <input type='text' className='form-control w-75 d-inline' value={typeMat.Descr} onKeyDown={(event) => this.onKeyDown(event)} onChange={(e) => this.onChangeValueEdit(e.target.value)} onBlur={(e) => this.saveEdit(e.target.value)}/>
                                {this.state.saving ? 
                                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                                  :null
                                }
                              </span>
                            :
                              typeMat.Descr
                            }
                          </td>
                          <td style={{width:500, position:'relative'}}>
                            <Select multi={true} name="familles" value={lodash.filter(this.state.familles, f => {return f.IDTypeMaterialAgri == typeMat.IDTypeMaterialAgri})} options={this.state.familles} searchable={true} placeholder='Familles' onChange={this.onChangeFamille.bind(this, typeMat.IDTypeMaterialAgri)} />
                            {this.state.savingFamilles === typeMat.IDTypeMaterialAgri ? 
                              <i style={{position:'absolute', top:14, right:52}} className="fa fa-spinner fa-spin"></i>
                            :
                              null
                            }
                          </td>
                          <td className="text-right">
                            {typeMat.NbChamps}
                          </td>
                          <td className="text-right">
                            <Link to={'/typesMaterielAgri/'+typeMat.IDTypeMaterialAgri+'/champs'} className="btn btn-primary btn-sm">Gérer les champs</Link>
                          </td>
                          </tr>
                        })}
                        </tbody>
                      </table>
                      :
                      <div className="p-2">
                        Aucun type de matériel Agri n'existe actuellement
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalAddTypeMaterielAgri ref="modalAddTypeMaterielAgri" onSubmit={this.fetchTypesMaterielAgri.bind(this)}/>
        <ModalCreateChamp ref="modalCreateChamp" onSubmit={this.fetchTypesMaterielAgri.bind(this)} typesMaterielAgri={this.state.typesMaterielAgri}/>
      </div>
    )
  }
}

export default Index;
