import React, { Component } from 'react';

import 'moment/locale/fr';
import moment from 'moment'
require('moment-precise-range-plugin')

var lodash = require('lodash')

class Columns extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      passerelle: null,
      columns: [],
      filterName: '',
      updated: false,
      fetchInProgress: false,
    }
  }

  componentWillMount() {
    this.fetchPasserelle();
    this.fetchColumns();
  }

  fetchPasserelle() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        passerelle: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchColumns() {
    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id+'/ColumnDMSMaterial', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        columns: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeChecked(event) {
    var name = event.target.name;
    var checked = event.target.checked;

    var columns = this.state.columns;
    var index = lodash.findIndex(columns, (column) => {
      return column.NameColumn === name;
    });

    columns[index].checked = checked;

    this.setState({
      columns: columns,
      updated: true,
    })
  }

  submit() {
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/passerelles/'+this.props.match.params.id+'/ColumnDMSMaterial', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.columns)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        columns: [],
        passerelle: null,
        fetchInProgress: false,
      }, () => {
        this.fetchPasserelle();
        this.fetchColumns();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.passerelle) {
      return null;
    }

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Passerelles</h2>

            <br />

            <div className="row">
              <div className='col'>
                <b>IDPasserelleDMS</b> : {this.state.passerelle.IDPasserelleDMS}
              </div>
              <div className='col'>
                <b>Libelle</b> : {this.state.passerelle.Libelle}
              </div>
              <div className='col'>
                <b>DateHRefProchainTransfert</b> : {this.state.passerelle.DateHRefProchainTransfert ? moment(this.state.passerelle.DateHRefProchainTransfert).utc(0).format('L LT') : null}
              </div>
            </div>

            <br />

            <div>
              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par nom' />
                  </div>
                </div>
              </div>
              <div className='clearfix'></div>
            </div>
            
            <br/>

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>Nom de la colonne</th>
                  <th className='text-center'>Mise à jour ?</th>
                </tr>
              </thead>
              <tbody>
              {this.state.columns.map((c) => {

                if (this.state.filterName) {
                  if (c.NameColumn.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                    return null;
                  }
                }

                return (<tr key={c.NameColumn}>
                  <td>{c.NameColumn}</td>
                  <td className='text-center'>
                    <input type='checkbox' checked={c.checked} name={c.NameColumn} onChange={this.onChangeChecked.bind(this)} />
                  </td>
                </tr>)
              })}
              </tbody>
            </table>

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" onClick={this.submit.bind(this)}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
                <br /><br /><br /><br />
              </div>
            : null}

          </div>
        </div>
      </div>
    );
  }
}

export default Columns;
