import styled from "styled-components"

export const ButtonAction = styled.a`
    transition:0.3s;
    opacity: 1;
    &:hover{ opacity: .7; }
`


export const ButtonAction2 = styled.button`
    transition:0.3s;
    opacity: 1;
    &:hover{ opacity: .7; }
`