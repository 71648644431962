import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      relances: [],
      filterLibelle: '',
    }
  }

  componentWillMount() {
    this.fetchRelances();
  }

  fetchRelances() {
    fetch(localStorage.backoffice_api + '/api/relances', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var relances = lodash.sortBy(json, (relance) => {
        return relance.Libelle;
      })

      this.setState({
        relances: relances,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteRelance(IDRelance) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cette relance ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/relances/'+IDRelance, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      this.setState({
        relances: [],
      }, () => {
        this.fetchRelances();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Relances</h2>

            <br />

            <div>
              <Link to='/relances/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>


              <div className='float-right'>

                <div className="form-inline float-left" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterLibelle} onChange={(event) => this.setState({filterLibelle: event.target.value})} placeholder='Filtre par libellé' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Libellé</th>
                  <th>Groupe / Plaques / Sites</th>
                  <th>Nombre de jours</th>
                  <th>Règle</th>
                  <th>Événement</th>
                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.relances.map((relance) => {
                  if (this.state.filterLibelle) {
                    if (relance.Libelle.toLowerCase().search(this.state.filterLibelle.toLowerCase()) === -1) {
                      return false;
                    }
                  }

                  return (<tr key={relance.IDRelanceAuto}>
                    <td>{relance.IDRelanceAuto}</td>
                    <td>{relance.Libelle}</td>
                    <td>
                      {relance.Groupe ?
                        <span>Groupe</span>
                      : null}

                      {relance.Plaques && relance.Plaques.length ?
                        <span>Sociétés :
                          <ul>
                            {relance.Plaques.map((p) => {
                              return <li key={p.IDPlaque}>{p.Libelle}</li>;
                            })}
                          </ul>
                        </span>
                      : null}

                      {relance.Sites && relance.Sites.length ?
                        <span>Sites :
                          <ul>
                            {relance.Sites.map((s) => {
                              return <li key={s.IDSite}>{s.Descr}</li>;
                            })}
                          </ul>
                        </span>
                      : null}
                    </td>
                    <td>{relance.NbJours}</td>
                    <td>{parseInt(relance.NbJours, 10) <= 0 ? 'Avant' : 'Après'}</td>
                    <td>{relance.RelanceAutoEvenement_Libelle}</td>
                    <td className="text-right">
                      <Link to={'/relances/'+relance.IDRelanceAuto} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      &nbsp;
                      {!Utils.isAccesClient() ?
                        <a onClick={this.deleteRelance.bind(this, relance.IDRelanceAuto)} className='btn btn-danger btn-sm text-white'>
                          <i className="fa fa-trash"></i>
                        </a>
                      :null}
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
