import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import moment from 'moment'
import { GrilleMalusEcologique } from './Models/GrilleMalusEcologique'

export default function ListGrilleMalusEcologique() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [fetching, setFetching] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [grillesMalusEcologique, setGrillesMalusEcologique] = useState<GrilleMalusEcologique[]>([])

    useEffect(() => {
        fetchGrillesMalusEcologique()
    }, [])

    const fetchGrillesMalusEcologique = async () => {
        setFetching(true)
        try {
            setGrillesMalusEcologique((await backofficeApiClient.get<GrilleMalusEcologique[]>("/malusEcologique")))
        }
        catch (error: any) {
            console.log(error)
            setError(error.message)
        }
        finally {
            setFetching(false)
        }
    }

    const deleteGrid = async (IDGrilleMalusEcologique: number) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cette grille de malus écologique ?'))
            return;

        backofficeApiClient.delete(`/malusEcologique/${IDGrilleMalusEcologique}`, null).then(() => {
            fetchGrillesMalusEcologique()
        })
    }

    return (
        <div className='container-fluid mb-2'>
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Grilles malus écologique</h2>
                    {fetching ?
                        <div className="d-flex flex-col align-items-center justify-content-center">
                            <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                        </div>
                        : error ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className='fa fa-warning'></i>
                                {error}
                            </div>
                            :
                            <div className="d-flex flex-column align-items-start" style={{gap: "1em"}}>
                                <Link to='/malusEcologique/create' className='btn btn-light'>
                                    <i className="fa fa-plus"></i> Ajouter
                                </Link>
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>Libellé</th>
                                            <th>Date de début</th>
                                            <th>Date de fin</th>
                                            <th>Commentaire</th>
                                            <th className='text-center'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grillesMalusEcologique.map((grille) => {

                                            return (
                                                <tr key={grille.IDGrilleMalusEcologique}>
                                                    <td>{grille.Libelle}</td>
                                                    <td>{moment(grille.DateDebut).format("DD/MM/YYYY")}</td>
                                                    <td>{grille.DateFin ? moment(grille.DateFin).format("DD/MM/YYYY") : ""}</td>
                                                    <td className="col-sm-3">{!grille.Completed ? <i className="fa fa-exclamation-triangle" aria-hidden="true"> Des malus ne sont pas renseignés ou sont à 0</i> : null} </td>
                                                    <td className='text-right'>
                                                        <Link to={'/malusEcologique/' + grille.IDGrilleMalusEcologique} className='btn btn-primary btn-sm'>
                                                            <i className="fa fa-pencil"></i>
                                                        </Link>
                                                        &nbsp;
                                                        <a onClick={() => deleteGrid(grille.IDGrilleMalusEcologique)} className='btn btn-danger btn-sm text-white'>
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}