import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class BackOffice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      profilsUtilisateurs: [],
      profils:[],

      filterName: '',
      filterProfil: '',
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs();
    this.fetchProfils();
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs/backoffice', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var utilisateurs = lodash.sortBy(json, (utilisateur) => {
        return utilisateur.Name;
      })

      var profils = lodash.map(json, (utilisateur) => {
        return {id: utilisateur.drprofile_id, name: utilisateur.Profil}
      });

      profils = lodash.uniqBy(profils, 'id');
      profils = lodash.sortBy(profils, 'name');

      this.setState({
        utilisateurs: utilisateurs,
        profilsUtilisateurs: profils,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchProfils(){
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/backoffice', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        profils: lodash.sortBy(json, (profil) => {
          return profil.DESCR;
        })
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Utilisateurs ayant accès au BackOffice</h2>

            <br />

            <div>
              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par nom' />
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <select value={this.state.filterProfil} onChange={(event) => this.setState({filterProfil: event.target.value})} className="form-control form-control-sm" placeholder='Filtre par profil'>
                      <option key='0' value=''>Filtre par profil</option>
                      {this.state.profilsUtilisateurs.map((profil) => {
                        if (!profil.id)
                          return null;
                        return <option key={profil.id} value={profil.id}>{profil.name}</option>
                      })}
                    </select>
                  </div>
                </div>

              </div>
            </div>

            <br/><br/>

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Téléphone</th>
                  <th>Email</th>
                  <th>Profil</th>
                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.utilisateurs.map((utilisateur) => {

                  if (this.state.filterName) {
                    if (utilisateur.Name.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                      return null;
                    }
                  }

                  if (this.state.filterProfil) {
                    if (parseInt(this.state.filterProfil, 10) !== parseInt(utilisateur.drprofile_id, 10)) {
                      return null;
                    }
                  }

                  return (<tr key={utilisateur.IDPeople}>
                    <td>{utilisateur.IDPeople}</td>
                    <td>{utilisateur.Name}</td>
                    <td>{utilisateur.PhoneNumber}</td>
                    <td>{utilisateur.EMail}</td>
                    <td>{utilisateur.Profil}</td>
                    <td className='text-right'>
                      <Link to={'/utilisateurs/'+utilisateur.IDPeople} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

            <br/>
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h5>Les profils suivants donnent accès au BackOffice</h5>
            <table className="table table-striped table-hover table-sm">
              <tbody>
              {this.state.profils.map((profil) => {
                return <tr key={profil.DRPROFILE_ID}><td>{profil.DESCR}</td></tr>
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default BackOffice;
