import React, { Component } from 'react';

var lodash = require('lodash')

class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ligne: null,
            updated: false,
            fetchInProgress: false,
            societes: [],
            fetchLigne: false,
            tauxTVA: 20,
            typeLignesFrequentes: []
        }
    }

    componentWillMount() {

        if (this.props.match.params.id) {
            this.fetchLigne()
        }
        else {
            this.setState({
                ligne: {
                    "Descr": '',
                    "MultipleCV": false,
                    "InclusMat": false,
                    "AffOffre": false,
                    "AffOffreVO": false,
                    "PxVenteHT": '',
                    "PxVenteTTC": '',
                    "IDActivite": 1,
                    "EstCarburant": 0,
                    "IDTypeLigneFrequente": 3
                }
            })
        }

        this.fetchSocietes();
        this.fetchTypeLignesFrequentes()
    }

    componentDidUpdate() {
        if (this.props.match.params.id && !this.state.fetchLigne) {
            this.fetchLigne()
        }
    }

    fetchLigne() {

        this.setState({
            fetchLigne: true,
        })

        fetch(localStorage.backoffice_api + '/api/lignesFrequentes/' + this.props.match.params.id, {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                var tauxTVA = 0;
                if (json.PxVenteHT) {
                    tauxTVA = (((json.PxVenteTTC / json.PxVenteHT) - 1) * 100).toFixed(1);
                }
                
                if(json.IDTypeLigneFrequente === null){
                    json.IDTypeLigneFrequente = 3
                }

                this.setState({
                    ligne: json,
                    tauxTVA: tauxTVA
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchSocietes() {
        fetch(localStorage.backoffice_api + '/api/societes', {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                var societes = lodash.sortBy(json, (societe) => {
                    return societe.Libelle;
                })

                let ligne = this.state.ligne
                if (societes.length > 0) {
                    ligne.IDActivite = societes[0].IDActivite
                }

                this.setState({
                    societes: societes,
                    ligne: ligne
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    fetchTypeLignesFrequentes() {
        fetch(localStorage.backoffice_api + '/api/TypesLignesFrequentes', {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                this.setState({
                    typeLignesFrequentes: json,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange(event) {
        var type = event.target.type
        var name = event.target.name
        var value = event.target.value

        if (name == 'PxVenteHT' || name == 'PxVenteTTC') {
            value = value.replace(',', '.');
        }

        var ligneFrequente = this.state.ligne

        if (type === 'checkbox') {
            ligneFrequente[name] = event.target.checked
        }
        else {
            ligneFrequente[name] = value
        }

        if (name === 'IDPlaque') {
            var societeIndex = lodash.findIndex(this.state.societes, (societe) => {
                return parseInt(societe.IDPlaque, 10) === parseInt(value, 10);
            })
            if (societeIndex !== -1) {
                var societe = this.state.societes[societeIndex];
                ligneFrequente.IDActivite = societe.IDActivite
            } else {
                ligneFrequente.IDActivite = this.state.societes[0].IDActivite
            }
        }

        if (name == 'PxVenteHT') {
            ligneFrequente.PxVenteTTC = ligneFrequente.PxVenteHT * (1 + this.state.tauxTVA / 100);
            ligneFrequente.PxVenteTTC = ligneFrequente.PxVenteTTC.toFixed(2);
        }
        else if (name == 'PxVenteTTC') {
            ligneFrequente.PxVenteHT = (ligneFrequente.PxVenteTTC / (1 + this.state.tauxTVA / 100)).toFixed(2);
        }

        this.setState({
            updated: true,
            ligne: ligneFrequente,
        })
    }

    onChangeTauxTVA(event) {
        var taux = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '');
        this.setState({
            tauxTVA: taux
        }, () => {
            var ligneFrequente = this.state.ligne;
            if (ligneFrequente.PxVenteHT) {
                ligneFrequente.PxVenteTTC = (ligneFrequente.PxVenteHT * (1 + this.state.tauxTVA / 100)).toFixed(2);
            }
            this.setState({
                ligne: ligneFrequente,
                updated: true
            })
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        this.setState({
            fetchInProgress: true,
        })

        if (!this.props.match.params.id) {
            this.createLigneFrequente();
        }
        else {
            this.editLigneFrequente();
        }
    }

    createLigneFrequente() {
        fetch(localStorage.backoffice_api + '/api/lignesFrequentes', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(this.state.ligne)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        alert(value.message)
                    });
                    this.setState({
                        fetchInProgress: false,
                    })
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                this.setState({
                    utilisateur: null,
                    updated: false,
                    fetchInProgress: false,
                }, () => {
                    this.props.history.push('/lignesFrequentes/' + json.IDLignFreq)
                })

            })
            .catch((error) => {
                console.log(error)
            });
    }

    editLigneFrequente() {
        fetch(localStorage.backoffice_api + '/api/lignesFrequentes/' + this.props.match.params.id, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(this.state.ligne)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        alert(value.message)
                    });
                    this.setState({
                        fetchInProgress: false,
                    })
                    throw Error(response);
                }

                this.setState({
                    updated: false,
                    ligne: null,
                    fetchInProgress: false,
                }, () => {
                    this.fetchLigne()
                })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {
        console.log(this.state.ligne)
        if (!this.state.ligne) {
            return null;
        }

        return (
            <div className='container-fluid'>

                <h2>Lignes fréquentes</h2>
                <br />

                <form onSubmit={this.handleSubmit.bind(this)}>

                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="IDPlaque" className="col-sm-3 col-form-label">Société</label>
                                <div className="col-sm-6">
                                    <select value={this.state.ligne.IDPlaque ? this.state.ligne.IDPlaque : ''} onChange={this.onChange.bind(this)} id='IDPlaque' name="IDPlaque" className="form-control form-control-sm">
                                        <option value=''></option>
                                        {this.state.societes.map((societe, i) => {
                                            return <option key={societe.IDPlaque} value={societe.IDPlaque}>{societe.Libelle}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="Descr" className="col-sm-3 col-form-label">Description</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.ligne.Descr} onChange={this.onChange.bind(this)} maxLength="255" required='required' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="PxVenteHT" className="col-sm-3 col-form-label">PxVenteHT</label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control form-control-sm" id="PxVenteHT" name="PxVenteHT" value={this.state.ligne.PxVenteHT} onChange={this.onChange.bind(this)} required='required' />
                                </div>
                                <label htmlFor="PxVenteHT" className="col-sm-2 col-form-label">Taux TVA</label>
                                <div className="col-sm-2">
                                    <input type="text" className="form-control form-control-sm" value={this.state.tauxTVA} onChange={this.onChangeTauxTVA.bind(this)} required='required' />
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group row">
                                <label htmlFor="PxVenteTTC" className="col-sm-3 col-form-label">PxVenteTTC</label>
                                <div className="col-sm-6">
                                    <input type="text" className="form-control form-control-sm" id="PxVenteTTC" name="PxVenteTTC" value={this.state.ligne.PxVenteTTC} onChange={this.onChange.bind(this)} required='required' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group row">
                                <label htmlFor="IDTypeLigneFrequente" className="col-sm-3 col-form-label">Type ligne fréquente</label>
                                <div className="col-sm-6">
                                    <select value={this.state.ligne.IDTypeLigneFrequente ? this.state.ligne.IDTypeLigneFrequente : ''} onChange={this.onChange.bind(this)} id='IDTypeLigneFrequente' name="IDTypeLigneFrequente" className="form-control form-control-sm">
                                        {this.state.typeLignesFrequentes.map((typeLigneFrequente, i) => {
                                            return <option key={typeLigneFrequente.IDTypeLigneFrequente} value={typeLigneFrequente.IDTypeLigneFrequente}>{typeLigneFrequente.Description}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="MultipleCV" checked={this.state.ligne.MultipleCV} onChange={this.onChange.bind(this)} />
                                            &nbsp; MultipleCV
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="InclusMat" checked={this.state.ligne.InclusMat} onChange={this.onChange.bind(this)} />
                                            &nbsp; InclusMat
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="AffOffre" checked={this.state.ligne.AffOffre} onChange={this.onChange.bind(this)} />
                                            &nbsp; AffOffre
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="AffOffreVO" checked={this.state.ligne.AffOffreVO} onChange={this.onChange.bind(this)} />
                                            &nbsp; AffOffreVO
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" name="EstCarburant" checked={this.state.ligne.EstCarburant} onChange={this.onChange.bind(this)} />
                                            &nbsp; Est carburant
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col">
                                    <div className="form-check">
                                        <label className="form-check-label">

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {this.state.updated ?
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col text-center">
                                <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                                    {this.state.fetchInProgress ?
                                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                                        : null}
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                        : null}
                </form>
            </div>
        );
    }
}

export default Form;
