import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class UtilisateurConnect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateur: null,
      configEsellerLead: null,
    }
  }

  componentWillMount() {
    this.fetchUtilisateur();
    this.fetchConfigEsellerLead();
  }

  fetchConfigEsellerLead() {
    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        configEsellerLead: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchUtilisateur() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateur: json,
      });

      fetch(localStorage.backoffice_api + '/api/bdref3/urls', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          urlTeam: this.getLinkEsellerTeam(json),
          urlLead: this.getLinkEsellerLead(json),
          urlReporting: this.getLinkEsellerReporting(json),
          urlEDataVoice: this.getLinkEDataVoice(json)
        }, () => {
          if (this.props.match.params.product === 'team')
            this.refs.formEsellerTeam.submit()
          else if (this.props.match.params.product === 'lead') {
            this.refs.formEsellerLead.submit()
          }else if (this.props.match.params.product === 'reporting') {
            this.refs.formEsellerReporting.submit()
          }else if (this.props.match.params.product === "eDataVoice"){
            this.refs.formEDataVoice.submit()
          }
        })
      })
      .catch((error) => {
        console.log(error)
      });

    })
    .catch((error) => {
      console.log(error)
    });
  }

  getLinkEsellerReporting(json) {
    var urlReporting = '';
    json.map((l) => {
      if (l.URL.toLowerCase().search('reporting.selectup.com') !== -1) {
        urlReporting = l.URL;
      }
      return l;
    })

    return urlReporting;
  }

  getLinkEsellerTeam(json) {
    var urlTeam = '';
    json.map((l) => {
      if (l.URL.toLowerCase().search('e-seller.selectup.com') !== -1) {
        urlTeam = l.URL;
      }
      else if (l.URL.toLowerCase().search('smit.selectup.com') !== -1) {
        urlTeam = l.URL;
      }
      return l;
    })

    return urlTeam;
  }

  getLinkEsellerLead(json) {
    var indexLinks = lodash.findIndex(json, (l) => {
      return l.URL.toLowerCase().search('e-seller.selectup.com') !== -1 || l.URL.toLowerCase().search('smit.selectup.com') !== -1;
    })

    if (indexLinks < 0) {
      return;
    }

    var linkEsellerLead = '';
    if (Utils.fromJWT('serverName') === 'PROD-CRM-SQL1' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL2' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL3') {
      linkEsellerLead = 'https://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'lead.e-seller.selectup.com').replace('smit.selectup.com', 'lead.e-seller.selectup.com');
    }
    else {
      linkEsellerLead = 'https://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'dev.lead.e-seller.selectup.com').replace('smit.selectup.com', 'dev.lead.e-seller.selectup.com');
    }

    return linkEsellerLead;
  }

  getLinkEDataVoice(json){
    var urlEDataVoice = '';
    json.map((l) => {
      if (l.URL.toLowerCase().search('crm.edatavoice.com') !== -1) {
        urlEDataVoice = l.URL;
      }
      return l;
    })
    return urlEDataVoice;
  }

  render() {

    var content;

    if (this.state.utilisateur) {
      switch (this.props.match.params.product) {
        case "team":
          content = (<form method="POST" action={"https://"+this.state.urlTeam+"/validation.asp"} ref="formEsellerTeam">
            <input type='hidden' name='LoginWeb' value={this.state.utilisateur.LoginWEB} />
            <input type='hidden' name='Password' value={this.state.utilisateur.Password} />
          </form>)
          break;
          case "reporting":
            content = (<form method="POST" action={"http://"+this.state.urlReporting+"/Login.aspx"} ref="formEsellerReporting">
            <input type='hidden' name='FROM' value='CRM' />

            <input type='hidden' name='LoginWeb' value={this.state.utilisateur.LoginWEB} />
            <input type='hidden' name='Password' value={this.state.utilisateur.Password} />

            <input type='hidden' name='IDCompany' value='1' />
            <input type='hidden' name='IDPeople' value={this.state.utilisateur.IDPeople} />
            <input type='hidden' name='IDSite' value='0' />
            <input type='hidden' name='GOTO' value='' />
          </form>)
          break;
          case "lead":
            content = (<form method="GET" action={this.state.urlLead+"/login"} ref="formEsellerLead">
            <input type='hidden' name='idclient' value={this.state.configEsellerLead.IDClient} />
            <input type='hidden' name='autologin' value='true' />
            <input type='hidden' name='login' value={this.state.utilisateur.LoginWEB} />
            <input type='hidden' name='pass' value={this.state.utilisateur.Password} />
          </form>)
          break;
          case "eDataVoice":
            content = (<form method="GET" action={this.state.urlEDataVoice+"/auth/signin"} ref="formEDataVoice">
            <input type='hidden' name='autologin' value='true' />
            <input type='hidden' name='login' value={this.state.utilisateur.LoginWEB} />
            <input type='hidden' name='password' value={this.state.utilisateur.Password} />
          </form>)
          break;
      }
    }

    return (
      <div className='container' style={{ marginTop: '50px' }}>
        <div className='row justify-content-center'>
          <h1 className='text-center' style={{ marginTop: 100 }}>
            Connexion en cours ...
            <div style={{ marginTop: 50 }} ><i className='fa fa-spinner fa-spin fa-fw'></i></div>
          </h1>

          {this.state.utilisateur ?
            <div>{content}</div>
          : null}
        </div>
      </div>
    );
  }
}

export default withRouter(UtilisateurConnect);
