import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: null,
      updated: false,
      fetchInProgress: false,
      fetchValue: false,
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchValue()
    }
    else {
      this.setState({value: {
        "Descr": '',
      }})
    }
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchValue) {
      this.fetchValue()
    }
  }

  fetchValue() {

    this.setState({
      fetchValue: true,
    })

    fetch(localStorage.backoffice_api + '/api/table/SITFAM/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        value: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var object = this.state.value

    if (type === 'checkbox') {
      object[name] = event.target.checked
    }
    else {
      object[name] = value
    }

    this.setState({
      updated: true,
      value: object,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createValue();
    }
    else {
      this.editValue();
    }
  }

  createValue() {
    fetch(localStorage.backoffice_api + '/api/table/SITFAM', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.value)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        updated: false,
        fetchInProgress: false,
      }, () => {
        this.props.history.push('/table/SITFAM/' + json.IDSitFam)
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  editValue() {
    console.log(this.props.match.params.id)
    fetch(localStorage.backoffice_api + '/api/table/SITFAM/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.value)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      this.setState({
        updated: false,
        value: null,
        fetchInProgress: false,
      }, () => {
        this.fetchValue()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.value) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Situations familiale</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Descr" className="col-sm-3 col-form-label">Libelle</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.value.Descr} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Form;
