import React, { Component } from 'react';
import ModalResponsables from './ModalResponsables';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      plaques: [],

      loading: false,
      selectedPlaque: null,
      showModalReponsables: false,
    }
  }

  componentDidMount() {
    this.fetchPlaquesResp();
  }

  fetchPlaquesResp(){
    this.setState({
      loading: true
    })
    fetch(localStorage.backoffice_api + '/api/estimationsReprise/plaquesResp', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        loading: false,
        plaques: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  selectPlaque(IDPlaque){
    var plaque = lodash.find(this.state.plaques, {IDPlaque: IDPlaque})
    this.setState({
      selectedPlaque: plaque
    }, () => {
      this.refs.modalResponsables.show();
    })
  }

  onResponsablesSaved(){
    this.fetchPlaquesResp();
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Estimations de reprise</h2>

            <div className="row mt-3">
              <div className="col">
                <table className="table table-striped table-sm table-hover">
                  <thead>
                    <tr>
                      <th>Société</th>
                      <th>Responsables</th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? 
                      <tr>
                        <td colSpan={3} className="text-center">
                          <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                        </td>
                      </tr>
                    :
                      this.state.plaques.map((plaque, i) => {
                        return (
                          <tr key={i}>
                            <td>{plaque.Libelle}</td>
                            <td>
                            {plaque.EstimationsRepriseResp.length ? 
                              lodash.map(plaque.EstimationsRepriseResp, 'Name').join(", ")
                            :
                              "Aucun"
                            }
                            </td>
                            <td className="text-right">
                              <button className='btn btn-light btn-sm' onClick={this.selectPlaque.bind(this, plaque.IDPlaque)}>
                                <i className='fa fa-pencil'></i> Modifier les reponsables
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ModalResponsables ref="modalResponsables" plaque={this.state.selectedPlaque} onSaved={this.onResponsablesSaved.bind(this)}/>
      </div>
    )
  }
}

export default Index;
