import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom'

var lodash = require('lodash')

class Transfert extends Component {

  constructor(props) {
    super(props);

    this.state = {
      originesContact: [],
      origineContactSource:null,
      origineContactDestination:null,
    }
  }

  componentWillMount() {
    this.fetchOriginesContact();
  }

  onChangeSource(event) {
    this.setState({
      origineContactSource: event ? event.value : null,
    });
  }
  
  onChangeDestination(event) {
    this.setState({
      origineContactDestination: event ? event.value : null,
    });
  }

  fetchOriginesContact() {
    fetch(localStorage.backoffice_api + '/api/originesContact', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        originesContact: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleSubmit(event) {
    event.preventDefault()


    fetch(localStorage.backoffice_api + '/api/originesContact/'+this.state.origineContactSource+'/'+this.state.origineContactDestination, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
        if (!response.ok) {
            console.log('fofo');
          response.json().then((value) => {
            alert(value.message)
          });
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.props.history.push('/originesContact')
      })
      .catch((error) => {
        console.log(error)
      });
    }

  render() {

    return (
        <div className='container' style={{ marginTop: '50px' }}>
            <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-6 text-center'>
                    <h3 style={{ marginBottom: '20px' }}>Transfert d'origine</h3>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="form-row align-items-center">
                            <div className="col-md-5 text-left" >
                                <p>Source :</p>
                                <Select multi={false} name="sourceTransfert" value={this.state.origineContactSource} options={this.state.originesContact.map((origine) => {
                                    return {value: origine.IDOrigineCont, label: origine.Descr};
                                })} searchable={true} placeholder='Choisissez une origine contact' required={true} onChange={this.onChangeSource.bind(this)} />
                            </div>
                            <div className="col-md-2" style={{paddingTop:"40px"}}>
                                <i className="fa fa-long-arrow-right fa-4x"></i>
                            </div>
                            <div className="col-md-5 text-left">
                                <p>Destination :</p>
                                <Select multi={false} name="destinationTransfert" value={this.state.origineContactDestination} options={this.state.originesContact.map((origine) => {
                                    return {value: origine.IDOrigineCont, label: origine.Descr};
                                })} searchable={true} placeholder='Choisissez une origine contact' required={true} onChange={this.onChangeDestination.bind(this)} />
                            </div>
                        </div>
                        {this.state.origineContactDestination && this.state.origineContactSource ?
                            <div className="form-row d-flex justify-content-center mt-5">
                                <div className='col-3'>
                                    <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" style={{ marginTop: '15px', width: '80%' }}>
                                        Valider
                                    </button>
                                </div>
                            </div>
                        : null}
                    </form>
                </div>
            </div>
        </div>
      );
    }
  }
  
  export default Transfert;
  