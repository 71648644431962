import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      libellesRemise: [],
      allLibellesRemise: [],
      filter: '',
      fetching: false
    }
  }

  componentDidMount() {
    this.fetchLibellesRemise();
  }

  async fetchLibellesRemise() {
    try {
      this.setState({
        fetching: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/libellesRemise', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })
      var libellesRemise = (await response.json())
      libellesRemise = lodash.sortBy(libellesRemise, 'Libelle')

      this.setState({
        allLibellesRemise: libellesRemise,
      }, this.filter)
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetching: false
      })
    }
  }

  setFilter(event) {
    this.setState({
      filter: event.target.value
    }, this.filter)
  }

  filter() {
    this.setState({
      libellesRemise: this.state.filter ?
        lodash.filter(this.state.allLibellesRemise, (l) => {
          return l.Libelle.toLowerCase().includes(this.state.filter);
        })
        : this.state.allLibellesRemise,
    });
  }

  async deleteLibelleRemise(IDLibelleRemise) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement ce libellé de remise ?'))
      return;

    try {
      const response = await fetch(localStorage.backoffice_api + '/api/libellesRemise/' + IDLibelleRemise, {
        method: 'DELETE',
        headers: {
          authorization: localStorage.JWT,
        }
      })

      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.setState({
        allLibellesRemise: this.state.allLibellesRemise.filter(l => l.IDLibelleRemise != IDLibelleRemise)
      }, this.filter)
    }
    catch (error) {
      console.log(error)
    }
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2 className="mb-4">Libellés remises</h2>

            <div className="d-flex flex-row align-items-center justify-content-between mb-3">
              <Link to='/libellesRemise/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

              <div className="d-flex flex-row align-items-center justify-content-end gap-2">
                <i className='fa fa-filter mr-1' />
                <input type='search' className='form-control form-control-sm float-right' value={this.state.filter} onChange={(e) => this.setFilter(e)} placeholder='Filtre par libellé' />
              </div>
            </div>

            {this.state.fetching ?
              <div className="d-flex flex-row align-items-center justify-content-center">
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              </div>
              :
              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Libellé</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.libellesRemise.map((l) => {
                    return (<tr key={l.IDLibelleRemise}>
                      <td>{l.IDLibelleRemise}</td>
                      <td>{l.Libelle}</td>
                      <td className="text-right">
                        <Link to={'/libellesRemise/' + l.IDLibelleRemise} className='btn btn-primary btn-sm'>
                          <i className="fa fa-pencil"></i>
                        </Link>
                        &nbsp;
                        <a href="#" onClick={this.deleteLibelleRemise.bind(this, l.IDLibelleRemise)} className='btn btn-danger btn-sm text-white'>
                          <i className="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>);
                  })}
                </tbody>
              </table>
            }

          </div>
        </div>
      </div>
    );
  }
}

export default List;
