import React, { Component } from 'react';

class Dupliicate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profils: [],
      name: '',
      DRPROFILE_ID: '',
      updated: false,
      fetchInProgress: false,
    }
  }

  componentWillMount() {
    this.fetchProfils()
  }

  fetchProfils() {
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        profils: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var name = event.target.name
    var value = event.target.value

    this.setState({
      updated: true,
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/'+this.state.DRPROFILE_ID+'/duplicate', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify({Name:this.state.name})
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }
      return response.json();
    })
    .then((json) => {
      this.props.history.push('/profils/eSellerTeam/' + json.DRPROFILE_ID)
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>

          <h2>Profils eSeller</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="name" className="col-sm-3 col-form-label">Nom du nouveau profil</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="name" name="name" value={this.state.name} onChange={this.onChange.bind(this)} maxLength="30" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="DRPROFILE_ID" className="col-sm-3 col-form-label">Profil à dupliquer</label>
                  <div className="col-sm-6">
                    <select value={this.state.DRPROFILE_ID} onChange={this.onChange.bind(this)} id='DRPROFILE_ID' name="DRPROFILE_ID" className="form-control form-control-sm">
                      <option value=''></option>
                      {this.state.profils.map((profil, i) => {
                        return <option key={i} value={profil.DRPROFILE_ID}>{profil.DESCR}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }
}

export default Dupliicate;
