import React, { Component } from 'react';

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      libelleRemise: {
        "Libelle": ""
      },
      updated: false,
      fetching: false,
      submitting: false,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchLibelleRemise(this.props.match.params.id)
    }
  }

  async fetchLibelleRemise(IDLibelleRemise) {
    try {
      this.setState({
        fetching: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/libellesRemise/' + IDLibelleRemise, {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })
      const libelleRemise = (await response.json())

      this.setState({
        libelleRemise: libelleRemise,
      })
    }
    catch(error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetching: false
      })
    }
  }

  onChangeValue(event) {
    var libelleRemise = this.state.libelleRemise;
    libelleRemise[event.target.name] = event.target.value;

    this.setState({
      updated: true,
      libelleRemise: libelleRemise
    })
  }

  canSubmit(){
    return (this.state.updated && !this.state.fetching && this.state.libelleRemise.Libelle != '');
  }

  onSubmit(event) {
    event.preventDefault()

    if (!this.props.match.params.id) {
      this.createLibelleRemise();
    }
    else {
      this.updateLibelleRemise();
    }
  }

  async createLibelleRemise() {
    try {
      this.setState({
        submitting: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/libellesRemise', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.libelleRemise)
      })

      if(!response.ok) {
        throw Error(await response.json());
      }

      this.props.history.push('/libellesRemise')
    }
    catch(error) {
      console.log(error)
      alert(error)
      this.setState({
        submitting: false
      })
    }
  }

  async updateLibelleRemise() {
    try {
      this.setState({
        submitting: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/libellesRemise/'+this.state.libelleRemise.IDLibelleRemise, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.libelleRemise)
      })

      if(!response.ok) {
        throw Error(await response.json());
      }

      this.props.history.push('/libellesRemise')
    }
    catch(error) {
      console.log(error)
      alert(error)
      this.setState({
        submitting: false
      })
    }
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2 className="mb-4">Libellés remises</h2>

            {this.state.fetching ? 
              <div className="d-flex flex-row align-items-center justify-content-center">
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              </div>
            :
              <form onSubmit={(e) => this.onSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="card">
                      <div className="card-header">
                        {`${this.props.match.params.id ? "Modifier" : "Ajouter"} un libellé de remise`}
                      </div>
                      <div className="card-body pb-3">
                        <div className="form-group row">
                          <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libellé</label>
                          <div className="col-sm-6">
                            <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.libelleRemise.Libelle} onChange={(e) => this.onChangeValue(e)} maxLength="200" required='required' />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col text-center">
                            <button disabled={!this.canSubmit()} type="submit" className="btn btn-primary">
                              {this.state.submitting ?
                                <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                              : null}
                              Valider
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            } 
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
