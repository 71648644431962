import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class Eseller extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      filterName: '',
      filterProfil: '',
      profils: [],
      sites: [],
      filterSite: '',
      filterActif: 'inactif',
      selected: [],
      selectedClotures:[],
      fetchInProgress: false,

      showConfirmActivation: false,
      showConfirmCloture: false,
      societes:[],
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs();
    this.fetchSocietes();
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var utilisateurs = lodash.sortBy(json, (utilisateur) => {
        return utilisateur.Nom;
      })

      var profils = lodash.map(json, (utilisateur) => {
        return {id: utilisateur.drprofile_id, name: utilisateur.DRPROFILE_DESCR}
      });

      profils = lodash.uniqBy(profils, (profil) => {
        return profil.id;
      });

      profils = lodash.sortBy(profils, (profil) => {
        return profil.name;
      });

      var sites = []
      lodash.map(json, (utilisateur) => {
        return lodash.map(utilisateur.Sites, (site) => {
          sites.push(site)
          return site;
        });
      });
      sites = lodash.uniqBy(sites, (site) => {
        return site.IDSite;
      });
      sites = lodash.sortBy(sites, (site) => {
        return site.Plaque_Libelle + ' - ' + site.Descr;
      });

      this.setState({
        utilisateurs: utilisateurs,
        profils: profils,
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        societes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onCheckUser(event) {

    var selected = this.state.selected;

    if (event.target.checked) {
      selected.push(parseInt(event.target.value, 10));
    }
    else {
      selected = lodash.without(selected, parseInt(event.target.value, 10));
    }

    this.setState({
      selected: selected
    });
  }

  onCheckCloture(event) {

    var cloture = this.state.selectedClotures;

    if (event.target.checked) {
      cloture.push(parseInt(event.target.value, 10));
    }
    else {
      cloture = lodash.without(cloture, parseInt(event.target.value, 10));
    }

    this.setState({
      selectedClotures: cloture
    });
  }

  onClickActivationAccount(event) {
    this.setState({
      showConfirmActivation: true
    });
    window.scrollTo(0, 0);
    return;
  }

  onClickCloseAccount(event) {
    this.setState({
      showConfirmCloture: true,
    });
    window.scrollTo(0, 0);
    return;
  }

  submitActivationAccount(){
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/utilisateurs/eseller/open', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.selected)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateurs: [],
        fetchInProgress: false,
        selected: [],
        selectedClotures: [],
        showConfirmActivation: false,
        showConfirmCloture: false,
      }, () => {
        this.fetchUtilisateurs()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  submitClotureAccount() {
    fetch(localStorage.backoffice_api + '/api/utilisateurs/eseller/close', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.selectedClotures)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateurs: [],
        fetchInProgress: false,
        selected: [],
        selectedClotures: [],
        showConfirmActivation: false,
        showConfirmCloture: false,
      }, () => {
        this.fetchUtilisateurs()
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  cancelActivationAccount(){
    window.location.reload();
  }

  cancelClotureAccount(){
    window.location.reload();
  }

  getUtilisateurSociete(utilisateur) {
    var societes = lodash.uniq(lodash.map(utilisateur.Sites, site => {
      return site.Plaque_Libelle;
    }));
    if(societes.length > 1)
      return "[Groupe]";
    else if(societes.length === 1)
      return societes[0];
    else 
      return "";
  }

  getEmailsConfirmation(isCloture) {
    var utilisateurs_ids = [];
    if(isCloture)
      utilisateurs_ids = this.state.selectedClotures;
    else 
      utilisateurs_ids = this.state.selected;

    var emails = ['sav@selectup.com'];
    //Email de l'utilisateur courant, si accès client
    if(Utils.isAccesClient())
    {
      var IDPeople = Utils.fromJWT("IDPeople");
      var currentUser = lodash.find(this.state.utilisateurs, {IDPeople:IDPeople});
      if(currentUser)
        emails.push(currentUser.EMail);
    }
    for(var i in utilisateurs_ids){
      var utilisateur = lodash.find(this.state.utilisateurs, {IDPeople:utilisateurs_ids[i]});
      if(utilisateur){
        for(var j in utilisateur.Sites){
          var soc = lodash.find(this.state.societes, {IDPlaque: utilisateur.Sites[j].IDPlaque});
          if(soc){
            emails.push(...soc.EMailsContacts.split(";"));
          }
        }
      }
    }
    return lodash.uniq(emails);
  }

  renderConfirmActivation() {
    return (
      <div className='container-fluid'>
      <div className="row">
        <div className="col">

          <h2>Activation de comptes eSeller</h2>
          <div className="card" style={{fontSize:'0.8em', fontFamily:'arial'}}>
            <div className="card-header">
              <h5 className="card-title mb-0">Conditions générales d'utilisation</h5>
            </div>
            <div className="card-body">
              <b>CONDITIONS GÉNÉRALES D'UTILISATION DU SERVICE E-SELLER</b><br/>
              (Version V1 du 15 Juin 2005)<br/>
              <b>GLOSSAIRE</b>
              <br/><b>SELECT'UP :</b> Premier fournisseur d’application hébergée à proposer aux concessionnaires de gérer leur force de vente en utilisant le mode ASP (Application Service Provider).
              <br/><b>e-Seller :</b> Module, du produit CRM-Motors, spécialisé dans la conquête et la connaissance clients, qui est la propriété de Select’up
              <br/><b>BackOffice :</b> Outil de paramétrage des comptes et profils utilisateurs du module e-seller.
              <br/><b>Client :</b> Personne physique ou morale achetant des produits et (ou) des services.
              <br/><b>Internet :</b> Réseau mondial associant des ressources de télécommunication et des ordinateurs serveurs et clients, destiné à l'échange de données électroniques.
              <br/><b>Intranet :</b> Réseau local et privé (entreprise) qui utilise les technologies de l'Internet, mais qui ne s'ouvre pas aux connexions publiques.
              <br/><b>URL (Uniform Ressource Locator) :</b> Adresse Internet permettant d'atteindre un site Web donné.
              <br/><b>OBJET :</b> Le présent contrat a pour objet de définir les conditions d'accès au produit e-seller. Les présentes conditions générales de vente régissent les relations contractuelles entre SELECT’UP et son client. Ces conditions générales de vente prévaudront sur toutes autres conditions figurant dans tout autre document, sauf dérogation préalable, expresse et écrite.
              <br/>
              <br/>
              <ol>
                <li>
                  <b>Article 1. ACCES AUX SERVICES E-SELLER</b><br/>
                  Le menu « gestion des comptes e-seller » du BackOffice vous permet d’ouvrir et de résilier les comptes utilisateurs pouvant accéder au module e-seller. Toute ouverture de compte, en cours de mois, déclenche une facturation, dès le premier jour du mois.
                </li>
                <li>
                  <b>Article 2. ACCEPTATION DES CONDITIONS</b><br/>
                  La validation par le bouton « Accepter » de ces « Condition générales de vente » vaut acceptation des présentes conditions sans aucune réserve, et déclenche un courriel de confirmation d’ouverture et/ou de résiliation de compte.
                </li>
                <li>
                  <b>Article 3. PROPRIETE INTELLECTUELLE</b><br/>
                  Les droits de propriété et de reproduction des fichiers informatiques, films, images, photos, logotypes, pages ou sites Internet créés par nos soins nous restent acquis. Aucune cession ne saurait en résulter, même si tout ou partie des frais y afférant ont été acquittés par le client, sauf si des droits particuliers contractuels ont été prévus et définis à la commande.
                </li>
                <li>
                  <b>Article 4. ENGAGEMENTS ET RESPONSABILITES DU CLIENT</b><br/>
                  Les Codes d'accès saisis dans le BackOffice par le client sont activés après la validation de la commande par le bouton « Accepter ». Le client est seul responsable de l'utilisation de ses codes d'accès. Toute utilisation du service effectuée en utilisant les Codes d'accès d’un utilisateur est réputée avoir été faite par lui-même, sauf preuve contraire.
                </li>
                <li>
                  <b>Article 5. ENGAGEMENTS DE SELECT’UP</b><br/>
                  SELECT’UP met en œuvre tous les moyens dont elle dispose pour assurer l'accès aux services de e-seller souscrits. La responsabilité de SELECT’UP ne peut pas être engagée en cas de mauvaise utilisation du service par un utilisateur, en cas de perturbations, d'indisponibilité totale ou partielle des services proposés sur les réseaux exploités par des Opérateurs Tiers, et/ou en cas de force majeure au sens de la jurisprudence de la Cour de Cassation.
                </li>
                <li>
                  <b>Article 6. FIN DE CONTRAT / RESILIATION COMPTES</b><br/>
                  SELECT’UP peut résilier ce contrat sans préavis en cas de prélèvement bancaire rejeté. La Clôture d’un compte utilisateur par le client s’effectue par l’intermédiaire du module de gestion des comptes e-seller du BackOffice. La validation de l’écran intitulée « Veuillez Confirmer la clôture du compte e-seller suivant : », clôture le compte et déclenche un courriel de confirmation de suppression des droits d’accès à e-seller. Toute fermeture de compte, en cours de mois sera facturée et prélevée en totalité (prélèvement mensuel).
                </li>
                <li>
                  <b>Article 7. LITIGES - ATTRIBUTION DE JURIDICTION</b><br/>
                  Pour toutes contestations de quelque nature quelles soient, le TRIBUNAL DE COMMERCE DE BORDEAUX sera seul compétent.
                </li>
              </ol>
            </div>
          </div>

          <br/>
          <div className="card">
            <div className="card-body">
              Veuillez confirmer l'ouverture des comptes eSeller suivants : <br/>
              <table className="table table-sm d-inline-block table-striped w-50">
                <thead>
                  <tr>
                    <th>Société</th>
                    <th className="pl-4">Utilisateur</th>
                  </tr>
                </thead>
                <tbody>
                  {lodash.map(this.state.utilisateurs, (u) => {
                    if(this.state.selected.indexOf(parseInt(u.IDPeople)) !== -1)
                      return (<tr key={u.IDPeople}><td>{this.getUtilisateurSociete(u)}</td><td className="pl-4">{u.Name}</td></tr>);
                  })}
                </tbody>
              </table>
              <br/>
              Un e-mail de confirmation de votre demande d'ouverture de ces comptes eSeller sera envoyé aux contacts suivants : <br/><br/>
              <ul>
                {this.getEmailsConfirmation(false).map(email => {
                  if(email != '')
                    return <li key={email}>{email}</li>;
                })}
              </ul>
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button type="submit" className="btn btn-primary" onClick={this.submitActivationAccount.bind(this)}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Accepter
                  </button>
                  &nbsp;&nbsp;
                  <button type="submit" className="btn btn-secondary" onClick={this.cancelActivationAccount.bind(this)}>
                    Refuser
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br/><br/>
        </div>
      </div>
      </div>
    )
  }

  renderConfirmCloture() {
    
    /*var utilisateur = lodash.find(this.state.utilisateurs, {IDPeople: this.state.selectedCloture});
    
    if(!utilisateur)
      return null;*/

    return (
      <div className='container-fluid'>
      <div className="row">
        <div className="col">

          <h2>Clôture de compte eSeller</h2>
          <br/>
          <div className="card">
            <div className="card-body">
              Veuillez confirmer la clôture du compte eSeller suivants : <br/>
              <table className="table table-sm d-inline-block table-striped w-50">
                <thead>
                  <tr>
                    <th>Société</th>
                    <th className="pl-4">Utilisateur</th>
                  </tr>
                </thead>
                <tbody>
                {lodash.map(this.state.utilisateurs, (u) => {
                    if(this.state.selectedClotures.indexOf(parseInt(u.IDPeople)) !== -1)
                      return (<tr key={u.IDPeople}><td>{this.getUtilisateurSociete(u)}</td>
                      <td className="pl-4">{u.Name}</td></tr>);
                  })}
                  {/*<tr><td>{this.getUtilisateurSociete(utilisateur)}</td>
                <td className="pl-4">{utilisateur.Name}</td></tr>*/}
                </tbody>
              </table>
              <br/>
              Un e-mail de confirmation de votre demande de clôture de ces comptes eSeller sera envoyé aux contacts suivants : <br/><br/>
              <ul>
                {this.getEmailsConfirmation(true).map(email => {
                  if(email != '')
                    return <li key={email}>{email}</li>
                })}
              </ul>
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button type="submit" className="btn btn-primary" onClick={this.submitClotureAccount.bind(this)}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Clôturer
                  </button>
                  &nbsp;&nbsp;
                  <button type="submit" className="btn btn-secondary" onClick={this.cancelClotureAccount.bind(this)}>
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br/><br/>
        </div>
      </div>
      </div>
    )
  }

  render() {
    if(this.state.showConfirmActivation)
      return this.renderConfirmActivation();
      
    if(this.state.showConfirmCloture)
      return this.renderConfirmCloture();

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Utilisateurs</h2>

            <br />

            <div>
              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({filterName: event.target.value})} placeholder='Filtre par nom' />
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <select value={this.state.filterProfil} onChange={(event) => this.setState({filterProfil: event.target.value})} className="form-control form-control-sm" placeholder='Filtre par profil'>
                      <option key='0' value=''>Filtre par profil</option>
                      {this.state.profils.map((profil) => {
                        if (!profil.id)
                          return null;
                        return <option key={profil.id} value={profil.id}>{profil.name}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <select value={this.state.filterSite} onChange={(event) => this.setState({filterSite: event.target.value})} className="form-control form-control-sm" placeholder='Filtre par site'>
                      <option key='0' value=''>Filtre par site</option>
                      {this.state.sites.map((site) => {
                        return <option key={site.IDSite} value={site.IDSite}>{site.Plaque_Libelle} - {site.Descr}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-inline float-left" style={{ marginTop: '2px', }}>
                  <div className="input-group">
                    <select value={this.state.filterActif} onChange={(event) => this.setState({filterActif: event.target.value,selected:[],selectedClotures:[]})} className="form-control form-control-sm" placeholder='Filtre compte actif'>
                      <option key='actif' value='actif'>Compte eSeller activé</option>
                      <option key='inactif' value='inactif'>Compte eSeller non activé</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>

            <h4>Compte eSeller</h4>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Téléphone</th>
                  <th>Email</th>
                  <th>Profil d'utilisateur</th>
                  <th className='text-center'></th>
                </tr>
              </thead>
              <tbody>
                {this.state.utilisateurs.map((utilisateur) => {

                  if (!utilisateur.AccesCRMeSeller || !utilisateur.Actif) {
                    return null;
                  }

                  if (this.state.filterActif) {
                    if (this.state.filterActif === 'actif' && !utilisateur.IsAccountOpened) {
                      return null;
                    }
                    if (this.state.filterActif === 'inactif' && utilisateur.IsAccountOpened) {
                      return null;
                    }
                  }

                  if (this.state.filterName) {
                    if (utilisateur.Name.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                      return null;
                    }
                  }

                  if (this.state.filterProfil) {
                    if (parseInt(this.state.filterProfil, 10) !== parseInt(utilisateur.drprofile_id, 10)) {
                      return null;
                    }
                  }

                  if (this.state.filterSite) {
                    var sitesUsersFind = lodash.findIndex(utilisateur.Sites, (site) => {
                      return parseInt(site.IDSite, 10) === parseInt(this.state.filterSite, 10)
                    })
                    if (sitesUsersFind <= 0) {
                      return null;
                    }
                  }

                  return (<tr key={utilisateur.IDPeople}>
                    <td>{utilisateur.IDPeople}</td>
                    <td>{utilisateur.Name}</td>
                    <td>{utilisateur.PhoneNumber}</td>
                    <td>{utilisateur.EMail}</td>
                    <td>{utilisateur.DRPROFILE_DESCR}</td>
                    <td className='text-right'>
                      {!utilisateur.IsAccountOpened ?
                        <input className="form-check-input" type="checkbox" value={utilisateur.IDPeople} checked={this.state.selected.indexOf(utilisateur.IDPeople) !== -1} onChange={this.onCheckUser.bind(this)}/>
                      : 
                      <input className="form-check-input" type="checkbox" value={utilisateur.IDPeople} checked={this.state.selectedClotures.indexOf(utilisateur.IDPeople) !== -1} onChange={this.onCheckCloture.bind(this)}/>
                      }
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

            {this.state.selected.length ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" onClick={this.onClickActivationAccount.bind(this)}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Activer les comptes sélectionnés
                  </button>
                </div>
              </div>
            : null}

            {this.state.selectedClotures.length ?
              <div className="row" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-danger" onClick={this.onClickCloseAccount.bind(this)}>
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Clôturer les comptes sélectionnés
                  </button>
                </div>
              </div>
            : null}

            <br/>
            <br/>
          </div>
        </div>
      </div>
    );
  }
}

export default Eseller;
