import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      IDProfil: props.match.params.id,
      profil: null,
      fetchingProfil: false,
      permissionsByCategory: [],
      fetchingPermissions: false,
      saving: false,
      updated: false,
    }
  }

  componentDidMount() {
    if(this.state.IDProfil) {
      this.fetchProfil();
    }
    this.fetchPermissions();
  }

  async fetchProfil() {
    try {
      this.setState({
        fetchingProfil: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/profils/eDataVoicePrc/' + this.state.IDProfil, {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })

      this.setState({
        profil: await response.json(),
      })
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetchingProfil: false
      })
    }
  }

  async fetchPermissions() {
    try {
      this.setState({
        fetchingPermissions: true
      })
      const response = await fetch(localStorage.backoffice_api + '/api/permissions', {
        method: 'GET',
        headers: {
          authorization: localStorage.JWT,
        },
      })

      let permissions = await response.json();
      let permissionsByCategory = lodash.groupBy(permissions, 'LibelleCategoriePermission')

      this.setState({
        permissionsByCategory: permissionsByCategory,
      })
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.setState({
        fetchingPermissions: false
      })
    }
  }

  async save() {
    try {
      this.setState({
        saving: true
      })
      await fetch(localStorage.backoffice_api + '/api/profils/eDataVoicePrc/'+this.state.IDProfil, {
        method: 'PUT',
        headers: {
          authorization: localStorage.JWT,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.profil)
      })

      this.setState({
        updated: false
      })

      this.fetchProfil()
    }
    catch (error) {
      console.log(error)
    }
    finally {
      this.setState({
        saving: false
      })
    }
  }

  onChangePermission(code, checked) {
    const profilPermissions = this.state.profil.Permissions
    const idx = profilPermissions.indexOf(code)
    if (checked) {
      if(idx === -1) {
        profilPermissions.push(code)
      }
    } 
    else if (!checked) {
      if(idx !== -1) {
        profilPermissions.splice(idx, 1);
      }
    }

    this.setState({
      profil: {
        ...this.state.profil, 
        permissions: profilPermissions
      },
      updated: true
    })
  }

  onSubmit() {
    this.save()
  }

  isFetching() {
    return this.state.fetchingProfil || this.state.fetchingPermissions
  }

  profilHasPermission(code) {
    return this.state.profil.Permissions.indexOf(code) !== -1
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <div className="d-flex justify-content-between align-items-center mb-2">
              <h2 className="mb-4">
                <Link to={'/profils/eDataVoicePrc'} className="text-dark">Profils eDataVoice (Poste Relation Client)</Link> - {this.state.profil?.Libelle}</h2>
              <div>
                  {this.state.updated ?
                      <div className="d-flex flex-row">
                          <div className="alert alert-warning m-0 p-1 mr-1">
                              <i className="fa fa-warning mr-1"></i>
                              Modifications non sauvegardées
                          </div>
                          <button type="submit" className="btn btn-primary m-0 p-1" onClick={this.onSubmit.bind(this)}>
                              {this.state.submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                              Valider
                          </button>
                      </div>
                  : null}
              </div>
            </div>

            {this.isFetching() ?
              <div className="d-flex flex-row align-items-center justify-content-center">
                <i className='fa fa-spinner fa-spin fa-fw'></i>
              </div>
              :
              <>
                {Object.entries(this.state.permissionsByCategory).map(([categorie, permissions]) => {
                  return (
                    <React.Fragment key={categorie}>
                      <h4>{categorie}</h4>
                      <table className="table table-striped table-hover table-sm">
                        <thead>
                          <tr>
                            <th style={{width: '12%'}}>#</th>
                            <th style={{width: '30%'}}>Libellé</th>
                            <th>Description</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {permissions.map(p => {
                          return (<tr key={p.Code}>
                              <td className="align-middle"><pre className="m-0">{p.Code}</pre></td>
                              <td className="align-middle">{p.Libelle}</td>
                              <td className="align-middle">{p.Description}</td>
                              <td>
                                <input
                                    className="form-check-input" type="checkbox"
                                    checked={this.profilHasPermission(p.Code)}
                                    onChange={(e) => this.onChangePermission(p.Code, e.currentTarget.checked)}
                                />
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </React.Fragment>
                  )
                })}

                <div className="row mt-4">
                  <div className="col text-center">
                    <button disabled={this.state.saving || !this.state.updated} type="submit" className="btn btn-primary" onClick={this.onSubmit.bind(this)}>
                        {this.state.saving && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                        Valider
                    </button>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default List;
