import React, { useState, useEffect } from "react"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { ButtonAction } from "../../../UtilsComponents/ButtonAction"
import { useParams } from "react-router-dom"
import { titleTypes } from "../Types/TitleType"
import { CompteWally } from "../Types/Class/CompteWally"
import { SiteWally } from "../Types/Class/SiteWally"
import { Site } from "../../../../types/Site"
import { CrmParam } from "../Types/CrmParam"
import List from "./List"

export default function Index() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [ fetchProgress, setFetchProgress ] = useState<boolean>(false)
    const [ wallySites, setWallySites ] = useState<SiteWally[]>([])
    const [ accounts, setAccounts ] = useState<CompteWally[]>([])
    const [ sites, setSites ] = useState<Site[]>([])
    const [ isSetting, setIsSetting ] = useState<boolean>(false)
    const [ isOnSaving, setIsOnSaving ] = useState<boolean>(false)
    const [ isCurrentSetting, setIsCurrentSetting ] = useState<boolean>(false)
    const [ accountSelected, setAccountSelect ] = useState<CompteWally | null>(null)

    const params: CrmParam = useParams<CrmParam>()

    useEffect(() => {
        fetchAccount()
        fetchSites()
    }, [])

    const fetchAccount = () => {
        setFetchProgress(true)
        backofficeApiClient
            .get<CompteWally[]>('/interfaces/wally/accounts')
            .then((accountData: CompteWally[]) => {
                if (accountData.length === 0) return
                const transformedAccounts = accountData.map(account => new CompteWally(account))
                setAccounts(transformedAccounts)
            }).catch((err) => {
            console.error('Erreur lors de la récupération des comptes:', err)
        }).finally(() => setFetchProgress(false))
    }
    const fetchSites = () => {
        setFetchProgress(true)
        backofficeApiClient
            .get<Site[]>('/interfaces/wally/sites')
            .then((r: Site[]) => {
                if (r.length === 0) return
                const transformedSites = r.map(site => new Site(site))
                setSites(transformedSites)
            }).catch((err) => {
            console.error('Erreur lors de la récupération des sites:', err)
        }).finally(() => setFetchProgress(false))
    }
    const fetchPointOfSale = (account_id: string) => {
        setFetchProgress(true)
        backofficeApiClient
            .get<SiteWally[]>('/interfaces/wally/accounts/' + account_id + '/point-of-sales')
            .then((r: SiteWally[]) => {
                if (r.length === 0) {
                    setWallySites([])
                    return
                }
                const transformedAccounts = r
                    .map(pdv => new SiteWally(pdv, sites))
                    .sort(sortByIDSite)
                setWallySites(transformedAccounts)
            }).catch((err) => {
            console.error('Erreur lors de la récupération des points de vente:', err)
        }).finally(() => setFetchProgress(false))
    }

    function sortByIDSite(a:SiteWally, b:SiteWally) {
        const nameA = a.IDSite // ignore upper and lowercase
        const nameB = b.IDSite // ignore upper and lowercase
        if (nameA > nameB) {
            return -1
        }
        if (nameA < nameB) {
            return 1
        }
        // names must be equal
        return 0
    }

    const handleSelectAccount = (e: any) => {
        const accSel = accounts.find(acc => acc.IdWallyCompte == e.target.value)
        if (!accSel) {
            setAccountSelect(null)
            setWallySites([])
            return
        }
        if (isCurrentSetting) { // Si en cours de modif alors on met une alert
            if (!window.confirm("Vous avez des modifications en cours. Annuler ? ")) return
        }
        setAccountSelect(accSel)
        fetchPointOfSale(accSel.IdWallyCompte)
        setIsCurrentSetting(false)
    }

    const handleChangePdv = (siteToUpdate: SiteWally) => {
        return () => {
            const updatedSite: SiteWally = {
                ...siteToUpdate,
                isCurrentChange: true,
            }
            setWallySites((prevPdv: SiteWally[]) =>
                prevPdv.map(ome =>
                    ome.getPrimaryKey() === siteToUpdate.getPrimaryKey() ? updatedSite : ome,
                ),
            )
        }
    }

    const handleFetchSaveChangePdv = (pdv: SiteWally) => {
        return () => {
            if (!accountSelected) return

            console.log('/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte +"/point-of-sales/" +pdv.IdWallySite+"/"+ pdv.IdWallyMarque)
            backofficeApiClient
                .put<any>(
                    '/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte +"/point-of-sales/" +pdv.IdWallySite+"/"+ pdv.IdWallyMarque,
                    { idSite: pdv.IDSiteModifying },
                )
                .then((r: any) => {
                    console.log(r)
                    fetchPointOfSale(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    const err_resp = JSON.parse(err.message)
                    if (err_resp.error_code === 409) alert(err_resp.message)
                    console.error('Erreur lors de la modification de l\'origine eSeller:', err)
                })
        }
    }

    const handleDeletingSite = (pdv: SiteWally) => {
        return () => {
            if (!accountSelected) return
            console.log(
                '/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte + "/point-of-sales/" +pdv.IdWallySite+"/"+ pdv.IdWallyMarque
            )
            backofficeApiClient
                .delete<any>('/interfaces/wally/accounts/' + accountSelected?.IdWallyCompte + "/point-of-sales/" +pdv.IdWallySite+"/"+ pdv.IdWallyMarque,null)
                .then((r: any) => {
                    console.log(r)
                    fetchPointOfSale(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    console.error('Erreur lors de la suppression de l\'origine:', err)
                })
        }
    }

    return <>
        <div className={"container-fluid mb-5 pr-5 pl-5"}>
            <div className={"row"}>
                <div>
                    <ButtonAction href={"/interfaces/wally/"} className={"btn btn-secondary"}>
                        <i className={"fa-solid fa-chevron-left fa-xs"}></i>
                    </ButtonAction>
                </div>
                <div className={"col"}>
                    <h2 className={"mb-4 d-flex justify-content-between"}>
                        <div>
                            Point de vente {titleTypes[params.crm_name]}
                        </div>
                        <div style={{ fontSize: "0.6em" }} className={"text-secondary"}>
                            Actif de 7h à 20h du Lundi au samedi
                        </div>
                    </h2>
                </div>
            </div>

            <div className={"row mb-4"}>
                <div className={"col"}>
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <div>
                            {!isSetting ?
                                <ButtonAction href={"#"} type="button" className="btn bg-info">
                                    <i className={"fa-solid fa-rotate fa-xs"}></i>
                                    <span className={"pl-2"}>Actualiser</span>
                                </ButtonAction>
                                :
                                <ButtonAction href={"#"} onClick={() => setIsSetting(false)} type="button" className="btn btn-secondary mr-2">
                                    <i className={"fa-solid fa-xmarx-circle fa-xs"}></i>
                                    <span className={"pl-2"}>Annuler</span>
                                </ButtonAction>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-3"}>
                <div className={"col"}>
                    <div className={"text-center"}>
                        {fetchProgress && <span><i className="fa fa-spinner fa-spin fa-fw fa-xl"></i>&nbsp;</span>}
                    </div>
                    <p>
                        Insérer un texte explicatif
                    </p>
                    <div className={"col bg-secondary p-1"}>
                        <select value={accountSelected?.IdWallyCompte} onChange={handleSelectAccount} name="IDDRProfileEsellerLead"
                                className="form-control form-control-sm col-3">
                            <option value="0">Sélectionner un compte</option>
                            {accounts.map((account) => {
                                return <option key={account.IdWallyCompte} value={account.IdWallyCompte}>{account.Identifiant + " " + account.EmailNotification}</option>
                            })}
                        </select>
                    </div>
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th className={"w-25"}>Point de vente {titleTypes[params.crm_name]}</th>
                            <th className={"w-auto"}>Marque {titleTypes[params.crm_name]}</th>
                            <th className={"w-50"}>Point de vente eSeller</th>
                            <th className={"w-auto"}>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {wallySites.map((ws: SiteWally) =>
                            <List key={ws.getPrimaryKey()}
                                  ws={ws}
                                  setWallySites={setWallySites}
                                  sites={sites}
                                  handleChangePdv={handleChangePdv}
                                  handleDeletingSite={handleDeletingSite}
                                  handleFetchSaveChangePdv={handleFetchSaveChangePdv}
                            />,
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </>
}
