import React, { Component } from 'react';

import Links from './Links'
import InterfaceEsellerLead from './InterfaceEsellerLead'
import InterfacePlanetVO from './InterfacePlanetVO'
import InterfaceBee2Link from './InterfaceBee2Link'
import InterfaceCorporateSales from './InterfaceCorporateSales'
import InterfaceBaseVN from './InterfaceBaseVN'
import InterfaceOpelVN from './InterfaceOpelVN'
import InterfaceSmartDataForLead from './InterfaceSmartDataForLead';
import DPOContact from './DPOContact';
import InterfacePlanetVO2 from './InterfacePlanetVO2';

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      facturation: null,
      dpoGroupExists: null,
    }
  }

  componentWillMount() {
    this.fetchFacturation();
  }

  fetchFacturation() {
    fetch(localStorage.backoffice_api + '/api/bdref3/facturation', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        facturation: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>

        {this.state.facturation && !this.state.facturation.FAM_FactureeSeller ?
          <div className="alert alert-danger text-center" role="alert" style={{ marginBottom: '25px' }}>
            <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ fontSize: '20px' }}></i> La facturation est désactivé pour ce client. <a href="#" className="alert-link">Gérer la facturation</a>.
          </div>
        : null}
        <div className='row'>
          <Links />
          <br /><br />
        </div>

        {this.state.dpoGroupExists !== null ?
          this.state.dpoGroupExists ? 
            <div className="text-success">DPO Contact : Le groupe existe</div>
          :
            <div className="text-danger">DPO Contact : Le groupe n'existe pas</div>
          :null
        }
        <hr />

        <div className='row'>
          <div className='col-12'>
            <h4>Interfaces</h4>

            <div className='row'>
              <InterfaceEsellerLead />
              <InterfacePlanetVO />
              <InterfaceBee2Link />
              <InterfaceCorporateSales />
              <InterfaceBaseVN />
              <InterfaceOpelVN />
              <DPOContact />
              <InterfaceSmartDataForLead />
              <InterfacePlanetVO2/>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Dashboard;
