import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      societes: [],
      filterName: '',
      filterSociete: '',
    }
  }

  componentWillMount() {
    this.fetchSites();
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        var sites = lodash.sortBy(json, (site) => {
          return site.Plaque_Libelle
        })

        if (Utils.isAccesClient()) {
          sites = lodash.filter(sites, (site) => {
            return !site.EstFictif
          })
        }

        var societes = []
        sites.forEach(societe => {
          let distinct = lodash.includes(societes, societe.Plaque_Libelle)
          if (distinct == false) {
            societes.push(societe.Plaque_Libelle)
          }
        })

        this.setState({
          sites: sites,
          societes: societes
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {
    console.log(this.state)
    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Sites</h2>

            <br />

            <div>
              <Link to='/sites/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

              <div className='float-right'>
                <div className="float-left form-inline" style={{ marginTop: '2px', marginRight: '4px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterName} onChange={(event) => this.setState({ filterName: event.target.value })} placeholder='Filtre par nom' />
                  </div>
                </div>

                <div className="float-left form-inline" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <select onChange={(event) => this.setState({ filterSociete: event.target.value })} className="form-control form-control-sm" placeholder='Filtre par société'>
                      <option key='0' value=''>Filtre par société</option>
                      {this.state.societes.map((societe) => {
                        return <option key={societe} value={societe}>{societe}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>

            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Société</th>
                  <th>Nom</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Code Constructeur</th>
                  {!Utils.isAccesClient() ? <th>Site fictif</th> : null}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.sites.map((site) => {
                  if (this.state.filterName) {
                    if (site.Descr.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
                      return false;
                    }
                  }
                  if (this.state.filterSociete) {
                    if (site.Plaque_Libelle.toLowerCase().search(this.state.filterSociete.toLowerCase()) === -1) {
                      return false;
                    }
                  }

                  return (<tr key={site.IDSite}>
                    <td>{site.IDSite}</td>
                    <td>{site.Plaque_Libelle}</td>
                    <td>{site.Descr}</td>
                    <td>{site.Code}</td>
                    <td>{site.TYPESITE_Descr}</td>
                    <td>{site.CodeConstructeur}</td>
                    {!Utils.isAccesClient() ? <td>{site.EstFictif ? 'Oui' : 'Non'}</td> : null}
                    <td>
                      <Link to={'/sites/' + site.IDSite} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
