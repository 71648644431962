export const titleTypes: Record<string, string> = {
    "customer_first": "Customer first - Stellantis",
    "hway": "H-Way - Hyundai",
}
export const urlLogoTypes: Record<string, string> = {
    "STELLANTIS": "https://companieslogo.com/img/orig/STLA-7ff80a99.png?t=1634834340",
    "HYUNDAI": "https://companieslogo.com/img/orig/HYMTF-f0fcea48.png?t=1634834834",
}


