import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      status: [],

      filterUtilisateursName: '',
      filterUtilisateurSites: [],
      filterUtilisateurSite: '',
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs()
  }

  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var sites = []
      lodash.map(json, (utilisateur) => {
        return lodash.map(utilisateur.Sites, (site) => {
          sites.push(site)
          return site;
        });
      });
      sites = lodash.uniqBy(sites, (site) => {
        return site.IDSite;
      });
      sites = lodash.sortBy(sites, (site) => {
        return site.Plaque_Libelle + ' - ' + site.Descr;
      });

      this.setState({
        utilisateurs: json,
        filterUtilisateurSites: sites,
      }, () => {
        this.state.utilisateurs.map((u) => {
          if (u.BasePVO) {
            this.checkUser(u.IDPeople);
          }
        })
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteConfUser(IDPeople) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer la configuration de cet utilisateur ?')) {
      return;
    }

    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/utilisateurs/'+IDPeople+'/societes', {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        utilisateurs: null,
      }, () => {
        this.fetchUtilisateurs();
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  checkUser(IDPeople) {
    console.log(IDPeople)
    var index = lodash.findIndex(this.state.utilisateurs, (u) => {
      return u.IDPeople == IDPeople;
    })

    if (index < 0) {
      return;
    }

    fetch(localStorage.backoffice_api + '/api/interfaces/planetvo/utilisateurs/'+IDPeople+'/check', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "LoginInterface": this.state.utilisateurs[index].LoginInterface,
        "PasswordInterface": this.state.utilisateurs[index].PasswordInterface,
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var status = this.state.status;
      status[IDPeople] = json;
      this.setState({
        status: status,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  displayStatus(utilisateur) {

    if (!utilisateur.BasePVO)
      return;

    if (this.state.status[utilisateur.IDPeople].response == 'nok') {
      return <i className="fa fa-times" style={{ color: '#dc3545', fontSize: '30px' }}></i>
    }

    var bases = this.state.status[utilisateur.IDPeople].map((ligne) => {
      return ligne.base;
    })

    var indexBase = lodash.findIndex(bases, (b) => {
      return b == utilisateur.BasePVO;
    })

    if (indexBase < 0) {
      return <i className="fa fa-exclamation-triangle" style={{ color: '#ffc107', fontSize: '28px' }}></i>
    }

    return <i className="fa fa-check" style={{ color: '#28a745', fontSize: '28px' }}></i>
  }

  render() {

    if (!this.state.utilisateurs)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <div className='clearfix'>
              <h2 className='pull-left'>PlanetVO - Interface Niveau 2</h2>
              <div className='pull-right' style={{ fontSize: '13px' }}>
                <i className="fa fa-times" style={{ color: '#dc3545', fontSize: '25px' }}></i> &nbsp; Les identifiants sont erronés<br />
                <i className="fa fa-exclamation-triangle" style={{ color: '#ffc107', fontSize: '23px' }}></i> &nbsp; L'utilisateur n'est pas associé à la Base PVO renseignée<br/>
                <i className="fa fa-check" style={{ color: '#28a745', fontSize: '23px' }}></i> &nbsp; L'utilisateur est correctement configuré<br/>
              </div>
            </div>

            <br />

            <div>
              <h3>Utilisateurs</h3>

              <div>
                <div className='float-left'>
                  <Link to='/utilisateurs' className='btn btn-light' style={{ marginTop: '5px', marginBottom: '15px', }}>
                    <i className="fa fa-list"></i> Gérer tous les utilisateurs
                  </Link>
                </div>

                <div className='float-right'>
                  <div className="form-inline float-left"  style={{ marginTop: '2px', marginRight: '4px' }}>
                    <div className="input-group">
                      <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                      <input type='search' className='form-control form-control-sm float-right' value={this.state.filterUtilisateursName} onChange={(event) => this.setState({filterUtilisateursName: event.target.value})} placeholder='Filtre par nom' />
                    </div>
                  </div>

                  <div className="form-inline float-left" style={{ marginTop: '2px', }}>
                    <div className="input-group">
                      <select value={this.state.filterUtilisateurSite} onChange={(event) => this.setState({filterUtilisateurSite: event.target.value})} className="form-control form-control-sm" placeholder='Filtre par site'>
                        <option key='0' value=''>Filtre par site</option>
                        {this.state.filterUtilisateurSites.map((site) => {
                          return <option key={site.IDSite} value={site.IDSite}>{site.Plaque_Libelle} - {site.Descr}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='clearfix'></div>
              </div>

              <table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Base PVO</th>
                    <th>NickName PVO</th>
                    <th>Login PVO</th>
                    <th>Password PVO</th>
                    <th className='text-center'>Vérification du compte</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.utilisateurs.map((utilisateur) => {

                    if (this.state.filterUtilisateursName) {
                      if (utilisateur.Name.toLowerCase().search(this.state.filterUtilisateursName.toLowerCase()) === -1) {
                        return false;
                      }
                    }

                    if (this.state.filterUtilisateurSite) {
                      var sitesUsersFind = lodash.findIndex(utilisateur.Sites, (site) => {
                        return parseInt(site.IDSite, 10) === parseInt(this.state.filterUtilisateurSite, 10)
                      })
                      if (sitesUsersFind <= 0) {
                        return null;
                      }
                    }

                    return (<tr key={utilisateur.IDPeople}>
                      <td>{utilisateur.IDPeople}</td>
                      <td>{utilisateur.Name}</td>
                      <td>{utilisateur.BasePVO}</td>
                      <td>{utilisateur.NickName}</td>
                      <td>{utilisateur.LoginInterface}</td>
                      <td>{utilisateur.PasswordInterface}</td>
                      <td className='text-center'>
                        {utilisateur.BasePVO && !this.state.status[utilisateur.IDPeople] ?
                          <i className='fa fa-spinner fa-spin fa-fw'></i>
                        : this.displayStatus(utilisateur)}
                      </td>
                      <td>
                        <Link to={'/interfaces/planetVO/utilisateurs/'+utilisateur.IDPeople} className='btn btn-primary btn-sm'>
                          <i className="fa fa-pencil"></i>
                        </Link>
                        &nbsp;
                        {utilisateur.BasePVO ?
                          <button type="button" className='btn btn-danger btn-sm' data-toggle="tooltip" title="Supprimer la configuration de l'utilisateur" onClick={this.deleteConfUser.bind(this, utilisateur.IDPeople)}>
                            <i className="fa fa-trash"></i>
                          </button>
                        : null}
                      </td>
                    </tr>)
                })}
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>);
  }
}

export default withRouter(Index);
