import React, { useEffect, useState } from "react"
import { SiteDBS, SiteESBO } from "./DBSSiteUtils"
import BackOfficeAPIClient from "../../../clients/BackofficeAPIClient"
import lodash from "lodash"

interface Props {
    backofficeApiClient : BackOfficeAPIClient,
    fetchInProgressConfig : boolean
}
export default function ListDBS(props: Props) {

    const [sitesDBS, setSitesDBS] = useState<SiteDBS[]>([])
    const [sitesESBO, setSitesESBO] = useState<SiteESBO[]>([])
    const [updated, setUpdated] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [alert, setAlert] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        fetchSiteESBO(true)
    }, [])

    useEffect(() => {
        fetchSiteDBS(true)
    }, [props.fetchInProgressConfig])

    const fetchSiteDBS = async (withLoading: boolean) => {
        if (withLoading) {  //  setFetchInProgressConfig(true); }
            await props.backofficeApiClient
                       .get<any>("/interfaces/dbs/sitesDBS")
                       .then(result => {
                           const sitesDBS = result.sitesDBS;
                           const sites = lodash.sortBy(sitesDBS, (site) => {
                               return (site.id+" "+site.company_name.trim()+" "+site.name).toLowerCase();
                           }) as SiteDBS[];
                           setSitesDBS(sites);
                       })
                       .catch(err => {
                           console.log(err)
                           setSitesDBS([]);
                           // window.alert("Erreur lors de la récupération des sites DBS")
                       })
            ;

        }
    }
    const fetchSiteESBO = async (withLoading: boolean) =>{
        if (withLoading) {  //  setFetchInProgressConfig(true); }
            try {
                const resultSites = await props.backofficeApiClient.get<any>("/interfaces/dbs/sites");
                const siteESBO = lodash.sortBy(resultSites.sites, (site) => {
                    return site.Libelle_Plaque + ' ' + site.Descr
                }) as SiteESBO[];
                setSitesESBO(siteESBO);
            } catch {
                throw new Error("Erreur lors de la récupération des sites ESBO")
            } finally {
                if (withLoading) {
                    //setFetchInProgressConfig(false); }
                }
            }
        }
    }
    const onChangeSiteDBS = (e :any) => {
        const id = e.target.id
        const value = e.target.value
        if(value !== 0){
            setSitesESBO(sitesESBO.map((site) =>
                site.IDSite == id ? { ...site,update :true, IdSiteDBS : value } : site
            ) as SiteESBO[]);
        }else{
            setSitesESBO(sitesESBO.map((site) =>
                site.IDSite == id ? { ...site,update :true, IdSiteDBS: 0 } : site
            )as SiteESBO[]);
        }
        setUpdated(true)
    }

    const submitConfig = (e: any) => {
        e.preventDefault()
        window.scroll(0, 0)
        const hasDuplicate = checkDuplicates(sitesESBO, "IdSiteDBS")
        if (hasDuplicate !== false) {
            window.alert('Attention, il existe ' + hasDuplicate.length +
                " doublon(s) dans les correspondances :\nLes identifiants concernés : " +
                JSON.stringify(hasDuplicate),
            )
            return
        }
        props.backofficeApiClient
             .put<any>("/interfaces/DBS/sites", sitesESBO.filter(s => s.update))
             .then<any>(response => {
                 fetchSiteESBO(false)
                 setAlert("success")
             })
             .catch(err => {
                 console.error(err)
                 setAlert('error')
             })

    }

    const checkDuplicates = (arr: SiteESBO[], key: string) => {
        // Utiliser reduce pour compter les occurrences de chaque valeur du champ "key"
        const occurrences = arr.reduce((acc: any, obj: any) => {
            const value = obj[key] // Obtenir la valeur du champ "key"
            if (value && value !== "0") {  // Vérifier si la valeur existe
                acc[value] = (acc[value] || 0) + 1 // Incrémenter le compteur pour cette valeur
            }
            return acc
        }, {})

        // Vérifier si une valeur apparaît plus d'une fois
        const duplicates = Object.keys(occurrences).filter(key => occurrences[key] > 1)

        return duplicates.length > 0 ? duplicates : false
    }

    if(sitesDBS.length === 0){
        return <>
            <div className='container-fluid text-center'><i className="fa fa-refresh fa-spin fa-2x"></i></div>
        </>
    }

    return (
        <div className='container-fluid mb-4'>
            {alert === 'success' ?
                <div className="alert alert-success" role="alert">Les modifications ont été prises en comptes.</div> : null}
            {alert === 'error' ?
                <div className="alert alert-danger" role="alert">Une erreur est survenue lors de la modification{errorMessage ? " : "+errorMessage : ""}</div> : null}
            <form onSubmit={submitConfig}>

                <div className="row">
                    <div className="col mt-1 mb-2">
                        <table className="table table-sm table-striped">
                            <thead>
                            <tr>
                                <th>Société</th>
                                <th>Site</th>
                                <th style={{width:'400px'}}>Site DBS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sitesESBO.map((site,index)=>{
                                return (
                                    <tr key={index}>
                                        <td>{site.Libelle_Plaque}</td>
                                        <td>{site.Descr}</td>
                                        <td>
                                            <select className="form-control form-control-sm" style={{width:'400px'}} id={site.IDSite} value={site.IdSiteDBS ? site.IdSiteDBS : 0} onChange={onChangeSiteDBS}>
                                                <option value={0}>-</option>
                                                {sitesDBS.map((dbs,index)=>{
                                                    return <option key={index} value={dbs.id}>{dbs.id} - {dbs.company_name.toUpperCase()} "{dbs.name}"</option>
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>

                {updated ?
                    <div className="row mb-2" style={{ marginTop: '15px' }}>
                        <div className="col text-center">
                            <button type="submit" className="btn btn-primary">
                                {saving ?
                                    <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                                    : null}
                                Enregistrer
                            </button>
                        </div>
                    </div>
                    : null}
            </form>
        </div>
    );
}