import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codesOP: [],
      filterDescription: '',
    }
  }

  componentWillMount() {
    this.fetchCodesOP();
  }

  fetchCodesOP() {
    fetch(localStorage.backoffice_api + '/api/codesOP', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        codesOP: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  deleteCodeOP(IDCodeOP) {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement ce code OP ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/codesOP/'+IDCodeOP, {
      method: 'DELETE',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      
      this.fetchCodesOP();
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Codes OP</h2>

            <br />

            <div>
              <Link to='/codesOP/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>


              <div className='float-right'>
                <div className="form-inline float-left" style={{ marginTop: '2px' }}>
                  <div className="input-group">
                    <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                    <input type='search' className='form-control form-control-sm float-right' value={this.state.filterDescription} onChange={(event) => this.setState({filterDescription: event.target.value})} placeholder='Filtre par description' />
                  </div>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Code OP</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.codesOP.map((codeOP) => {
                  if (this.state.filterDescription) {
                    if (codeOP.COP_Description.toLowerCase().search(this.state.filterDescription.toLowerCase()) === -1) {
                      return false;
                    }
                  }

                  return (<tr key={codeOP.COP_IDCodeOP}>
                    <td>{codeOP.COP_IDCodeOP}</td>
                    <td>{codeOP.COP_Code}</td>
                    <td>{codeOP.COP_Description}</td>
                    <td className='text-right'>
                      <Link to={'/codesOP/'+codeOP.COP_IDCodeOP} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                      &nbsp;
                      <a onClick={this.deleteCodeOP.bind(this, codeOP.COP_IDCodeOP)} className='btn btn-danger btn-sm text-white'>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
