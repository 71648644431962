import React, { Component } from 'react';

var lodash = require('lodash')
var generator = require('generate-password');

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      wsimport: {
        Login: '',
        Password: '',
        IDPlaque: null,
        IDSite: null,
      },
      fetchInProgress: false,
      societes: [],
      sites: [],
      partenaire:'',
    }

    this.partenaires = [
      {'prefix':'GS','libelle':'GarageScore'},
      {'prefix':'FID','libelle':'FidCard'},
    ]
  }

  componentWillMount() {
    this.fetchSocietes();
    this.fetchSites();
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var societes = lodash.sortBy(json, (societe) => {
        return societe.Libelle;
      })

      this.setState({
        societes: societes,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSites() {
    fetch(localStorage.backoffice_api + '/api/sites', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var sites = lodash.sortBy(json, (site) => {
        return site.Libelle;
      })

      this.setState({
        sites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generatePassword() {
    var password = generator.generate({
        length: 10,
        numbers: true
    });
    var wsimport = this.state.wsimport
    wsimport['Password'] = password;

    this.setState({
      wsimport: wsimport,
    });
  }

  onChange(event) {
    var name = event.target.name
    var value = event.target.value

    var wsimport = this.state.wsimport

    wsimport[name] = value

    this.setState({
      wsimport: wsimport,
    }, () => {
      if(name === 'Login')
        this.generateLogin()
    })
  }

  onChangePartenaire(event){
    this.setState({
      partenaire: event.target.value
    }, () => this.generateLogin())
  }

  generateLogin(){
    var wsimport = this.state.wsimport
    var login = wsimport.Login;
    var partenaire = this.state.partenaire;
    var firstUnderscore = login.indexOf('_');
    if(firstUnderscore !== -1)
      login = partenaire+login.substr(firstUnderscore+1);
    else 
      login = partenaire+login;

    wsimport.Login = login;

    this.setState({
      wsimport: wsimport,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/wsimport', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.wsimport)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      this.props.history.push('/wsimport')

    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.societes || !this.state.sites) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>WSImport (Importation des leads)</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDPlaque" className="col-sm-3 col-form-label">Société</label>
                  <div className="col-sm-6">
                    <select value={this.state.wsimport.IDPlaque ? this.state.wsimport.IDPlaque : ''} onChange={this.onChange.bind(this)} id='IDPlaque' name="IDPlaque" className="form-control form-control-sm" required="required">
                      <option value=''></option>
                      {this.state.societes.map((societe, i) => {
                        return <option key={societe.IDPlaque} value={societe.IDPlaque}>{societe.Libelle}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col">
                {this.state.wsimport.IDPlaque ?
                  <div className="form-group row">
                    <label htmlFor="IDSite" className="col-sm-3 col-form-label">Site</label>
                    <div className="col-sm-6">
                      <select value={this.state.wsimport.IDSite ? this.state.wsimport.IDSite : ''} onChange={this.onChange.bind(this)} id='IDSite' name="IDSite" className="form-control form-control-sm">
                        <option value=''></option>
                        {this.state.sites.map((site, i) => {

                          if (parseInt(site.IDPlaque) !== parseInt(this.state.wsimport.IDPlaque)) {
                            return null;
                          }

                          return <option key={site.IDSite} value={site.IDSite}>{site.Descr} ({site.Code})</option>
                        })}
                      </select>
                    </div>
                  </div>
                : null}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDPlaque" className="col-sm-3 col-form-label">Partenaire</label>
                  <div className="col-sm-6">
                    <select value={this.state.partenaire} onChange={this.onChangePartenaire.bind(this)} name="partenaire" className="form-control form-control-sm">
                      <option value=''></option>
                      {this.partenaires.map((partenaire) => {
                        return <option key={partenaire.prefix} value={partenaire.prefix+'_'}>{partenaire.libelle}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col">
                
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Login" className="col-sm-3 col-form-label">Login</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Login" name="Login" value={this.state.wsimport.Login} onChange={this.onChange.bind(this)} required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Password" className="col-sm-3 col-form-label">Password</label>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-10">
                        <input type="text" className="form-control form-control-sm" id="Password" name="Password" value={this.state.wsimport.Password} onChange={this.onChange.bind(this)} required='required' />
                      </div>
                      <div className="col-sm-2 pl-0">
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => this.generatePassword()}>Générer un mot de passe</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col text-center">
                <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                  {this.state.fetchInProgress ?
                    <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                  : null}
                  Ajouter
                </button>
              </div>
            </div>
        </form>
      </div>
    );
  }
}

export default Form;
