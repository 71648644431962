import { GrilleMalusMasseValueDTO } from "./GrilleMalusMasseValueDTO"

export class GrilleMalusMasseDTO
{
    IDGrilleMalusMasse?: number
    Libelle: string = ""
    DateDebut?: string
    DateFin?: string
    Values: GrilleMalusMasseValueDTO[]

    constructor() {
        this.Values = [ new GrilleMalusMasseValueDTO() ]
    }
}