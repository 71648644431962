import React, { Component } from 'react';
var lodash = require('lodash')

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      config: null,
      societes: null,
      saving: false,
      updated: false
    }
  }

  componentWillMount() {
    this.fetchConfig();
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/emailSMS/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        config: json.config,
        societes: json.societes
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeConfig(name, value) {
    var config = this.state.config;
    config[name] = value;
    this.setState({
      updated: true,
      config: config
    });
  }

  submitConfig(event) {
    event.preventDefault();

    if(this.state.config.GRP_ModuleEmailSMS && !this.canEnableEmailSMS()){
      alert("Toute la configuration doit être effectuée pour pouvoir activer le module Email/SMS");
      return false;
    }

    this.setState({
      saving: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/emailSMS/config', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify({
        config: this.state.config,
        societes:this.state.societes
      })
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          saving: false,
        })
        throw Error(response);
      }

      this.setState({
        saving: false,
        config: null,
        societes:[]
      }, () => this.fetchConfig());
    })
    .catch((error) => {
      console.log(error)
    });
  }

  canEnableEmailSMS(){
    for(var s in this.state.societes){
      if(!this.state.societes[s].client_id || !this.state.societes[s].client_secret)return false;
    }
    return (
      this.state.config.GRP_SMTP_Host && this.state.config.GRP_SMTP_Port && this.state.config.GRP_SMTP_Login && this.state.config.GRP_SMTP_Password && this.state.config.GRP_SMTP_From
    );
  }

  enableEmailSMS(isEnabled) {
    var config = this.state.config;
    config.GRP_ModuleEmailSMS = isEnabled;
    this.setState({
      config:config,
      updated: true
    });
  }

  setClientID(IDPlaque, value){
    var societes = this.state.societes;
    var idx = lodash.findIndex(societes, {IDPlaque:IDPlaque});
    societes[idx].client_id = value;
    societes[idx].updated = true;

    this.setState({
      societes: societes,
      updated: true,
    });
  }

  setClientSecret(IDPlaque, value){
    var societes = this.state.societes;
    var idx = lodash.findIndex(societes, {IDPlaque:IDPlaque});
    societes[idx].client_secret = value;
    societes[idx].updated = true;

    this.setState({
      societes: societes,
      updated: true,
    });
  }

  render() {

    if (!this.state.config) {
      return null;
    }

    return (
      <div className='container-fluid'>

          <h2>Module Email/SMS</h2>
          <br />

          <form onSubmit={(e) => this.submitConfig(e)}>
            <div className="row mb-3">
              <div className="col">
                <div className="card">
                  <div className="card-body p-2 pl-4 pt-3">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="GRP_ModuleEmailSMS" checked={this.state.config.GRP_ModuleEmailSMS} onChange={(e) => this.enableEmailSMS(e.target.checked)} />
                        &nbsp; Activer le module Email/SMS
                        <small className="form-text text-muted">Si coché : activation du module d'envoi de messages (email/sms)<br/>
                        Cela nécessite que les configurations ci-dessous (Email et SMS) aient été effectuées</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col mb-2">
                <div className="card">
                  <div className="card-header">
                    SMTP (Emails)
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group row">
                          <label htmlFor="GRP_SMTP_Host" className="col-sm-3 col-form-label">Host</label>
                          <div className="col-sm-9">
                            <input type="text" required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" id="GRP_SMTP_Host" name="GRP_SMTP_Host" value={this.state.config.GRP_SMTP_Host ? this.state.config.GRP_SMTP_Host : ''} onChange={(e) => this.changeConfig(e.target.name, e.target.value)} maxLength='255'/>
                            <small className="form-text text-muted">Adresse du serveur SMTP</small>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-group row">
                          <label htmlFor="GRP_SMTP_Port" className="col-sm-3 col-form-label">Port</label>
                          <div className="col-sm-9">
                            <input type="text" required={this.state.config.GRP_ModuleEmailSMS} pattern="[0-9]{1,5}" className="form-control form-control-sm" id="GRP_SMTP_Port" name="GRP_SMTP_Port" value={this.state.config.GRP_SMTP_Port ? this.state.config.GRP_SMTP_Port : ''} onChange={(e) => this.changeConfig(e.target.name, e.target.value)} maxLength='5' />
                            <small className="form-text text-muted">Port du serveur SMTP</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group row">
                          <label htmlFor="GRP_SMTP_Login" className="col-sm-3 col-form-label">Login</label>
                          <div className="col-sm-9">
                            <input type="email" required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" id="GRP_SMTP_Login" name="GRP_SMTP_Login" value={this.state.config.GRP_SMTP_Login ? this.state.config.GRP_SMTP_Login : ''} onChange={(e) => this.changeConfig(e.target.name, e.target.value)} maxLength='255' />
                            <small className="form-text text-muted">Login de connexion au serveur SMTP</small>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-group row">
                          <label htmlFor="GRP_SMTP_Password" className="col-sm-3 col-form-label">Password</label>
                          <div className="col-sm-9">
                            <input type="text" required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" id="GRP_SMTP_Password" name="GRP_SMTP_Password" value={this.state.config.GRP_SMTP_Password ? this.state.config.GRP_SMTP_Password : ''} onChange={(e) => this.changeConfig(e.target.name, e.target.value)} maxLength='255' />
                            <small className="form-text text-muted">Mot de passe associé au login</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group row">
                          <label htmlFor="GRP_SMTP_From" className="col-sm-3 col-form-label">From</label>
                          <div className="col-sm-9">
                            <input type="text" required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" id="GRP_SMTP_From" name="GRP_SMTP_From" value={this.state.config.GRP_SMTP_From ? this.state.config.GRP_SMTP_From : ''} onChange={(e) => this.changeConfig(e.target.name, e.target.value)} maxLength='255' />
                            <small className="form-text text-muted">Adresse d'expédition des emails</small>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Utiliser SSL</label>
                          <div className="col-sm-9">
                            <label className="form-check-label mb-3">
                              <input className="form-check-input" type="checkbox" name="GRP_SMTP_UseSSL" checked={this.state.config.GRP_SMTP_UseSSL} onChange={(e) => this.changeConfig(e.target.name, e.target.checked)} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className="row">
            <div className="col mt-2 mb-2">
              <div className="card">
                <div className="card-header">
                  API Digitaleo (SMS)
                </div>
                <div className="card-body">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>Société</th>
                        <th>client_id</th>
                        <th>client_secret</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.societes.map((societe) => {
                      return (
                        <tr key={societe.IDPlaque}>
                          <td>{societe.Libelle}</td>
                          <td><input required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" placeholder="client_id" type="text" value={societe.client_id?societe.client_id:''} onChange={(e) => this.setClientID(societe.IDPlaque, e.target.value)}/></td>
                          <td><input required={this.state.config.GRP_ModuleEmailSMS} className="form-control form-control-sm" placeholder="client_secret" type="text" value={societe.client_secret?societe.client_secret:''} onChange={(e) => this.setClientSecret(societe.IDPlaque, e.target.value)}/></td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.state.updated ?
              <div className="row mb-2" style={{ marginTop: '15px' }}>
                <div className="col text-center">
                  <button disabled={this.state.saving} type="submit" className="btn btn-primary">
                    {this.state.saving ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>);

  }
}

export default Form;
