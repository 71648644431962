import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './Cloture.css';

var moment = require('moment');

class Cloture extends Component {

  constructor(props) {
    super(props);

    this.state = {
      utilisateurs: [],
      usersSelect: [],
      recapitulatif: [],
      nextActive: false,
      dateOK: false,
      cloturer: false,
      date: '',
      answer: '',
      maxDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    }
  }
  
 //recuperer les utilisateurs ayant des rapports en cours
  fetchUtilisateurs() {
    fetch(localStorage.backoffice_api + '/api/cloture?date='+this.state.date, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
          utilisateurs: json
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  //supprime une personne selectionné et supprime le recapitulatif
  delete(e){
    var selects = this.state.usersSelect;
    var users = this.state.utilisateurs;
    var newSelect = [];

    selects.map((userS, i) => {
      if(userS.ID != e.target.value){
        newSelect.push(userS)
      }
      if(userS.ID == e.target.value){
        users.push(userS)
      }
    });

    this.setState({
      usersSelect: newSelect,
      utilisateurs: users,
      recapitulatif: [],
      cloturer: false
    })
  }

  //verifie si la date choisie est bien inférieur à un mois
  checkDate(e){
    this.setState({
      utilisateurs: []
    })

    var today = moment().subtract(1, 'month').format('YYYY-MM-DD');
    var choix = moment(e.target.value).format('YYYY-MM-DD')

    if(choix < today || choix == today){
      this.setState({
        nextActive: true,
        dateOK: true,
        date: choix
      },()=>{
        this.fetchUtilisateurs();
      })
    }else{
      this.setState({
        nextActive: false,
        dateOK: false
      })
    }
    
  }

  //ajoute dans la liste, la personne selectionné
  addSelected(e){
    var users = this.state.utilisateurs;
    var newUser = [];
    var selects = this.state.usersSelect
   
    users.map((user, i) => {
      if(user.ID != e.target.value){
        newUser.push(user)
      }
      if(user.ID == e.target.value){
        selects.push(user)
      }
    });

    this.setState({
      utilisateurs: newUser,
      usersSelect: selects
    })
  }
  
  //recapitulatif du nombre de rapport qui vont être fermé par personne
  recapitulatif(event) {
    event.preventDefault()

    fetch(localStorage.backoffice_api + '/api/cloture/recapitulatif', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'users': this.state.usersSelect,
        'date': this.state.date
      })
    })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      this.setState({
        recapitulatif: json,
        cloturer: true
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

//cloture les rapports puis les affaires
  cloturer(event) {
    event.preventDefault()

    fetch(localStorage.backoffice_api + '/api/cloture', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        'users': this.state.usersSelect,
        'date': this.state.date
      })
    })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      this.setState({
        answer: json,
        cloturer: true,
        usersSelect: [],
        nextActive: false,
        recapitulatif: [],
        date: '',
        cloturer: false,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className='col-3'>
            <h2>Clôture des rapports</h2>
            <div className='form-group'>
              <label htmlFor="exampleInputEmail1">Date max pour clôturer les rapports </label>
              <input  max={this.state.maxDate} onChange={this.checkDate.bind(this)} type="date" className={this.state.dateOK ? 'form-control is-valid' : 'form-control is-invalid'} id="dateEpuration" name="dateEpuration"></input>
            </div>
            <div className='form-group'>
              <select onChange={this.addSelected.bind(this)} id='utilisateurs' name="utilisateurs" className="form-control form-control-sm" value="default">
                {this.state.dateOK ? <option value='default'>Selectionner un utilisateur</option> :<option value='default'>Selectionner une date avant de choisir les utilisateurs</option>}
                {this.state.utilisateurs.map((user, i) => {
                    return <option key={i} value={user.ID}>{user.Name}</option>
                })}
              </select>
            </div>
            <div className={this.state.usersSelect.length > 0 ? 'form-control selected is-valid' : 'form-control selected is-invalid'}>
              <ul className="list-group">
                {this.state.usersSelect.map((user, i) => {
                  return <li key={i} className="list-group-item" value={user.ID}>{user.Name}
                  <button type="button" onClick={this.delete.bind(this)} value={user.ID} className="btn emp-purple white right" >Supprimer</button>
                </li>
                })}                
              </ul>
            </div>
            <div className='form-group'>
              <form onSubmit={(e) => this.recapitulatif(e)}>
                <button type="submit" className="btn emp-purple white margin" disabled={!this.state.nextActive || this.state.usersSelect.length==0 ? 'disabled' : ''}>Suivant</button>
              </form>    
            </div>  
          </div>
          <div className='col-9'>
            <h2>Récapitulatif</h2>
            <div className='form-control' id="usersSelected">
            {this.state.answer.success  ? 
              <div className="alert alert-success" role="alert">
              {this.state.answer.success}
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
            :null }
            {this.state.answer.success && this.state.answer.rapports ==1 ? 
              <div className="alert alert-success" role="alert">
              {this.state.answer.rapports} rapport a été clôturé.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
            :null }
            {this.state.answer.success && this.state.answer.rapports >1 ? 
              <div className="alert alert-success" role="alert">
              {this.state.answer.rapports} rapports ont été clôturés.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
            :null }
            {this.state.answer.success && this.state.answer.affaires==1 ? 
              <div className="alert alert-success" role="alert">
              {this.state.answer.affaires} affaire a été clôturée.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
            :null }
            {this.state.answer.success && this.state.answer.affaires>1 ? 
              <div className="alert alert-success" role="alert">
              {this.state.answer.affaires} affaires ont été clôturées.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
            :null }
            {this.state.recapitulatif != null ? this.state.recapitulatif.map((user, i) => {
              if(user.Nombre == 1){
                return <div className="alert alert-info alert-dismissible fade show" key={i} role="alert">{user.Nombre} rapport va être cloturé pour : {user.Name}</div>
              }else{
                return <div className="alert alert-info alert-dismissible fade show" key={i} role="alert">{user.Nombre} rapports vont être cloturés pour : {user.Name}</div>
              }   
                })
              :null}
            </div>
            <form onSubmit={(e) => this.cloturer(e)}>
              <div className='form-group' id="cloturer">
                <button type="submit" className="btn emp-purple white margin" disabled={!this.state.cloturer ? 'disabled' : ''}>Clôturer</button>
              </div>  
            </form> 
          </div>
        </div>
      </div>
    );
  }
}

export default Cloture;

