export class SiteDBS {
    id!: string
    name!: string
    company_id !: number
    company_name!: string
}
export class ConfigDBS {
    GRP_DBS_ClientId!: string
    GRP_DBS_ClientSecret!: string
    constructor() {
        this.GRP_DBS_ClientId ="";
        this.GRP_DBS_ClientSecret ="";
    }

}
export class SiteESBO {
    IDSite!: string
    Descr!: string
    Libelle_Plaque!: string
    update?: boolean
    IdSiteDBS?: string
    constructor(data?: Partial<SiteESBO>) {
        Object.assign(this, data)
        this.update = false
        this.IdSiteDBS = "0"
    }
}