import React, { Component } from 'react';
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      filterName: '',
    }
  }

  componentWillMount() {
    this.fetchValues();
  }

  fetchValues() {
    fetch(localStorage.backoffice_api + '/api/table/MoyenPaiement', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var values = lodash.sortBy(json, (value) => {
        return value.Descr;
      })

      this.setState({
        values: values,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Moyens de paiement</h2>

            <br />

            <div>
              <Link to='/table/MoyenPaiement/create' className='btn btn-light'>
                <i className="fa fa-plus"></i> Ajouter
              </Link>

            </div>

            <br />

            <table className="table table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Libellé</th>
                  <th>Afficher champs numéro de chèque</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.values.map((v) => {
                  return (<tr key={v.IDMoyenPaiement}>
                    <td>{v.IDMoyenPaiement}</td>
                    <td>{v.Descr}</td>
                    <td>{v.AfficherNumCheque ? 'oui' : 'non'}</td>
                    <td>
                      <Link to={'/table/MoyenPaiement/'+v.IDMoyenPaiement} className='btn btn-primary btn-sm'>
                        <i className="fa fa-pencil"></i>
                      </Link>
                    </td>
                  </tr>)
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
